/**
 * System libraries
 */
import React from "react";

/**
 * Bootstrap
 */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ElementsLoader() {
  return (
    <Container>
      <Row>
        <Col>
          <div className="text-center mt-5">
            <div
              className="spinner-grow text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
