/**
 * Modules
 */
import { createDocument } from "components/Document/processor";

import { TextSelection } from "prosemirror-state";
// import { wrapItem, blockTypeItem, icons, MenuItem, Dropdown, DropdownSubmenu } from "prosemirror-menu" // , joinUpItem, liftItem, selectParentNodeItem // , undoItem, redoItem
import {
  wrapItem,
  blockTypeItem,
  MenuItem,
  Dropdown,
} from "../menu/menu-build"; // icons, , DropdownSubmenu
//import {NodeSelection} from "prosemirror-state"
import { toggleMark } from "prosemirror-commands";
// import {wrapInList} from "prosemirror-schema-list"
import { convertList } from "components/ProseMirror/commands/commandsLists";
import { TextField, openPrompt } from "../prompt";
import { updateMark } from "../Utils";

import { myToggleMark } from "../commands/commandsDefault";

// Helpers to create specific types of items

/*
function canInsert(state, nodeType) {
  let $from = state.selection.$from
  for (let d = $from.depth; d >= 0; d--) {
    let index = $from.index(d)
    if ($from.node(d).canReplaceWith(index, index, nodeType)) return true
  }
  return false
}
*/

/* function insertImageItem(nodeType) {
  return new MenuItem({
    title: "Insert image",
    label: "Image",
    enable(state) { return canInsert(state, nodeType) },
    run(state, _, view) {
      let {from, to} = state.selection, attrs = null
      if (state.selection instanceof NodeSelection && state.selection.node.type == nodeType)
        attrs = state.selection.node.attrs
      openPrompt({
        title: "Insert image",
        fields: {
          src: new TextField({label: "Location", required: true, value: attrs && attrs.src}),
          title: new TextField({label: "Title", value: attrs && attrs.title}),
          alt: new TextField({label: "Description",
                              value: attrs ? attrs.alt : state.doc.textBetween(from, to, " ")})
        },
        callback(attrs) {
          view.dispatch(view.state.tr.replaceSelectionWith(nodeType.createAndFill(attrs)))
          view.focus()
        }
      })
    }
  })
} */

function cmdItem(cmd, options) {
  let passedOptions = {
    label: options.title,
    run: cmd,
  };
  for (let prop in options) passedOptions[prop] = options[prop];
  if ((!options.enable || options.enable === true) && !options.select)
    passedOptions[options.enable ? "enable" : "select"] = (state) => cmd(state);

  return new MenuItem(passedOptions);
}

function markActive(state, type) {
  let { from, $from, to, empty } = state.selection;

  // No selection
  if (empty) {
    return type.isInSet(state.storedMarks || $from.marks());

    // Is a selection
  } else {
    // console.log('markActive.Selection', state.doc.rangeHasMark(from, to, type), from, to, type)
    return state.doc.rangeHasMark(from, to, type);
  }
}

/**
 * Search for a mark of a given type and return it as an object
 * @param {object} State
 * @param {object} Mark type
 * @return {object} Mark found (false if none)
 */
function getMarks(state, type) {
  let { from, $from, to, empty } = state.selection;
  // console.log('getMarks', empty, state, $from.marks(), from, to)

  // Selection is empty?
  if (empty) return type.isInSet(state.storedMarks || $from.marks());
  // We have a selection
  else {
    let found = false;
    if (to > from)
      state.doc.nodesBetween(from, to, (node) => {
        if (!found) {
          const markFound = type.isInSet(node.marks);
          if (markFound) found = markFound;
        }
        return !found;
      });
    return found;
  }
}

/**
 * Search for a mark of a given type and return it as an object
 * @param {object} State
 * @param {object} Mark type
 * @return {object} Mark found (false if none)
 */
function getAllMarks(state, type) {
  let { from, $from, to, empty } = state.selection;
  // console.log('getMarks', empty, state, $from.marks(), from, to)

  // Selection is empty?
  if (empty) {
    const currMarks = state.storedMarks || $from.marks();
    return type.isInSet(currMarks) ? currMarks : false;

    // We have a selection
  } else {
    let found = [];
    if (to > from)
      state.doc.nodesBetween(from, to, (node) => {
        // if (!found) {
        const markFound = type.isInSet(node.marks);
        if (markFound) found.push(node.marks); // found = markFound
        // }
        // return !found
        return true;
      });
    return found;
  }
}

function markItem(markType, options) {
  let passedOptions = {
    active(state) {
      return markActive(state, markType);
    },
    enable: true,
  };
  for (let prop in options) passedOptions[prop] = options[prop];
  return cmdItem(toggleMark(markType), passedOptions);
}

// :: (NodeType, Object) → MenuItem
// Build a menu item for changing the type of the textblock around the
// selection to the given type. Provides `run`, `active`, and `select`
// properties. Others must be given in `options`. `options.attrs` may
// be an object to provide the attributes for the textblock node.
// !!! AK comment: for "todo_list", "bullet_list" and "ordered_list" nodes ONLY
function wrapListItem(nodeType, options) {
  let passedOptions = {
    /**
     * Check if this menu item could be shown as active for current cursor position
     * @param {Object} Current editor state
     * @return {Boolean} True if menu item could be active
     */
    active: function active(state) {
      /*
				Current state we are in (where is the cursor?)
			*/

      // console.log('MenuSchema.wrapListItem', nodeType?.name)

      const ref = state.selection;
      const $from = ref.$from;
      const to = ref.to;
      let node = ref.node;

      /*
				This is a Single cursor position
			*/

      if (node) {
        // console.log('MenuSchema.wrapListItem.node', node, node.hasMarkup(nodeType, options.attrs))
        return node.hasMarkup(nodeType, options.attrs);
      }

      /*
				This is a Selection
			*/

      // This is a cross-nodes selection, we can't mark this menu item as active
      if (!(to <= $from.end())) return false;

      // I'm inside someting?
      if ($from?.depth >= 3) {
        const parent = $from.node($from.depth - 2);

        // console.log('MenuSchema.wrapListItem.parent', $from.depth, nodeType?.name, parent?.type?.name, parent2?.type?.name)

        // My parent is a list? And I'm of the same type?
        if (
          (parent?.type?.name === "todo_list" ||
            parent?.type?.name === "bullet_list" ||
            parent?.type?.name === "ordered_list") &&
          parent?.type?.name === nodeType?.name
        ) {
          return true;
        }
      }

      return false;
    },
    /**
     * Check if this menu item could be shown as inactive (disabled) for current cursor position
     * @param {Object} Current editor state
     * @return {Boolean} True if menu item could be active
     */
    enable: function enable() {
      // state
      /*
				Current state we are in (where is the cursor?)
				COMMENTED FOR NOW, SHOW AVAILABLE FOR ALL CONVERSION TYPES
			* /

			// console.log('MenuBuild.blockTypeItem', nodeType?.name)
			
			const ref = state.selection
			const $from = ref.$from
			// const to = ref.to
			let node = ref.node

			/*
				Selection
			* /

			if (!node) {
				// Take a parent node from the Selection start
				node = $from?.parent

				// Heading -> lists conversion disabled
				if (node.type.name === 'heading') {
					return false
				}
			}
			*/
      return true;
    },
  };
  for (let prop in options) passedOptions[prop] = options[prop];

  /*
		Return the menu item and add "run" command,
		which will run when the menu item is clicked.
	*/
  return cmdItem(
    // Run command when the menu item is clicked
    // OLD run command:wrapInList(nodeType, options.attrs)
    convertList(nodeType, options.attrs),
    // Options
    passedOptions
  );
}

/**
 * Menu and it's items.
 * Based on: https://github.com/prosemirror/prosemirror-menu#user-content-menubar
 * @param {object} documentData
 * @param {object} schema
 */
export function buildMenuItems(documentData, schema) {
  // console.log('buildMenuItems', documentData)

  // Menu
  let menus = {
    // Hover inline menu
    inline: [],
    // Individual items list
    items: {},
  };

  /*
		Block types
	*/

  // Paragraph
  menus.items.Paragraph = blockTypeItem(schema.nodes.paragraph, {
    icon: { dom: submenuItem("text", "Text") },
  });

  // H1
  menus.items.H1 = blockTypeItem(schema.nodes.heading, {
    attrs: { level: 1 },
    icon: { dom: submenuItem("h1", "Heading 1") },
  });

  // H2
  menus.items.H2 = blockTypeItem(schema.nodes.heading, {
    attrs: { level: 2 },
    icon: { dom: submenuItem("h2", "Heading 2") },
  });

  // H3
  menus.items.H3 = blockTypeItem(schema.nodes.heading, {
    attrs: { level: 3 },
    icon: { dom: submenuItem("h3", "Heading 3") },
  });

  // Todo list
  menus.items.TodoList = wrapListItem(schema.nodes.todo_list, {
    attrs: { "data-type": "todo_list" },
    icon: { dom: submenuItem("todo", "To-do list") },
  });

  // Bullet List
  menus.items.BulletList = wrapListItem(schema.nodes.bullet_list, {
    icon: { dom: submenuItem("bulletlist", "Bulleted list") },
  });

  // Ordered List
  menus.items.OrderedList = wrapListItem(schema.nodes.ordered_list, {
    icon: { dom: submenuItem("orderlist", "Numbered list") },
  });

  /*
	// Code
	// menus.items.Code = blockTypeItem(schema.nodes.code_block, {
	menus.items.Code = markItem(schema.marks.code, {
		icon: { dom: submenuItem('code', 'Code') },
	})
	*/

  // Blockquote
  menus.items.BlockQuote = wrapItem(schema.nodes.blockquote, {
    icon: { dom: submenuItem("quote", "Quote") },
  });

  /*
		Separate elements
	*/

  /*
	// HR line
	let hr = schema.nodes.horizontal_rule
	menus.items.HR = new MenuItem({
		title: "Insert horizontal rule",
		label: "Horizontal rule",
		enable(state) { return canInsert(state, hr) },
		run(state, dispatch) { dispatch(state.tr.replaceSelectionWith(hr.create())) }
	})
	*/

  // Link
  menus.items.Link = linkItem(schema.marks.link);

  // New Page
  // console.log('Schema.Menus.DocData', documentData.current)
  menus.items.NewPage = pageItem(
    schema.marks.linkdoc,
    documentData?.current?.knowledgebaseId
      ? documentData.current.knowledgebaseId
      : null
  );

  /*
	// Image
	if (type = schema.nodes.image)
	items.insertImage = insertImageItem(schema.nodes.image)
	*/

  /*
		Marks
	*/

  // Bold
  menus.items.Bold = markItem(schema.marks.strong, {
    icon: {
      dom: renderIcon("bold", "Toggle bold"),
    },
  });

  // Italics
  menus.items.Italics = markItem(schema.marks.em, {
    icon: {
      dom: renderIcon("italics", "Toggle italics"),
    },
  });

  // Underline
  menus.items.Underline = markItem(schema.marks.underline, {
    icon: {
      dom: renderIcon("underline", "Toggle underline"),
    },
  });

  // Code
  menus.items.Code = markItem(schema.marks.code, {
    icon: {
      dom: renderIcon("code", "Toggle code"),
    },
  });

  /*
		Colors
	*/

  menus.items.colorRed = colorItem(schema.marks.color, "red");
  menus.items.colorYellow = colorItem(schema.marks.color, "yellow");
  menus.items.colorGreen = colorItem(schema.marks.color, "green");
  menus.items.colorBlue = colorItem(schema.marks.color, "blue");
  menus.items.colorPurple = colorItem(schema.marks.color, "purple");

  // r.insertMenu = new Dropdown(cut([r.insertImage, r.insertHorizontalRule]), {label: "Insert"})
  // r.typeMenu = new Dropdown(cut([r.makeParagraph, r.makeCodeBlock, r.makeHead1 && new DropdownSubmenu(cut([
  //   r.makeHead1, r.makeHead2, r.makeHead3, r.makeHead4, r.makeHead5, r.makeHead6
  // ]), {label: "Heading", title:"A", css:"color:blue"})]), {label: "Type..."})

  // r.typeMenu = new Dropdown(cut([r.makeParagraph, r.makeCodeBlock, r.makeHead1, r.makeHead2, r.makeHead3, r.makeHead4, r.makeHead5, r.makeHead6]), { label: "Type...", class: "AAA", css: "color:blue;" })
  // console.log(r.typeMenu);

  menus.items.DropdownType = new Dropdown(
    [
      menus.items.Paragraph,
      menus.items.H1,
      menus.items.H2,
      menus.items.H3,
      menus.items.TodoList,
      menus.items.BulletList,
      menus.items.OrderedList,
      // menus.items.Code, // turned OFF for now
      // menus.items.BlockQuote, // turned OFF for now
    ],
    {
      label: "Type...",
      // class: "submenu-label",
      /* css: "color:blue;" */
    }
  );

  /*
		Compile the inline menu
	*/

  menus.inline = [
    // Dropdown part
    [menus.items.DropdownType],
    // Marks
    [
      menus.items.Bold,
      menus.items.Italics,
      menus.items.Underline,
      menus.items.Code,

      // Colors
      menus.items.colorRed,
      menus.items.colorYellow,
      // menus.items.colorGreen,
      menus.items.colorBlue,
      // menus.items.colorPurple,
    ],
    // New Page
    [menus.items.NewPage],
    // Link
    [menus.items.Link],
  ];

  /*
	// Join this block with the block up above into one
	// joinUpItem,
	// Lift this block into up block (was item of a list, become paragraph)
	// liftItem,
	// Select a parent node
	// selectParentNodeItem
	undoItem
	redoItem
	*/

  // Return object containing all menus
  return menus;
}

/**
 * Render icon as svg image
 * @param {string} Icon name (should be placed and named as /images/icons/menu_*.svg)
 * @param {string} Icon title
 */
function renderIcon(path, title, className) {
  // Create img tag
  let img = document.createElement("img");

  // Set title and alt
  img.title = img.alt = title;

  // Set class
  img.className = "menu-item-" + path + (className ? " " + className : "");

  // Set src
  img.src = "/images/icons/menu_" + path + ".svg";

  return img;
}

/**
 * Render menu individual item
 * @param {string} Icon name (should be placed and named as /images/icons/submenu_*.svg)
 * @param {string} Text title
 */
function menuItem(id, title, icon, className) {
  // Create div wrapper tag
  let wrapper = document.createElement("div");
  wrapper.className =
    "wt-menu-inline-item-wrapper wt-menu-inline-item-wrapper-" + id;

  /*
		Icon
	*/

  let img = null;
  if (icon) {
    // Create img tag
    img = document.createElement("img");

    // Set title and alt
    img.title = img.alt = title;

    // Set class
    img.className = "wt-menu-inline-icon-img wt-menu-inline-icon-img-" + icon;

    // Set src
    img.src = "/images/icons/menu_" + icon + ".svg";
  }

  /*
		Text
	*/
  let p = null;
  if (title !== "") {
    p = document.createElement("div");
    p.className = "wt-menu-label" + (className ? " " + className : "");
    var text = document.createTextNode(title);
    p.appendChild(text);
  }

  // Add to div
  if (img) wrapper.appendChild(img);
  if (p) wrapper.appendChild(p);

  return wrapper;
}

/**
 * Render submenu individual item
 * @param {string} Icon name (should be placed and named as /images/icons/submenu_*.svg)
 * @param {string} Text title
 */
function submenuItem(path, title) {
  // Create div wrapper tag
  let wrapper = document.createElement("div");
  wrapper.className = "wt-menu-inline-item-submenu-wrapper";

  /*
		Icon
	*/

  // Create img tag
  let img = document.createElement("img");

  // Set title and alt
  img.title = img.alt = title;

  // Set class
  img.className = "submenu-icon submenu-icon-" + path;

  // Set src
  img.src = "/images/icons/submenu_" + path + ".svg";

  /*
		Text
	*/

  let p = document.createElement("div");
  p.className = "wt-submenu-label";
  var text = document.createTextNode(title);
  p.appendChild(text);

  // Add to div
  wrapper.appendChild(img);
  wrapper.appendChild(p);

  return wrapper;
}

/**
 * Creating a link
 *
 * @param {object} The mark we are working with
 */
function linkItem(markType) {
  return new MenuItem({
    // title: "Add or remove link",
    // icon: icons.link,
    // label: 'Link',
    icon: {
      dom: menuItem("link", "Link", null, "wt-menu-label-link"),
    },
    active(state) {
      return markActive(state, markType);
    },
    enable(state) {
      return !state.selection.empty;
    },
    run(state, dispatch, view) {
      /*
			// If there is a link -> just remove it
			if (markActive(state, markType)) {
				toggleMark(markType)(state, dispatch)
				return true
			} */

      // console.log('LinkMenu.markActive', markType)
      const marksFound = getMarks(state, markType);
      // console.log('LinkMenu.marksFound', marksFound)

      // Menu
      const menu = document.getElementsByClassName("wt-menu-inline");

      // Coordinates
      let rect = null;

      // Position link prompt relative to the menu position and hide the menu
      if (
        menu &&
        menu[0] &&
        menu[0]?.style?.display &&
        menu[0]?.style?.display !== "none"
      ) {
        // Get the coordinates for the menu
        rect = menu[0].getBoundingClientRect();
        // console.log('Menu POS', rect.top, rect.right, rect.bottom, rect.left);

        // Hide the menu
        // menu[0].style.display = "none";
      }

      // Viewport width
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      // Prompt pos
      let promptPosTop = rect?.top ? rect.top + 30 : window.innerHeight / 2;
      let promptPosLeft = rect?.left ? rect.left + 70 : window.innerWidth / 2;
      if (promptPosLeft + 480 >= vw) {
        promptPosLeft = vw - 480 - 20;
      }

      // Open prompt
      openPrompt({
        // title: "Create a link",
        // Position the prompt
        pos: {
          top: promptPosTop,
          left: promptPosLeft,
        },
        fields: {
          href: new TextField({
            label: "Type or paste your link",
            value:
              marksFound &&
              marksFound?.attrs?.href &&
              marksFound.attrs.href !== ""
                ? marksFound.attrs.href
                : "",
            // required: true
          }),
          /* ,
					title: new TextField({label: "Title"}) */
        },
        callback(attrs) {
          // console.log('PromptAttrs', attrs)

          // The link is empty?
          if (!attrs || !attrs?.href || attrs.href === "") {
            // Clear attributes
            // console.log('PromptAttrs.Clear')
            // toggleMark(markType)(state, dispatch)
            updateMark(markType)(state, dispatch);

            // Add/change link
          } else {
            // Store attributes
            // console.log('PromptAttrs.Set')

            // No http prefix?
            if (
              attrs.href.toLowerCase().indexOf("http") !== 0 &&
              attrs.href.toLowerCase().indexOf("/") !== 0
            ) {
              attrs.href = "https://" + attrs.href;
            }

            // toggleMark(markType, attrs)(view.state, view.dispatch)
            updateMark(markType, attrs)(view.state, view.dispatch);
          }

          /* // Set the
					let tr = state.tr
					tr.setSelection(TextSelection.create(tr.doc, )) */

          // Move the cursor to the end of the selection
          let trx = view.state.tr;
          const selection = TextSelection.create(
            view.state.doc,
            state.selection.to
          );
          trx = trx.setSelection(selection);
          view.dispatch(trx.scrollIntoView());

          view.focus();
        },
      });

      // return true
    },
  });
}

/**
 * Create a New Page
 *
 * @param {object} The mark we are working with
 * @param {string} Knowledgebase Id
 */
function pageItem(markType, knowledgebaseId) {
  return markItem(markType, {
    /*
		title: "Create a page",
		icon: {
			width:1000, height:1000,
			path: "M888.4,229.2c-21.3-29-50.9-62.9-83.4-95.4c-32.5-32.5-66.4-62.2-95.4-83.4c-49.4-36.2-73.3-40.4-87-40.4H147.8c-42.2,0-76.6,34.3-76.6,76.6v826.9c0,42.2,34.3,76.6,76.6,76.6h704.4c42.2,0,76.6-34.3,76.6-76.6V316.3C928.8,302.5,924.6,278.6,888.4,229.2z M761.6,177.1c29.4,29.4,52.4,55.9,69.5,77.9H683.8V107.7C705.7,124.7,732.3,147.7,761.6,177.1L761.6,177.1z M867.5,913.4c0,8.3-7,15.3-15.3,15.3H147.8c-8.3,0-15.3-7-15.3-15.3V86.6c0-8.3,7-15.3,15.3-15.3c0,0,474.6,0,474.7,0v214.4c0,16.9,13.7,30.6,30.6,30.6h214.4V913.4z"
		},
		*/
    icon: {
      dom: menuItem("newpage", "", "newpage"),
    },
    enable(state) {
      return !state.selection.empty;
    },
    // On menu button click processor
    run(state, dispatch, view) {
      // We need to run this asynchronously, as we are waiting for Firestore to create a page
      const createPage = async () => {
        // Get actual data
        // const knowledgebaseId =
        // const documentId = documentData.current.id
        // console.log('Menu.Link', documentId) // knowledgebaseId,

        /*
					Remove link
				*/
        if (markActive(state, markType)) {
          // Remove the wrapping tags
          toggleMark(markType)(state, dispatch);

          /*
					Add link
				*/
        } else {
          /*
						Selected Node
					*/

          // Get the node in a selection
          const selectedNode = state.doc.cut(
            state.selection.from,
            state.selection.to
          );

          // Let's take the selected text
          const selectedText = selectedNode.textContent; // state.doc.cut(state.selection.from, state.selection.to).textContent
          // console.log('Menu.Innerlink.Debug', selectedNode, selectedText)

          /*
						Document
					*/

          // Let's search the title
          const targetDocumentId = await createDocument(
            knowledgebaseId,
            selectedText
          );
          // console.log('Menu.Innerlink.Document', selectedText, targetDocumentId)

          /*
						Wrap in tag
					*/

          // Wrap the text in A-tag with properties
          toggleMark(
            markType,
            {
              href: "/document/" + targetDocumentId,
              "data-link-inner": targetDocumentId,
            }
            // toggleMark returns the function which will be run with these params
          )(view.state, view.dispatch);
        }

        // Move the cursor to the end of the selection
        let trx = view.state.tr;
        const selection = TextSelection.create(
          view.state.doc,
          state.selection.to
        );
        trx = trx.setSelection(selection);
        view.dispatch(trx.scrollIntoView());

        return;
      };
      createPage();
    },
  });
}

/**
 * Mark with a color
 * @param {*} markType
 */
function colorItem(markType, color) {
  return new MenuItem({
    // title: "change color",
    // icon: icons.strong,
    icon: {
      dom: renderIcon(color, "Color: " + color, "menu-item-colors"),
    },
    // Is it active?
    active(state) {
      const hasMarks = getAllMarks(state, markType);
      // console.log('MenuInline.colorItem.hasMarks', hasMarks)

      // No marks?
      if (!hasMarks || !Array.isArray(hasMarks) || !hasMarks?.length)
        return false;
      console.log("MenuInline.colorItem.hasMarks", hasMarks);

      // Foreach mark
      for (let currMark in hasMarks) {
        // console.log('MenuInline.colorItem.currMark', color, hasMarks[currMark])
        if (
          !hasMarks[currMark] ||
          !Array.isArray(hasMarks[currMark]) ||
          !hasMarks[currMark]?.length ||
          !hasMarks[currMark][0] ||
          !hasMarks[currMark][0]?.attrs ||
          !hasMarks[currMark][0].attrs?.color
        )
          continue;

        // Has the right color!
        if (hasMarks[currMark][0].attrs.color === color) return true;
      }

      // Color was not found
      return false;

      // console.log('MenuInline.colorItem', state, markType, color)
      // console.log('MenuInline.colorItem.getMarks', getMarks(state, markType))
      // return markActive(state, markType)
    },
    // Is it allowed?
    enable(state) {
      return !state.selection.empty;
    },
    run(state, dispatch, view) {
      /* // Remove the mark
			if (markActive(state, markType)) {
				toggleMark(markType)(state, dispatch)
				return true
			} */

      // Get active color marks as types
      const activeColorTypes = markActiveTypes(state, markType);
      console.log("MenuInline.Colors.Types", activeColorTypes);

      // Has other colors?
      if (activeColorTypes.hasOthers) {
        // Remove all!
        console.log("MenuInline.Colors.RemoveAll");
        toggleMark(markType)(state, dispatch);
        return true;

        // Has black?
      } else if (activeColorTypes.hasBlack) {
        // Remove it AND continue
        // toggleMark(markType)(state, dispatch)
      }

      // Has black or none

      // Set the mark
      console.log("MenuInline.Colors.MarkCurrent");
      // toggleMark(markType, {color:color})(state, dispatch)
      myToggleMark(markType, { color: color })(state, dispatch);
    },
  });
}

/**
 * Search for a mark of a given type and return it as an object
 * @param {object} State
 * @param {object} Mark type
 * @return {object} Mark found (false if none)
 */
function markActiveTypes(state, type) {
  let { from, $from, to, empty } = state.selection;
  // console.log('getMarks', empty, state, $from.marks(), from, to)

  let allMarks = false;

  // Selection is empty?
  if (empty) {
    const currMarks = state.storedMarks || $from.marks();
    allMarks = type.isInSet(currMarks) ? [currMarks] : false;

    // We have a selection
  } else {
    let found = [];
    if (to > from)
      state.doc.nodesBetween(from, to, (node) => {
        // if (!found) {
        const markFound = type.isInSet(node.marks);
        if (markFound) found.push(node.marks); // found = markFound
        // }
        // return !found
        return true;
      });
    allMarks = found;
  }

  // Remove black marks
  let markTypes = {
    hasBlack: false,
    hasOthers: false,
  };

  // Foreach mark
  for (let currMark in allMarks) {
    // console.log('MenuInline.colorItem.currMark', color, hasMarks[currMark])
    if (
      !allMarks[currMark] ||
      !Array.isArray(allMarks[currMark]) ||
      !allMarks[currMark]?.length ||
      !allMarks[currMark][0] ||
      !allMarks[currMark][0]?.attrs ||
      !allMarks[currMark][0].attrs?.color
    )
      continue;

    // Is black
    if (allMarks[currMark][0].attrs.color === "black")
      markTypes.hasBlack = true;
    // Non black
    else markTypes.hasOthers = true;
  }

  return markTypes;
}
