/**
 * System libraries
 */
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * Timeline navigation icon in TopNav
 */
export function NavButtonTimeline(props) {
  /*
		System inits
	*/

  let history = useHistory();
  let location = useLocation();

  /*
		Props
	*/

  // Page loader Toggle function
  const togglePageLoaded = props.togglePageLoaded;

  /**
   * Navigate to the Timeline
   */
  const handleClick = () => {
    // console.log('NavButtonTimeline.handleClick', location)

    // We are already here?
    if (location && location?.pathname && location.pathname === "/") {
      // Do nothing
      // Hide the Page loader
      // console.log('NavButtonTimeline.handleCreate.reload')
      // togglePageLoaded(true)
      // CAN'T DO THIS, AS IT WILL RELOAD THE WHOLE APP!! Full reload
      // history.go(0)
    } else {
      // Show the Page loader
      togglePageLoaded(false);

      // Go to it
      history.push("/");

      // Show the Page loader
      // togglePageLoaded(true)
    }
  };

  /**
   * Button
   */
  return (
    <>
      <button
        type="button"
        className="btn-link wotodo-header__button"
        onClick={handleClick}
      >
        <img
          src="/images/icons/topmenu_timeline.svg"
          className="sidebar-icon-timeline"
          title="Daily notes"
          alt="Daily notes"
        />
      </button>
    </>
  );
}
