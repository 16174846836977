/**
 * System libraries
 */
import { Schema } from "prosemirror-model";

/**
 * ProseMirror, setup
 */
import {
  orderedListSpec,
  bulletListSpec,
  listItemSpec,
} from "components/ProseMirror/schema/lists";
import {
  todoItemSpec,
  todoListSpec,
} from "components/ProseMirror/schema/listsTodo";
// import { hiddenListItemSpec, hiddenListSpec } from 'components/ProseMirror/schema/listsHidden'
import {
  blockElementSpec,
  blockTitleSpec,
} from "components/ProseMirror/schema/block";

// BlockId attr
const getBlockId = (dom) => ({ blockid: dom.getAttribute("data-blockid") });

// :: Object
// [Specs](#model.NodeSpec) for the nodes defined in this schema.
export const nodes = {
  // :: NodeSpec The top level document node.
  doc: {
    content: "block+",
  },

  /*
		Paragraph element
	*/

  paragraph: {
    group: "block",
    content: "inline*",
    /*
			Element attributes
		*/
    attrs: {
      blockid: { default: "" },
    },
    /*
			Parse element on Copy/Paste operation
		*/
    parseDOM: [
      {
        tag: "p",
        getAttrs: getBlockId,
      },
    ],
    /*
			Rules of creating element in DOM
		*/
    toDOM(node) {
      return [
        "p",
        {
          class: "drag-n-drop-parent-node-paragraph",
          "data-blockid": node.attrs.blockid,
        },
        0,
      ];
      /*
			return [
				'div',
				{
					'class': 'wotodo-paragraph',
					'data-blockid': node.attrs.blockid
				},
				[
					'p',
					0
				]
			]
			*/
      /*
			return [
				'p',
				{
					'data-blockid': node.attrs.blockid,
					'class': 'wotodo-paragraph'
				},
				0
			]
			*/
    },
  },

  // :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.
  blockquote: {
    content: "block+",
    attrs: {
      blockid: { default: "" },
    },
    group: "block",
    defining: true,
    parseDOM: [
      {
        tag: "blockquote",
        getAttrs: getBlockId,
      },
    ],
    toDOM(node) {
      return [
        "blockquote",
        {
          "data-blockid": node.attrs.blockid,
          class: "drag-n-drop-parent-node",
        },
        0,
      ];
    },
  },

  // :: NodeSpec A horizontal rule (`<hr>`).
  horizontal_rule: {
    group: "block",
    parseDOM: [{ tag: "hr" }],
    toDOM() {
      return ["hr"];
    },
  },

  // :: NodeSpec A heading textblock, with a `level` attribute that
  // should hold the number 1 to 6. Parsed and serialized as `<h1>` to
  // `<h6>` elements.
  heading: {
    attrs: {
      level: { default: 1 },
      blockid: { default: "" },
    },
    content: "inline*",
    group: "block",
    defining: true,
    parseDOM: [
      { tag: "h1", attrs: { level: 1 }, getAttrs: getBlockId },
      { tag: "h2", attrs: { level: 2 }, getAttrs: getBlockId },
      { tag: "h3", attrs: { level: 3 }, getAttrs: getBlockId },
      /* { tag: "h4", attrs: { level: 4 } },
			{ tag: "h5", attrs: { level: 5 } },
			{ tag: "h6", attrs: { level: 6 } } */
    ],
    toDOM(node) {
      return [
        "h" + node.attrs.level,
        {
          "data-blockid": node.attrs.blockid,
          class: "drag-n-drop-parent-node",
        },
        0,
      ];
      /*
			return [
				'div',
				{
					'class': 'wotodo-heading',
					'data-blockid': node.attrs.blockid
				},
				[
					"h" + node.attrs.level,
					0
				]
			]
			*/
      /*
			return [
				'div',
				{
					'class': 'wotodo-heading',
					'data-blockid': node.attrs.blockid,
					'data-level': node.attrs.level
				},
				0
			]
			*/
    },
  },

  // :: NodeSpec A code listing. Disallows marks or non-text inline
  // nodes by default. Represented as a `<pre>` element with a
  // `<code>` element inside of it.
  code_block: {
    content: "text*",
    attrs: {
      blockid: { default: "" },
    },
    marks: "",
    group: "block",
    code: true,
    defining: true,
    parseDOM: [
      {
        tag: "pre",
        preserveWhitespace: "full",
        getAttrs: getBlockId,
      },
    ],
    toDOM(node) {
      return [
        // "pre", ["code", 0]
        "pre",
        { "data-blockid": node.attrs.blockid },
        ["code", 0],
      ];
    },
  },

  // :: NodeSpec The text node.
  text: {
    group: "inline",
  },

  // :: NodeSpec An inline image (`<img>`) node. Supports `src`,
  // `alt`, and `href` attributes. The latter two default to the empty
  // string.
  image: {
    inline: true,
    attrs: {
      blockid: { default: "" },
      src: {},
      alt: { default: null },
      title: { default: null },
    },
    group: "inline",
    draggable: true,
    parseDOM: [
      {
        tag: "img[src]",
        getAttrs(dom) {
          return {
            blockid: dom.getAttribute("data-blockid"),
            src: dom.getAttribute("src"),
            title: dom.getAttribute("title"),
            alt: dom.getAttribute("alt"),
          };
        },
      },
    ],
    toDOM(node) {
      node.attrs["data-blockid"] = node.attrs.blockid;
      return ["img", node.attrs];
    },
  },

  // :: NodeSpec A hard line break, represented in the DOM as `<br>`.
  hard_break: {
    inline: true,
    group: "inline",
    selectable: false,
    parseDOM: [{ tag: "br" }],
    toDOM() {
      return ["br"];
    },
  },

  /*
		Lists
	*/

  ordered_list: orderedListSpec,
  bullet_list: bulletListSpec,
  list_item: listItemSpec,

  /*
		ToDo list
	*/

  todo_item: todoItemSpec,
  todo_list: todoListSpec,

  /*
		Hidden list
	* /
	hidden_list_item: hiddenListItemSpec,
	hidden_list: hiddenListSpec, */

  /*
		Block
	*/

  block_title: blockTitleSpec,
  block_element: blockElementSpec,
};

/**
 * Marks are inline <wrappers>for the text</wrappers
 */
export const marks = {
  /*
		Link mark.
		Has `href` and `title` attributes.
		`title` defaults to the empty string.
		Rendered and parsed as an `<a>` element.
	*/
  link: {
    attrs: {
      href: {},
      title: {
        default: null,
      },
      onclick: {
        default: null,
      },
    },
    inclusive: false,
    parseDOM: [
      {
        tag: "a[href]",
        getAttrs(dom) {
          return {
            href: dom.getAttribute("href"),
            title: dom.getAttribute("title"),
          };
        },
      },
    ],
    toDOM(node) {
      // node.attrs.onCLick = "javascript:alert('123')"
      // node.attrs.onClick = doClick(node.attrs.href)
      // console.log('link: ', node.attrs)
      return ["a", node.attrs, 0];
    },
  },

  // Color
  color: {
    attrs: {
      color: { default: "black" },
    },
    inclusive: false,
    parseDOM: [{ tag: "span" }],
    toDOM(node) {
      return [
        "span",
        {
          // style: `color:${node.attrs.color}`
          class: `text-color text-color-${node.attrs.color}`,
        },
        0,
      ];
    },
  },

  /*
		Emphasis mark.
		Rendered as an `<em>` element.
		Has parse rules that also match `<i>` and `font-style: italic`
	*/
  em: {
    parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
    toDOM() {
      return ["em", 0];
    },
  },

  /*
		Strong mark.
		Rendered as `<strong>`, parse rules
		also match `<b>` and `font-weight: bold`.
	*/
  strong: {
    parseDOM: [
      { tag: "strong" },
      // This works around a Google Docs misbehavior where
      // pasted content will be inexplicably wrapped in `<b>`
      // tags with a font-weight normal.
      {
        tag: "b",
        getAttrs: (node) => node.style.fontWeight !== "normal" && null,
      },
      {
        style: "font-weight",
        getAttrs: (value) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
      },
    ],
    toDOM() {
      return ["strong", 0];
    },
  },

  /*
		Underline mark.
		Rendered as `<u>`, parse rules
	*/
  underline: {
    parseDOM: [{ tag: "u" }],
    toDOM() {
      return ["u", 0];
    },
  },

  /*
		Strikethrough.
		Rendered as `<s>`, parse rules
	*/
  strikethrough: {
    parseDOM: [{ tag: "s" }],
    toDOM() {
      return ["s", 0];
    },
  },

  /*
		Code mark.
		Represented as a `<code>` element.
	*/
  code: {
    parseDOM: [
      {
        tag: "code",
      },
    ],
    toDOM() {
      return ["code", 0];
    },
  },

  /*
		Document mark. Internal link to a document.
		Has `href` and `title` attributes.
		`title` defaults to the empty string.
		Rendered and parsed as an `<a>` element.
	*/
  linkdoc: {
    attrs: {
      href: {},
      title: {
        default: null,
      },
      "data-link-inner": {
        //'test' ,
        default: null,
      },
      onclick: {
        default: null,
      },
    },
    inclusive: false,
    parseDOM: [
      {
        tag: "a[href][data-link-inner]",
        getAttrs(dom) {
          return {
            href: dom.getAttribute("href"),
            title: dom.getAttribute("title"),
            "data-link-inner": dom.getAttribute("data-link-inner"),
            onclick: dom.getAttribute("onclick"),
          };
        },
        /*
					Set this tag of a higher priority than plan A-tag
					https://prosemirror.net/docs/ref/#model.ParseRule.priority
				*/
        priority: 60,
      },
    ],
    toDOM(node) {
      // node.attrs.onCLick = "javascript:alert('123')"
      // node.attrs.onClick = doClick(node.attrs.href)
      // console.log('link: ', node.attrs)
      return ["a", node.attrs, 0];
    },
  },
};

// :: Schema
// This schema rougly corresponds to the document schema used by
// [CommonMark](http://commonmark.org/), minus the list elements,
// which are defined in the [`prosemirror-schema-list`](#schema-list)
// module.
//
// To reuse elements from this schema, extend or read from its
// `spec.nodes` and `spec.marks` [properties](#model.Schema.spec).
export const editorSchema = new Schema({ nodes, marks });
