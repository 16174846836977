/**
 * System libraries
 */
import React, { Component, createContext } from "react";

/**
 * Firebase
 */
import firebase from "firebase/app";

/**
 * Modules
 */
import {
  firestoreUserCreate,
  firestoreUserUpdate,
} from "components/Firestore/User";
import { FirestoreGetKnowledgebase } from "components/Firestore/Knowledgebase";

/**
 * Create user context
 */
export const UserContext = createContext({
  userData: null,
  logOut: null,
  changeShortcuts: null,
  // userSaved: false,
});

/**
 * User provider class
 */
export default class UserProvider extends Component {
  // Construct the user data
  constructor(props) {
    // console.log('UserProvider.constructor')
    super(props);

    // Default state
    this.state = {
      // User data is empty
      userData: null, // JSON.parse(localStorage.getItem('userData')) || null
      // Is the user saved in Firebase already?
      // userSaved: localStorage.getItem('userSaved') || false,
      logOut: async () => {
        // console.log('UserProvider.logOut')

        // () => firebase.auth().signOut()

        // Firebase, sign out
        await firebase.auth().signOut();
        // console.log('UserProvider.firebase')

        // Go to index
        window.location.href = "/";
        // console.log('UserProvider.location')
      },
      changeShortcuts: (newShortcuts) => {
        console.log("UserProvider.changeShortcuts", newShortcuts);

        // Set state
        const newUserData = this.userData;
        newUserData.shortcuts = newShortcuts;
        this.setState({
          userData: newUserData,
        });
      },
    };

    // console.log('UserProvider.Constructor.state', this.state)
  }

  // Compounent mounted?
  componentDidMount = () => {
    // console.log('UserProvider.componentDidMount')

    // Authorize the user
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((userAuth) => {
        // console.log('UserProvider.Firebase.onAuthStateChanged');

        // Authorized?
        if (userAuth) {
          // console.log('UserProvider.Firebase.Authorized') // , userAuth

          /*
						Read user data from our Firestore database
					*/

          const readProfile = async () => {
            // console.log('UserProvider.Firestore.readProfile')

            // Reset user profile data
            let profileData = {};

            // Should we make updates to the profile?
            let updateProfileData = {};

            // Get the document
            let firebaseProfile = await firebase
              .firestore()
              .collection("users")
              .doc(userAuth.uid)
              .get();

            // User is not yet registered?
            if (!firebaseProfile.exists) {
              /*
								Show the loader while we are processing the request
							*/

              this.setState({
                userData: {
                  authLoading: true,
                },
              });

              /*
								Save new user
							*/

              // Set the user profile
              profileData = {
                uid: userAuth.uid,
                displayName: userAuth.displayName,
                email: userAuth.email,
                datetimeRegistered: firebase.firestore.Timestamp.now(),

                /*
									User settings are below
								*/
                shortcuts: {},
                knowledgebaseId: "",
                lastDocumentId: "",
                onboard: 1,
              };

              console.log("UserProvider.Firestore.createProfile", profileData);

              // Save to Firestore
              await firestoreUserCreate(profileData.uid, profileData);

              console.log("UserProvider.Firestore.createProfile.saved");

              /* profileData = {
								authError: <div>
									Oops! It seems that you haven't registered yet.<br />
									Please <a href="https://app.wotodo.co/onboard/">Sign up here</a>.
								</div>
							} */

              // Greetings!
              const requestResult = await fetch(
                "https://app.wotodo.co/onboard/getting-started/?uid=" +
                  profileData.uid
              );

              console.log(
                "UserProvider.Firestore.gettingStarted.requestResult",
                requestResult
              );

              /* // Set state
							this.setState({
								userData: profileData
							})

							return false */

              // Follow the survey
              // window.location.href = 'https://app.wotodo.co/survey/'
              // return

              // Re-fetch the profile
              let firebaseProfile = await firebase
                .firestore()
                .collection("users")
                .doc(userAuth.uid)
                .get();
              profileData = firebaseProfile.data();

              console.log(
                "UserProvider.Firestore.gettingStarted.reFetch",
                profileData
              );

              // User is registered?
            } else {
              profileData = firebaseProfile.data();
            }

            /*
						// Not onboarded yet?
						if (!profileData?.onboard) { 
							profileData = {
								authError: <div>Oops! It seems that you haven't been onboarded yet. Please expect email from us.</div>
							}

							// Set state
							this.setState({
								userData: profileData
							})

							return false
						}
						*/

            // console.log('UserProvider.profileData', profileData)

            /*
							We do not have last known knowledgebase?
						*/
            if (
              !profileData.knowledgebaseId ||
              profileData.knowledgebaseId === ""
            ) {
              // Get the knowledgebase
              profileData.knowledgebaseId = await FirestoreGetKnowledgebase(
                profileData
              );

              // Save it to the profile
              // firestoreUserUpdate(profileData.uid, { knowledgebaseId: profileData.knowledgebaseId })
              updateProfileData.knowledgebaseId = profileData.knowledgebaseId;
            }

            /*
							Onboarding?
						*/

            if (profileData.onboard === 1) {
              // console.log('UserProvider.firstOnboard')

              // Is onboarding
              // profileData.isOnboarding = true

              // Change local variable
              // profileData.onboard = 2

              // Change Firestore data
              updateProfileData.onboard = 2;
            }

            /*
							Resets
						*/

            // Smart links counter
            if (
              !profileData?.smartLinksCount ||
              profileData.smartLinksCount < 0
            ) {
              profileData.smartLinksCount = 0;
            }

            // Paragraphs counter
            if (
              !profileData?.paragraphsCount ||
              profileData.paragraphsCount < 0
            ) {
              profileData.paragraphsCount = 0;
            }

            // Account is paid?
            if (!profileData?.isPaid) {
              profileData.isPaid = false;
            }

            // Account plan?
            if (!profileData?.plan || profileData.plan === "") {
              profileData.plan = "personal";
            }

            /*
							Should we update user profile?
						*/

            if (
              updateProfileData &&
              Object.keys(updateProfileData).length > 0
            ) {
              console.log("readProfile.updateProfileData", updateProfileData);
              await firestoreUserUpdate(profileData.uid, updateProfileData);
            }

            /*
							Onboarding?
						*/

            if (
              profileData.onboard === 1 &&
              profileData?.lastDocumentId &&
              profileData.lastDocumentId !== ""
            ) {
              // console.log('readProfile.isOnboarding', profileData.lastDocumentId)

              // One-time onboarding
              profileData.onboard = 2;

              // Go to it
              //history.push(`/document/${profileData.lastDocumentId}`)
              window.location.href = `/document/${profileData.lastDocumentId}`;

              // Do nothing
              return;
            }

            // Go to index
            //window.location.href = '/'
            //console.log('UserProvider.location')

            /*
							Set state
						*/

            // console.log('UserProvider.profileData.Processed', profileData)

            this.setState({
              userData: profileData,
            });
          };
          readProfile();

          // Not authorized
        } else {
          // console.log('UserProvider.Firebase.NotAuthorized')

          // Set state
          this.setState({
            userData: false,
          });
        }
      });
  };

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    // console.log('UserProvider.componentWillUnmount')

    this.unregisterAuthObserver();
  }

  // Render it
  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
