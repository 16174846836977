/**
 * System libraries
 */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Modules
 */
import {
  getDocumentWithBlocks,
  getDocumentDate,
  getDocumentDateBefore,
} from "../Document/processor";

/**
 * ProseMirror
 */
import Editor from "components/Editor/Editor";

/**
 * Utils
 */
import { getDateObject } from "components/System/Utils";

/**
 * Draw calendar carousel
 * @param {object} Props
 */
export default function Calendar(props) {
  // console.log('Calendar', props)

  // Redirect to the page after creation
  // let history = useHistory()

  /*
		System inits
	*/

  // Get the User context
  const { userData } = useContext(UserContext);

  /*
		Forward params to all editors
	*/

  // Page loader Toggle function
  const togglePageLoaded = props.togglePageLoaded;

  // Save is pending function
  const setSavePending = props.setSavePending;

  // Shortcuts
  const shortcuts = props.shortcuts;
  const toggleShortcut = props.toggleShortcut;

  // Save status
  // const toggleIsSaved = props.toggleIsSaved

  // Limits
  const toggleLimitHit = props.toggleLimitHit;

  /*
		Previous loader
	*/

  // Is the document from the earlier date loading?
  const previousLoading = React.useRef(false); // useRef does not re-render!

  // Was that the last document?
  const previousIsLast = React.useRef(false); // useRef does remember!

  // Last loaded document
  const previousLastDocument = React.useRef({});

  // Last known date
  const lastKnownDate = React.useRef(null);

  /*
		Documents
	*/

  // Documents list. Initial is the first one
  const [dateDocIds, setDateDocIds] = useState([]);
  const knownDocsIds = React.useRef([]);

  // Previous document
  const [previousDocument, setPreviousDocument] = useState({});

  /*
		Focused editor
	*/

  const [focusedEditor, setFocusedEditor] = React.useState(null);
  const focusedEditorChanged = (focusedEditorId) => {
    // console.log('Calendar.focusedEditorChanged', focusedEditorId)
    setFocusedEditor(focusedEditorId);
  };

  /*
		Search previous document
	*/
  const searchPrevious = async (currentDocumentData) => {
    // console.log('Calendar.searchPrevious', currentDocumentData)

    // This document is not a date document
    if (!currentDocumentData?.is_date || !currentDocumentData?.date_set) {
      // Finished loading
      previousLoading.current = false;

      return false;
    }

    // Search a single document before this one
    const dateDocumentBefore = await getDocumentDateBefore(
      userData.knowledgebaseId,
      currentDocumentData.date_set
    );

    // Found?
    if (Object.keys(dateDocumentBefore).length > 0) {
      // console.log('Calendar.searchPrevious.gotDocumentBefore', dateDocumentBefore)

      // Update pre previous block
      setPreviousDocument(dateDocumentBefore);

      // Remember the last loaded document
      previousLastDocument.current = dateDocumentBefore;

      // Not found
    } else {
      // Set the flag of Last document found
      previousIsLast.current = true;
    }

    // Finished loading
    previousLoading.current = false;
    // console.log('Calendar.searchPrevious.done')
  };

  /*
		Load previous document
	*/
  const loadPrevious = async (currentDocumentData) => {
    // console.log('Calendar.loadPrevious', currentDocumentData)

    // Already loading?
    if (previousLoading.current) {
      // console.log('Calendar.loadPrevious.already')
      return;
    }

    // Set we are loading
    previousLoading.current = true;

    // Remove previous preview
    setPreviousDocument({});

    // Get the document and add it to the Editors list
    setDateDocIds((oldDocIds) => [...oldDocIds, currentDocumentData.id]);
    knownDocsIds.current.push(currentDocumentData.id);

    // Search for the new previous
    await searchPrevious(currentDocumentData);
  };

  /*
		Handle the scroll
	*/
  const handleScroll = async () => {
    // console.log('Calendar.handleScroll')

    // Was that a last document?
    if (previousIsLast.current) {
      // console.log('Calendar.handleScroll.last')
      return;
    }

    // No valid last document yet?
    if (
      !previousLastDocument.current ||
      !Object.keys(previousLastDocument.current).length
    ) {
      // console.log('Calendar.handleScroll.nodoc')
      return;
    }

    // Load the previous document (starting from the last)
    loadPrevious(previousLastDocument.current);
  };

  /*
		On Mount. Run once.
	*/
  useEffect(() => {
    // console.log('Calendar.useEffect')

    // Flag for the Unmount (to check againts after async operations are finished)
    let isUnmounted = false;

    /**
     * Init the Calendar
     */
    const initCalendar = async () => {
      // console.log('Calendar.initCalendar')

      // Get today as object
      const dateObject = getDateObject();

      // Get the last known date
      lastKnownDate.current = dateObject.short;
      // console.log('Calendar.initCalendar.lastKnownDate', lastKnownDate.current)

      // Search the page in database
      const initialDocumentId = await getDocumentDate(
        userData.knowledgebaseId,
        dateObject
      );
      // console.log('Calendar.initCalendar.getToday', initialDocumentId)

      // Get the initial Document data
      const initialDocumentData = await getDocumentWithBlocks(
        userData.knowledgebaseId,
        // This is the initial document id at the time the component is mounted
        initialDocumentId
      );

      // Document is not found?
      if (initialDocumentData === null) {
        console.log("Calendar.initCalendar.notFound");

        // We can't do the effect!
        return;
      }

      /*
				Component already is unmounted?
			*/

      if (isUnmounted) {
        // console.log('Calendar.initCalendar.UNMOUNTED')

        // We can't do the update!
        return;
      }

      // console.log('Calendar.initCalendar.initialDocumentData', initialDocumentData)

      // Get the document and add it to the Editors list
      setDateDocIds((oldDocIds) => [...oldDocIds, initialDocumentId]);
      knownDocsIds.current.push(initialDocumentId);

      // Update the previous
      await searchPrevious(initialDocumentData);

      /*
				Load 7 more days
			*/

      for (let i = 0; i < 7; i++) {
        await loadPrevious(previousLastDocument.current);
      }

      // Add the on-scroll listener
      window.addEventListener("scroll", handleScroll);
    };
    initCalendar();

    /**
     * Check if the last date we remember changed.
     * In case it is, let's refresh selves!
     */
    const isDateChanged = async () => {
      // console.log('Calendar.isDateChanged', lastKnownDate.current)

      // Date is not yet known
      if (lastKnownDate === null) return;

      // Get date right now
      const dateObject = getDateObject();
      // console.log('Calendar.isDateChanged.dateObject', dateObject)

      // Changed?
      if (lastKnownDate.current !== dateObject.short) {
        // Date changed
        console.log(
          "Calendar.isDateChanged.Changed",
          lastKnownDate.current,
          dateObject.short
        );

        // We are not in focus to do the changes!
        if (!document.hasFocus()) {
          console.log("Calendar.isDateChanged.notInFocus");
          return;
        }

        /*
					Let's search for a new date
				*/

        // Search a single document before this one
        const todayDocumentId = await getDocumentDate(
          userData.knowledgebaseId,
          dateObject
        );

        // Got the document?
        if (todayDocumentId) {
          console.log(
            "Calendar.isDateChanged.Array",
            [...knownDocsIds.current],
            todayDocumentId,
            knownDocsIds.current.includes(todayDocumentId)
          );

          // Document already loaded?
          if (knownDocsIds.current.includes(todayDocumentId)) {
            console.log(
              "Calendar.isDateChanged.Already",
              dateObject,
              todayDocumentId
            );

            // Append the document to the begginning of the Editors list
          } else {
            setDateDocIds((oldDocIds) => [todayDocumentId, ...oldDocIds]);
            knownDocsIds.current.push(todayDocumentId);
          }

          // We are in a new Day
          lastKnownDate.current = dateObject.short;
        }
      }
    };

    /*
			Check for a date change
		*/

    // Listen for focus changes
    window.addEventListener("focus", isDateChanged);

    // Check every N seconds if the date changed
    const dateChangeInterval = setInterval(isDateChanged, 5000);

    /*
			Component unmounts?
		*/
    return () => {
      // console.log('Calendar.Unmount')

      // Set new state to prevent the async processing from finishing
      isUnmounted = true;

      // Remove the on-scroll listener
      window.removeEventListener("scroll", handleScroll);

      // Remove on focus listener
      window.removeEventListener("focus", isDateChanged);

      // Remove the interval timer
      // console.log('Calendar.Unmount.Interval', dateChangeInterval)
      clearInterval(dateChangeInterval);
    };
  }, []);

  /*
		Show all Documents
	*/

  const momentDate = moment();

  // Result
  return (
    <>
      {/*
				// Month name bar
				<div className="wotodo-pick-month">
					<button className="wotodo-pick-month__button js-visible">{ momentDate.format('MMMM YYYY') }</button>
                </div>
			*/}
      {
        // Shown dates-documents (Editors)
        dateDocIds.map((currentDocumentId, index) => {
          // console.log('Calendar.map', currentDocumentId)

          /*
						Is this editor last in focus?
					*/
          let currentEditorFocused = false;

          // Editor known
          if (focusedEditor !== null) {
            // This is the one
            if (focusedEditor === currentDocumentId) {
              currentEditorFocused = true;
            }

            // First one is in focus
          } else if (index === 0) {
            currentEditorFocused = true;
          }

          // Show the Editor
          return (
            <Editor
              key={`editor-${currentDocumentId}`}
              focusedEditor={currentEditorFocused}
              focusedChange={focusedEditorChanged}
              toggleLimitHit={toggleLimitHit}
              togglePageLoaded={togglePageLoaded}
              setSavePending={setSavePending}
              documentId={currentDocumentId}
              shortcuts={shortcuts}
              toggleShortcut={toggleShortcut}
              // toggleIsSaved={toggleIsSaved}
              fromCalendar={true}
            />
          );
        })
      }
      {/* 
				// Next date-document (if any)
				(
					Object.keys(previousDocument).length > 0 ?
						<CalendarPreviewPrevious
							previousDocument={previousDocument}
							loadPrevious={loadPrevious}
						/>
						: ''
				)
			*/}
    </>
  );
}

/**
 * Previous document preview
 * /
function CalendarPreviewPrevious(props) {
	/*
		Props
	* /

	const previousDocument = props.previousDocument
	const loadPrevious = props.loadPrevious

	/*
		Validation
	* /

	if (!previousDocument?.title) return null

	// Get date object from string like: "October 16th, 2020"
	const momentDate = moment(previousDocument.title, "MMMM Do YYYY")

	/*
		Show the preview bar (document title)
	* /

	return (
		/* <Row className="wt-calendar-previous">
			<Col>
				<h3 className="text-muted">{previousDocument.title}</h3>
			</Col>
			<Col className="text-right">
				<span className="text-muted">SHOW</span>
			</Col>
		</Row> * /

		<div className="wotodo-item-header wotodo-item-header-inactive" onMouseEnter={() => { loadPrevious(previousDocument) }}>
			<time className="wotodo-item-header__date wotodo-item-header__date_bigger">
				<strong>
					{momentDate.format('DD') /* 17 <-- day of the month * /}
				</strong>
				<small>
					{momentDate.format('dddd') /* Monday <-- Week day name * /}
				</small>
			</time>
			<button className="wotodo-item-header__fav wotodo-item-header__fav_bigger" onClick={() => { loadPrevious(previousDocument) }}>
				SHOW
			</button>
		</div>
	)
}
*/
