/**
 * Components
 */
import { createParagraph } from "components/ProseMirror/Utils";

export const headingEnter = () => {
  return function (state, dispatch) {
    console.log("headingEnter");

    /*
			Data
		*/

    let tr = state.tr;
    const $cursor = state?.selection?.$cursor;

    if (!$cursor) return false;

    console.log("headingEnter", $cursor);

    /*
			Am I the heading?
		*/

    if ($cursor?.parent?.type?.name !== "heading") return false;

    console.log("headingEnter.heading");

    /*
			Start?
		*/

    if ($cursor?.pos === $cursor?.start()) {
      console.log("headingEnter.start");

      // Create paragraph above
      tr = createParagraph(tr, $cursor.before(), false);

      // Dispatch
      dispatch(tr.scrollIntoView());

      return true;
    }

    return false;
  };
};
