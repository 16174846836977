/**
 * This is a StorageKeeper.
 * It checks the local store queue and exports it to Firestore.
 */

/**
 * System libraries
 */
import { useEffect, useContext } from "react";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Storage
 */
import LocalStorage from "components/Storage/local";
import RemoteStorage from "components/Storage/firestore";

import { firestoreUserUpdate } from "components/Firestore/User";

/**
 * Utils
 */
import { objectsHaveSamekeys } from "components/System/Utils";

/**
 * Read queue.
 * Export to Firestore.
 */
export default function StorageKeeper(props) {
  /*
		System
	*/

  // Get the User context
  const { userData } = useContext(UserContext);

  // KnowledgebaseId
  const knowledgebaseId =
    userData && userData?.knowledgebaseId ? userData?.knowledgebaseId : null;

  /*
		Settings
	*/

  // Turn on debugging via console.log
  const _debug = false;

  // if (_debug) console.log('StorageKeeper.Init')

  /*
		Props
	*/

  const setSavePending = props.setSavePending;

  /*
		Run once
	*/
  useEffect(() => {
    // if (_debug) console.log('StorageKeeper.Mount', knowledgebaseId)

    /*
			Queue processor Timer function
		*/
    let processorTimeout = null;
    const scheduleQueueProcessor = (timeoutMsec = 700) => {
      // if (_debug) console.log('StorageKeeper.scheduleQueueProcessor', timeoutMsec)

      // Set timeout
      processorTimeout = setTimeout(async () => {
        // if (_debug) console.log('StorageKeeper.Process')

        // Clear timeout
        processorTimeout = null;

        /*
					Validations
				*/

        // Missing knowledgebaseId
        if (!knowledgebaseId) {
          if (_debug) console.log("StorageKeeper.missing.knowledgebaseId");

          // Set a bigger delay before next run
          scheduleQueueProcessor(1000);

          // We can't process now
          return false;
        }

        // LocalStorage not ready
        if (typeof LocalStorage.wtReadQueue !== "function") {
          if (_debug) console.log("StorageKeeper.LocalStorage.notReady");

          // Set a bigger delay before next run
          scheduleQueueProcessor(1000);

          // We can't process now
          return false;
        }

        // We are offline
        if (!navigator.onLine) {
          if (_debug) console.log("StorageKeeper.offline");

          // Set a bigger delay before next run
          scheduleQueueProcessor(2000);

          // We can't process
          return false;
        }

        /*
					Run
				*/

        // if (_debug) console.log('StorageKeeper.run')

        // Init LocalStorage
        LocalStorage.initDb(knowledgebaseId);

        /*
					Read queue {Array}
				*/

        const currentRawQueue = await LocalStorage.wtReadQueue();

        // Queue is empty
        if (!currentRawQueue || currentRawQueue.length === 0) {
          // if (_debug) console.log('StorageKeeper.noEntries')

          // Mark as processed
          setSavePending(false);

          // Set a new timer for the next run
          scheduleQueueProcessor();

          // We can't process
          return false;
        }

        /*
					We have tasks
				*/

        if (_debug)
          console.log("StorageKeeper.gotEntries", currentRawQueue.length);

        // Remember the last entry we were working with
        let latestEntry = null;

        /*
					Replace multiple 'save' operation for a single document
					into 1 operation, the latest one.

					Local storage savequeue table has Auto-incremented primary key
					and there is .sortBy('id'), so we can safely rely
					on the order of elements in an array.
				*/

        if (_debug)
          console.log("StorageKeeper.currentRawQueue", currentRawQueue);

        // For each entry
        let currentWorkQueue = [];
        for (const currentRawEntry of currentRawQueue) {
          // Is it a 'save' operation?
          if (
            currentRawEntry.operation === "save" &&
            currentRawEntry?.document_id
          ) {
            // This operation was replaced
            let isReplaced = false;

            // For each operation we are going to process
            for (let i = 0; i < currentWorkQueue.length; i++) {
              // Do we already have 'save' operation for this document?
              if (
                currentWorkQueue[i]?.operation === "save" &&
                currentWorkQueue[i]?.document_id &&
                currentWorkQueue[i].document_id === currentRawEntry.document_id
              ) {
                /*
									Yes, we have!
								*/

                // Remove old operation from local storage queue
                await LocalStorage.wtSaveQueueDelete(currentWorkQueue[i].id);

                // Replace the work queue entry with a new one
                currentWorkQueue[i] = { ...currentRawEntry };

                // The operation was replaced
                isReplaced = true;

                // We don't need to scan further
                break;
              }
            }

            // There was no replace?
            if (!isReplaced) {
              /*
								Just push this 'save' operation.
								It's either first one in queue or single of this type.
							*/
              currentWorkQueue.push(currentRawEntry);
            }

            // Is it a 'reference_add' operation?
          } else if (
            currentRawEntry.operation === "reference_add" &&
            currentRawEntry?.document_id &&
            currentRawEntry?.data
          ) {
            // This operation was replaced
            let isReplaced = false;

            // For each operation we are going to process
            for (let i = 0; i < currentWorkQueue.length; i++) {
              // Do we already have 'reference_add' operation for this document?
              if (
                currentWorkQueue[i]?.operation === "reference_add" &&
                currentWorkQueue[i]?.document_id &&
                currentWorkQueue[i].document_id ===
                  currentRawEntry.document_id &&
                currentWorkQueue[i].data === currentRawEntry.data
              ) {
                /*
									Yes, we have!
								*/

                // Remove old operation from local storage queue
                await LocalStorage.wtSaveQueueDelete(currentWorkQueue[i].id);

                // Replace the work queue entry with a new one
                currentWorkQueue[i] = { ...currentRawEntry };

                // The operation was replaced
                isReplaced = true;

                // We don't need to scan further
                break;
              }
            }

            // There was no replace?
            if (!isReplaced) {
              /*
								Just push this 'reference_add' operation.
								It's either first one in queue or single of this type.
							*/
              currentWorkQueue.push(currentRawEntry);
            }

            // Is it a 'user_update' operation?
          } else if (
            currentRawEntry.operation === "user_update" &&
            currentRawEntry?.document_id &&
            currentRawEntry?.data
          ) {
            // This operation was replaced
            let isReplaced = false;

            // For each operation we are going to process
            for (let i = 0; i < currentWorkQueue.length; i++) {
              // Do we already have 'user_update' operation for this document?
              if (
                currentWorkQueue[i]?.operation === "user_update" &&
                currentWorkQueue[i]?.document_id &&
                currentWorkQueue[i].document_id ===
                  currentRawEntry.document_id &&
                objectsHaveSamekeys(
                  currentWorkQueue[i].data,
                  currentRawEntry.data
                )
              ) {
                // console.log('user_update.duplicate', objectsHaveSamekeys(currentWorkQueue[i].data, currentRawEntry.data), currentWorkQueue[i].data, currentRawEntry.data)

                /*
									Yes, we have!
								*/

                // Remove old operation from local storage queue
                await LocalStorage.wtSaveQueueDelete(currentWorkQueue[i].id);

                // Replace the work queue entry with a new one
                currentWorkQueue[i] = { ...currentRawEntry };

                // The operation was replaced
                isReplaced = true;

                // We don't need to scan further
                break;
              }
            }

            // There was no replace?
            if (!isReplaced) {
              /*
								Just push this 'user_update' operation.
								It's either first one in queue or single of this type.
							*/
              currentWorkQueue.push(currentRawEntry);
            }

            // Other operation types are not replaced
          } else {
            currentWorkQueue.push(currentRawEntry);
          }
        }

        if (_debug)
          console.log("StorageKeeper.currentWorkQueue", currentWorkQueue);

        /*
					Look for errors
				*/
        try {
          // For each entry
          for (const currentEntry of currentWorkQueue) {
            if (_debug) console.log("StorageKeeper.currentEntry", currentEntry);
            latestEntry = currentEntry;

            /*
							Process entry
						*/

            switch (currentEntry.operation) {
              /*
								currentEntry contents:
									id {Int, autoincrement}
									operation {String}
									knowledgebase_id {String}
									document_id {String}
									data {Object} / {String} / {null} if is not set
									locked {int, 1/0}
							*/

              /*
								Update the document
							*/
              case "update":
                if (_debug) console.log("StorageKeeper.currentEntry.update");

                await RemoteStorage.wtUpdateDocument(
                  currentEntry.knowledgebase_id,
                  currentEntry.document_id,
                  currentEntry.data
                );

                break;

              /*
								Delete the document
							*/
              case "delete":
                if (_debug) console.log("StorageKeeper.currentEntry.delete");

                await RemoteStorage.wtDeleteDocument(
                  currentEntry.knowledgebase_id,
                  currentEntry.document_id
                );

                break;

              /*
								Delete the reference
							*/
              case "reference_delete":
                if (_debug)
                  console.log("StorageKeeper.currentEntry.reference_delete");

                await RemoteStorage.wtUpdateDocumentReferences(
                  currentEntry.knowledgebase_id,
                  currentEntry.document_id,
                  currentEntry.data,
                  true
                );

                break;

              /*
								Add the reference
							*/
              case "reference_add":
                if (_debug)
                  console.log("StorageKeeper.currentEntry.reference_add");

                await RemoteStorage.wtUpdateDocumentReferences(
                  currentEntry.knowledgebase_id,
                  currentEntry.document_id,
                  currentEntry.data
                );

                break;

              /*
								Save the Document and it's Blocks to Firestore
							*/
              case "save":
                if (_debug) console.log("StorageKeeper.currentEntry.save");

                /*
									Validate data
								*/

                if (
                  !("documentVersion" in currentEntry.data) ||
                  !("blocksCurrent" in currentEntry.data) ||
                  !("blocksMissing" in currentEntry.data) ||
                  !("processId" in currentEntry.data) ||
                  !("datetimeUpdated" in currentEntry.data)
                ) {
                  throw new Error("Invalid data in save operation");
                }

                await RemoteStorage.wtStoreDocument(
                  currentEntry.knowledgebase_id,
                  currentEntry.document_id,
                  currentEntry.data.documentVersion,
                  currentEntry.data.blocksCurrent,
                  currentEntry.data.blocksMissing,
                  currentEntry.data.processId,
                  currentEntry.data.datetimeUpdated
                );

                break;

              /*
								Update the user data
							*/
              case "user_update":
                if (_debug)
                  console.log("StorageKeeper.currentEntry.user_update");

                await firestoreUserUpdate(
                  currentEntry.document_id,
                  currentEntry.data
                );

                break;

              /*
								Unknown entry
							*/
              default:
                console.error(
                  "StorageKeeper.currentEntry.unknown",
                  currentEntry
                );
              // code block
            }

            /*
							Delete entry
						*/

            if (_debug)
              console.log(
                "StorageKeeper.currentEntry.wtSaveQueueDelete",
                currentEntry.id
              );
            await LocalStorage.wtSaveQueueDelete(currentEntry.id);
          }

          if (_debug) console.log("StorageKeeper.finished");

          /*
					Error while processing, could not continue
				*/
        } catch (e) {
          console.error("StorageKeeper.processingError", e);

          /*
						Soft error?
					*/

          if (e?.message && e.message.indexOf("No document to update") !== -1) {
            console.log("StorageKeeper.processingError.softNoDoc");

            // Delete entry
            if (latestEntry?.id) {
              console.log(
                "StorageKeeper.processingError.Soft.Delete",
                latestEntry.id
              );
              await LocalStorage.wtSaveQueueDelete(latestEntry.id);
            }
          }

          /*
						We need to unlock any locked entries
					*/

          await LocalStorage.wtUnlockQueue(currentRawQueue);
        }

        /*
					Mark as processed
				*/

        setSavePending(false);

        /*
					Set a new Timer
				*/

        scheduleQueueProcessor();
      }, timeoutMsec);
    };

    /*
			Initialize the first Timer
		*/
    scheduleQueueProcessor();

    /*
			Component unmounts
		*/
    return () => {
      if (_debug) console.log("StorageKeeper.Unmount", knowledgebaseId);

      // Do we have an active timer?
      if (processorTimeout !== null) {
        // Clear the timer
        clearTimeout(processorTimeout);
        processorTimeout = null;
      }
    };
  }, [knowledgebaseId, _debug]);

  // We do not need to render anything
  return null;
}
