/**
 * System libraries
 */
import React, { useState, useEffect } from "react";

// History
import { useHistory } from "react-router-dom";

/**
 * Modules
 */
import { fullSearchDocument } from "components/Document/processor";
import { useFocus, highlightText } from "components/views/Utils";

/**
 * Searchbar
 * @param {Object} props
 * @return {object} React component
 */
const SearchBar = (props) => {
  /*
		System
	*/

  // History
  let history = useHistory();

  /*
		Props
	*/

  const isVisible = props.isVisible;
  const knowledgebaseId = props.knowledgebaseId;
  const modalClose = props.modalClose;

  /*
		Hooks
	*/

  // Input focus
  const [inputRef, setInputFocus] = useFocus();

  /*
		States
	*/

  // Query input
  const [query, setQuery] = useState("");

  // Result array
  const [result, setResult] = useState([]);

  // Selected result item index
  const [selectedItem, setSelectedItem] = useState(0);

  /**
   * Do the search (on input change)
   * @param {object} JavaScript event
   */
  const doSearch = (e) => {
    const query = e.target.value;

    // Set Query state
    setQuery(query);

    // Query is empty
    if (query === "") {
      // Remove results
      setResult([]);
      return;
    }

    // Run the asunchronous search function
    (async () => {
      // Get results
      const result = await fullSearchDocument(knowledgebaseId, query.trim());

      // Update results state
      setResult(result);
    })();
  };

  /**
   * Go to selected page
   * @param {string} Document id
   */
  const goToDoc = (docId) => {
    // Close modal
    modalClose();

    // Go to the document page
    history.push(`/document/${docId}`);
  };

  /*
		When the Modal is open/closed
	*/
  useEffect(() => {
    // Opened, set Focus
    if (isVisible) {
      // Not yet focused?
      if (document.activeElement !== inputRef.current) {
        setInputFocus();
      }

      // Closed
    } else {
      // Query is not empty
      if (query !== "") {
        // Clear query
        setQuery("");

        // Clear results
        setResult([]);

        // Clear selected item index
        setSelectedItem(0);
      }
    }
  }, [isVisible, setInputFocus, query, inputRef]);

  /**
   * Select result item with a Keyboard
   */
  useEffect(() => {
    /**
     * Select the Item with Keyboard
     * @param {Object} event
     */
    const selectItem = (event) => {
      // Modal is closed
      if (!isVisible) return true;

      // Down key
      if (event.key === "ArrowDown") {
        // Select new item
        setSelectedItem((old) => {
          if (old + 1 >= result.length) return 0;
          else return old + 1;
        });

        // Up key
      } else if (event.key === "ArrowUp") {
        // Select previous item
        setSelectedItem((old) => {
          if (old - 1 < 0) return result.length - 1;
          else return old - 1;
        });

        // Enter key
      } else if (event.key === "Enter") {
        console.log("SearchBar.Enter");

        // Got item?
        if (selectedItem in result && result[selectedItem]?.id) {
          // Go to the document
          goToDoc(result[selectedItem].id);
        }
      }
    };

    // Listen to Keydown
    window.addEventListener("keydown", selectItem);

    return () => {
      // Remove Keydown listener
      window.removeEventListener("keydown", selectItem);
    };
  });

  return (
    <>
      {/* Search input */}
      <input
        id="topnavSearchInput"
        type="text"
        ref={inputRef}
        className="wotodo-search-form__input"
        value={query}
        placeholder="Search"
        onChange={(e) => doSearch(e)}
        autoFocus={true}
        autoComplete="off"
      />

      {/* Search results */}
      <div className="wotodo-search-form__results">
        {result.map((currentDoc, index) => {
          return (
            <button
              key={`search-result-doc-${index}`}
              className={`wotodo-search-form__result${
                selectedItem === index ? " js-active" : ""
              }`}
              onClick={(e) => goToDoc(currentDoc.id)}
            >
              <strong>{highlightText(currentDoc.title, query)}</strong>
            </button>
          );
        })}
        {
          /* No results */
          result.length === 0 && query !== "" ? (
            <button className={`wotodo-search-form__result`}>No results</button>
          ) : (
            ""
          )
        }
      </div>

      {/* Search footer */}
      <div className="wotodo-search-form__bindings">
        <p>
          <strong>↑↓</strong>
          <small>Select</small>
        </p>
        <p>
          <strong>Enter</strong>
          <small>Open</small>
        </p>
        {/* <p><strong>Alt+Enter</strong>
					<small>Open in a new window</small>
				</p> */}
      </div>
    </>
  );
};

export default SearchBar;
