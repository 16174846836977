/**
 * System libraries
 */
import React from "react";

/**
 * Modules
 */
import ModalWrapper from "components/views/modals/Wrapper";
import ShareBlock from "components/views/elements/ShareBlock";

/**
 * Share modal
 * @param {Object} props
 * @return {Object} React component
 */
export function ModalShare(props) {
  return (
    <ModalWrapper
      isOpen={props.isOpen}
      content={
        <div className="wotodo-share-wrapper">
          <ShareBlock
            isVisible={props.isOpen}
            documentId={props.documentId}
            knowledgebaseId={props.knowledgebaseId}
            modalClose={props.modalClose}
            isShared={props.isShared}
            setShare={props.setShare}
          />
        </div>
      }
      modalClose={props.modalClose}
    />
  );
}
