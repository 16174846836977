/**
 * System libraries
 */
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Modules
 */
import { createDocument } from "components/Document/processor";

/**
 * DatePicker
 * @doc: https://reactdatepicker.com/#example-custom-date-format
 */
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
registerLocale("en-gb", enGb);

/**
 * Calendar navigation icon in TopNav
 */
export function NavButtonCalendar(props) {
  // Get the User context
  const { userData } = useContext(UserContext);

  let history = useHistory();
  let location = useLocation();

  const [startDate, setStartDate] = useState(new Date());
  const [pickerIsOpen, setPickerIsOpen] = useState(false);

  /*
    Props
*/

  // Page loader Toggle function
  const togglePageLoaded = props.togglePageLoaded;

  const togglePicker = () => {
    setPickerIsOpen((prevState) => !prevState);
  };

  const gotoDate = (date) => {
    // Compile the date
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    const stringDate = [year, month, day].join("-");

    console.log("gotoDate", stringDate);

    // Show the Page loader
    togglePageLoaded(false);
    // date => setStartDate(date)

    // setStartDate(new Date());

    // Close calendar
    togglePicker();

    // Create document at Firestore
    const createAsync = async () => {
      // console.log('BootstrapCreateDocument.createAsync', documentTitle)

      // Create the new Document
      const documentId = await createDocument(
        userData.knowledgebaseId, // in exact Knowledgebase
        stringDate // with specified Title
      );

      // Success
      if (documentId) {
        console.log(
          "gotoDate.handleCreate.success",
          documentId,
          location.pathname
        );

        // We are already here?
        if (
          location &&
          location?.pathname &&
          location.pathname === `/document/${documentId}`
        ) {
          togglePageLoaded(true);
        } else {
          history.push(`/document/${documentId}`);
        }

        // Error
      } else {
        console.log("gotoDate.handleCreate.error");
      }
    };

    // Run create function
    createAsync();
  };

  /**
   * Button
   */
  return (
    <>
      <button
        type="button"
        className="btn-link wotodo-header__button"
        onClick={togglePicker}
      >
        <img
          src="/images/icons/topmenu_calendar.svg"
          className="sidebar-icon-calendar"
          title="Calendar"
          alt="Calendar"
        />
      </button>
      <DatePicker
        selected={startDate}
        onChange={gotoDate}
        open={pickerIsOpen}
        onClickOutside={togglePicker}
        dateFormat="yyyy-MM-dd"
        locale="en-gb"
      />
    </>
  );
}
