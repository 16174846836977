/**
 * System libraries
 */
import React, { useEffect, useRef } from "react";

/**
 * Modal wrapper
 * @param {Object} props
 * @return {Object} React component
 */
function ModalWrapper(props) {
  /*
		Props
	*/

  const isOpen = props.isOpen;
  const modalClose = props.modalClose;
  const modalContent = props.content;
  const showCloseButton = "showCloseButton" in props;

  /*
		References
	*/

  // Modal block reference (used for click outside event registration)
  const modalWrapperRef = useRef(null);

  /**
   * Keydown event processing
   */
  useEffect(() => {
    const onKeyDown = (event) => {
      if (
        // Esc key was hit
        event.key === "Escape" &&
        // Modal is open
        isOpen
      ) {
        // Close modal
        modalClose();
      }
    };

    // Listen for the keydown event
    document.addEventListener("keydown", onKeyDown);

    return () => {
      // Stop listening
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [isOpen, modalClose]);

  /**
   * Click outside the modal event processing
   */
  useEffect(() => {
    const onClickOutside = (event) => {
      // Modal is open
      if (isOpen) {
        if (
          // We have the Modal Ref?
          modalWrapperRef.current &&
          // And the Clicked target is NOT a descendant of Modal?
          !modalWrapperRef.current.contains(event.target)
        ) {
          // Close the Modal
          modalClose();
        }
      }
    };

    // Listen for the outside click
    document.addEventListener("mousedown", onClickOutside);

    return () => {
      // Stop listening
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [isOpen, modalClose]);

  /**
   * Return the Modal wrapper with Child React Component
   */
  return (
    <div className={`wotodo-modal-wrapper${isOpen ? " js-active" : ""}`}>
      {/* Gray overlay */}
      <div className="wotodo-modal-wrapper__overlay">
        <div className="wotodo-modal-content">
          {/* Modal close button */}
          {showCloseButton ? (
            <div className="wotodo-modal-close">
              <button
                onClick={() => {
                  modalClose();
                }}
              >
                x
              </button>
            </div>
          ) : (
            ""
          )}

          {/* Modal content */}
          <div ref={modalWrapperRef}>{modalContent}</div>
        </div>
      </div>
    </div>
  );
}

export default ModalWrapper;
