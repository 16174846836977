/**
 * System libraries
 */
import * as React from "react";
import moment from "moment";

/**
 * Display document Title in a Calendar view
 */
export default function CalendarTitle(props) {
  // Create a Moment Date object from Title
  const momentDate = moment(props.documentTitle, "MMMM Do YYYY");

  // Item title class
  let divTitleClass = "wotodo-item-header wotodo-item-header_v2";
  if (props?.isBookmarked) divTitleClass += " js-fav";

  return (
    <div className={divTitleClass}>
      {/* "Add new Card" button */}
      <div className="wotodo-item-add-template wotodo-item-add-template_in-header">
        <button
          className="wotodo-item-add-template__button"
          onClick={(e) => props.createBlock(e)}
        ></button>
      </div>

      {/* Date, centered */}
      <time className="wotodo-item-header__date">
        {/* momentDate.format('dddd DD') Monday 7 */}
        {momentDate.format("MMMM DD") /* Monday 7 */}
      </time>

      {/* Document action buttons */}
      <div className="wotodo-item-title__actions">
        {/* Shortcut icon/button */}
        <button
          className={`wotodo-item-title__action wotodo-item-title__action_fav${
            props.isBookmarked ? " js-active" : ""
          }`}
          onClick={(e) => props.toggleShortcut(e, !props.isBookmarked)}
        >
          <img src="/images/icons/chunk-action-fav.svg" alt="" />
        </button>
      </div>
    </div>
  );
}
