/**
 * System libraries
 */
import React, { useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";

/**
 * Pages and elements
 */
import PageAuthorize from "components/views/Authorize";
import PageIndex from "components/views/Index";
import ElementLoader from "components/views/elements/Loader";

/**
 * Services
 */
import AnalyticsWotodo from "services/AnalyticsWotodo";
import StorageKeeper from "services/StorageKeeper";
import RemoteSync from "services/RemoteSync";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Components
 */
import DocumentList from "components/DocumentList";

/**
 * Main Application entry point
 */
export default function App() {
  /*
		System
	*/

  // Get the User context
  const { userData } = useContext(UserContext);

  /*
		Save pending
	*/

  const [savePending, setSavePending] = useState(false);

  /*
		Render
	*/

  // Waiting for Authorization?
  if (userData === null) {
    // console.log('App.Authorization.Waiting')

    // Show loader
    return <ElementLoader />;

    // Unauthorized?
  } else if (!userData) {
    // console.log('App.Unauthorized')

    // Show Authorization page
    return <PageAuthorize />;

    // Error
  } else if (userData?.authError) {
    // console.log('App.authError')

    // Show Error
    return <PageAuthorize authError={userData.authError} />;

    // Auth processing
  } else if (userData?.authLoading) {
    // console.log('App.authError')

    // Show Error
    return <PageAuthorize authLoading={true} />;

    // Waiting for UserData
  } else if (!userData?.uid || !userData?.knowledgebaseId) {
    // Show loader
    return <ElementLoader />;

    // Authorized
  } else {
    // console.log('App.Authorized')

    // Routes
    return (
      <Router>
        {/* Export locally modified Documents to RemoteStorage */}
        <StorageKeeper setSavePending={setSavePending} />

        {/* Monitor user activity and send reports */}
        <AnalyticsWotodo userId={userData.uid} />

        {/* Synchronize LocalStorage of Documents with RemoteStorage */}
        <RemoteSync knowledgebaseId={userData.knowledgebaseId} />

        <Switch>
          <Route path={`/document/:documentId`}>
            <PageDocument setSavePending={setSavePending} />
          </Route>
          <Route path="/documents">
            <DocumentList />
          </Route>
          <Route path="/">
            <PageIndex
              calendarView="true"
              documentId=""
              setSavePending={setSavePending}
            />
          </Route>
        </Switch>

        {/* The document save is pending */}
        {savePending ? <div className="wotodo-save-pending"></div> : ""}
      </Router>
    );
  }
}

/**
 * Exact page processor
 */
function PageDocument(props) {
  // Get the DocumentId from the path parameter
  let { documentId } = useParams();

  /*
		Props
	*/

  const setSavePending = props.setSavePending;

  // console.log('App.PageDocument', documentId)

  // Render the Page
  return <PageIndex documentId={documentId} setSavePending={setSavePending} />;
}
