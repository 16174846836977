/**
 * System libraries
 */
import React from "react";
import { render } from "react-dom";
import * as serviceWorker from "./serviceWorker";

// Unique IDs generation
import { nanoid } from "nanoid";

/**
 * Package
 */
import packageJson from "../package.json";

/**
 * Application libraries
 */
import App from "./App";

/**
 * Contexts
 */
import UserProvider from "contexts/UserContext";

/**
 * Styling
 */
import "./css/prosemirror.css?v23";
import "./css/index.css?v23";
import "./css/editor.css?v23";
import "./css/update.css?v23";
import "./css/sidebars.css?v23";
import "./css/topnav.css?v23";
import "./css/calendar.css?v23";
import "./css/modals.css?v23";
import "./css/smartlinks.css?v23";
import "./css/menu-inline.css?v23";

/**
 * Globals
 */

// Version
global.appVersion = packageJson.version;

// Process id
global.processId = nanoid(10);

/**
 * Let's run!
 */
render(
  <UserProvider>
    <App />
  </UserProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
