import React from "react";

export default function PageItem({ children, active, value, onClick }) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (children) {
    return children;
  }

  return (
    <button
      className={`buttons-group__button ${
        active ? "buttons-group__button_active" : ""
      }`}
      onClick={handleClick}
    >
      {value}
    </button>
  );
}
