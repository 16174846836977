// Configure Firebase.
export const firebaseConfig = {
  apiKey: "AIzaSyDMsIzKJat1MCr1hTRapvOe02kr5in4Lx4",
  authDomain: "wotodo-edfcd.firebaseapp.com",
  databaseURL: "https://wotodo-edfcd.firebaseio.com",
  projectId: "wotodo",
  storageBucket: "wotodo.appspot.com",
  messagingSenderId: "176839400925",
  appId: "1:176839400925:web:c500d8b923fe5dfcaeaa34",
  measurementId: "G-SEVK0T8Y3J",
};
