/**
 * System libraries
 */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * Modules
 */
import { versionsCompare } from "components/System/Utils";

/**
 * Version control logics.
 * Shows alert for a refresh when a new version is out.
 */
export default function VersionControl() {
  // console.log('VersionControl')

  /*
		System
	*/

  // History
  let history = useHistory();

  /*
		States
	*/

  // Do we need to show the alert?
  const [showAlert, setShowAlert] = useState(false);

  // Are we reloading?
  const [showReloading, setShowReloading] = useState(false);

  /*
		App reload function
	*/
  const appReload = () => {
    console.log("VersionControl.appReload");

    // We are in a reloading state
    setShowReloading(true);

    // Set timeout (for everything to be saved)
    setTimeout(() => {
      // Do the full reload!
      history.go(0);
    }, 5 * 1000);
  };

  /*
		Run once
	*/
  useEffect(() => {
    // console.log('VersionControl.Mount')

    /*
			System checks
		*/

    // Invalid version
    if (!global?.appVersion) {
      console.log("VersionControl.invalidVersion");
      return () => {};
    }

    console.log("VersionControl.Version", global.appVersion);

    /*
			Version check Timer function
		*/
    let versionTimeout = null;
    const setVersionCheckTimeout = () => {
      // console.log('VersionControl.setTimeout')

      // Set timeout
      versionTimeout = setTimeout(() => {
        // console.log('VersionControl.Timeout')

        // Clear timeout
        versionTimeout = null;

        /*
					Prepare request data
				*/

        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        /*
					Send request
				*/
        fetch("https://app.wotodo.co/analytics/log/?version", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            // console.log('VersionControl.Send.Response', data, global.appVersion)

            // New version?
            if (
              // Got version in reply
              data?.version &&
              // Versions are different
              data.version !== global.appVersion &&
              // Version is higher
              versionsCompare(data.version, global.appVersion) === 1
            ) {
              console.log("VersionControl.Updated", data.version);

              // Show alert!
              setShowAlert(true);

              // Version is the same
            } else {
              // console.log('VersionControl.Skip')

              // Set a new Timer
              setVersionCheckTimeout();
            }
          });
      }, 5 * 60 * 1000);
    };

    /*
			Initialize the first Timer to check version
		*/
    setVersionCheckTimeout();

    /*
			Component unmounts?
		*/
    return () => {
      // console.log('VersionControl.Unmount')

      // Do we need to clear the timer?
      if (versionTimeout !== null) {
        clearTimeout(versionTimeout);
      }
    };
  }, []);

  /*
		Do we need to show the alert?
	*/

  // Yeap, show it!
  if (showAlert) {
    return (
      <div className={"wt-alert-global wt-alert-global-open"}>
        <div
          className={
            "wotodo-update__content wotodo-update__content_update" +
            (showReloading ? "" : " js-active")
          }
        >
          <h4 className={"wotodo-update__title"}>New update available</h4>
          <button className={"wotodo-update__button"} onClick={appReload}>
            Update
          </button>
        </div>
        <div
          className={
            "wotodo-update__content wotodo-update__content_loading" +
            (showReloading ? " js-active" : "")
          }
        >
          <h4 className={"wotodo-update__title"}>Reloading, please wait…</h4>
        </div>
      </div>
    );

    // No output is required
  } else return null;
}
