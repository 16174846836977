import { TextSelection } from "prosemirror-state";

/**
 * Find a neighbour before this resolved pos
 * @param {object} Resolved position
 * @return {object} Resolved pos of a neighbour
 */
export const findNeighbourBefore = (resolvedPos) => {
  /*
		Validations
	*/

  // No depth or We are at the top level and there are no parents above me
  if (!resolvedPos?.depth) return null;

  /*
		Get the neighbour
	*/

  // Get the neighbour index inside my parent (before me)
  if (!resolvedPos?.index) return null;
  const neighbourIndex = resolvedPos.index(resolvedPos.depth - 1) - 1;

  // We are the first one in the parent, so no neighbours before me
  if (neighbourIndex < 0) return null;

  const neighbourPos =
    resolvedPos.posAtIndex(neighbourIndex, resolvedPos.depth - 1) + 1;
  const neighbourNode = resolvedPos.doc.resolve(neighbourPos);

  // console.log('findNeighbourBefore', neighbourPos, neighbourNode.start(), neighbourNode.end(), neighbourNode.before(), neighbourNode.after(), neighbourNode)

  // None found
  if (!neighbourNode?.parent) return null;

  return neighbourNode;
};

/**
 * Search all Inner links in ProseMirror document
 *
 * @param {object} ProseMirror Node element
 * @returns {array} Found links
 */
export const proseMirrorSearchLinks = (node, parentBlockId = null) => {
  let foundLinks = [];
  // let

  // Node has children
  if (node.content && node.content.length) {
    // Got data-blockid tag?
    if (parentBlockId === null && node.attrs && node.attrs.blockid) {
      parentBlockId = node.attrs.blockid;
      // console.log('proseMirrorSearchLinks.parentBlockId', parentBlockId)
    }

    // Foreach child
    node.content.forEach((child) => {
      // Search links inside
      const innerLinks = proseMirrorSearchLinks(child, parentBlockId);

      // Got something?
      if (innerLinks) {
        // Add to resulting array
        foundLinks = [...foundLinks, ...innerLinks];
        // foundLinks = {...foundLinks, ...innerLinks}
      }
    });

    // Block is final
  } else {
    // Does Node have marks?
    if (node.marks && node.marks instanceof Array && node.marks.length > 0) {
      // console.log('proseMirrorSearchLinks.final', node)
      // console.log('proseMirrorSearchLinks.final.parentBlockId', parentBlockId)

      // Foreach mark
      node.marks.forEach((mark) => {
        // Is it of type Inner link to document?
        if (mark.type === "linkdoc" && "data-link-inner" in mark.attrs) {
          // Add it to array of found links
          foundLinks.push({
            blockId: parentBlockId,
            linkId: mark.attrs["data-link-inner"],
          });
          // foundLinks[parentBlockId] = mark.attrs['data-link-inner']
        }
      });
    }
  }

  // Return result
  return foundLinks;
};

/**
 * Search for inside oibject with a backlink
 * @param {object} Node
 * @param {string} Document id
 * /
export const proseMirrorSearchBacklink2 = (elementObject, DocumentId, parentOffset) => {
	// let foundObject = null

	// Latest parent with content we are aworking with
	// let latestParent = elementObject

	// Do we have a content block inside the parent?
	if (
		elementObject?.content
		&& elementObject.content?.length > 0
	) {
		console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), elementObject)
		parentOffset++

		// Reset new contents holder
		// let newContent = []
		let returnObject = false

		// For each content object
		for (let i = 0; i < elementObject.content.length; i++) {
			const currentElement = elementObject.content[i]

			// Does it have inner content?
			// if (currentElement?.content) {
			// Can we process it?
			const backlinkFound = proseMirrorSearchBacklink(currentElement, DocumentId, parentOffset)

			// Found? Return the parent
			if (backlinkFound) {
				// This is the leaf node with the ref link?
				if (backlinkFound?._ref === true) {
					/*
						Right now it will return the FIRST parent,
						but maybe I need to go up to some exact element, like LI, or TODO item?
					* /
					delete backlinkFound._ref
					elementObject.content[i] = backlinkFound
					// return elementObject
					// newContent.push(backlinkFound)
					returnObject = elementObject

				// We already have a parent, just return it up
				} else {
					// Is it a block_title and we have a parent as block_element?
					if (
						backlinkFound?.type
						&& backlinkFound.type === "block_title"
						&& elementObject?.type
						&& elementObject.type === "block_element"
					) {
						// Let's mark the block as closed if it's open
						if (elementObject?.attrs?.open) {
							elementObject.attrs.open = false
						}

						// We need to return the full block!
						elementObject = proseMirrorSearchLinksCleanup(elementObject, DocumentId)
						// return elementObject
						returnObject = elementObject

					// Is it a list_item and we have a parent *_list wrapper?
					} else if (
						backlinkFound?.type
						&& (
							backlinkFound.type === "list_item"
							|| backlinkFound.type === "todo_item"
						)
						&& elementObject?.type
						&& (
							elementObject.type === "bullet_list"
							|| elementObject.type === "ordered_list"
							|| elementObject.type === "todo_list"
						)
					) {
						// Let's mark all the block children as closed (if they have such a an attr)
						if (
							backlinkFound?.content
							&& backlinkFound.content?.length > 0
						) { 
							for (let k = 0; k < backlinkFound.content.length; k++) {
								if (backlinkFound.content[k]?.attrs?.open) {
									backlinkFound.content[k].attrs.open = false
								}
							}
						}
						
						// Reset it's content to single child with a found reference link
						elementObject.content = [backlinkFound]
						// console.log('proseMirrorSearchBacklink.ListWrapper', backlinkFound)
						// return elementObject
						returnObject = elementObject

					// Is it a paragraph and we have a list_item OR block_title?
					} else if (
						backlinkFound?.type
						&& backlinkFound.type === "paragraph"
						&& elementObject?.type
						&& (
							elementObject.type === "list_item"
							|| elementObject.type === "todo_item"
							|| elementObject.type === "block_title"
						)
					) {
						// Replace all possible inner links to self
						elementObject = proseMirrorSearchLinksCleanup(elementObject, DocumentId)
						// return elementObject
						returnObject = elementObject
						
					// Return what we've remembered
					} else {
						// return backlinkFound
						returnObject = backlinkFound
					}
				}
			}

			// Is it the end leaf without inner content?
			// } else {
				
			// }
		}

		return returnObject

	// Last leaf element
	} else {
		// Element is a plain text
		if (
			elementObject?.type
			&& elementObject.type === 'text'
		) {
			// Has Marks
			if (
				elementObject?.marks
				&& elementObject.marks?.length > 0
			) {
				// Reset found state
				let elementFound = false
				
				// For each Mark
				for (let j = 0; j < elementObject.marks.length; j++) {
					const currentMark = elementObject.marks[j]

					// Reference link with Link to DocumentId
					if (
						currentMark?.attrs
						&& currentMark.attrs.hasOwnProperty('data-link-inner')
						&& currentMark.attrs['data-link-inner'] === DocumentId
					) {
						// Let's remove the link
						elementObject.marks.splice(j, 1)

						// Let's replace the link with the strong
						// elementObject.marks.push({ "type": "underline" })
						// elementObject.marks.push({ "type": "strong" })
						elementObject.marks.push({ "type": "em" })

						// Mark this as a ref node
						elementObject._ref = true

						// Return it!
						elementFound = true

						// return true
						// hasReferrence = true
						// break
						
					// Ordinary link
					} else if (
						currentMark?.attrs
						&& currentMark.attrs.hasOwnProperty('href')
					) {
						elementObject.marks[j].attrs.onclick = "return false;"
						// console.log('hasOwnProperty3.href', elementObject.marks[j].attrs)
					}
				}

				if (elementFound) return elementObject
			}

			/* // Yeap, reference found!
			if (hasReferrence) {
				// Let's return the parent we've found!
				return latestParent
			} * /
		}
	}

	// We've found nothing, so no point to return anything
	return false
}
*/

/**
 * Check all nodes for links to self and replace them all
 *
 * @param {object} node
 * @param {string} Document id
 */
export const proseMirrorSearchLinksCleanup = (elementObject, DocumentId) => {
  // Do we have a content block inside the parent?
  if (elementObject?.content && elementObject.content?.length > 0) {
    // Close the block if it's open
    if (
      elementObject?.type &&
      elementObject.type === "block_element" &&
      elementObject?.attrs &&
      elementObject?.attrs?.open &&
      elementObject.attrs.open === true
    ) {
      elementObject.attrs.open = false;
    }

    // For each content object
    for (let i = 0; i < elementObject.content.length; i++) {
      elementObject.content[i] = proseMirrorSearchLinksCleanup(
        elementObject.content[i],
        DocumentId
      );
    }

    // Plain leaf element?
  } else {
    // Is it a plain text?
    if (elementObject?.type && elementObject.type === "text") {
      // Does it have a reference link?
      // let hasReferrence = false
      if (elementObject?.marks && elementObject.marks?.length > 0) {
        for (let j = 0; j < elementObject.marks.length; j++) {
          const currentMark = elementObject.marks[j];

          // console.log('hasOwnProperty2', currentMark)

          // We've found the reference!
          if (
            currentMark?.attrs &&
            currentMark.attrs.hasOwnProperty("data-link-inner") &&
            currentMark.attrs["data-link-inner"] === DocumentId
          ) {
            // Let's remove the link
            elementObject.marks.splice(j, 1);

            // Let's replace the link with the strong
            // elementObject.marks.push({ "type": "underline" })
            // elementObject.marks.push({ "type": "strong" })
            elementObject.marks.push({ type: "em" });

            // Mark this as a ref node
            // elementObject._ref = true

            // Return it!
            // return elementObject

            // return true
            // hasReferrence = true
            // break

            // Do we have a link?
          } else if (
            currentMark?.attrs &&
            currentMark.attrs.hasOwnProperty("href")
          ) {
            elementObject.marks[j].attrs.onclick = "return false;";
            // console.log('hasOwnProperty3.href', elementObject.marks[j].attrs)
          }
        }
      }

      /* // Yeap, reference found!
			if (hasReferrence) {
				// Let's return the parent we've found!
				return latestParent
			} */
    }
  }

  return elementObject;
};

/**
 * Updating Mark for a selection
 * @param {object} markType
 * @param {object} attrs (null if removing)
 */
export function updateMark(markType, attrs) {
  return function (state, dispatch) {
    let { empty, $cursor, ranges } = state.selection;

    // No selection, no cursor or no application
    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType))
      return false;

    // Process the transformation
    if (dispatch) {
      // Cursor?
      if ($cursor) {
        // Got mark?
        if (markType.isInSet(state.storedMarks || $cursor.marks())) {
          // Remove it
          let tr = state.tr.removeStoredMark(markType);

          // Got attrs?
          if (attrs) {
            // Add new mark
            tr.addStoredMark(markType.create(attrs));
          }

          // Dispatch it!
          dispatch(tr);

          // No mark
        } else {
          // Got attrs?
          if (attrs) {
            // Add new mark
            let tr = state.tr.addStoredMark(markType.create(attrs));

            // Dispatch it!
            dispatch(tr);
          }
        }

        // Selection?
      } else {
        let has = false,
          tr = state.tr;

        // Do we have a mark?
        for (let i = 0; !has && i < ranges.length; i++) {
          let { $from, $to } = ranges[i];
          has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
        }

        // For each range
        for (let i = 0; i < ranges.length; i++) {
          let { $from, $to } = ranges[i];

          // Got mark, remove it
          if (has) tr.removeMark($from.pos, $to.pos, markType);
          // else tr.addMark($from.pos, $to.pos, markType.create(attrs))

          // Got attrs?
          if (attrs) {
            // Add new mark
            tr.addMark($from.pos, $to.pos, markType.create(attrs));
          }
        }

        dispatch(tr.scrollIntoView());
      }
    }
    return true;
  };
}

function markApplies(doc, ranges, type) {
  for (let i = 0; i < ranges.length; i++) {
    let { $from, $to } = ranges[i];
    let can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
    doc.nodesBetween($from.pos, $to.pos, (node) => {
      if (can) return false;
      can = node.inlineContent && node.type.allowsMarkType(type);
    });
    if (can) return true;
  }
  return false;
}

export const proseMirrorSearchBacklink = (
  elementObject,
  DocumentId,
  parentOffset
) => {
  parentOffset++;
  // const debugObject = JSON.parse(JSON.stringify(elementObject))
  // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), debugObject)

  // Does this object has content and it's an array?
  if (elementObject?.content && Array.isArray(elementObject.content)) {
    // Reset the new content array
    let newContent = [];
    // let debugContent = []

    // Foreach sub-Object
    let latestFound = false;
    // for (let curIndex in elementObject.content) {
    for (
      let curIndex = 0;
      curIndex < elementObject.content.length;
      curIndex++
    ) {
      const curObject = elementObject.content[curIndex];

      // Recursively process it at push to the array!
      const objectFound = proseMirrorSearchBacklink(
        curObject,
        DocumentId,
        parentOffset
      );

      // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'found:' + curIndex, objectFound, elementObject)
      // debugger;

      // Link was already found below
      if (objectFound) {
        // Are we in the list head?
        if (
          // First index?
          curIndex === 0 &&
          objectFound?.type &&
          elementObject?.type &&
          ((objectFound.type === "paragraph" &&
            elementObject.type === "list_item") ||
            elementObject.type === "block_element")
        ) {
          // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'header', objectFound, newContent, elementObject)
          // debugger;
          return elementObject;
        } else {
          latestFound = objectFound;
          newContent.push(objectFound);
          // debugContent.push(objectFound)
          // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'push', objectFound, newContent, elementObject)
          // debugger;
        }
      }
    }

    // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'array', latestFound, newContent, elementObject)
    // debugger;

    // Update the current node content
    if (newContent.length > 0) {
      // Block title -> Block Element
      if (
        latestFound?.type &&
        latestFound.type === "block_title" &&
        elementObject?.type &&
        elementObject.type === "block_element"
      ) {
        /* // Let's mark the block as closed if it's open
				if (elementObject?.attrs?.open) {
					elementObject.attrs.open = false
				} */

        // Selected items only
        // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'Block', elementObject)
        // elementObject.content = newContent
        return elementObject;

        // List item -> List
      } else if (
        latestFound?.type &&
        (latestFound.type === "list_item" ||
          latestFound.type === "todo_item") &&
        elementObject?.type &&
        (elementObject.type === "bullet_list" ||
          elementObject.type === "ordered_list" ||
          elementObject.type === "todo_list")
      ) {
        // Let's mark all the block children as closed (if they have such a an attr)
        for (let j = 0; j < newContent.length; j++) {
          if (newContent[j]?.content && newContent[j].content?.length > 0) {
            // console.log('proseMirrorSearchBacklink.In', newContent[j].content)

            for (let k = 0; k < newContent[j].content.length; k++) {
              if (newContent[j].content[k]?.attrs?.open) {
                newContent[j].content[k].attrs.open = false;
              }
            }
          }
        }

        // Selected items only
        // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'List', elementObject, newContent)
        // debugger;
        elementObject.content = newContent;
        return elementObject;

        // Paragraph -> List item
      } else if (
        latestFound?.type &&
        (latestFound.type === "paragraph" || latestFound.type === "heading") &&
        elementObject?.type &&
        (elementObject.type === "list_item" ||
          elementObject.type === "todo_item" ||
          elementObject.type === "block_title")
      ) {
        // All content
        // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'listItem', elementObject)
        return elementObject;

        // Text -> Paragraph
      } else if (
        latestFound?.type &&
        latestFound.type === "text" &&
        elementObject?.type &&
        (elementObject.type === "paragraph" || elementObject.type === "heading")
      ) {
        // All content
        // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'paragraph', elementObject)
        return elementObject;

        // Multiple array objects
      } else if (newContent.length > 1) {
        /*
				// do i need this?
				// List_item with first element as a paragraph?
				if (
					elementObject?.type
					&& elementObject.type === 'list_item'
					&& elementObject?.content
					&& Array.isArray(elementObject.content)
					&& elementObject.content.length > 0
					&& elementObject.content[0]?.type
					&& elementObject.content[0].type === 'paragraph'
				) {
					// Selected items only
					console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'multi.li>p', elementObject, newContent)
					debugger;

					return elementObject

				} else { */
        // Selected items only
        // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'multi', elementObject, newContent)
        // debugger;

        elementObject._ismulti = true;
        elementObject.content = newContent;
        return elementObject;
        // }
      }

      // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'unknown:' + latestFound.type, latestFound, newContent)
      return latestFound;
    }

    // Nothing was found
    // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'empty', latestFound, newContent, elementObject)
    return false;

    // This is the last node
  } else {
    // Element is a plain text
    if (elementObject?.type && elementObject.type === "text") {
      // Has Marks
      if (elementObject?.marks && elementObject.marks?.length > 0) {
        // Reset found state
        let elementFound = false;

        // For each Mark
        for (let j = 0; j < elementObject.marks.length; j++) {
          const currentMark = elementObject.marks[j];

          // Reference link with Link to DocumentId
          if (
            currentMark?.attrs &&
            currentMark.attrs.hasOwnProperty("data-link-inner") &&
            currentMark.attrs["data-link-inner"] === DocumentId
          ) {
            // Let's remove the link
            //elementObject.marks.splice(j, 1)

            // Let's replace the link with the strong
            //// elementObject.marks.push({ "type": "underline" })
            //// elementObject.marks.push({ "type": "strong" })
            //elementObject.marks.push({ "type": "em" })

            // Mark this as a ref node
            // elementObject._ref = true

            // Return it!
            elementFound = true;

            // return true
            // hasReferrence = true
            // break
          }
        }

        if (elementFound) {
          // console.log('proseMirrorSearchBacklink', "*".repeat(parentOffset), 'LINK', elementObject)
          return elementObject;
        }
      }

      /* // Yeap, reference found!
			if (hasReferrence) {
				// Let's return the parent we've found!
				return latestParent
			} */
    }
  }

  return false;
};

/**
 * Count all Paragraphs in ProseMirror document
 *
 * @param {object} ProseMirror Node element
 * @returns {array} Found links
 */
export const proseMirrorCountParagraphs = (node) => {
  let paragraphsCount = 0;

  // Am I a Paragraph?
  if (node?.type && node.type === "paragraph") {
    paragraphsCount++;
  }

  // Got children?
  if (node?.content && node?.content.length) {
    // Foreach child
    node.content.forEach((child) => {
      // Search paragraphs in a child
      paragraphsCount += proseMirrorCountParagraphs(child);
    });
  }

  // Return the counter
  return paragraphsCount;
};

/**
 * Plan ProseMirror commands one by one using same state and dispatch
 * IMPORTANT: this does not execute commands, only returns the function
 * @param {function1} List of commands
 * @param {function2} List of commands
 * ..
 * @return {function} Commands to run
 */
export const chainTransactions = (...commands) => {
  return (state, dispatch) => {
    const dispatcher = (tr) => {
      state = state.apply(tr);
      dispatch(tr);
    };
    const last = commands.pop();
    const reduced = commands.reduce((result, command) => {
      return result || command(state, dispatcher);
    }, false);
    return reduced && last !== undefined && last(state, dispatch);
  };
};

/**
 * Check if this current position is a child of a parent of specified type
 * @param {object} Resolved position
 * @param {object} Node type
 */
export const isChildOf = (position, parentNodeType) => {
  if (!position?.depth) return false;

  for (let i = position.depth; i > 0; i--) {
    const parent = position.node(i);

    // We've found the right parent
    if (parent?.type && parent.type === parentNodeType) return true;
  }

  // No such parent was found
  return false;
};

/**
 * Returns end of the Paragraph node before the position
 * @param {Object} Document (to resolve in)
 * @param {Number} Starting position to travel from
 * @return {Number} End position of the Paragraph found OR null if not found
 */
export const findParagraphBefore = (doc, pos) => {
  const _debug = false;
  let foundPos = null;

  // Move cursor backward till the start of the document
  while (pos > 0) {
    const node = doc.resolve(pos);
    if (_debug)
      console.log(
        "findParagraphBefore.node",
        pos,
        node?.parent?.type?.name,
        node?.parent?.attrs?.blockid
      );

    if (node?.parent?.type?.name === "paragraph") {
      // Remember this pos as possibly the one we need
      foundPos = pos;

      // Check parents
      let paragraphDepth = node?.depth > 0 ? node?.depth : 0;
      while (paragraphDepth > 0) {
        const parent = node.node(paragraphDepth - 1);
        if (_debug)
          console.log(
            "findParagraphBefore.node.parent",
            paragraphDepth - 1,
            parent?.type?.name,
            parent?.attrs?.blockid
          );

        // Parent is a block_element
        if (parent?.type?.name === "block_element") {
          // if (_debug) console.log('findParagraphBefore.node.parent.block_element')

          // Closed?
          if (parent?.attrs?.open !== true) {
            if (_debug)
              console.log(
                "findParagraphBefore.node.parent.block_element.closed"
              );

            // Do we have block_title?
            const blockElementPos = node.start(paragraphDepth - 1);
            const blockElement = doc.resolve(blockElementPos);
            if (blockElement.nodeAfter?.type?.name === "block_title") {
              if (_debug)
                console.log(
                  "findParagraphBefore.node.parent.block_element.closed.block_title"
                );

              const titleParagraphPos = findParagraphAfter(
                doc,
                blockElementPos
              );
              // if (_debug) console.log('findParagraphBefore.node.parent.block_element.closed.titleParagraphPos', titleParagraphPos)
              if (titleParagraphPos) foundPos = titleParagraphPos;
            }
          }

          // Parent is a list/todo item
        } else if (
          parent?.type?.name === "list_item" ||
          parent?.type?.name === "todo_item"
        ) {
          // Closed and not a first child
          if (
            parent?.attrs?.open !== true &&
            parent?.firstChild !== node?.parent
          ) {
            if (_debug)
              console.log(
                "findParagraphBefore.node.parent.list.closed",
                parent,
                node
              );

            // Reset the found pos, let's look further
            foundPos = null;

            // Stop looking for parents
            break;
          }
        }

        paragraphDepth--;
      }

      if (foundPos !== null) return foundPos;

      // Skip this paragraph
      pos = node.start();
    }

    pos--;
  }

  // Can't find any suitable paragraph :(
  return null;
};

/**
 * Place the cursor at the end of the closest paragraph before the specified position
 * @param {Object} Current Editor state
 * @param {Object} Current transaction
 * @param {Number} Position of the cursor
 * @return {Object} New transaction
 */
export const cursorParagraphBefore = (state, tr, pos) => {
  // Find a paragraph position
  const paragraphEndPos = findParagraphBefore(state.doc, pos);

  // Paragraph was found
  if (paragraphEndPos) {
    // Set the cursor to the found position
    tr = tr.setSelection(TextSelection.create(tr.doc, paragraphEndPos));
  }

  return tr;
};

/**
 * Returns end of the Paragraph node after the position
 * @param {Object} Document (to resolve in)
 * @param {Number} Starting position to travel from
 * @return {Number} End position of the Paragraph found OR null if not found
 */
export const findParagraphAfter = (doc, pos) => {
  const _debug = false;
  if (_debug) console.log("findParagraphAfter", doc, pos);

  try {
    if (!doc?.nodeSize) return null;

    // Move cursor forward till the end of the document
    while (pos < doc.nodeSize - 1) {
      const node = doc.resolve(pos);
      if (_debug) console.log("findParagraphAfter.node", node);

      // Paragraph is found
      if (node?.parent?.type?.name === "paragraph") {
        // Return the position + paragraph content size (end of the paragraph)
        return (
          pos + (node?.parent?.content?.size ? node.parent.content.size : 0)
        );
      }

      pos++;
    }
  } catch (e) {
    console.error("findParagraphAfter.Exception", e);
  }

  return null;
};

/**
 * Create a paragraph at position
 * @param {object} Transaction
 * @param {int} Position
 * @param {boolean} Move cursor to this position
 * @return {object} Transaction
 */
export const createParagraph = (tr, pos, setCursor = true) => {
  // console.log(createParagraph, tr)

  const paragraphType = tr.doc.type.schema.nodes.paragraph;

  tr = tr.insert(pos, paragraphType.createAndFill());

  // Move cursor?
  if (setCursor) {
    tr = tr.setSelection(TextSelection.create(tr.doc, pos));
  }

  return tr;
};

/**
 * Travel up starting from cursor and open all closed parents
 * @param {object} Transaction
 * @param {number} Cursor position
 * @return {object} Transaction
 */
export const openAllClosedParents = (tr, cursorPos) => {
  const _debug = true;

  try {
    const self = tr.doc.resolve(cursorPos);
    let selfDepth = self?.depth > 0 ? self?.depth : 0;

    if (_debug) console.log("openAllClosedParents.self", selfDepth, self);

    // Travel up starting from self
    while (selfDepth > 0) {
      const parent = self.node(selfDepth);

      if (_debug) console.log("openAllClosedParents.parent", parent);

      // Parent has "open" attribute?
      if (
        parent?.attrs &&
        "open" in parent.attrs &&
        parent.attrs.open === false
      ) {
        if (_debug) console.log("openAllClosedParents.parent.open");

        // Let's open the parent!
        tr.setNodeMarkup(
          self.start(selfDepth) - 1, // get outside of the last item
          null,
          { ...parent.attrs, ...{ open: true } }
        );
      }

      selfDepth--;
    }
  } catch (e) {
    console.error("openAllClosedParents.Exception", e);
  }

  return tr;
};

/**
 * Find the closesest parent of a type and return it as a ResolvedPos
 * @param {object} Node
 * @param {object} Parent Node type
 * @return {object} Parent Node resolved
 */
export const closestParent = (doc, node, parentNodeType) => {
  if (!node?.depth) return false;

  for (let i = node.depth; i > 0; i--) {
    const parent = node.node(i);

    if (parent?.type && parent.type === parentNodeType) {
      return doc.resolve(node.start(i));
    }
  }

  return false;
};

/**
 * Check if this current Node is a child (of any depth) of another Node
 * @param {object} Resolved Child node
 * @param {object} Parent node
 */
export const nodeIsChildOfNode = (childNode, parentNode) => {
  // console.log('nodeIsChildOfNode', childNode)

  if (!childNode?.depth) return false;

  for (let i = childNode.depth; i > 0; i--) {
    const parent = childNode.node(i);

    // We've found the right parent
    // console.log('nodeIsChildOfNode.parent', parent, parentNode)
    if (parent === parentNode) return true;
  }

  // No such parent was found
  return false;
};