/**
 * System libraries
 */

// Unique IDs generation
import { nanoid } from "nanoid";

/**
 * All communications to Firestore
 * come through this wrapper.
 */

// Firestore main lib
import firebase from "firebase/app";

// Utils
import { firestoreReadGroups } from "components/Firestore/Utils";

/**
 * Modules
 */
import { getDocumentWithBlocks } from "components/Document/processor";

/**
 * Storage
 */
import LocalStorage from "components/Storage/local";

/**
 * RemoteStorage wrapper class
 */
class RemoteStorage {
  db = null;

  constructor() {
    // Init Firebase
    this.db = firebase.firestore();
  }

  /**
   * Read exact single document
   * @param {String} knowledgebaseId
   * @param {String} documentId
   */
  async wtGet(knowledgebaseId, documentId) {
    let documentData = {};

    // Fetch from Firestore
    documentData = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .doc(documentId)
      .get();

    // Not found?
    if (!documentData.exists) return null;

    // We have the data
    documentData = {
      id: documentData.id,
      ...documentData.data(),
    };

    // Convert data
    documentData = this.wtConvertAfterRead(documentData);

    return documentData;
  }

  /**
   * Blocks function
   * @param {String} Knowledgebase Id
   * @param {Array} Documents ids (strings)
   * @return {Array} Array of Documents (objects)
   */
  async wtGetDocBlocks(knowledgebaseId, documentData) {
    // console.log('RemoteStorage.wtGetDocBlocks', knowledgebaseId, documentData)

    // Empty blocks?
    if (!documentData.length) {
      // console.log('RemoteStorage.wtGetDocBlocks.Empty')
      return [];
    }

    /*
			Get blocks
		*/

    const documentBlocks = await firestoreReadGroups(
      this.db
        .collection("knowledgebases")
        .doc(knowledgebaseId)
        .collection("documents"),
      documentData
    );

    // Sort
    let orderedBlocks = [];
    documentData.forEach((documentId) => {
      // Convert data
      documentBlocks[documentId] = this.wtConvertAfterRead(
        documentBlocks[documentId]
      );

      // Append to array
      orderedBlocks.push({
        id: documentId,
        ...documentBlocks[documentId],
      });
    });

    // Return blocks
    return orderedBlocks;
  }

  /**
   * Save the Document and it's Blocks to Firestore if the version allows
   * @param {String} Knowledgebase Id
   * @param {String} Document Id
   * @param {Int} Document version
   * @param {Object} Current blocks
   * @param {Array} Missing blocks ids
   * @param {String} Writer process id (App id)
   * @param {Object} Javascript Date object
   */
  async wtStoreDocument(
    knowledgebaseId,
    documentId,
    documentVersion,
    blocksCurrent,
    blocksMissing,
    processId,
    datetimeUpdated
  ) {
    /*
		console.log(
			'RemoteStorage.wtStoreDocument',
			knowledgebaseId,
			documentId,
			documentVersion,
			blocksCurrent,
			blocksMissing,
			processId,
			datetimeUpdated
		)
		*/

    /*
			Prepare data
		*/

    // Do we need to update the document in cache?
    let docReloadRequired = false;

    // Array of current Block ids (to save in the main document)
    const currentBlockIds = Object.keys(blocksCurrent);

    // Table to process as transaction
    const docRef = this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .doc(documentId);

    /*
			Run transaction
		*/

    var VersionException = {};

    try {
      await this.db.runTransaction(async (transaction) => {
        // console.log('RemoteStorage.wtStoreDocument.runTransaction')

        /*
					Get actual document version
				*/

        // Get the document
        const doc = await transaction.get(docRef);

        // Not found?
        if (!doc.exists) return null;

        // Get document data
        const docData = doc.data();

        /*
					Check version
					VERSION COMMENT

				// Reset version to zero if are not set
				if (!('_version' in docData)) docData._version = 0

				console.log('RemoteStorage.wtStoreDocument.Versions (local, remote)', documentVersion, docData._version)
				
				// Can't update, the version changed!
				if (docData._version > documentVersion) {
					// We need to update the local version!
					docReloadRequired = true

					console.log('RemoteStorage.wtStoreDocument.Version.Changed')
					
					throw VersionException
				}
				*/

        /*
					Datetime updated check
				*/

        // Check
        let docDataDatetimeUpdated = 0;
        if (docData?.datetime_updated) {
          // Convert to Javascript Date object
          docDataDatetimeUpdated = docData.datetime_updated.toDate();

          // Convert to timestamp number
          docDataDatetimeUpdated = docDataDatetimeUpdated.getTime();
        }

        /*
				console.log(
					'RemoteStorage.wtStoreDocument.dateUpdated (local, remote)',
					datetimeUpdated.getTime(),
					docDataDatetimeUpdated
				)
				*/

        // Can't update, the datetime_updated is higher
        if (docDataDatetimeUpdated > datetimeUpdated.getTime()) {
          // We need to update the local version!
          docReloadRequired = true;

          // console.log('RemoteStorage.wtStoreDocument.dateUpdated.Changed')

          throw VersionException;
        }

        /*
					Update version
				*/

        const newVersion = docData._version + 1;

        /*
					Remove blocks
				*/

        // No change?
        if (!blocksMissing || blocksMissing.length === 0) {
          // console.log('RemoteStorage.wtStoreDocument.blocksMissing.Empty')
          // Remove missing docs
        } else {
          // console.log('RemoteStorage.wtStoreDocument.blocksMissing')

          for (const currentBlockId of blocksMissing) {
            // console.log('RemoteStorage.wtStoreDocument.blocksMissing.Delete', currentBlockId)

            // Add to Transaction, Delete
            await transaction.delete(
              this.db
                .collection("knowledgebases")
                .doc(knowledgebaseId)
                .collection("documents")
                .doc(currentBlockId)
            );
          }
        }

        /*
					Add/update blocks
				*/

        // console.log('RemoteStorage.wtStoreDocument.blocksCurrent')

        // Blocks
        for (let blockId in blocksCurrent) {
          // console.log('RemoteStorage.wtStoreDocument.blocksCurrent.Save', blockId, blocksCurrent[blockId])

          // Add to Transaction, Set (To create or overwrite a single document)
          await transaction.set(
            this.db
              .collection("knowledgebases")
              .doc(knowledgebaseId)
              .collection("documents")
              .doc(blockId),
            {
              is_block: true,
              parent_id: documentId,
              json: blocksCurrent[blockId].json, // '{"type":"paragraph","content":[{"type":"text","text":"Hello, there!"}]}'
            }
          );
        }

        /*
					Save THE document
				*/

        // console.log('RemoteStorage.wtStoreDocument.saveDoc')

        // Update date
        const firestoreDatetimeUpdated = firebase.firestore.Timestamp.fromDate(
          datetimeUpdated
        );

        // Add to Transaction, Update
        await transaction.update(docRef, {
          blocks: currentBlockIds,
          datetime_updated: firestoreDatetimeUpdated,
          // We have a new version!
          _version: newVersion,
          // Who is the writer?
          _process_id: processId,
        });

        /*
					Save actual version of the document to local storage
					to prevent Firestore.onSnapshot to trigger reload
					on our own update we just initiated.
					We need to do it inside transaction to prevent the onSnapshot coming
					earlier than local save
				*/

        // console.log('RemoteStorage.wtStoreDocument.wtDocumentVersion', documentId, newVersion)

        LocalStorage.initDb(knowledgebaseId);
        await LocalStorage.wtDocumentVersionSet(
          documentId,
          newVersion,
          firestoreDatetimeUpdated
        );
      });

      // console.log('RemoteStorage.wtStoreDocument.Transaction.Success')
    } catch (e) {
      // Transaction failed for unknown reason
      if (e !== VersionException) {
        console.error("RemoteStorage.wtStoreDocument.Transaction.Failure", e);
        throw e;
      }

      // That's a possible version changed exception
      // so we should do nothing
    }

    /*
			Document reload required?
		*/
    if (docReloadRequired) {
      // console.log('RemoteStorage.wtStoreDocument.docReloadRequired')

      // Re-read the document data
      await getDocumentWithBlocks(
        knowledgebaseId,
        documentId,
        true, // force reading from Remote and writing to Local
        true // we do not need Json
      );
    }

    // console.log('RemoteStorage.wtStoreDocument.Finished')
  }

  /**
   * Listen to the document changes
   * @doc https://firebase.google.com/docs/firestore/query-data/listen#web
   * @param {string} Knowledgebase Id
   * @param {string} Document id
   * @param {function} Change processor
   * @return {Function} Unsibscribe
   */
  wtDocumentListen(knowledgebaseId, documentId, onSnapshotProcessor) {
    // console.log('RemoteStorage.wtDocumentListen', documentId)

    // Bypass the initial (first load) snapshot
    let initState = true;

    // Attach
    const unsubscribe = this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .doc(documentId)
      .onSnapshot(
        {
          // Listen for document metadata changes
          // includeMetadataChanges: true
        },
        (doc) => {
          /*
					Initial load?
				*/

          if (initState) {
            initState = false;
            // console.log('RemoteStorage.wtDocumentListen.onSnapshot.initial', doc.id, documentId)
            return;
          }

          // console.log('RemoteStorage.wtDocumentListen.onSnapshot', doc.id, documentId) // , doc

          /*
					Validate the data
				*/

          // Missing id
          if (!doc?.id) {
            console.error(
              "RemoteStorage.wtDocumentListen.onSnapshot.missingId"
            );
            return;
          }

          // Document changed!
          if (documentId !== doc.id) {
            console.error(
              "RemoteStorage.wtDocumentListen.onSnapshot.docDiff",
              documentId,
              doc.id
            );
            return;
          }

          /*
				THIS DOES NOT WORK WELL WITH enablePersistence && synchronizeTabs
				// This is a selfmade change (local)?
				// var changeSource = doc.metadata.hasPendingWrites ? "Local" : "Server";
				if (doc?.metadata?.hasPendingWrites) {
					console.log('RemoteStorage.wtDocumentListen.onSnapshot.isLocal')
					return
				}
				*/

          /*
					Process the change
				*/

          let documentData = doc.data();

          // Convert data
          documentData = this.wtConvertAfterRead(documentData);

          // Process
          onSnapshotProcessor(
            doc.id, // Document id
            documentData // Document data
          );
        }
      );

    // Function to detach the Firestore Listener
    return unsubscribe;
  }

  /**
   * Get/create a document for exact date (NO TRANSACTION, UNSAFE!)
   * @param {string} Knowledgebase Id
   * @param {object} Date object
   * @returns {string} Document Id
   */
  async wtSearchDocumentDate(knowledgebaseId, dateObject) {
    console.log(
      "RemoteStorage.wtSearchDocumentDate",
      knowledgebaseId,
      dateObject
    );

    /*
			Sadly I can't use Transaction for checking if document does not exist.
			This will require Firestore to lock ALL the document collection and look for changes.
			The right approach for uniqueness is a separate collection like:
			collection('dates').doc('2020-10-24') where I can set a lock using Transaction.
			For now I'm skipping this as it's too much time.
			
			Info:
				With Firestore, you can only guarantee uniqueness on a document ID,
				and not by the contents of a document's fields.
				What you could do instead is concatenate the two participant IDs
				into a single string and use that as the document ID.
				Then, you would use a transaction to ensure that a document does
				not already exist with that new composite ID before writing it.

			OR using Firestore rules:
				https://stackoverflow.com/questions/54236388/prevent-duplicate-entries-in-firestore-rules-not-working/54238476#54238476
				and catching Exceptions in writing

			Transaction below is NOT working!
		* /

		// We need the Document Id from it
		const documentId = await this.db.runTransaction(async (transaction) => {
			console.log('RemoteStorage.wtSearchDocumentDate.runTransaction')

			/*
				Search for the document with this date
			* /
			
			// Fetch a document from Firestore
			const dateDocument = await transaction.get(
				// ERROR! I need exact document Reference here
				this.db
					.collection('knowledgebases').doc(knowledgebaseId)
					.collection('documents')
					.where('is_page', '==', true)
					.where('is_date', '==', true)
					.where('date_set', '==', dateObject.firestore)
					.limit(1)
			)
			
			// Found
			if (!dateDocument.empty) {
				console.log('RemoteStorage.wtSearchDocumentDate.Found', dateDocument.docs[0].id)

				// Return it's id
				return dateDocument.docs[0].id
			}

			// Save the new document without a transaction
			const newDocumentId = await this.wtSaveDocument(
				knowledgebaseId,
				{
					is_date: true,
					date_set: dateObject.firestore,
					title: dateObject.long,
				},
				transaction
			)
			
			console.log('RemoteStorage.wtSearchDocumentDate.Added', newDocumentId)
			return newDocumentId
		})
		
		console.log('RemoteStorage.wtSearchDocumentDate.Transaction.Success', documentId)
		*/

    /*
			Date doc exists
		*/

    const dateDocument = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .where("is_page", "==", true)
      .where("is_date", "==", true)
      .where("date_set", "==", dateObject.firestore)
      .limit(1)
      .get();

    // Found
    if (!dateDocument.empty) {
      console.log(
        "RemoteStorage.wtSearchDocumentDate.Found",
        dateDocument.docs[0].id
      );

      // Return it's id
      return dateDocument.docs[0].id;
    }

    /*
			Create a document
		*/

    console.log("RemoteStorage.wtSearchDocumentDate.notFound", dateObject.long);

    // Save the new document without a transaction
    const newDocumentId = await this.wtSaveDocument(knowledgebaseId, {
      is_date: true,
      date_set: dateObject.firestore,
      title: dateObject.long,
    });

    console.log("RemoteStorage.wtSearchDocumentDate.Added", newDocumentId);

    // Return the document id
    return newDocumentId;
  }

  /**
   * Get/create a document for exact date via Transaction
   * @param {string} Knowledgebase Id
   * @param {object} Date object (own)
   * @returns {string} Document Id
   */
  async wtSearchDocumentDateTransaction(knowledgebaseId, dateObject) {
    const _debug = false;

    if (_debug) {
      console.log(
        "RemoteStorage.wtSearchDocumentDateTransaction",
        knowledgebaseId,
        dateObject
      );
    }

    /*
			Prepare the write data if
			I will need to write the new document later
		*/

    // Generate new document Id
    const newDocId = nanoid(10);

    // Prepare new document data
    const newDocData = {
      _process_id: global.processId,
      _version: 0,
      is_date: true,
      is_page: true,
      blocks: [],
      date_set: dateObject.firestore,
      title: dateObject.long,
      datetime_created: firebase.firestore.Timestamp.now(),
      datetime_title_updated: firebase.firestore.Timestamp.now(),
    };
    const firestoreConvertedNewDocData = this.wtConvertBeforeSave(newDocData);

    // The document is not created
    let newDocCreated = false;

    /*
			Search/create the date document using Firestore Transaction
		*/

    // I need to lock specific document with known id:
    const docTitleRef = this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("doctitles")
      .doc(dateObject.long);

    // We need the Document Id from it
    const documentId = await this.db.runTransaction(async (transaction) => {
      if (_debug)
        console.log("RemoteStorage.wtSearchDocumentDateTransaction.run");

      // Fetch a document from Firestore
      const docTitle = await transaction.get(docTitleRef);

      /*
				DocTitle found
			*/

      if (docTitle.exists) {
        if (_debug)
          console.log(
            "RemoteStorage.wtSearchDocumentDateTransaction.docTitleRef",
            docTitle,
            docTitle.data().documentId
          );

        // Return document id
        return docTitle.data().documentId;
      }

      if (_debug)
        console.log(
          "RemoteStorage.wtSearchDocumentDateTransaction.noDocTitleRef"
        );

      /*
				DocTitle not found
			*/

      // Fall back to simple document search
      const dateDocument = await this.db
        .collection("knowledgebases")
        .doc(knowledgebaseId)
        .collection("documents")
        .where("is_page", "==", true)
        .where("is_date", "==", true)
        .where("date_set", "==", dateObject.firestore)
        .limit(1)
        .get();

      // Document found
      if (!dateDocument.empty) {
        if (_debug)
          console.log(
            "RemoteStorage.wtSearchDocumentDateTransaction.docFound",
            dateDocument.docs[0].id
          );

        // Return it's id
        return dateDocument.docs[0].id;
      }

      /*
				Let's create new Document
			*/

      if (_debug)
        console.log(
          "RemoteStorage.wtSearchDocumentDateTransaction.notFound",
          dateObject.long
        );

      // Create the Document
      await transaction.set(
        this.db
          .collection("knowledgebases")
          .doc(knowledgebaseId)
          .collection("documents")
          .doc(newDocId),
        firestoreConvertedNewDocData,
        { merge: true }
      );

      // Create DocTitle
      await transaction.set(
        docTitleRef,
        {
          documentId: newDocId,
          datetime_created: firebase.firestore.Timestamp.now(),
        },
        { merge: true }
      );

      if (_debug)
        console.log(
          "RemoteStorage.wtSearchDocumentDateTransaction.Added",
          newDocId
        );

      // We have a new doc
      newDocCreated = true;

      // Return the generated id
      return newDocId;
    });

    if (_debug)
      console.log(
        "RemoteStorage.wtSearchDocumentDateTransaction.Success",
        documentId,
        newDocCreated
      );

    // New document created?
    if (newDocCreated) {
      if (_debug)
        console.log(
          "RemoteStorage.wtSearchDocumentDateTransaction.newDocCreated",
          documentId,
          newDocData
        );

      // Write to local storage
      LocalStorage.initDb(knowledgebaseId);
      LocalStorage.wtWrite({
        id: documentId,
        ...newDocData,
      });
    }

    if (_debug)
      console.log("RemoteStorage.wtSearchDocumentDateTransaction.Done");

    // Return the document id
    return documentId;
  }

  /**
   * Get a document before exact date
   * @param {string} Knowledgebase Id
   * @param {object} JavaScript Date Object
   * @returns {object} Document data
   */
  async wtSearchDocumentDateBefore(knowledgebaseId, jsDateObject) {
    // console.log('RemoteStorage.wtSearchDocumentDateBefore', knowledgebaseId, jsDateObject)

    /*
			Search for the document before this date
		*/

    const firestoreDate = firebase.firestore.Timestamp.fromDate(jsDateObject);
    // console.log('RemoteStorage.wtSearchDocumentDateBefore.firestoreDate', firestoreDate)

    // Fetch a document from Firestore
    const dateDocument = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .where("is_page", "==", true)
      .where("is_date", "==", true)
      .where("date_set", "<", firestoreDate)
      .orderBy("date_set", "desc")
      .limit(1)
      .get();
    // console.log('RemoteStorage.wtSearchDocumentDateBefore.raw', dateDocument)

    // Found
    if (!dateDocument.empty) {
      // console.log('RemoteStorage.wtSearchDocumentDateBefore.Found', dateDocument.docs[0].id)

      // Convert data
      let documentData = this.wtConvertAfterRead(dateDocument.docs[0].data());

      // Return Document id and data
      return {
        id: dateDocument.docs[0].id,
        ...documentData,
      };
    }

    // Not found
    // console.log('RemoteStorage.wtSearchDocumentDateBefore.notFound')
    return {};
  }

  /**
   * Search a document with conditions
   * @param {string} Knowledgebase Id
   * @param {object} Search conditions
   * @returns {object} Document data or null
   */
  async wtSearch(knowledgebaseId, searchConditions) {
    // console.log('RemoteStorage.wtSearch', knowledgebaseId, searchConditions)

    // Do we already have a document with this Title in database?
    const foundDocument = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .where("title", "==", searchConditions.title)
      .limit(1)
      .get();

    // Yeap, we have it already
    if (!foundDocument.empty) {
      // console.log('RemoteStorage.wtSearch.Exists', foundDocument)

      // Convert data
      let documentData = this.wtConvertAfterRead(foundDocument.docs[0].data());

      // Return document with id
      return {
        id: foundDocument.docs[0].id,
        ...documentData,
      };
    }

    // Not found
    return null;
  }

  /**
   * Save document in Firestore
   *
   * @param {string} Knowledgebase Id
   * @param {object} Document data
   * @param {object} Firestore transaction handle
   * @returns {string} Document Id
   */
  async wtSaveDocument(
    knowledgebaseId,
    documentData,
    transactionHandle = null
  ) {
    console.log("RemoteStorage.wtSaveDocument", knowledgebaseId, documentData);

    /*
			Add data to the Document object
		*/

    // Process id?
    if (!documentData?._process_id) {
      documentData._process_id = global.processId;
    }

    // No version?
    if (!documentData?._version) {
      documentData._version = 0;
    }

    // Is Page
    if (!documentData?.is_page) {
      documentData.is_page = true;
    }

    // Empty blocks
    if (!documentData?.blocks) {
      documentData.blocks = [];
    }

    // Empty Title
    if (!documentData?.title) {
      documentData.title = "";
    }

    // Datetime created
    if (!documentData?.datetime_created) {
      documentData.datetime_created = firebase.firestore.Timestamp.now();
    }

    // Title is not empty, and there is no datetime_title_updated
    if (documentData.title !== "" && !documentData?.datetime_title_updated) {
      documentData.datetime_title_updated = firebase.firestore.Timestamp.now();
    }

    /*
			Document Id
		*/

    // Generate new document Id
    const documentId = nanoid(10);

    console.log("RemoteStorage.wtSaveDocument.Data", documentId, documentData);

    /*
			Save it

			Firestore DataTypes:
			https://firebase.google.com/docs/firestore/manage-data/data-types
		*/

    const firestoreConvertedDocumentData = this.wtConvertBeforeSave(
      documentData
    );

    // Via Transaction
    if (transactionHandle !== null) {
      // Add to Transaction, Set (To create or overwrite a single document)
      await transactionHandle.set(
        // Document reference
        this.db
          .collection("knowledgebases")
          .doc(knowledgebaseId)
          .collection("documents")
          .doc(documentId),
        // Data
        firestoreConvertedDocumentData,
        // Options
        { merge: true }
      );

      // Simple operation
    } else {
      // Save in Firestore
      await this.db
        .collection("knowledgebases")
        .doc(knowledgebaseId)
        .collection("documents")
        .doc(documentId)
        .set(
          firestoreConvertedDocumentData,
          // The merge here ensures we will not overwrite the data in case it appeared inbetween
          { merge: true }
        );
    }

    console.log("RemoteStorage.wtSaveDocument.Saved", documentId);

    /*
			Write to local storage
		*/

    LocalStorage.initDb(knowledgebaseId);
    LocalStorage.wtWrite({
      id: documentId,
      ...documentData,
    });

    /*
			Return result
		*/

    // Return the document id
    return documentId;
  }

  /**
   * We need to convert Firestore format to App format after reading
   * @param {object} Document data to process
   * @return {object} Document data to return
   */
  wtConvertAfterRead(documentData) {
    // Empty? (undefined, null, ..)
    if (!documentData) {
      // do nothing
      // Is Array?
    } else if (Array.isArray(documentData)) {
      // Recursive processing
      return documentData.map((currentDocument) => {
        return this.wtConvertAfterRead(currentDocument);
      });

      // Is Object
    } else if (typeof documentData === "object" && documentData !== null) {
      const newDocument = { ...documentData };

      /*
				Missing fields
			*/

      // Check version
      if (!("_version" in newDocument)) newDocument._version = 0;

      /*
				Convert Firestore Timestamp object to Javascript Date
				https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp#fromdate
			*/

      // Date set
      if ("date_set" in newDocument) {
        // Is Firestore Timestmap?
        if (newDocument.date_set instanceof firebase.firestore.Timestamp) {
          // Convert to Javascript Date Object
          newDocument.date_set = newDocument.date_set.toDate();
        }
      }

      // Updated
      if ("datetime_updated" in newDocument) {
        // Is Firestore Timestmap?
        if (
          newDocument.datetime_updated instanceof firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newDocument.datetime_updated = newDocument.datetime_updated.toDate();
        }
      }

      // Created
      if ("datetime_created" in newDocument) {
        // Is Firestore Timestmap?
        if (
          newDocument.datetime_created instanceof firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newDocument.datetime_created = newDocument.datetime_created.toDate();
        }
      }

      // Title updated date
      if ("datetime_title_updated" in newDocument) {
        // Is Firestore Timestmap?
        if (
          newDocument.datetime_title_updated instanceof
          firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newDocument.datetime_title_updated = newDocument.datetime_title_updated.toDate();
        }
      }

      return newDocument;

      // Unknown
    } else {
      console.error("wtConvertAfterRead.unknownFormat", documentData);
    }

    return documentData;
  }

  /**
   * We need to insure data is of valid Firestore format before saving.
   * @param {object} Document data to process
   * @return {object} Document data to return
   */
  wtConvertBeforeSave(documentData, versionAdd = true) {
    /*
			Settings
		*/

    const _debug = false;

    /*
			Process
		*/

    if (_debug) console.log("wtConvertBeforeSave.source", documentData);

    // Empty? (undefined, null, ..)
    if (!documentData) {
      // do nothing
      // Is Array?
    } else if (Array.isArray(documentData)) {
      // Recursive processing
      return documentData.map((currentDocument) => {
        return this.wtConvertBeforeSave(currentDocument);
      });

      // Is Object
    } else if (typeof documentData === "object" && documentData !== null) {
      const newDocument = { ...documentData };

      /*
				Removing unnecessary data
			*/

      if ("_localStorage" in newDocument) delete newDocument._localStorage;

      /*
				Missing fields
			*/

      // Check version
      if (versionAdd) {
        if (!("_version" in newDocument)) newDocument._version = 0;
      }

      /*
				Booleans conversion
			*/

      // Is page
      if ("is_page" in newDocument && typeof newDocument.is_page === "number")
        newDocument.is_page = newDocument.is_page === 1 ? true : false;

      // Is block
      if ("is_block" in newDocument && typeof newDocument.is_block === "number")
        newDocument.is_block = newDocument.is_block === 1 ? true : false;

      // Is date
      if ("is_date" in newDocument && typeof newDocument.is_date === "number")
        newDocument.is_date = newDocument.is_date === 1 ? true : false;

      // Is shared
      if (
        "is_shared" in newDocument &&
        typeof newDocument.is_shared === "number"
      )
        newDocument.is_shared = newDocument.is_shared === 1 ? true : false;

      /*
				Objects conversion
			*/

      /*
				Convert Javascript Date object to Firestore Timestamp object
				https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp#fromdate

				IMPORTANT:
				value could be "instanceof fb.firestore.FieldValue"
				when deleting a field or updating it
				@doc https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#delete
			*/

      // Date set
      if ("date_set" in newDocument) {
        // Check before conversion
        if (
          // Is NOT Firestore Timestmap?
          !(newDocument.date_set instanceof firebase.firestore.Timestamp) &&
          // AND is a Javascript Date
          newDocument.date_set instanceof Date
        ) {
          // Convert to Firestore Timestmap
          newDocument.date_set = firebase.firestore.Timestamp.fromDate(
            newDocument.date_set
          );
        }
      }

      // Updated
      if ("datetime_updated" in newDocument) {
        // Check before conversion
        if (
          // Is NOT Firestore Timestmap?
          !(
            newDocument.datetime_updated instanceof firebase.firestore.Timestamp
          ) &&
          // AND is a Javascript Date
          newDocument.datetime_updated instanceof Date
        ) {
          // Convert to Firestore Timestmap
          newDocument.datetime_updated = firebase.firestore.Timestamp.fromDate(
            newDocument.datetime_updated
          );
        }
      }

      // Created
      if ("datetime_created" in newDocument) {
        // Check before conversion
        if (
          // Is NOT Firestore Timestmap?
          !(
            newDocument.datetime_created instanceof firebase.firestore.Timestamp
          ) &&
          // AND is a Javascript Date
          newDocument.datetime_created instanceof Date
        ) {
          // Convert to Firestore Timestmap
          newDocument.datetime_created = firebase.firestore.Timestamp.fromDate(
            newDocument.datetime_created
          );
        }
      }

      // Title updated date
      if ("datetime_title_updated" in newDocument) {
        // Check before conversion
        if (
          // Is NOT Firestore Timestmap?
          !(
            newDocument.datetime_title_updated instanceof
            firebase.firestore.Timestamp
          ) &&
          // AND is a Javascript Date
          newDocument.datetime_title_updated instanceof Date
        ) {
          // Convert to Firestore Timestmap
          newDocument.datetime_title_updated = firebase.firestore.Timestamp.fromDate(
            newDocument.datetime_title_updated
          );
        }
      }

      if (_debug) console.log("wtConvertBeforeSave.result", newDocument);
      return newDocument;

      // Unknown
    } else {
      console.error("wtConvertBeforeSave.unknownFormat", documentData);
    }

    if (_debug) console.log("wtConvertBeforeSave.result", documentData);
    return documentData;
  }

  /**
   * Update exact document with new data (only passed parameters will be updated)
   * @param {string} Knowledgebase Id
   * @param {string} Document id
   * @param {object} Update data
   * @returns {boolean}
   */
  async wtUpdateDocument(knowledgebaseId, documentId, newData) {
    // console.log('RemoteStorage.wtUpdateDocument', knowledgebaseId, documentId, newData)

    /*
			Pre-process updated data
		*/

    // Title in update?
    // Add datetime_title_updated if none
    if (
      !newData?.datetime_title_updated &&
      "title" in newData &&
      newData.title !== ""
    ) {
      newData.datetime_title_updated = firebase.firestore.Timestamp.now();
    }

    // Title, block or reference update?
    // Add datetime_updated if none
    if (
      !newData?.datetime_updated &&
      /*
				Can't monitor for title change
				(
					'title' in newData
					&& newData.title !== ''
				)
				|| */
      ("blocks" in newData || "references" in newData)
    ) {
      newData.datetime_updated = firebase.firestore.Timestamp.now();
    }

    // Convert the data into correct format
    newData = this.wtConvertBeforeSave(newData, false);

    /*
			Update Firestore document
			@doc https://firebase.google.com/docs/firestore/manage-data/add-data
			To update some fields of a document without overwriting the entire document,
			use the update() method
		*/
    await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .doc(documentId)
      .update(newData);

    return true;
  }

  /**
   * Delete exact document in Firestore
   * @param {string} Knowledgebase Id
   * @param {string} Document id
   * @returns {boolean}
   */
  async wtDeleteDocument(knowledgebaseId, documentId) {
    console.log("RemoteStorage.wtDeleteDocument", knowledgebaseId, documentId);

    await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .doc(documentId)
      .delete();

    // Ensure the LocalStorage document is also deleted
    LocalStorage.initDb(knowledgebaseId);
    LocalStorage.wtDelete(documentId);

    return true;
  }

  /**
   * Update the Document references array
   * @param {string} Knowledgebase Id
   * @param {string} Document id to which the reference is set
   * @param {string} Block id from which the reference is going
   * @param {boolean} Delete the reference?
   * @return {boolean}
   */
  async wtUpdateDocumentReferences(
    knowledgebaseId,
    linkId,
    blockId,
    deleteReference = false
  ) {
    /*
			Settings
		*/

    const _debug = false;

    /*
			Process
		*/

    // Add a new Reference
    if (!deleteReference) {
      if (_debug)
        console.log(
          "RemoteStorage.wtUpdateDocumentReferences.Add",
          linkId,
          blockId
        );

      await this.db
        .collection("knowledgebases")
        .doc(knowledgebaseId)
        .collection("documents")
        .doc(linkId)
        .update({
          references: firebase.firestore.FieldValue.arrayUnion(blockId),
        });

      // Remove a Reference
    } else {
      if (_debug)
        console.log(
          "RemoteStorage.wtUpdateDocumentReferences.Delete",
          linkId,
          blockId
        );

      await this.db
        .collection("knowledgebases")
        .doc(knowledgebaseId)
        .collection("documents")
        .doc(linkId)
        .update({
          references: firebase.firestore.FieldValue.arrayRemove(blockId),
        });
    }

    return true;
  }

  /**
   * Get all the documents from the Knowledgebase
   * @param {String} knowledgebaseId
   * @return {Array} Array of document objects
   */
  async wtGetAllDocs(knowledgebaseId) {
    // Snapshot
    const snapshot = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .get();

    return snapshot.docs.map((doc) => {
      // Convert data
      const documentData = this.wtConvertAfterRead(doc.data());

      return {
        id: doc.id,
        ...documentData,
      };
    });
  }

  /**
   * Get all the Pages (is_page = true) from the Knowledgebase
   * @param {String} knowledgebaseId
   * @return {Array} Array of document objects
   */
  async wtGetAllPages(knowledgebaseId) {
    // Snapshot
    const snapshot = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .where("is_page", "==", true)
      .get();

    return snapshot.docs.map((doc) => {
      // Convert data
      const documentData = this.wtConvertAfterRead(doc.data());

      return {
        id: doc.id,
        ...documentData,
      };
    });
  }

  /**
   * Get all the Pages (is_page = true) with Title updated after lastPagesSync Date
   * @param {String} knowledgebaseId
   * @param {Object} Javascript Date object
   * @return {Array} Array of document objects
   */
  async wtGetPagesWithTitleUpdate(knowledgebaseId, lastPagesSync) {
    // Snapshot
    const snapshot = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .where("is_page", "==", true)
      .where(
        "datetime_title_updated",
        ">",
        firebase.firestore.Timestamp.fromDate(lastPagesSync)
      )
      .get();

    return snapshot.docs.map((doc) => {
      // Convert data
      const documentData = this.wtConvertAfterRead(doc.data());

      return {
        id: doc.id,
        ...documentData,
      };
    });
  }

  /**
   * Get all the Pages (is_page = true) with created/updated after lastSyncCompat Date
   * @param {String} knowledgebaseId
   * @param {Object} Javascript Date opbject
   * @return {Array} Array of document objects
   */
  async wtGetPagesWithUpdated(knowledgebaseId, lastSyncCompat) {
    /*
			Reset
		*/

    let knownDocIds = [];
    let combinedDocs = [];

    /*
			Get datetime_updated
		*/

    const updatedDocs = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .where("is_page", "==", true)
      .where(
        "datetime_updated",
        ">",
        firebase.firestore.Timestamp.fromDate(lastSyncCompat)
      )
      .get();

    // Do we have docs?
    if (!updatedDocs.empty) {
      // console.log('wtGetPagesWithUpdated.updatedDocs', updatedDocs.docs.length, updatedDocs.docs)

      for (const currentDocument of updatedDocs.docs) {
        // Not yet saved
        if (knownDocIds.indexOf(currentDocument.id) === -1) {
          knownDocIds.push(currentDocument.id);

          // Convert data
          const documentData = this.wtConvertAfterRead(currentDocument.data());

          // Add to documents array
          combinedDocs.push({
            id: currentDocument.id,
            ...documentData,
          });
        }
      }
    }

    /*
			Get datetime_created
		*/

    const createdDocs = await this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .where("is_page", "==", true)
      .where(
        "datetime_created",
        ">",
        firebase.firestore.Timestamp.fromDate(lastSyncCompat)
      )
      .get();

    // Do we have docs?
    if (!createdDocs.empty) {
      // console.log('wtGetPagesWithUpdated.createdDocs', createdDocs.docs.length, createdDocs.docs)

      for (const currentDocument of createdDocs.docs) {
        // Not yet saved
        if (knownDocIds.indexOf(currentDocument.id) === -1) {
          knownDocIds.push(currentDocument.id);

          // Convert data
          const documentData = this.wtConvertAfterRead(currentDocument.data());

          // Add to documents array
          combinedDocs.push({
            id: currentDocument.id,
            ...documentData,
          });
        }
      }
    }

    // Return docs
    // console.log('wtGetPagesWithUpdated', combinedDocs, knownDocIds)
    return combinedDocs;
  }

  /**
   * Listen to the title updates
   * @doc https://firebase.google.com/docs/firestore/query-data/listen#events-local-changes
   * @param {String} Knowledgebase Id
   * @param {Object} Last known update, Javascript Date
   * @param {Function} Change processor
   * @return {Function} Unsibscribe
   */
  wtTitleUpdatesListen(knowledgebaseId, lastPagesSync, onSnapshotProcessor) {
    // console.log('RemoteStorage.wtTitleUpdatesListen', knowledgebaseId, lastPagesSync)

    // Attach
    const unsubscribe = this.db
      .collection("knowledgebases")
      .doc(knowledgebaseId)
      .collection("documents")
      .where("is_page", "==", true)
      .where(
        "datetime_title_updated",
        ">",
        firebase.firestore.Timestamp.fromDate(lastPagesSync)
      )
      .onSnapshot((snapshot) => {
        // console.log('RemoteStorage.wtTitleUpdatesListen.snapshot', snapshot)

        /*
					Pre-process pages
				*/

        let syncPages = [];
        // snapshot.docChanges().forEach(function (change) {
        for (const change of snapshot.docChanges()) {
          console.log("RemoteStorage.wtTitleUpdatesListen.docChanges", change);

          // Added or modified only
          if (
            change?.type === "added" ||
            change?.type === "modified"
            // change.type === "removed"
          ) {
            console.log(
              "RemoteStorage.wtTitleUpdatesListen.docProcess",
              change.doc.id
            );

            // Convert data
            let documentData = change.doc.data();
            documentData = this.wtConvertAfterRead(documentData);

            // Push to array
            syncPages.push({
              id: change.doc.id,
              ...documentData,
            });
          }
        }
        // })

        /*
					Got pages to update?
				*/

        if (syncPages && syncPages.length > 0) {
          console.log(
            "RemoteStorage.wtTitleUpdatesListen.runOnSnapshotProcessor"
          );

          // Process
          onSnapshotProcessor(knowledgebaseId, syncPages, lastPagesSync);
        }
      });

    // Function to detach the Firestore Listener
    return unsubscribe;
  }
}

export default new RemoteStorage();
