/**
 * Toggle the list item as done
 *
 * @param {object} Current state
 * @param {int} Cursor position
 * @param {object} Current item (Node)
 */
export function TodoToggle(state, pos, todoItemNode) {
  // console.log('TodoToggle')

  // Replace element with new one with reverted "done" attribute
  return state.tr.setNodeMarkup(pos, null, {
    done: !todoItemNode.attrs.done,
  });
}

/**
 * Process the Todo list item "done" attribute toggle.
 * @param {object} List Node type (todo_list)
 * @return {function} PRocessing function
 */
export function todoMarkToggle(listType) {
  /**
   * Do the conversion
   * @param {object} Current document state
   * @param {object} Transformation dispatcher (if missing - the command will not run)
   * @return {boolean} Processing is possible (if no dispatch)? Processing is successfull (with dispatch)?
   */
  return function (state, dispatch) {
    console.log("todoMarkToggle", listType);

    /*
			Prepare the data to work with
		*/

    const { $from, $to } = state?.selection;

    /*
			Single cursor position (using from)
		*/

    // console.log('todoMarkToggle', $from)

    if (
      // Got from?
      $from &&
      // A list item could be not higher than 3 (doc(0) > list(1) > list_item(2) > paragraph(3))
      $from?.depth >= 3
    ) {
      // console.log('todoMarkToggle.from', $from)

      /*
				Validate
			*/

      const schemaNodes = state.doc.type.schema.nodes;
      const listItemNode = $from.node($from.depth - 1);
      const listNode = $from.node($from.depth - 2);

      if (
        listNode?.type != schemaNodes.todo_list ||
        listItemNode?.type != schemaNodes.todo_item
      ) {
        return false;
      }

      /*
				Mass toggle (not working, needs remake)
			let tr = state.tr
			let range = $from.blockRange($to)
			let list = range.parent

			console.log('todoMarkToggle.range', range)

			for (let pos = range.end, i = range.endIndex - 1, e = range.startIndex; i > e; i--) {
				const toggleNode = list.child(i)
				pos -= toggleNode.nodeSize

				console.log('todoMarkToggle.toggleNode', pos, toggleNode)
				
				// tr.delete(pos - 1, pos + 1)

				// List item attrs
				if (!('attrs' in toggleNode)) toggleNode.attrs = { }
				const newAttrs = { ...toggleNode.attrs, ...{ done: !toggleNode.attrs?.done } }
				
				// console.log('todoMarkToggle.newAttrs', newAttrs)
				
				// Set the mark
				tr.setNodeMarkup(
					pos,
					null,
					newAttrs
				)
			}

			// console.log('todoMarkToggle.newAttrs', listNode, listItemNode, range)
			*/

      /*
				Process the transaction
			*/

      let tr = state.tr;

      // List item pos
      const listItemPos = $from.start($from.depth - 1) - 1;

      // List item attrs
      if (!("attrs" in listItemNode)) listItemNode.attrs = {};
      const newAttrs = {
        ...listItemNode.attrs,
        ...{ done: !listItemNode.attrs?.done },
      };

      // console.log('todoMarkToggle.newAttrs', newAttrs)

      // Set the mark
      tr.setNodeMarkup(listItemPos, null, newAttrs);

      dispatch(tr.scrollIntoView());

      return true;
    }
  };
}
