/**
 * System libraries
 */
import React, { useRef, useEffect, useContext, useState } from "react";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Modules
 */
import { NavButtonCreateDocument } from "./IconCreatePage";
import { NavButtonTimeline } from "./IconTimeline";
import { NavButtonCalendar } from "./IconCalendar";
import { ModalSearch } from "components/views/modals/Search";

/**
 * Top navigation bar
 *
 * @param {Object} props
 */
export function TopNav(props) {
  /*
		Context
	*/

  // Get the User context
  const { userData } = useContext(UserContext);

  /*
		Props
	*/

  // Page loaded state
  const pageLoaded = props.pageLoaded;

  // Shortcuts open state
  const leftBarOpen = props.leftBarOpen;

  // Page loader Toggle function
  const togglePageLoaded = props.togglePageLoaded;

  // Toggle left bar open/close
  const toggleLeftBar = props.toggleLeftBar;

  /*
		States
	*/

  // Search modal (visible / hidden)
  const [modalSearchState, setModalSearchState] = useState(false);

  /*
		References
	*/

  // Shortcuts div
  const shortcutsDivRef = useRef(null);

  /*
		Search Modal open/close
	*/

  // Search modal, Open
  const modalSearchOpen = () => {
    setModalSearchState(true);
  };

  // Search modal, Close
  const modalSearchClose = () => {
    setModalSearchState(false);
  };

  /**
   * Ctrl + F opens the Search modal
   */
  useEffect(() => {
    /**
     * Process keydown events
     * @param {Object} Event data
     */
    const onKeyDown = (event) => {
      // console.log('TopNav.onKeyDown', event.key, event.ctrlKey)

      // Ctrl/Cmd + f/а <-- русский
      if (
        // Windows
        (event.ctrlKey && // Ctrl
          ["F", "f", "А", "а"].includes(event.key)) || // Eng/Рус
        // Mac
        (event.metaKey && // ⌘
          ["F", "f", "А", "а"].includes(event.key)) // Eng/Рус
      ) {
        // Modal is already open
        if (modalSearchState) {
          // Close modal
          setModalSearchState(false);

          return true;

          // Closed
        } else {
          // Show modal
          setModalSearchState(true);

          // Prevent from further Key event processing
          event.preventDefault();
          return false;
        }
      }
    };

    // Listen for KeyDown event
    document.addEventListener("keydown", onKeyDown);

    return () => {
      // Stop listening for KeyDown event
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  /*
		Click outside closes leftbar.
		On reference change OR on Shortcuts open state change
	*/
  useEffect(() => {
    /**
     * Close leftbar if the mouse click was outside the left bar
     */
    function handleClickOutside(event) {
      // Click was outside
      if (
        // We have the Leftbar Ref?
        shortcutsDivRef.current &&
        // And the Clicked target is NOT a descendant of Leftbar?
        !shortcutsDivRef.current.contains(event.target)
      ) {
        // Do we need to close it?
        if (
          // Shortcuts are open
          leftBarOpen &&
          // And it's not SIdebar open icon
          (!event.target?.className ||
            !event.target.className.includes("sidebar-icon-shortcuts"))
        ) {
          // Close it
          toggleLeftBar();
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shortcutsDivRef, leftBarOpen, toggleLeftBar]);

  /*
		Return result
	*/
  return (
    <div className="wotodo-header wotodo-header_v2">
      <a className="wotodo-header__logo" href="#">
        <img src="/images/wotodo-logo.svg" alt="" title="Wotodo" />
      </a>
      <div className="wotodo-header__content wotodo-header__content_v2">
        <div className="wotodo-header__buttons">
          {/* Shortcuts */}
          <button
            type="button"
            className="btn-link wotodo-header__button wotodo-header__button_menu"
            onClick={() => {
              toggleLeftBar();
            }}
          >
            <img
              src={`/images/icons/topmenu_shortcuts${
                leftBarOpen ? "_close" : ""
              }.svg`}
              className="sidebar-icon-shortcuts"
              title="Shortcuts"
              alt="Shortcuts"
            />
          </button>

          {/* Timeline */}
          <NavButtonTimeline togglePageLoaded={togglePageLoaded} />

          {/* Calendar */}
          <NavButtonCalendar togglePageLoaded={togglePageLoaded} />

          {/* Create new Document */}
          <NavButtonCreateDocument togglePageLoaded={togglePageLoaded} />

          {/* Search */}
          <button
            type="button"
            className="btn-link wotodo-header__button"
            onClick={() => {
              modalSearchOpen();
            }}
          >
            <img
              src="/images/icons/topmenu_search.svg"
              className="sidebar-icon-search"
              title="Search"
              alt="Search"
            />
          </button>

          {/* Search modal window */}
          <ModalSearch
            isOpen={modalSearchState}
            modalClose={modalSearchClose}
            knowledgebaseId={userData.knowledgebaseId}
          />
        </div>

        {/* Loader blinking bar */}
        <div className={`wt-loader-bar ${pageLoaded ? "d-none" : "d-block"}`}>
          <div></div>
        </div>
      </div>
    </div>
  );
}
