/**
 * System libraries
 */
import {
  findWrapping,
  liftTarget,
  canSplit,
  ReplaceAroundStep,
} from "prosemirror-transform";
import { Slice, Fragment, NodeRange } from "prosemirror-model";
import { TextSelection } from "prosemirror-state";

// import { liftOutOfList } from 'components/ProseMirror/commands/commandsLists'
import {
  isChildOf,
  findNeighbourBefore,
  cursorParagraphBefore,
  openAllClosedParents,
  findParagraphBefore,
} from "components/ProseMirror/Utils";

/**
 * Toggle the block open/closed
 *
 * @param {object} Current state
 * @param {int} Cursor position
 * @param {object} Current item (Node)
 */
export function BlockToggle(state, pos, itemNode) {
  console.log("BlockToggle", state, pos, itemNode);

  /*
		Looking for block_element
	*/

  const tr = state.tr;

  const elementSelf = tr.doc.resolve(pos);

  const elementBefore = state.doc.resolve(pos - 1);
  console.log("BlockToggle.elementBefore", elementBefore);

  /*
	console.log('BlockToggle.elementSelf', elementSelf)
	console.log('BlockToggle.elementSelf.Node', elementSelf.node())
	console.log('BlockToggle.elementSelf.Index', elementSelf.index())
	console.log('BlockToggle.elementSelf.Start', elementSelf.start())
	console.log('BlockToggle.elementSelf.Before', elementSelf.before())
	*/

  /*
	const elementBefore = tr.doc.resolve(pos - 1)
	console.log('BlockToggle.elementBefore', elementBefore)
	console.log('BlockToggle.elementBefore.Node', elementBefore.node())
	*/

  /*
	const elementAfter = tr.doc.resolve(pos + 1)
	console.log('BlockToggle.elementAfter', elementAfter)
	*/

  // const elementSelf2 = tr.doc.resolve(pos)
  // const elementPrev = tr.doc.resolve(pos - 1)
  // console.log('BlockToggle.elementPrev', (pos - 1), elementPrev)

  // const tr = tr
  // const self = tr.selection.$from

  /*
		Validation
	* /

	if (self.depth < 2) { 
		console.log('BlockToggle.InvalidDepth', self.depth)
		return false
	}

	/*
		Get block_element pos
	* /
	const blockElementPos = self.start(self.depth - 2)
	const blockElement = tr.doc.resolve(blockElementPos)

	if (!blockElementPos) { 
		console.log('BlockToggle.InvalidElement')
		return false
	}

	console.log('BlockToggle.Pos', pos, blockElementPos)
	console.log('BlockToggle.Element', blockElement)
	console.log('BlockToggle.Element.Attr', blockElement.parent.attrs.blockid, !blockElement.parent.attrs.open)

	/*
	const blockElementStart = self.start(self.depth) // child.pos
	const resolvedNode = state.doc.resolve(self.depth)
	const blockElementParent1Pos = self.start(self.depth - 1) // child.pos
	const blockElementParent1 = state.doc.resolve(blockElementParent1Pos)
	const blockElementParent2Pos = self.start(self.depth - 2) // child.pos
	const blockElementParent2 = state.doc.resolve(blockElementParent2Pos)
	console.log('BlockToggle.Pos.Self', pos, self.pos, blockElementStart, resolvedNode)
	console.log('BlockToggle.Pos.Parent1', blockElementParent1Pos, blockElementParent1)
	console.log('BlockToggle.Pos.Parent2', blockElementParent2Pos, blockElementParent2)
	*/

  let newTr = null;
  try {
    newTr = state.tr.setNodeMarkup(
      pos - 1, // (blockElementPos - 1), // why -1?? not sure. but works
      null,
      {
        blockid: elementSelf.parent.attrs.blockid, // blockid: blockElement.parent.attrs.blockid,
        open: !elementSelf.parent.attrs.open, // open: !blockElement.parent.attrs.open
      }
    );
  } catch (e) {
    console.log("BlockToggle.Pos.Exception", e);
  }

  // Replace element with new one with same ID and reverted "open" attribute
  if (newTr) return newTr;
  else return;
}

/**
 * Toggle the block open/closed
 *
 * @param {object} Current state
 * @param {int} Cursor position
 * @param {object} Current item (Node)
 */
export function BlockToggleV3(state, pos, itemNode) {
  const _debug = false;

  if (_debug) console.log("BlockToggleV3", state, pos, itemNode);

  let newTr = null;
  try {
    if (_debug) {
      console.log(
        "BlockToggleV3",
        { ...itemNode.attrs, open: !itemNode.attrs.open },
        pos
      );
    }

    newTr = state.tr.setNodeMarkup(
      pos,
      null,
      { ...itemNode.attrs, open: !itemNode.attrs.open }
    );

  } catch (e) {
    console.error("BlockToggleV3.Pos.Exception", e);
  }

  if (newTr) return newTr;
  else return;
}

/**
 * Processing Backspace key in a block_title element
 *
 * @param {object} blockType
 * @param {object} attrs
 */
export function blockBackspace(blockType, attrs) {
  return function (state, dispatch) {
    console.log("blockBackspace.In", state);

    /*
			Working with..
		*/

    // Transaction
    const tr = state.tr;

    // Returns a resolved position if this is a cursor selection (an empty text selection), and null otherwise.
    const cursor = tr.selection.$cursor;

    // Node types in schema
    const nodesTypes = state.schema.nodes;

    /*
			Are we the single cursor?
		*/

    if (
      // This is not a cursor selection
      cursor === null ||
      // There is no depth (or we are at root doc)
      !cursor?.depth
    ) {
      // Allow next function to run
      return false;
    }

    /*
			Where are we?
		*/

    // Am I inside the block_element?
    let firstBlockChild = false;
    let firstBlockChildResolved = null;
    let insideBlock = false;
    let blockElement = null; // block_element to work with (as Node)
    let blockElementResolved = null; // block_element to work with (as ResolvedPos)

    // Am I inside block_title?
    let insideBlockTitle = false;
    let blockTitle = null; // block_title to work with (as Node)
    let blockTitleResolved = null; // block_title to work with (as ResolvedPos)

    // For each parent Node (except the root doc)
    for (let i = cursor.depth; i > 0; i--) {
      // Get the parent as Node
      const currentParent = cursor.node(i);
      // console.log('blockBackspace.Parent:' + i, currentParent)

      // This parent is block_element
      if (currentParent.type === nodesTypes.block_element) {
        // We are inside the block_element!
        insideBlock = true;

        // Save this for later usage
        blockElement = currentParent; // cursor.node(i)
        blockElementResolved = tr.doc.resolve(cursor.start(i));

        console.log(
          "blockBackspace.inBlockElement at: " + cursor.start(i),
          blockElement,
          blockElementResolved
        );

        // Am I in first child?
        if (cursor.node(i + 1) === blockElement.firstChild) {
          firstBlockChildResolved = tr.doc.resolve(cursor.start(i + 1));
          console.log(
            "blockBackspace.inBlockElement.firstChild",
            firstBlockChildResolved,
            cursor.pos,
            firstBlockChildResolved.start()
          );
          firstBlockChild = true;
        }

        // No further traversing up is required
        break;

        // We are inside the title
      } else if (currentParent.type === nodesTypes.block_title) {
        // We are inside the block_title!
        insideBlockTitle = true;

        // Save this for later usage
        blockTitle = currentParent; // cursor.node(i)
        blockTitleResolved = tr.doc.resolve(cursor.start(i));
      }
    }

    /*
			Processing
		*/

    // First block child
    if (firstBlockChild && insideBlock) {
      // Last symbol?
      if (firstBlockChildResolved.parent.textContent.length < 1) {
        console.log(
          "blockBackspace.firstBlockChildResolved.LastSymbol",
          blockElementResolved.before(),
          blockElementResolved.after()
        );

        /*
					Get all Nodes of the block_element
				*/

        const childrenArray = [];
        if (blockElement?.childCount) {
          for (let i = 0; i < blockElement.childCount; i++) {
            const currentChildren = blockElement.child(i);

            // console.log('blockBackspace.firstBlockChildResolved.LastSymbol.currentChildren', currentChildren, firstBlockChildResolved.parent)

            // Skip title
            if (currentChildren.type === nodesTypes.block_title) continue;

            // Skip self
            if (firstBlockChildResolved.parent === currentChildren) {
              // console.log('blockBackspace.firstBlockChildResolved.LastSymbol.currentChildren.self')
              continue;
            }

            // Save child
            childrenArray.push(currentChildren);
          }
        }
        console.log(
          "blockBackspace.firstBlockChildResolved.Children",
          childrenArray
        );

        /*
					Process the operation
				*/

        // Remove the block_title
        // tr.delete(blockTitleResolved.before(), blockTitleResolved.after())

        // Remove the entire block_element
        tr.delete(blockElementResolved.before(), blockElementResolved.after());

        // Insert block_element contents into the same place
        for (let i = 0; i < childrenArray.length; i++) {
          tr.insert(blockElementResolved.before(), childrenArray[i]);
        }

        // Nothing was inserted?
        let cursorSelectionPos = blockElementResolved.start();
        if (childrenArray.length === 0) {
          cursorSelectionPos = findParagraphBefore(
            tr.doc,
            blockElementResolved.before()
          );
        }

        // Move the cursor to the start
        tr.setSelection(TextSelection.create(tr.doc, cursorSelectionPos)); // blockElementResolved.before()

        // Dispatch it and focus!
        dispatch(tr.scrollIntoView());

        // console.log('blockBackspace.Dispatched', tr)
        // Do not allow other functions to run
        return true;

        // The cursor is at the start?
      } else if (cursor.pos === firstBlockChildResolved.start()) {
        console.log("blockBackspace.firstBlockChildResolved.Beginning");

        /*
					Get all Nodes of the block_element including title!
				*/

        const childrenArray = [];
        if (blockElement?.childCount) {
          for (let i = blockElement.childCount - 1; i >= 0; i--) {
            const currentChildren = blockElement.child(i);

            // Is block_title
            if (currentChildren.type === nodesTypes.block_title) {
              // Save child
              if (currentChildren?.firstChild)
                childrenArray.push(currentChildren.firstChild);

              // Is child
            } else {
              // Save child
              childrenArray.push(currentChildren);
            }
          }
        }

        console.log(
          "blockBackspace.firstBlockChildResolved.Beginning.Children",
          childrenArray
        );

        // Remove the entire block_element
        tr.delete(blockElementResolved.before(), blockElementResolved.after());

        // Insert block_element contents into the same place
        for (let i = 0; i < childrenArray.length; i++) {
          tr.insert(blockElementResolved.before(), childrenArray[i]);
        }

        // Move the cursor to the start
        tr.setSelection(
          TextSelection.create(tr.doc, blockElementResolved.start())
        ); // blockElementResolved.before()

        // Dispatch it and focus!
        dispatch(tr.scrollIntoView());

        // console.log('blockBackspace.Dispatched', tr)
        // Do not allow other functions to run
        return true;
      }
    }

    // Inside block_title within block_element
    if (insideBlockTitle && insideBlock) {
      console.log(
        "blockBackspace.insideBlockTitle",
        cursor.pos,
        blockTitleResolved.start() + 1
        /* blockTitleResolved.before(),
				blockTitleResolved.indexAfter(),
				blockTitle,
				blockTitleResolved */
      );

      // Last symbol
      if (blockTitle.textContent.length < 1) {
        console.log(
          "blockBackspace.insideBlockTitle.LastSymbol",
          blockElementResolved.before(),
          blockElementResolved.after()
        );

        /*
					Get all Nodes of the block_element
				*/

        const childrenArray = [];
        if (blockElement?.childCount) {
          for (let i = 0; i < blockElement.childCount; i++) {
            const currentChildren = blockElement.child(i);

            // Skip title
            if (currentChildren.type === nodesTypes.block_title) continue;

            // Save child
            childrenArray.push(currentChildren);
          }
        }
        console.log("blockBackspace.insideBlockTitle.Children", childrenArray);

        /*
					Process the operation
				*/

        // Remove the block_title
        // tr.delete(blockTitleResolved.before(), blockTitleResolved.after())

        // Remove the entire block_element
        tr.delete(blockElementResolved.before(), blockElementResolved.after());

        // Insert block_element contents into the same place
        for (let i = 0; i < childrenArray.length; i++) {
          tr.insert(blockElementResolved.before(), childrenArray[i]);
        }

        // Move the cursor to the start
        tr.setSelection(
          TextSelection.create(tr.doc, blockElementResolved.start())
        ); // blockElementResolved.before()

        // Dispatch it and focus!
        dispatch(tr.scrollIntoView());

        // console.log('blockBackspace.Dispatched', tr)
        // Do not allow other functions to run
        return true;

        // Beginning. The cursor is at the block_title start?
      } else if (cursor.pos === blockTitleResolved.start() + 1) {
        console.log("blockBackspace.insideBlockTitle.Beginning");

        /*
					Get all Nodes of the block_element including title!
				*/

        const childrenArray = [];
        if (blockElement?.childCount) {
          for (let i = blockElement.childCount - 1; i >= 0; i--) {
            const currentChildren = blockElement.child(i);

            // Is block_title
            if (currentChildren.type === nodesTypes.block_title) {
              // Save child
              if (currentChildren?.firstChild)
                childrenArray.push(currentChildren.firstChild);

              // Is child
            } else {
              // Save child
              childrenArray.push(currentChildren);
            }
          }
        }

        console.log(
          "blockBackspace.insideBlockTitle.Beginning.Children",
          childrenArray
        );

        // Remove the entire block_element
        tr.delete(blockElementResolved.before(), blockElementResolved.after());

        // Insert block_element contents into the same place
        for (let i = 0; i < childrenArray.length; i++) {
          tr.insert(blockElementResolved.before(), childrenArray[i]);
        }

        // Move the cursor to the start
        tr.setSelection(
          TextSelection.create(tr.doc, blockElementResolved.start())
        ); // blockElementResolved.before()

        // Dispatch it and focus!
        dispatch(tr.scrollIntoView());

        // console.log('blockBackspace.Dispatched', tr)
        // Do not allow other functions to run
        return true;
      }

      // Inside block_element
    } else if (insideBlock) {
      console.log("blockBackspace.insideBlockElement");

      // We are not the last child? (block_title + at least last element)
      if (blockElement.childCount > 2) {
        // console.log('blockBackspace.Not a single child')

        // Allow next function to run
        return false;
      }

      console.log("blockBackspace.singleChild");

      /*
				Last symbol in a block_element (excluding block_title)?
			*/

      let blockContentLength = 0;
      if (blockElement?.childCount) {
        for (let i = 0; i < blockElement.childCount; i++) {
          const currentChild = blockElement.child(i);

          // Child is not a block_title
          if (currentChild.type !== nodesTypes.block_title) {
            blockContentLength += currentChild.textContent.length;
          }
        }
      }

      /*
				How many symbols left?
			*/

      console.log("blockBackspace.Symbols", blockContentLength);

      // Not the last symbol?
      if (blockContentLength > 0) {
        // console.log('blockBackspace.Not the last symbol')

        // Allow next function to run
        return false;
      }

      console.log("blockBackspace.lastSymbol");

      /*
				Do we need to perform the operation?
			*/
      if (dispatch) {
        /*
					Additional validation
				*/

        // No firstChild inside block_element? Or it's not block_title?
        if (
          !blockElement?.firstChild?.type ||
          blockElement?.firstChild?.type !== nodesTypes.block_title
        ) {
          console.log(
            "blockBackspace.Exception.InvalidFirstChild",
            blockElement
          );
        }

        // No content inside title?
        if (!blockElement.firstChild?.firstChild) {
          console.log(
            "blockBackspace.Exception.missingFirstChildContent",
            blockElement.firstChild
          );
        }

        /*
					Process the operation
				*/

        // Remove the whole block_element from .before() till .after()
        tr.delete(blockElementResolved.before(), blockElementResolved.after());
        // tr.replaceWith(blockElementResolved.before(), (blockElementResolved.after() - 2), blockElement.firstChild.firstChild)
        // tr.delete(blockElementResolved.start() + 1, blockElementResolved.end() + 2)

        // Insert block_title contents into deleted block_element place
        tr.insert(
          blockElementResolved.before(),
          blockElement.firstChild.firstChild
        );

        // Resolve this new created block
        const paragraphPos = tr.doc.resolve(blockElementResolved.start());
        // console.log('blockBackspace.paragraphPos', paragraphPos, paragraphPos.end())

        // Move the cursor to the end of it
        // tr.setSelection(TextSelection.create(tr.doc, blockElementResolved.start()))
        tr.setSelection(TextSelection.create(tr.doc, paragraphPos.end())); // blockElementResolved.before()

        // Dispatch it and focus!
        dispatch(tr.scrollIntoView());

        // console.log('blockBackspace.Dispatched', tr)
        // Do not allow other functions to run
        return true;
      }

      // We are somewhere else
    } else {
      // Allow next function to run
      return false;
    }
  };
}

/**
 * Processing Enter key in a block_element
 */
export function blockEnter() {
  return function (state, dispatch) {
    const _debug = true;
    const { selection, tr } = state;
    const { from, $from, to, $to } = selection;
    const nodesTypes = state.schema.nodes;

    if (_debug) console.log("blockEnter", from, $from, to, $to);

    // Range selected
    if (from !== to) {
      if (_debug) console.log("blockEnter.rangeSelected");

      // do nothing for now

      // Simple cursor position
    } else {
      if (_debug) console.log("blockEnter.cursor");

      // Invalid depth? (doc > block_element > paragraph)
      if ($from.depth < 2) {
        if (_debug) console.log("blockEnter.invalidDepth", $from.depth);
        return false;
      }

      // Are we in block_element?
      let blockElement;
      for (let i = $from.depth; i > 0; i--) {
        const parent = $from.node(i);

        // We've found the right parent
        if (parent?.type && parent.type === nodesTypes.block_element) {
          blockElement = parent;
          if (_debug) console.log("blockEnter.blockElement", blockElement);

          // Are we the first Node in block_element?
          const myNode = $from.node(i + 1);
          if (myNode !== blockElement.firstChild) {
            if (_debug) console.log("blockEnter.notFirstNode");
            return false;
          }

          break;
        }
      }

      // Not in a block_element
      if (!blockElement) {
        if (_debug) console.log("blockEnter.notBlockElement");
        return false;
      }

      const self = $from;
      const selfStart = self.start(self.depth);
      const selfEnd = self.end(self.depth);
      const cursorPos = from;

      if (_debug)
        console.log("blockEnter.cursor.pos", selfStart, selfEnd, cursorPos);

      /*
				Cursor is at the start of the title?
				Create a paragraph before it.
			*/
      if (cursorPos === selfStart) {
        if (_debug) console.log("blockEnter.cursor.start");

        // Find the first block_element after the position
        let parentElement = null;
        let blockElement = null;
        for (let i = from; i >= 0; i--) {
          const nextNode = tr.doc.resolve(i);

          // We've found block_element previously?
          if (blockElement) {
            parentElement = nextNode;
            break;
          }

          // Not a block_element
          if (
            !nextNode?.parent?.type ||
            nextNode.parent.type !== state.schema.nodes.block_element
          ) {
            continue;
          }

          // Is block element!
          blockElement = nextNode;
        }

        // Not found?
        if (!parentElement) {
          if (_debug) console.log("blockEnter.cursor.start.parentNotFound");
          return;
        }

        if (_debug)
          console.log("blockEnter.cursor.start.parentFound", parentElement);

        var pos = parentElement.indexAfter();

        // console.log('blockEnter.Pos', pos)

        // var contentMatchAt = $from.parent.contentMatchAt(pos)
        var contentMatchAt = parentElement.parent.contentMatchAt(pos);

        // console.log('blockEnter.indexAfter', pos)
        // console.log('blockEnter.contentMatchAt', contentMatchAt)
        var type = defaultBlockAt(contentMatchAt);
        // console.log('blockEnter.Type', type)
        if (!type || !type.isTextblock) {
          // console.log('blockEnter.TypeNot')
          return true;
        }

        if (dispatch) {
          // var side = (!$from.parentOffset && $to.index() < $to.parent.childCount ? $from : $to).pos;
          var side = parentElement.pos;
          // console.log('blockEnter.Dispatch', side, type)
          // var tr = state.tr.insert(side, type.createAndFill());
          tr.insert(side, type.createAndFill());
          tr.setSelection(TextSelection.create(tr.doc, side + 1));
          dispatch(tr.scrollIntoView());
        }

        /*
				Cursor is at the end of the title?
			*/
      } else if (cursorPos === selfEnd) {
        if (_debug) console.log("blockEnter.cursor.end");

        /*
					Search block_element
				*/
        let blockElement = null;
        for (let i = from; i >= 0; i--) {
          const nextNode = tr.doc.resolve(i);

          // Not a block_element
          if (
            !nextNode?.parent?.type ||
            nextNode.parent.type !== state.schema.nodes.block_element
          ) {
            continue;
          }

          // Is block element!
          // console.log('blockEnter.End.Found!', nextNode)
          blockElement = nextNode;
          break;
        }

        // Not found?
        if (!blockElement) {
          // console.log('blockEnter.End.ParentNotFound')
          return;
        }

        /*
					Toggle is opened/closed
				*/

        // Open
        if (blockElement?.parent?.attrs?.open === true) {
          if (_debug) console.log("blockEnter.cursor.end.open");

          /*
						Look for the children
					*/
          let titleFound = null;
          let childFound = null;
          // for (let i = 0; i < blockElement.parent.childCount; i++) {
          // const currentChild = blockElement.parent.child(i)

          for (let i = blockElement.pos; i <= blockElement.end(); i++) {
            const currentChild = tr.doc.resolve(i);

            // console.log('blockEnter.End.Open.Child.' + i, currentChild)

            // Block_element?
            if (
              currentChild?.parent?.type === state.schema.nodes.block_element
            ) {
              // console.log('blockEnter.End.Open.Child.BlockElement')
              continue;
            }

            // Who is next after title?
            if (titleFound) {
              // Is it an inner block_element?
              if (
                currentChild?.parent?.type === state.schema.nodes.block_title ||
                currentChild?.parent?.type ===
                  state.schema.nodes.block_element ||
                currentChild?.parent?.type ===
                  state.schema.nodes.block_wrapper ||
                currentChild?.parent?.type === state.schema.nodes.block_item ||
                currentChild?.parent?.type ===
                  state.schema.nodes.paragraph_wrapper ||
                currentChild?.parent?.type === state.schema.nodes.paragraph_item
              ) {
                // Fallback to a paragraph
                // console.log('blockEnter.End.Open.isBlock.Fallback')
                break;
              }

              // Is a list?
              if (
                currentChild?.parent?.type ===
                  state.schema.nodes.ordered_list ||
                currentChild?.parent?.type === state.schema.nodes.bullet_list ||
                currentChild?.parent?.type === state.schema.nodes.todo_list
              ) {
                // console.log('blockEnter.End.Open.SkipList', currentChild?.parent?.type)
                continue;

                // Standard block
              } else {
                childFound = currentChild;
                break;
              }
            }

            // Found title!
            if (currentChild?.parent?.type === state.schema.nodes.block_title) {
              titleFound = currentChild;
              i = titleFound.end();
              // console.log('blockEnter.End.Open.titleFound', titleFound, i, titleFound.end())
            }
          }

          /*
						No title???
						Can't do anything :(
					*/
          if (!titleFound) {
            // console.log('blockEnter.End.Open.NoTitle')
            return;
          }

          if (dispatch) {
            // This option uses last NodeType used
            // const newNode = newType.createAndFill(newAttrs)
            // And this will use default paragraph
            const newNode = state.schema.nodes.paragraph.createAndFill();
            const newPos = titleFound.end() + 2;

            // console.log('blockEnter.End.Open.NewNode', newNode)

            tr.insert(newPos - 1, newNode);
            // console.log('blockEnter.End.Open.Tr2', JSON.stringify(tr))

            tr.setSelection(TextSelection.create(tr.doc, newPos - 1));

            dispatch(tr.scrollIntoView()); //
          }

          // Closed
        } else if (blockElement?.parent?.attrs?.open === false) {
          if (_debug) console.log("blockEnter.cursor.end.closed");

          let newPos = blockElement.end() + 1;
          // console.log('blockEnter.End.Closed.Pos', blockElement, blockElement.end())

          // Insert a paragraph
          if (dispatch) {
            tr.insert(newPos, state.schema.nodes.paragraph.createAndFill());
            // console.log('blockEnter.End.Open.Tr2', JSON.stringify(tr))
            tr.setSelection(TextSelection.create(tr.doc, newPos));
            dispatch(tr.scrollIntoView());
          }

          // N/a ???
        } else {
          // console.log('blockEnter.End.Unknown')
        }

        /*
				Between
			*/
      } else {
        if (_debug) console.log("blockEnter.cursor.between", self);

        // Invalid depth?
        if (self.depth < 3) {
          // console.log('blockEnter.Between.InvalidDepth')
          return true;
        }

        // Get grand parent (block_element)
        const grandParent = self.node(self.depth - 2);
        const grandParentBeforePos = self.before(self.depth - 2);

        // Invalid?
        if (
          !grandParent ||
          grandParent.type !== state.schema.nodes.block_element
        ) {
          // console.log('blockEnter.Between.InvalidGrandParent', grandParent, grandParentBeforePos)
          return true;
        }

        if (dispatch) {
          // Cut text from block_title before the cursor
          // const contentBefore = tr?.doc?.slice(cursorPos, selfEnd)
          const contentBefore = self.parent.content.cut(
            0,
            cursorPos - selfStart
          );
          // console.log('blockEnter.Between.contentBefore', contentBefore)
          // console.log('blockEnter.Between.Split')

          // Do we have content?
          if (contentBefore) {
            // Delete it from the block_title
            tr.delete(selfStart, cursorPos);

            // Create a paragraph above
            const newParagraph = state.schema.nodes.paragraph.createAndFill(
              null,
              contentBefore
            );

            // console.log('blockEnter.Between.newParagraph', newParagraph)

            // Insert a paragraph
            tr.insert(grandParentBeforePos, newParagraph);

            // Set a selection at the start of a new paragraph
            // tr.setSelection(TextSelection.create(tr.doc, grandParentBeforePos + 1 + (cursorPos - selfStart)))

            // Set a selection at the block_element
            const cursorNewPos = selfStart + (cursorPos - selfStart);
            tr.setSelection(TextSelection.create(tr.doc, cursorNewPos));

            // Dispatch it!
            dispatch(tr.scrollIntoView());

            // console.log('blockEnter.Between.Done')
          }
        }
      }
    }

    // Succesfully processed, skip other commands
    return true;
  };
}

function defaultBlockAt(match) {
  for (var i = 0; i < match.edgeCount; i++) {
    var ref = match.edge(i);
    var type = ref.type;
    console.log("defaultBlockAt", ref, type);

    if (type.isTextblock && !type.hasRequiredAttrs()) {
      return type;
    }
  }
  return null;
}

// :: (NodeType, ?Object) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Returns a command function that wraps the selection in a list with
// the given type an attributes. If `dispatch` is null, only return a
// value to indicate whether this is possible, but don't actually
// perform the change.
export function wrapInBlock(blockType, attrs) {
  // blockType, attrs
  // console.log('wrapInBlock', blockType)
  return function (state, dispatch) {
    /*
			Get the current element which is going to be tabbed as a child into a block
		*/

    console.log("wrapInBlock", state);

    const schema = state.schema;

    // Node types in schema
    const nodesTypes = state.schema.nodes;

    // ResolvedPos of a cursor place (child)
    let childFrom = state.selection.$from;
    let childTo = state.selection.$to;
    const childFromId = childFrom?.parent?.attrs?.blockid;
    const childToId = childTo?.parent?.attrs?.blockid;

    // We are at doc level? Not allowed to run
    if (!childFrom.depth) return false;

    // No child id?
    if (!childFromId || !childToId) {
      console.log("wrapInBlock.NoChildIds", childTo, childFrom);
    }

    /*
			Let's search for a parent
		*/

    // ResolvedPos of a Parent
    let parent = null;

    // Set the start position of the child
    // let childFromStart = childFrom.start(childFrom.depth) // child.pos
    // let childToStart = childTo.start(childTo.depth) // child.pos

    /*
			Get parent
		*/
    console.log("wrapInBlock.Child", childFrom);

    const parentNode = childFrom.node(childFrom.depth - 1);
    const parentNodeResolved = state.tr.doc.resolve(
      childFrom.start(childFrom.depth - 1)
    );

    console.log("wrapInBlock.parentNode", parentNode, parentNodeResolved);
    if (!parentNode) {
      console.log("wrapInBlock.NoParent");
      return true; // can't do
    }

    // Get my index in a parent node
    const neighbourIndex = childFrom.index(childFrom.depth - 1) - 1;
    const neighbourPos = childFrom.posAtIndex(
      neighbourIndex,
      childFrom.depth - 1
    );
    parent = state.tr.doc.resolve(neighbourPos + 1);
    console.log("wrapInBlock.Neighbour", neighbourIndex, neighbourPos, parent);

    /*
		// index(depth: ?⁠number) → number
		console.log('wrapInBlock.myPos', childFrom.pos)
		console.log('wrapInBlock.myDepth1', childFrom.before(childFrom.depth + 1))
		console.log('wrapInBlock.myDepth2', childFrom.before(childFrom.depth))
		console.log('wrapInBlock.myDepth2.Resolve', state.tr.doc.resolve(childFrom.before(childFrom.depth)))
		// console.log('wrapInBlock.myDepth3', childFrom.before(childFrom.depth - 1))
		console.log('wrapInBlock.myDepth', childFrom.depth)
		console.log('wrapInBlock.myIndex', childFrom.index())
		console.log('wrapInBlock.myIndex2', childFrom.index(childFrom.depth))
		console.log('wrapInBlock.myIndex3', childFrom.index(childFrom.depth - 1))
		console.log('wrapInBlock.posAtIndex', childFrom.posAtIndex(0, childFrom.depth - 1))
		console.log('wrapInBlock.posAtIndex1', childFrom.posAtIndex(11, 0))
		console.log('wrapInBlock.posAtIndex1.Resolve', state.tr.doc.resolve(childFrom.posAtIndex(11, 0) + 1))
		// console.log('wrapInBlock.posAtIndex1', childFrom.posAtIndex(11, 1))
		return true
		*/

    /*
			Get neighbour
		* /
		
		let lastNode = null
		for (let i = 0; i < parentNode.childCount; i++) {
			const currentNode = parentNode.child(i)
			console.log('wrapInBlock.Parent.Child', currentNode)

			// I've found myself!
			if (
				currentNode?.attrs?.blockid
				&& currentNode.attrs.blockid === childFromId
			) {
				if (lastNode) {
					console.log('wrapInBlock.FoundNeighbour', lastNode)
					parent = lastNode
				}
				break
			}

			lastNode = currentNode // parentNode.resolve(cursor.start(i)) // 
		}
		*/

    // No neighbour?
    if (!parent?.parent) {
      console.log("wrapInBlock.NoNeighbour");
      return true;
    }

    // Check neighbour type
    if (!parent.parent?.type) {
      console.log("wrapInBlock.NoNeighbour.Type");
      return true;
    }

    // Check neighbour type
    if (
      parent.parent.type !== nodesTypes.paragraph &&
      parent.parent.type !== nodesTypes.heading
    ) {
      console.log(
        "wrapInBlock.NoNeighbour.UnsupportedType",
        parent.parent.type
      );
      return true;
    }

    // Resolve parent
    // parent = tr.doc.resolve(childFrom.start(i))

    /*
		// For each parent Node
		console.log('wrapInBlock.Child', childFrom)
		for (let i = (childFrom.depth - 1); i >= 0; i--) {
			// Get the parent as Node
			const currentParent = childFrom.node(i)
			console.log('wrapInBlock.Parent:' + i, currentParent)

			// Can't define type?
			if (!currentParent?.type) {
				console.log('wrapInBlock.findMyParent.NoType')
				continue
			}

			// This parent is block_element
			if (currentParent.type === nodesTypes.block_element) {
				console.log('wrapInBlock.findMyParent.AlreadyInBlock', currentParent.type.name)

				// Am I the Parent or first child of this block_element?
				// If yes - do not allow to wrap twice!
				for (let j = 0; j <= (currentParent.childCount - 1) && j < 2; j++) {
					const blockChild = currentParent.child(j)

					console.log('wrapInBlock.findMyParent.AlreadyIn.Child', j, blockChild)
					
					// Same id as mine?
					if (
						blockChild?.attrs?.blockid
						&& blockChild.attrs.blockid === childFromId
					) {
						// Do not allow to wrap twice!
						console.log('wrapInBlock.findMyParent.AlreadyIn.TwiceBlocked', blockChild.attrs.blockid + ' === ' + childFromId)
						return true // this triggers doing nothing and ignoring the Tab key at all
					}
				}

				// Let's skip to the next Node in search of a parent
				// console.log('findMyParent.AlreadyIn.ContinueLooking')
				// continue
			}

			// Invalid type?
			if (
				currentParent.type !== nodesTypes.paragraph
				&& currentParent.type !== nodesTypes.heading
			) {
				// Is it a blockquote?
				if (currentParent.type === nodesTypes.blockquote) {
					console.log('wrapInBlock.findMyParent.Blockquote.Cant')
					return false
				}

				console.log('wrapInBlock.findMyParent.InvalidParentType', currentParent.type.name)
				continue
			}

			// No parent with id?
			const parentId = currentParent.attrs?.blockid
			if (!parentId) {
				console.log('wrapInBlock.findMyParent.NoParentId')
				continue
			}

			// Ids are the same?
			if (parentId === childFromId) {
				console.log('wrapInBlock.SameIds', parentId + ' === ' + childFromId)
				continue
			}

			// Save this for later usage
			// blockElement = currentParent // childFrom.node(i)
			// blockElementResolved = tr.doc.resolve(childFrom.start(i))

			// Got parent with id?
			console.log('wrapInBlock.findMyParent.ParentId', parentId)

			// Return the node
			parent = tr.doc.resolve(childFrom.start(i)) // resolvedNode
			break
		}
		*/

    /*
		// Let's start moving backwards from the beginning of the child
		for (let i = childFromStart; i > 0; i--) {
			// ResolvedPos
			const resolvedNode = state.doc.resolve(i)

			console.log('findMyParent.Resolve', i, resolvedNode)

			// No parent type found?
			if (!resolvedNode?.parent?.type) {
				console.log('findMyParent.NoType')
				continue
			}

			// I'm already in a block_element!
			if (
				resolvedNode.parent.type === schema.nodes.block_element
			) {
				console.log('findMyParent.AlreadyInBlock', resolvedNode.parent.type.name)

				// Am I the Parent or first child of this block_element?
				// If yes - do not allow to wrap twice!
				for (let j = 0; j <= (resolvedNode.parent.childCount - 1) && j < 2; j++) {
					const blockChild = resolvedNode.parent.child(j)

					console.log('findMyParent.AlreadyIn.Child', j, blockChild)
					
					// Same id as mine?
					if (
						blockChild?.attrs?.blockid
						&& blockChild.attrs.blockid === childFromId
					) {
						// Do not allow to wrap twice!
						console.log('findMyParent.AlreadyIn.TwiceBlocked', blockChild.attrs.blockid + ' === ' + childFromId)
						return true // this triggers doing nothing and ignoring the Tab key at all
					}
				}

				// Let's skip to the next Node in search of a parent
				console.log('findMyParent.AlreadyIn.ContinueLooking')
				continue
			}
			
			// Invalid type?
			if (
				resolvedNode.parent.type !== schema.nodes.paragraph
				&& resolvedNode.parent.type !== schema.nodes.heading
			) {
				// Is it a blockquote?
				if (resolvedNode.parent.type === schema.nodes.blockquote) {
					console.log('findMyParent.Blockquote.Cant')
					return false
				}

				console.log('findMyParent.InvalidParentType', resolvedNode.parent.type.name)
				continue
			}

			// No parent with id?
			const parentId = resolvedNode?.parent?.attrs?.blockid
			if (!parentId) {
				console.log('findMyParent.NoParentId')
				continue
			}

			// Ids are the same?
			if (parentId === childFromId) {
				console.log('wrapInBlock.SameIds', parentId + ' === ' + childFromId)
				continue
			}

			// Got parent with id?
			console.log('findMyParent.ParentId', parentId)

			// Return the node
			parent = resolvedNode
			break
		}
		
		// No parent found
		if (!parent) {
			// We can't make it happen
			console.log('wrapInBlock.NotAllowed')
			return true // this triggers doing nothing and ignoring the Tab key at all
		}

		*/

    /*
			Set instance of a transaction
		*/

    let tr = state.tr;

    /*
			Pick the Range starting from Parent and to the child
		*/

    var range = parent.blockRange(childTo),
      wrapping =
        range && findWrapping(range, schema.nodes.block_element, attrs);
    // console.log('wrapInBlock.RangeWrapping', range, wrapping)

    // No wrapping found
    if (!wrapping) {
      console.log("wrapInBlock.NoWrapping");
      return false;
    }

    // console.log('wrapInBlock.Step1.Before', tr)

    // Wrap it!
    console.log("wrapInBlock.Wrap");
    tr.wrap(range, wrapping);

    // console.log('wrapInBlock.Step1.After', tr)

    /*
			Wrap first node as title
		*/

    // New child pos
    let child = tr.selection.$from; // $to

    // Reset the Parent
    parent = null;

    // Set the new start position of the child
    let childStart = child.start(child.depth); // child.pos

    const newChildId = child?.parent?.attrs?.blockid;

    // No child id?
    if (!newChildId) {
      console.log("wrapInBlock.NoNewChildId", child);
    }

    // Child mismatch?
    if (newChildId !== childFromId) {
      console.log(
        "wrapInBlock.ChildIds.Mismatch",
        newChildId + " !== " + childFromId
      );
    }

    // Search for a new parent location
    // parent = findMyParent(tr, schema, childStart, newChildId)
    // Let's start moving backwards from the beginning of the child
    for (let i = childStart; i > 0; i--) {
      // ResolvedPos
      const resolvedNode = tr.doc.resolve(i);

      console.log("findMyParent.Resolve", i, resolvedNode);

      // No parent type found?
      if (!resolvedNode?.parent?.type) {
        console.log("findMyParent.NoType");
        continue;
      }

      // Already in title?
      if (resolvedNode.parent.type === schema.nodes.block_title) {
        console.log(
          "findMyParent.AlreadyInTitle",
          resolvedNode.parent.type.name
        );
        break;
      }

      // Invalid type?
      if (
        resolvedNode.parent.type !== schema.nodes.paragraph &&
        resolvedNode.parent.type !== schema.nodes.heading
      ) {
        console.log(
          "findMyParent.InvalidParentType",
          resolvedNode.parent.type.name
        );
        continue;
      }

      // No parent with id?
      const parentId = resolvedNode?.parent?.attrs?.blockid;
      if (!parentId) {
        console.log("findMyParent.NoParentId");
        continue;
      }

      // Ids are the same?
      if (parentId === newChildId) {
        console.log("wrapInBlock.SameIds", parentId + " === " + newChildId);
        continue;
      }

      // Got parent with id?
      console.log("findMyParent.ParentId", parentId);

      // Return the node
      parent = resolvedNode;
      break;
    }

    // No parent found
    if (!parent) {
      // We can't make it happen
      console.log("wrapInBlock.NotAllowed");
      return true; // this triggers doing nothing and ignoring the Tab key at all
    }

    // ResolvedPos
    // const parent2 = tr.selection.$from

    var range2 = parent.blockRange(parent),
      wrapping2 =
        range2 && findWrapping(range2, schema.nodes.block_title, attrs);
    // console.log('wrapInBlock.RangeWrapping2', range2, wrapping2)
    // console.log('wrapInBlock.')
    // var range = parent.blockRange(parent), wrapping = range && findWrapping(range, schema.nodes.block_title, attrs)

    // No wrapping found
    if (!wrapping2) {
      console.log("wrapInBlock.NoWrapping2");
      return false;
    }

    // Wrap it!
    console.log("wrapInBlock.Wrap2");
    tr.wrap(range2, wrapping2);
    /*
		ReplaceAroundStep.prototype.apply = function apply(doc) {
    if (this.structure && (contentBetween(doc, this.from, this.gapFrom) || contentBetween(doc, this.gapTo, this.to))) {
      return StepResult.fail("Structure gap-replace would overwrite content");
    }

    var gap = doc.slice(this.gapFrom, this.gapTo);
    if (gap.openStart || gap.openEnd) {
      return StepResult.fail("Gap is not a flat range"); <-------------------------------------------
    }
    var inserted = this.slice.insertAt(this.insert, gap.content);
    if (!inserted) {
      return StepResult.fail("Content does not fit in gap");
    }
    return StepResult.fromReplace(doc, this.from, this.to, inserted);
  };
  */

    // console.log('wrapInBlock2.newState0', newState.doc.toString()) // The modified document
    // console.log('wrapInBlock2.newState1', newState.steps.length)   // → 2
    // console.log('wrapInBlock2.newState2', newState)
    // console.log('wrapInBlock2.newState3', state.tr)
    // console.log('wrapInBlock2.newState3', state)
    console.log("wrapInBlock.Step2", tr);

    /*
			Dispatch it!
		*/

    if (dispatch) {
      dispatch(tr.scrollIntoView());
    }

    // Success
    return true;

    // var ref = state.selection;
    // var $from = ref.$from;
    // var $to = ref.$to;

    /*
		console.log('wrapInBlock2.State', state)
		
		// let $from = state.doc.resolve(2)
		// let $to = state.doc.resolve(15)
		// var range = $from.blockRange($to), wrapping = range && findWrapping(range, blockType, attrs);

		console.log('wrapInBlock2.Range', range)

		if (!wrapping) { return false }
		if (dispatch) { dispatch(state.tr.wrap(range, wrapping).scrollIntoView()); }
		return true
		*/

    /*
			OLD
		*/
    console.log("wrapInBlock.State", state);
    // console.log('wrapInBlock.Selection', state.selection)
    // console.log('wrapInBlock.Resolve', state.doc.resolve())

    // const predicate = node => node.type === schema.nodes.blockquote;
    // const parent = findParentNode(predicate)(selection);
    // console.log('wrapInBlock.parent', parent)

    /* let pos = state.selection.$from
		for (let i = pos.depth; i > 0; i--) {
			const node = pos.node(i);
			/* if (predicate(node)) {
				return { * /
			console.log('wrapInBlock.Parent', {
				pos: i > 0 ? pos.before(i) : 0,
				start: pos.start(i),
				depth: i,
				node
			})
				// };
			// }
		} */

    /*
		let maybeSelection = Selection.findFrom(state.selection.$from, -1)
		console.log('wrapInBlock.maybeSelection1', maybeSelection)

		maybeSelection = Selection.findFrom(state.selection.$from, -10)
		console.log('wrapInBlock.maybeSelection2', maybeSelection)

		const maybeSelectionFrom = state.doc.resolve(2)
		console.log('wrapInBlock.maybeSelectionFrom', maybeSelectionFrom)

		const maybeSelectionTo = state.doc.resolve(15)
		console.log('wrapInBlock.maybeSelectionTo', maybeSelectionTo)

		const maybeRange = maybeSelectionFrom.blockRange(maybeSelectionTo)
		console.log('wrapInBlock.Range', maybeRange)
		*/

    /* const { nodeBefore } = state.selection.$from;
		const maybeSelection = Selection.findFrom(state.selection.$from, -1)
		console.log('wrapInBlock.maybeSelection', maybeSelection)
		if (maybeSelection && nodeBefore) {
			console.log('wrapInBlock.maybeSelection.Inside', maybeSelection, nodeBefore)
			/ * // leaf node
			// const parent = findParentNodeOfType(nodeBefore.type)(maybeSelection);
			let parent = null
			{ 

			}
			if (parent) {
				// return parent.pos;
				console.log('wrapInBlock.maybeSelection.Parent', parent.pos)
			} else {
				// return maybeSelection.$from.pos;
				console.log('wrapInBlock.maybeSelection.Before', maybeSelection.$from.pos)
			}* /
		} */

    /* // let {$from, $to} = state.selection
		let $from = state.doc.resolve(2)
		let $to = state.doc.resolve(15)
		let range = $from.blockRange($to), doJoin = false, outerRange = range
		
		if (!range) {
			console.log('wrapInBlock.norange')
			return false
		}

		// This is at the top of an existing list item
		if (range.depth >= 2 && $from.node(range.depth - 1).type.compatibleContent(blockType) && range.startIndex === 0) {
			// Don't do anything if this is the top of the list
			if ($from.index(range.depth - 1) === 0) {
				console.log('wrapInBlock.nodeep')
				return false
			}

			let $insert = state.doc.resolve(range.start - 2)
			outerRange = new NodeRange($insert, $insert, range.depth)
			if (range.endIndex < range.parent.childCount)
			range = new NodeRange($from, state.doc.resolve($to.end(range.depth)), range.depth)
			doJoin = true
		}

		let wrap = findWrapping(outerRange, blockType, attrs, range)
		if (!wrap) {
			console.log('wrapInBlock.nowrap')
			return true
		}

		if (dispatch) dispatch(doWrapInBlock(state.tr, range, wrap, doJoin, blockType).scrollIntoView())

		console.log('wrapInBlock.finish')
		return true
		*/
  };
}

function doWrapInBlock(tr, range, wrappers, joinBefore, blockType) {
  let content = Fragment.empty;
  for (let i = wrappers.length - 1; i >= 0; i--)
    content = Fragment.from(
      wrappers[i].type.create(wrappers[i].attrs, content)
    );

  tr.step(
    new ReplaceAroundStep(
      range.start - (joinBefore ? 2 : 0),
      range.end,
      range.start,
      range.end,
      new Slice(content, 0, 0),
      wrappers.length,
      true
    )
  );

  let found = 0;
  for (let i = 0; i < wrappers.length; i++)
    if (wrappers[i].type === blockType) found = i + 1;
  let splitDepth = wrappers.length - found;

  let splitPos = range.start + wrappers.length - (joinBefore ? 2 : 0),
    parent = range.parent;
  for (
    let i = range.startIndex, e = range.endIndex, first = true;
    i < e;
    i++, first = false
  ) {
    if (!first && canSplit(tr.doc, splitPos, splitDepth)) {
      tr.split(splitPos, splitDepth);
      splitPos += 2 * splitDepth;
    }
    splitPos += parent.child(i).nodeSize;
  }
  return tr;
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Build a command that splits a non-empty textblock at the top level
// of a list item by also splitting that list item.
export function splitBlockItem(itemType) {
  return function (state, dispatch) {
    let { $from, $to, node } = state.selection;
    if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to))
      return false;
    let grandParent = $from.node(-1);
    if (grandParent.type !== itemType) return false;
    if ($from.parent.content.size === 0) {
      // In an empty block. If this is a nested list, the wrapping
      // list item should be split. Otherwise, bail out and let next
      // command handle lifting.
      if (
        $from.depth === 2 ||
        $from.node(-3).type !== itemType ||
        $from.index(-2) !== $from.node(-2).childCount - 1
      )
        return false;
      if (dispatch) {
        let wrap = Fragment.empty,
          keepItem = $from.index(-1) > 0;
        // Build a fragment containing empty versions of the structure
        // from the outer list item to the parent node of the cursor
        for (
          let d = $from.depth - (keepItem ? 1 : 2);
          d >= $from.depth - 3;
          d--
        )
          wrap = Fragment.from($from.node(d).copy(wrap));
        // Add a second list item with an empty default start node
        wrap = wrap.append(Fragment.from(itemType.createAndFill()));
        let tr = state.tr.replace(
          $from.before(keepItem ? null : -1),
          $from.after(-3),
          new Slice(wrap, keepItem ? 3 : 2, 2)
        );
        tr.setSelection(
          state.selection.constructor.near(
            tr.doc.resolve($from.pos + (keepItem ? 3 : 2))
          )
        );
        dispatch(tr.scrollIntoView());
      }
      return true;
    }
    let nextType =
      $to.pos === $from.end()
        ? grandParent.contentMatchAt(0).defaultType
        : null;
    let tr = state.tr.delete($from.pos, $to.pos);
    let types = nextType && [null, { type: nextType }];
    if (!canSplit(tr.doc, $from.pos, 2, types)) return false;
    if (dispatch) dispatch(tr.split($from.pos, 2, types).scrollIntoView());
    return true;
  };
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Create a command to lift the list item around the selection up into
// a wrapping list.
export function liftBlockItem(itemType) {
  return function (state, dispatch) {
    let { $from, $to } = state.selection;
    let range = $from.blockRange(
      $to,
      (node) => node.childCount && node.firstChild.type === itemType
    );
    if (!range) return false;
    if (!dispatch) return true;
    if ($from.node(range.depth - 1).type === itemType)
      // Inside a parent list
      return liftToOuterBlock(state, dispatch, itemType, range);
    // Outer list node
    else return liftOutOfBlock(state, dispatch, range);
  };
}

function liftToOuterBlock(state, dispatch, itemType, range) {
  let tr = state.tr,
    end = range.end,
    endOfList = range.$to.end(range.depth);
  if (end < endOfList) {
    // There are siblings after the lifted items, which must become
    // children of the last item
    tr.step(
      new ReplaceAroundStep(
        end - 1,
        endOfList,
        end,
        endOfList,
        new Slice(
          Fragment.from(itemType.create(null, range.parent.copy())),
          1,
          0
        ),
        1,
        true
      )
    );
    range = new NodeRange(
      tr.doc.resolve(range.$from.pos),
      tr.doc.resolve(endOfList),
      range.depth
    );
  }
  dispatch(tr.lift(range, liftTarget(range)).scrollIntoView());
  return true;
}

function liftOutOfBlock(state, dispatch, range) {
  let tr = state.tr,
    list = range.parent;
  // Merge the list items into a single big item
  for (
    let pos = range.end, i = range.endIndex - 1, e = range.startIndex;
    i > e;
    i--
  ) {
    pos -= list.child(i).nodeSize;
    tr.delete(pos - 1, pos + 1);
  }
  let $start = tr.doc.resolve(range.start),
    item = $start.nodeAfter;
  let atStart = range.startIndex === 0,
    atEnd = range.endIndex === list.childCount;
  let parent = $start.node(-1),
    indexBefore = $start.index(-1);
  if (
    !parent.canReplace(
      indexBefore + (atStart ? 0 : 1),
      indexBefore + 1,
      item.content.append(atEnd ? Fragment.empty : Fragment.from(list))
    )
  )
    return false;
  let start = $start.pos,
    end = start + item.nodeSize;
  // Strip off the surrounding list. At the sides where we're not at
  // the end of the list, the existing list is closed. At sides where
  // this is the end, it is overwritten to its end.
  tr.step(
    new ReplaceAroundStep(
      start - (atStart ? 1 : 0),
      end + (atEnd ? 1 : 0),
      start + 1,
      end - 1,
      new Slice(
        (atStart
          ? Fragment.empty
          : Fragment.from(list.copy(Fragment.empty))
        ).append(
          atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))
        ),
        atStart ? 0 : 1,
        atEnd ? 0 : 1
      ),
      atStart ? 0 : 1
    )
  );
  dispatch(tr.scrollIntoView());
  return true;
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Create a command to sink the list item around the selection down
// into an inner list.
export function sinkBlockItem(itemType) {
  return function (state, dispatch) {
    let { $from, $to } = state.selection;
    let range = $from.blockRange(
      $to,
      (node) => node.childCount && node.firstChild.type === itemType
    );
    if (!range) return false;
    let startIndex = range.startIndex;
    if (startIndex === 0) return false;
    let parent = range.parent,
      nodeBefore = parent.child(startIndex - 1);
    if (nodeBefore.type !== itemType) return false;

    if (dispatch) {
      let nestedBefore =
        nodeBefore.lastChild && nodeBefore.lastChild.type === parent.type;
      let inner = Fragment.from(nestedBefore ? itemType.create() : null);
      let slice = new Slice(
        Fragment.from(
          itemType.create(null, Fragment.from(parent.type.create(null, inner)))
        ),
        nestedBefore ? 3 : 1,
        0
      );
      let before = range.start,
        after = range.end;
      dispatch(
        state.tr
          .step(
            new ReplaceAroundStep(
              before - (nestedBefore ? 3 : 1),
              after,
              before,
              after,
              slice,
              1,
              true
            )
          )
          .scrollIntoView()
      );
    }
    return true;
  };
}

/**
 * Search for the parent (which is a previous H1-3 or a paragraph block)
 * going backwards. The parent is on the same level as Me.
 * 
 * @param {object} Document State
 * @param {object} My schema
 * @param {int} Position to start from and go backward
 * @param {string} My id
 * /
const findMyParent = (state, schema, startPos, myId) => {
	console.log('findMyParent', startPos)

	

	return false
} */

/* 
const isChildOf2 = ($pos, parentType) => {
	for (let i = $pos.depth; i > 0; i--) {
		const node = $pos.node(i);
		console.log('isChildOf2', node);

		/* if (predicate(node)) {
			return {
				pos: i > 0 ? $pos.before(i) : 0,
				start: $pos.start(i),
				depth: i,
				node
			};
		} * /
	}
} */

/* // :: (NodeType, ?Object) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Returns a command function that wraps the selection in a list with
// the given type an attributes. If `dispatch` is null, only return a
// value to indicate whether this is possible, but don't actually
// perform the change.
const isChildOf = (path, parentType) => {
	// Foreach Node in Path (starting from the top)
	for (let i = 0; i < path.length; i++) {
		// Not a node
		if (!path[i]?.type) continue

		// Right parent Type found!
		if (path[i].type === parentType) return true
	}

	// We are not child of this type
	return false
} */

/**
 * Process the Tab key press to create a block structure
 */
export const blockTab = () => {
  return function (state, dispatch) {
    const _debug = true;
    let { tr, schema } = state;
    const $from = state.selection.$from;
    const $to = state.selection.$to;
    let toAfter = $to.after();

    if (_debug) console.log("blockTab", $from);

    /*
			Basic validations
		*/

    // Doc level depth, not allowed
    if (!$from.depth) {
      if (_debug) console.log("blockTab.neighbourNode.noDepth");
      return false;
    }

    /*
			Get previous neighbour node before the $from selection
		*/

    let neighbourBeforeNode;

    // Parent
    let parentNode = $from.node($from.depth - 1);
    if (!parentNode?.type) return false;

    if (_debug) console.log("blockTab.parentNode", parentNode);

    // The parent is a list item?
    if (
      parentNode.type === schema.nodes.todo_item ||
      parentNode.type === schema.nodes.list_item
    ) {
      // Let's check if the $from cursor is in the first item of the list
      const listNode = $from.node($from.depth - 2);
      if (_debug)
        console.log("blockTab.parentNode.list_item", listNode, parentNode);
      if (listNode?.firstChild !== parentNode) {
        if (_debug) console.log("blockTab.parentNode.notFirst");
        return false;
      }

      // Move up, till I have the list node itself
      const listNodePos = tr.doc.resolve($from.start($from.depth - 2));
      if (_debug) console.log("blockTab.parentNode.list", listNodePos);
      if (!listNodePos?.parent?.type) {
        return false;
      }

      // Prev neighbour
      neighbourBeforeNode = findNeighbourBefore(listNodePos);

      // The parent is a block_title?
    } else if (parentNode.type === schema.nodes.block_title) {
      /* // Let's check if the $from cursor is in the first item of the list
			const listNode = $from.node($from.depth - 2)
			if (_debug) console.log('blockTab.neighbourBeforeNode.parentNode.list_item', listNode, parentNode)
			if (listNode?.firstChild !== parentNode) {
				if (_debug) console.log('blockTab.neighbourBeforeNode.parentNode.notFirst')
				return false
			} */

      // Move up, till I have the block_element itself
      const blockElementPos = tr.doc.resolve($from.start($from.depth - 2));
      if (_debug)
        console.log("blockTab.parentNode.block_title", blockElementPos);
      if (
        !blockElementPos?.parent?.type ||
        blockElementPos.parent.type !== schema.nodes.block_element
      ) {
        if (_debug)
          console.log("blockTab.parentNode.notABlockElement", blockElementPos);
        return false;
      }

      // Prev neighbour
      neighbourBeforeNode = findNeighbourBefore(blockElementPos);

      // The parent is a block_element and I'm in it's first child?
    } else if (
      parentNode.type === schema.nodes.block_element &&
      parentNode.firstChild === $from.parent
    ) {
      const blockElementPos = tr.doc.resolve($from.start($from.depth - 1));
      if (_debug)
        console.log("blockTab.parentNode.block_element.first", blockElementPos);

      // Prev neighbour
      neighbourBeforeNode = findNeighbourBefore(blockElementPos);

      // The parent is a simple element
    } else {
      // Prev neighbour
      neighbourBeforeNode = findNeighbourBefore($from);
    }

    // Not found
    if (!neighbourBeforeNode?.parent?.type) {
      if (_debug) console.log("blockTab.neighbourBeforeNode.empty");
      return false;
    }

    // Is it a first child of a block_element?
    if (
      parentNode.type === schema.nodes.block_element &&
      parentNode.firstChild === neighbourBeforeNode.parent
    ) {
      if (_debug)
        console.log("blockTab.neighbourBeforeNode.firstChildOfBlockElement");
      return false;
    }

    // Header, paragraph
    if (
      neighbourBeforeNode.parent.type === schema.nodes.paragraph ||
      neighbourBeforeNode.parent.type == schema.nodes.heading ||
      neighbourBeforeNode.parent.type == schema.nodes.ordered_list ||
      neighbourBeforeNode.parent.type == schema.nodes.bullet_list ||
      neighbourBeforeNode.parent.type == schema.nodes.todo_list
    ) {
      if (_debug)
        console.log(
          "blockTab.neighbourBeforeNode.single",
          neighbourBeforeNode.parent.type?.name,
          neighbourBeforeNode
        );

      const neighbourParent = tr.doc.resolve(
        neighbourBeforeNode.start(neighbourBeforeNode.depth - 1)
      );
      if (_debug)
        console.log(
          "blockTab.neighbourBeforeNode.neighbourParent",
          neighbourParent
        );

      // Block_element
      if (neighbourParent?.parent?.type == schema.nodes.block_element) {
        // First child?
        if (
          neighbourParent?.parent?.firstChild === neighbourBeforeNode.parent
        ) {
          if (_debug)
            console.log(
              "blockTab.neighbourBeforeNode.neighbourParent.firstChild"
            );
          return false;
        }
      }

      // Lists
      if (
        neighbourBeforeNode.parent.type == schema.nodes.ordered_list ||
        neighbourBeforeNode.parent.type == schema.nodes.bullet_list ||
        neighbourBeforeNode.parent.type == schema.nodes.todo_list
      ) {
        // Has toggle
        if (neighbourBeforeNode.parent?.lastChild?.attrs?.children === true) {
          console.log("blockTab.neighbourBeforeNode.listHasChild.skip");
          return false;
        }

        // Has many children
        if (neighbourBeforeNode.parent?.childCount > 1) {
          console.log("blockTab.neighbourBeforeNode.listManyChildren.skip");
          return false;
        }
      }

      /*
				Create block_element
			*/

      // Get nodes to transfer (all within the selection)
      let nodes = [];
      if (_debug)
        console.log(
          "blockTab.nodesBetween",
          neighbourBeforeNode.before(),
          toAfter
        );
      state.doc.nodesBetween(
        neighbourBeforeNode.before(),
        toAfter,
        (node, position) => {
          if (node.isBlock) {
            const pushedNodeResolved = tr.doc.resolve(position + 1);
            if (_debug)
              console.log(
                "blockTab.nodesBetween.raw",
                node?.type?.name,
                node?.attrs?.blockid,
                node,
                pushedNodeResolved
              );

            const pushedNodeParent = tr.doc.resolve(position);
            const sameParent = neighbourParent.sameParent(pushedNodeParent);
            if (_debug)
              console.log(
                "blockTab.sameParent",
                sameParent,
                neighbourParent,
                pushedNodeParent
              );

            // Not the same parent?
            if (!sameParent) {
              console.log("blockTab.notSameParent");
              return true;

              // Skip first block_element
            } else if (
              nodes.length === 0 &&
              node?.type === schema.nodes.block_element
            ) {
              // do nothing
            } else {
              if (_debug)
                console.log(
                  "blockTab.nodesBetween.node",
                  node,
                  position + 1,
                  pushedNodeResolved
                );
              nodes.push(node);

              // Do we need to extend toAfter to include parent element of a list item?
              if (pushedNodeResolved.after() > toAfter) {
                if (_debug)
                  console.log(
                    "blockTab.nodesBetween.extend",
                    pushedNodeResolved.after(),
                    ">",
                    toAfter
                  );
                toAfter = pushedNodeResolved.after();
              }

              return false;
            }
          }
        }
      );

      /*
			// Do we need to extend toAfter to include parent element of a list item?
			if ($to.depth > 2) {
				const toNodeParent = $to.node($to.depth - 2)
				// console.log('blockTab.toAfter.expand', toNodeParent)
				if (
					toNodeParent?.type === schema.nodes.ordered_list
					|| toNodeParent?.type === schema.nodes.bullet_list
					|| toNodeParent?.type === schema.nodes.todo_list
					|| toNodeParent?.type === schema.nodes.block_element
				) {
					if (_debug) console.log('blockTab.toAfter.expand', toNodeParent, $to.after($to.depth - 2), toAfter)
					if ($to.after($to.depth - 2) > toAfter) {
						toAfter = $to.after($to.depth - 2)
					}
				}
			}
			*/

      // Replace the selected range with block_element
      let blockElement = schema.nodes.block_element.create(null, nodes);
      tr.replaceWith(neighbourBeforeNode.before(), toAfter, blockElement);

      /*
				Create block_title
			

			// Search for the first node within block_element
			blockElement = tr.doc.resolve(neighbourBeforeNode.start())
			let blockFirstChild = null
			if (_debug) console.log('blockTab.blockTitle.resolve', blockElement)
			blockElement.parent.forEach((node, offset, index) => {
				// console.log('blockTab.blockFirstChild.node', node)

				if (blockFirstChild) return
				let childPos = offset + blockElement.start() + 1
				blockFirstChild = tr.doc.resolve(childPos)

				if (_debug) console.log('blockTab.blockFirstChild', neighbourBeforeNode.start(), blockElement.start(), childPos, blockFirstChild)
			})

			// No child?
			if (!blockFirstChild) {
				if (_debug) console.log('blockTab.blockTitle.noChild')
				return false
			}

			// Replace first node with block_title
			const blockTitle = schema.nodes.block_title.create(null, blockFirstChild.parent)
			tr.replaceWith(blockFirstChild.before(), blockFirstChild.after(), blockTitle)
			*/

      // Place the cursor at the end of the closest paragraph before the position
      blockElement = tr.doc.resolve(neighbourBeforeNode.start());
      if (_debug)
        console.log(
          "blockTab.blockTitle.cursor",
          blockElement,
          blockElement.end()
        );
      state = state.apply(tr);
      tr = cursorParagraphBefore(state, tr, blockElement.end());

      dispatch(tr.scrollIntoView());
      return true;

      // Block element
    } else if (neighbourBeforeNode.parent.type === schema.nodes.block_element) {
      if (_debug)
        console.log(
          "blockTab.neighbourBeforeNode.block_element",
          neighbourBeforeNode.parent.type?.name
        );

      /*
				Prepare the nodes to cut
			*/

      let cutFrom = $from.before();
      let cutTo = $to.after();

      // Get nodes to transfer (all within the selection)
      let nodes = [];
      state.doc.nodesBetween($from.start(), $to.end(), (node, position) => {
        if (node.isBlock) {
          if (_debug)
            console.log(
              "blockTab.neighbourBeforeNode.block_element.nodes",
              node
            );

          /* // Skip first block_element
					if (nodes.length === 0 && node?.type === schema.nodes.block_element) {
						// do nothing
						
					} else { */
          // Is it a list_item?
          if (
            node?.type === schema.nodes.bullet_list ||
            node?.type === schema.nodes.ordered_list ||
            node?.type === schema.nodes.todo_list ||
            node?.type === schema.nodes.block_element
          ) {
            const pushedNodeResolved = tr.doc.resolve(position + 1);
            if (_debug)
              console.log(
                "blockTab.nodesBetween.block_element.extend",
                pushedNodeResolved
              );

            // Do we need to extend the cut range pos to include it?
            if (pushedNodeResolved.before() < cutFrom) {
              if (_debug)
                console.log(
                  "blockTab.nodesBetween.block_element.extendFrom",
                  pushedNodeResolved.before(),
                  "<",
                  cutFrom
                );
              cutFrom = pushedNodeResolved.before();
            }
            if (pushedNodeResolved.after() > cutTo) {
              if (_debug)
                console.log(
                  "blockTab.nodesBetween.block_element.extendTo",
                  pushedNodeResolved.after(),
                  ">",
                  toAfter
                );
              cutTo = pushedNodeResolved.after();
            }
          }

          // Add node to list
          nodes.push(node);

          // This will ensure that we will not go into this node adding it's children to the array of nodes
          // We take this node as a WHOLE with all it's children
          return false;
          // }
        }
      });

      // Remove all nodes in a selection
      tr.delete(cutFrom, cutTo);
      // state = state.apply(tr)

      // Insert the list of nodes into block_element
      {
        // var side = thisListResolved.before() + 1
        tr.insert(neighbourBeforeNode.end(), nodes);
      }

      // Open all closed parents
      tr = openAllClosedParents(tr, neighbourBeforeNode.end());

      /*// Get the range
			let range = $from.blockRange($to/*, node => {
				return node.childCount && node.firstChild.type === itemType
			}* /)

			console.log('blockTab.neighbourBeforeNode.block_element.range', range);

			liftOutOfList(state, dispatch, range) */

      dispatch(tr.scrollIntoView());
      return true;

      // Could not process
    } else {
      if (_debug)
        console.log(
          "blockTab.neighbourBeforeNode.unsupported",
          neighbourBeforeNode.parent.type?.name
        );
      return false;
    }
  };
};

/**
 * Block_element with last child? Replace it with this child!
 * @param {object} Transformation
 * @return {object} Resolved position to work with
 * @return {object} Transformation
 */
export const blockElementLastChild = (tr, resolvedPos) => {
  if (resolvedPos?.depth && resolvedPos.depth > 1) {
    try {
      const parentNodeResolved = tr.doc.resolve(
        resolvedPos.start(resolvedPos.depth - 1)
      );

      if (
        parentNodeResolved?.parent?.type ===
          tr.doc.type.schema.nodes.block_element &&
        // Only Block_element non-Card
        ((parentNodeResolved?.parent?.childCount === 1 &&
          parentNodeResolved?.parent?.attrs?.iscard === false) ||
          // Card
          parentNodeResolved?.parent?.childCount === 0)
      ) {
        console.log("blockElementLastChild", resolvedPos, parentNodeResolved);

        // Replace with the last Child
        tr.replaceWith(
          parentNodeResolved.before(),
          parentNodeResolved.after(),
          parentNodeResolved.parent?.firstChild
        );
      }
    } catch (e) {
      console.log("blockElementLastChild.exception", e);
    }
  }

  return tr;
};

/**
 * Create a new Card
 * @param {object} Editor view
 * @return {boolean} Operation status
 */
export const cardElementCreate = (editorView) => {
  const _debug = false;
  const tr = editorView.current.state.tr;
  const schemaNodes = editorView.current.state.schema.nodes;

  if (_debug) console.log("cardElementCreate");

  // Get the last child of the entire document
  const lastDocChild = tr.doc.resolve(tr.doc.nodeSize - 3);
  if (_debug) console.log("cardElementCreate.lastDocChild", lastDocChild);

  // Create the Card
  const newCard = [
      schemaNodes.block_element.create({ iscard: true }, [
      schemaNodes.paragraph.create(
        null,
        editorView.current.state.schema.text("New card")
      ),
      schemaNodes.paragraph.create(),
      ]),
      schemaNodes.paragraph.create(),
    ];

  // Last child is an Empty paragraph? Replace it!
  if (
    lastDocChild?.parent?.type === schemaNodes.paragraph &&
    lastDocChild?.parent?.content.size === 0
  ) {
    if (_debug) console.log("cardElementCreate.replaceEmptyParagraph");

    tr.replaceWith(lastDocChild.before(), lastDocChild.after(), newCard);

    // Add Card as a new last Node to the document
  } else {
    if (_debug) console.log("cardElementCreate.insert");

    tr.insert(tr.doc.nodeSize - 2, newCard);
  }

  tr.setSelection(TextSelection.create(tr.doc, tr.doc.nodeSize - 6));

  editorView.current.dispatch(tr.scrollIntoView());
  editorView.current.focus();

  return true;
};
