/**
 * System libraries
 */
import * as React from "react";
import { useVisibility } from "components/views/Utils";

/**
 * Display document Title in a single document view
 */
export default function EditorTitle(props) {
  const documentTitle = props.documentTitle ? props.documentTitle : "";

  // Small title
  const [smallTitleVisible, divRef] = useVisibility(true);

  // Item title class
  let divTitleClass = "wotodo-item-title";
  if (props?.isBookmarked) divTitleClass += " js-active";

  // Title sizing
  let titleStyle = {};
  const maxSymbols = 30;
  if (documentTitle.length > maxSymbols) {
    let sizePercent = Math.round((maxSymbols * 100) / documentTitle.length);
    sizePercent = Math.max(70, sizePercent);
    titleStyle.fontSize = sizePercent + "%";
  }

  return (
    <div ref={divRef} className={divTitleClass}>
      {/* "Add new Card" button */}
      <div className="wotodo-item-add-template wotodo-item-add-template_in-header">
        <button
          className="wotodo-item-add-template__button"
          onClick={(e) => props.createBlock(e)}
        ></button>
      </div>

      {/* Main Title */}
      <div className="wotodo-item-title__input">
        {props.isEditable ? (
          <input
            type="text"
            onKeyDown={props.titleKeyDown}
            onChange={props.updateTitle}
            value={documentTitle}
            autoFocus={documentTitle === "" ? true : false}
            placeholder="Page title"
            style={titleStyle}
          />
        ) : (
          <input
            type="text"
            value={documentTitle}
            placeholder="Page title"
            style={titleStyle}
            readOnly
          />
        )}
      </div>

      {/* Buttons */}
      <div className="wotodo-item-title__actions">
        {/* Shortcut */}
        <button
          className={`wotodo-item-title__action wotodo-item-title__action_fav`}
          onClick={(e) => props.toggleShortcut(e, !props.isBookmarked)}
        >
          {props.isBookmarked ? (
            <img
              src="/images/icons/chunk-action-fav-active.svg"
              alt="Bookmark"
            />
          ) : (
            <img src="/images/icons/chunk-action-fav.svg" alt="Bookmark" />
          )}
        </button>

        {/* Share */}
        <button
          className={`wotodo-item-title__action wotodo-item-title__action_share${
            props.isShared ? " js-active" : ""
          }`}
          onClick={(e) => props.modalShareOpen()}
        >
          {props.isShared ? (
            <img
              src="/images/icons/chunk-action-share-active.svg"
              alt="Share"
            />
          ) : (
            <img src="/images/icons/chunk-action-share.svg" alt="Share" />
          )}
        </button>
      </div>

      {/* Small Title (hidden by default, shown on scroll) */}
      <div
        className={`wotodo-item-title__small-title${
          smallTitleVisible ? "" : " js-active"
        }`}
      >
        <div
          className={`wotodo-item-title__text${
            props.isBookmarked ? " js-bookmarked" : ""
          }`}
        >
          <span>{documentTitle}</span>

          {/* Buttons */}
          <div className="wotodo-item-title__actions">
            {/* Shortcut */}
            <button
              className={`wotodo-item-title__action wotodo-item-title__action_fav`}
              onClick={(e) => props.toggleShortcut(e, !props.isBookmarked)}
            >
              {props.isBookmarked ? (
                <img
                  src="/images/icons/chunk-action-fav-active.svg"
                  alt="Bookmark"
                />
              ) : (
                <img src="/images/icons/chunk-action-fav.svg" alt="Bookmark" />
              )}
            </button>

            {/* Share */}
            <button
              className={`wotodo-item-title__action wotodo-item-title__action_share`}
              onClick={(e) => props.modalShareOpen()}
            >
              {props.isShared ? (
                <img
                  src="/images/icons/chunk-action-share-active.svg"
                  alt="Share"
                />
              ) : (
                <img src="/images/icons/chunk-action-share.svg" alt="Share" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
