/**
 * Modules
 */
import { documentReferenceAdd } from "components/Document/processor";

// Unique IDs generation
import { nanoid } from "nanoid";

/**
 * Blocks function
 */
export const FromBlocks = async (blocks, knowledgebaseId, documentId) => {
  // console.log('ProseMirror.FromBlocks.run: ', blocks);

  // Reset the document
  let jsonDoc = {
    type: "doc",
    content: [],
  };

  // Foreach block
  for (let i = 0; i < blocks.length; i++) {
    let block = blocks[i];

    // Check block
    if (!block || !block.json) {
      console.log("ProseMirror.FromBlocks.NoBlock", block);
      continue;
    }

    // Parse exact node from string to JSON
    let nodeJson = null;
    try {
      nodeJson = JSON.parse(block.json);
    } catch (e) {
      console.log("ProseMirror.FromBlocks.invalidJSON: ", block.json);
      return;
    }

    // console.log('ProseMirror.FromBlocks.nodeJson: ', nodeJson)

    if (!nodeJson) {
      console.log("ProseMirror.FromBlocks.nodeJson.empty");
      return;
    }

    // Recursive fix (links)
    nodeJson = recursiveFixLinks(
      nodeJson,
      knowledgebaseId,
      documentId,
      null,
      0
    );

    // Lists, set children attribute
    nodeJson = recursiveListsChildren(nodeJson);

    // Recursive fix (block_title)
    nodeJson = recursiveFixBlockTitle(nodeJson);

    jsonDoc.content.push(nodeJson);
  }

  // Empty?
  if (jsonDoc.content.length === 0) {
    // console.log('ProseMirror.empty')
    jsonDoc.content.push({
      type: "paragraph",
      content: [
        /* {
                    type:"text",
                    text:" "
                } */
      ],
    });
  }

  // console.log('ProseMirror.HTML: ', wrapper)
  // console.log('ProseMirror.JSON: ', jsonDoc)

  // Return
  return jsonDoc;
};

/**
 * Convert ProseMirror nodes array into Firestore objects list
 *
 * @param {Array} Nodes list (objects)
 * @return {Object} Blocks list
 */
export const ToBlocks = async (nodes) => {
  // console.log('ProseMirror.ToBlocks.run: ', nodes, knowledgebaseId, documentId)

  /*
		Reset
	*/

  let blocks = {};

  /*
		Convert
	*/

  nodes.forEach((doc) => {
    // Get Json
    const jsonString = JSON.stringify(doc);

    // Check Id
    const blockId =
      doc.attrs && doc.attrs.blockid ? doc.attrs.blockid : nanoid(10);
    // console.log('ProseMirror.ToBlocks.blockId: ', blockId)

    // Push
    blocks[blockId] = {
      json: jsonString,
    };
  });

  return blocks;
};

/**
 * Go through each nodes
 *
 * @param {object} Node
 */
function recursiveFixLinks(
  node,
  knowledgebaseId,
  documentId,
  parentId,
  parentOffset
) {
  // console.log('recursiveFixLinks' + "*".repeat(parentOffset), node)

  parentOffset++;
  // let origNode = node

  // Set parentId
  if (parentId === null) {
    parentId = node?.attrs?.blockid;
  }

  // Does this node has content and it's an array?
  if (node?.content && Array.isArray(node.content)) {
    // Reset the new content array
    let newContent = [];

    // The content has something?
    if (node.content.length > 0) {
      // Foreach sub-Node
      for (let curNodeIndex in node.content) {
        const curNode = node.content[curNodeIndex];

        // Recursively process it at push to the array!
        newContent.push(
          recursiveFixLinks(
            curNode,
            knowledgebaseId,
            documentId,
            parentId,
            parentOffset
          )
        );
      }
    }

    // Update the current node content
    node.content = newContent;

    // This is the last node
  } else {
    /*
     *** Fixes go here ***
     */

    /*
			Link fix
		*/

    // Is it a text node and it has marks?
    if (
      node?.type &&
      node.type === "text" &&
      node?.marks &&
      Array.isArray(node.marks)
    ) {
      // Reset the array of marks
      let newMarks = [];

      // Foreach Marks for this text
      for (let curMarkIndex in node.marks) {
        const curMark = node.marks[curMarkIndex];

        // Is it a local link?
        if (
          curMark?.type &&
          curMark.type === "link" &&
          curMark?.attrs &&
          curMark.attrs?.href &&
          curMark.attrs.href.toLowerCase().indexOf("/document/") === 0
        ) {
          // Let's update it (change type to doc and change link)
          curMark.type = "linkdoc";
          const referenceDocumentId = curMark.attrs.href.replace(
            "/document/",
            ""
          );
          curMark.attrs["data-link-inner"] = referenceDocumentId;

          if (parentId) {
            // Set the reference
            const targetDocumentId = {
              linkId: referenceDocumentId,
              blockId: parentId,
            };

            console.log("recursiveFixLinks.found", targetDocumentId, node);

            // Let's restore the Reference link!
            documentReferenceAdd(
              knowledgebaseId,
              targetDocumentId.linkId,
              targetDocumentId.blockId
            );
          }
        }

        newMarks.push(curMark);
      }

      // Update the marks
      node.marks = newMarks;
    }
  }

  // Let's return the updated result
  return node;
}

/**
 * Set "children" to all list Nodes
 * @param {object} Node
 * @return {object} Changed node
 */
const recursiveListsChildren = (node) => {
  // console.log('recursiveListsChildren', node)

  if (node?.content && Array.isArray(node.content)) {
    // Old scheme, Hidden list Node, replace
    {
      if (node?.type === "hidden_list") {
        node.type = "bullet_list";
      }

      if (node?.type === "hidden_list_item") {
        node.type = "list_item";
      }
    }

    // Set the data-children
    if (
      node?.type &&
      (node?.type === "list_item" || node?.type === "todo_item")
    ) {
      // No attrs?
      if (!("attrs" in node)) node.attrs = {};

      // Children?
      if (node.content.length > 1) node.attrs.children = true;
      else node.attrs.children = false;
    }

    // Got content?
    if (node.content.length > 0) {
      for (let curNodeIndex in node.content) {
        // Recursively process it and push to the array!
        node.content[curNodeIndex] = recursiveListsChildren(
          node.content[curNodeIndex]
        );
      }
    }
  }

  return node;
};

/**
 * Remove the block_title Node
 * @param {object} Node
 * @return {object} Changed node
 */
export const recursiveFixBlockTitle = (node) => {
  const _debug = false;

  if (_debug) console.log("recursiveFixBlockTitle", node);

  if (node?.content && Array.isArray(node.content)) {
    if (node?.type && node?.type === "block_title") {
      // Replace it with it's first element contents
      if (node.content.length === 1) {
        if (_debug)
          console.log(
            "recursiveFixBlockTitle.block_title.replace",
            { ...node },
            { ...node.content[0] }
          );
        node = node.content[0];
      } else {
        console.error(
          "recursiveFixBlockTitle.block_title.invalidContents",
          node
        );
      }
    }

    // Got content?
    if (node.content.length > 0) {
      for (let curNodeIndex in node.content) {
        // Recursively process it and push to the array!
        node.content[curNodeIndex] = recursiveFixBlockTitle(
          node.content[curNodeIndex]
        );
      }
    }
  }

  return node;
};
