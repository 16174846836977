/**
 * System libraries
 */
import React from "react"; // , { useContext, useState }

/**
 * Contexts
 */
// import { UserContext } from 'providers/UserProvider'

/**
 * Bootstrap
 */
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

/**
 * Modules
 */
// import { SearchBar } from '../../components/views/topnav/search/IconSearch'

/**
 * Duplicated document modal
 * @param {*} props
 */
export function DuplicatedDocumentModal(props) {
  // Get the User context
  // const { userData } = useContext(UserContext)
  // console.log('DuplicatedDocumentModal.userData', userData)

  return (
    <Modal
      show={props.show}
      onHide={props.handleDecline}
      animation={false}
      aria-labelledby="DuplicatedModalTitle"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="DuplicatedModalTitle">
					{userData.displayName && userData.displayName !== '' ? 'Hi, ' + userData.displayName : 'Hi, there' }
				</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <p>
          Document "{props.duplicatedTitle}" already exists, merge documents?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleDecline}>
          No
        </Button>
        <Button variant="primary" onClick={props.handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
