import React from "react";
import { SingleArrowDown, SingleArrowUp } from "../../views/icons/FontAwesome";

export default function FilterButton({ title, onClick, value }) {
  return (
    <div className="filter-button" onClick={onClick}>
      <p className="filter-button__title">{title}</p>
      <div className="filter-button__arrows">
        <span
          className={`filter-button__arrow ${
            value === "desc" ? "filter-button__arrow_active" : ""
          }`}
        >
          <SingleArrowDown />
        </span>
        <span
          className={`filter-button__arrow ${
            value === "asc" ? "filter-button__arrow_active" : ""
          }`}
        >
          <SingleArrowUp />
        </span>
      </div>
    </div>
  );
}
