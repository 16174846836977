import React from "react";
import "../../../css/documentList.css";
import {
  SingleArrowLeft,
  SingleArrowRight,
  DoubleArrowLeft,
  DoubleArrowRight,
} from "../../views/icons/FontAwesome";
import PageItem from "./PageItem";

export default function DocumentListPagination({
  changePage,
  countPage,
  currentPage,
  goNext,
  goBack,
  goFirst,
  goLatest,
}) {
  return (
    <div className="list__buttons-group">
      <button
        className="buttons-group__button buttons-group__first buttons-group__button_jump"
        disabled={currentPage === 1}
        onClick={goFirst}
      >
        <DoubleArrowLeft />
      </button>

      <button
        className="buttons-group__button"
        disabled={currentPage === 1}
        onClick={goBack}
      >
        <SingleArrowLeft />
      </button>

      {countPage > 2 && currentPage === countPage && currentPage - 2 > 0 && (
        <PageItem
          value={currentPage - 2}
          onClick={() => changePage(currentPage - 2)}
        />
      )}
      {currentPage > 1 ? (
        <PageItem
          value={currentPage - 1}
          onClick={() => changePage(currentPage - 1)}
        />
      ) : null}

      <PageItem value={currentPage} active />

      {currentPage < countPage ? (
        <PageItem
          value={currentPage + 1}
          onClick={() => changePage(currentPage + 1)}
        />
      ) : null}
      {currentPage === 1 && currentPage + 2 <= countPage ? (
        <PageItem
          value={currentPage + 2}
          onClick={() => changePage(currentPage + 2)}
        />
      ) : null}

      <button
        className="buttons-group__button"
        disabled={currentPage === countPage}
        onClick={goNext}
      >
        <SingleArrowRight />
      </button>

      <button
        className="buttons-group__button buttons-group__last buttons-group__button_jump"
        disabled={currentPage === countPage}
        onClick={goLatest}
      >
        <DoubleArrowRight />
      </button>
    </div>
  );
}
