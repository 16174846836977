/**
 * System libraries
 */
import React from "react";

/**
 * Modules
 */
import ModalWrapper from "components/views/modals/Wrapper";
import SearchBar from "components/views/elements/SearchBar";

/**
 * Search modal
 * @param {Object} props
 * @return {Object} React component
 */
export function ModalSearch(props) {
  return (
    <ModalWrapper
      isOpen={props.isOpen}
      content={
        <div className="wotodo-search-form">
          <SearchBar
            isVisible={props.isOpen}
            knowledgebaseId={props.knowledgebaseId}
            modalClose={props.modalClose}
          />
        </div>
      }
      modalClose={props.modalClose}
    />
  );
}
