/**
 * Block element
 * Main block wrapper element
 */
export const blockElementSpec = {
  group: "block",
  content: "block+", // content: "block*",

  /*
		Element attributes
	*/
  attrs: {
    // Unique block id (used for saving the elements)
    blockid: { default: "" },
    // Is the block togglable and open by default?
    open: { default: true },
    // Is the block a card?
    iscard: { default: false },
  },
  parseDOM: [
    {
      tag: "div.block-element",
      getAttrs(dom) {
        return {
          // Get the block ID
          blockid: dom.getAttribute("data-blockid"),
          // Is the element open?
          open: dom.getAttribute("data-open") === "true",
          // Is the element a card?
          iscard: dom.getAttribute("data-iscard") === "true",
        };
      },
    },
  ],
  toDOM(node) {
    //
    return [
      "div",
      {
        class: "block-element drag-n-drop-parent-node",
        "data-blockid": node.attrs.blockid,
        "data-open": node.attrs.open.toString(),
        "data-iscard": node.attrs.iscard.toString(),
      },
      0,
    ];
  },
  defining: true,
};

/**
 * Block element Title
 */
export const blockTitleSpec = {
  group: "block",
  content:
    "(paragraph{1} | heading{1} | ordered_list{1} | bullet_list{1} | todo_list{1})",

  /*
		Element attributes
	*/
  attrs: {
    // Unique block id (used for saving the elements)
    // blockid: { default: '' },
    // Is the block togglable and open by default?
    // open: { default: true },
  },
  parseDOM: [
    {
      tag: "div.block-title",
      getAttrs(dom) {
        return {
          // Get the block ID
          // blockid: dom.getAttribute('data-blockid'),
          // Is the lement open?
          // open: dom.getAttribute('data-open') === 'true',
        };
      },
    },
  ],
  toDOM(node) {
    return [
      "div",
      {
        class: "block-title",
      },
      [
        "span",
        {
          class: "block-title-toggle",
          contenteditable: "false",
        },
      ],
      [
        "div",
        {
          class: "block-title-wrapper",
        },
        0,
      ],
    ];
  },
  defining: true,
};
