/**
 * ProseMirror, system libraries
 */
import { Plugin } from "prosemirror-state";

/**
 * Plugin for lists,
 * sets the "data-children" attribute to LI-tags
 * if they have more than 1 child inside
 */
export const listsTogglePlugin = () => {
  /**
   * Create a new plugin
   */
  return new Plugin({
    appendTransaction: (transactions, prevState, nextState) => {
      // console.log('createPlugin.appendTransaction')

      /*
				Env
			*/

      // Transaction
      const tr = nextState.tr;

      // Was there any modification?
      let modified = false;

      /*
				Any changes?
				transaction.docChanged - True when the document has been changed (when there are any steps).
				The some() method tests whether at least one element in the array passes the test implemented by the provided function. It returns a Boolean value.
			*/
      if (transactions.some((transaction) => transaction.docChanged)) {
        /*
					Call the given callback for every descendant node.
					Doesn't descend into a node when the callback returns false.
				*/
        nextState.doc.descendants((node, pos) => {
          // List item?
          if (
            node &&
            (node?.type?.name === "list_item" ||
              node?.type?.name === "todo_item")
          ) {
            // More than one child inside
            if (node?.childCount > 1) {
              // No "data-children" attribute
              if (!node?.attrs?.children) {
                // Update the attributes for this block
                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  children: true,
                });

                // Set as modified
                modified = true;
              }

              // No children
            } else {
              // Has mark and it's true
              if (node?.attrs?.children === true) {
                // Update the attributes for this block
                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  children: false,
                });

                // Set as modified
                modified = true;
              }
            }
          }
        });
      }

      // Do we have any modification? Return the transaction!
      return modified ? tr : null;
    },
  });
};
