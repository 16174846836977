/**
 * Firestore
 */
import firebase from "firebase/app";

/**
 * Modules
 */
import { asyncForEachArray } from "../System/Utils";

/**
 * Read bulk documents list in groups (as the default Firestore .where('in') has a limit of 10 docs
 *
 * @param {object} Firestore collection
 * @param {array} List of document ids
 * @return {object} Documents
 */
export const firestoreReadGroups = async (collection, documentIds) => {
  // console.log('firestoreReadGroups', collection, documentIds)

  /*
		Settings
	*/

  const groupLimit = 10;

  /*
		Let's create groups
	*/

  // List of all groups
  const groups = [];

  // Reset temp group holder
  let currentGroup = [];

  // Compile Groups
  for (let i = 0; i < documentIds.length; i++) {
    // Add while the group is not reached the limit
    if (currentGroup.length < groupLimit) currentGroup.push(documentIds[i]);
    // Limit reached
    else {
      // Save as a group
      groups.push(currentGroup);

      // Start a new group
      currentGroup = [];
      currentGroup.push(documentIds[i]);
    }
  }

  // Add the last uncomplete group (if any)
  if (currentGroup.length) groups.push(currentGroup);

  // console.log('firestoreReadGroups', groups)

  /*
		Let's read the groups of documents
	*/

  // Reset the document holder
  let returnDocuments = {};

  // Read all the groups and combine them into single result
  const processRead = async () => {
    await asyncForEachArray(groups, async (currentGroup) => {
      // console.log('Firestore.Blocks.Groups.Get: ', currentGroup);

      // Get the N documents
      const firestoreDocuments = await collection
        .where(firebase.firestore.FieldPath.documentId(), "in", currentGroup)
        .get();

      // Add them to the list
      firestoreDocuments.forEach((document) => {
        returnDocuments[document.id] = document.data();
      });
    });
  };

  // Wait till all documents are read
  await processRead();

  // Return
  return returnDocuments;
};
