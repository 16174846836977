/**
 * UL, ToDo wrapper
 */
export const todoListSpec = {
  group: "block",
  content: "todo_item+", //  | list_item+
  /*
		Element attributes
	*/
  attrs: {
    // Unique block id (used for saving the elements)
    blockid: { default: "" },
    // Is the block togglable and open by default?
    // open: { default: true },
  },
  /*
		Parse element on Copy/Paste operation
	*/
  parseDOM: [
    {
      priority: 51, // Needs higher priority than other nodes that use a "ul" tag
      tag: '[data-type="todo_list"]',
      getAttrs(dom) {
        return {
          // Get the block ID
          blockid: dom.getAttribute("data-blockid"),
          // Is the element open? Always open!
          // open: true, // dom.getAttribute('data-open') === 'true',
        };
      },
    },
  ],
  /*
		Rules of creating element in DOM
	*/
  toDOM(node) {
    return [
      "div",
      {
        class: "wotodo-list",
        "data-blockid": node.attrs.blockid,
      },
      [
        "ul",
        {
          "data-type": "todo_list",
        },
        0,
      ],
    ];
  },
};

/**
 * LI, ToDo checkbox element
 */
export const todoItemSpec = {
  /*
		Element attributes
	*/
  attrs: {
    // Is the block togglable and open by default?
    open: { default: true },
    // Is the item checked and done?
    done: { default: false },
    // Has some children
    children: { default: false },
  },
  content: "paragraph block*",
  /*
		Parse element on Copy/Paste operation
	*/
  parseDOM: [
    {
      priority: 51, // Needs higher priority than other nodes that use a "li" tag
      tag: '[data-type="todo_item"]',
      getAttrs(dom) {
        return {
          // Is the element open? Always open!
          open: true, // dom.getAttribute('data-open') === 'true'
          // Is the element marked as done?
          done: dom.getAttribute("data-done") === "true",
          // Is the element marked as done?
          children: dom.getAttribute("data-children") === "true",
        };
      },
    },
  ],
  /*
		Rules of creating element in DOM
		*** BAD ***
	   	Generates error:
		TransformError: Invalid content for node todo_item while pasting this fragment.
		Error is triggered by ProseMirror/plugins/blockIds.js:88
		tr.setNodeMarkup(pos, undefined, { ...attrs, blockid: newBlockId })
	* /
	toDOM(node) {
		return [
			'li',
			{
				'data-type': 'todo_item',
				'data-done': node.attrs.done.toString(),
				'data-open': node.attrs.open.toString(),
				'data-children': node.attrs.children.toString(),
			},
			[
				'span',
				{
					'class': 'list-item-toggle',
					contenteditable: 'false'
				},
			],
			[
				'span',
				{
					class: 'todo-checkbox-wrapper',
					contenteditable: "false"
				},
				[
					'span',
					{
						class: 'todo-checkbox todo-checkbox-unchecked',
						contenteditable: "false"
					}
				],
				[
					'span',
					{
						class: 'todo-checkbox todo-checkbox-checked',
						contenteditable: "false"
					}
				]
			],
			[
				'div',
				{
					class: 'todo-item-content'
				},
				0
			],
		]
	},
	*/
  toDOM(node) {
    return [
      "li",
      {
        class: "drag-n-drop-parent-node",
        "data-type": "todo_item",
        "data-done": node.attrs.done.toString(),
        "data-open": node.attrs.open.toString(),
        "data-children": node.attrs.children.toString(),
      },
      [
        "span",
        {
          class: "list-item-toggle",
          contenteditable: "false",
        },
      ],
      [
        "span",
        {
          class: "todo-checkbox todo-checkbox-unchecked",
          contenteditable: "false",
        },
      ],
      [
        "span",
        {
          class: "todo-checkbox todo-checkbox-checked",
          contenteditable: "false",
        },
      ],
      [
        "div",
        {
          class: "todo-item-content",
        },
        0,
      ],
    ];
  },
  defining: true,
};
