import { Plugin } from "prosemirror-state";
// import {renderGrouped} from "prosemirror-menu"
import { renderGrouped } from "./menu-build.js";
/*import { coordsAtPos } from "../utils/position.js"*/

const dragnDropStatusMetaKey = "Drag'n'Drop status";

/**
 * Create a new plugin for a floating menu
 * @param {object} options
 */
export function selectionMenu(options) {
  let menu = null;
  return new Plugin({
    view(editorView) {
      menu = new SelectionMenu(editorView, options);
      return menu || {};
    },
    filterTransaction(tr) {
      const dragnDropStatus = tr.getMeta(dragnDropStatusMetaKey);
      if (
        menu &&
        menu.setChangeDragnDropStatus &&
        typeof dragnDropStatus === "boolean"
      ) {
        const isTimeout = !dragnDropStatus;
        menu.setChangeDragnDropStatus(dragnDropStatus, isTimeout);
      }
      return true;
    },
  });
}

/**
 * Create a new floating menu
 */
class SelectionMenu {
  constructor(editorView, options) {
    // console.log('SelectionMenu.constructor', editorView)

    this.timer = null;
    this.editorView = editorView;
    this.options = options;

    // Create menu div
    this.menu = document.createElement("div");
    this.menu.className = "wt-menu-inline";

    let { dom, update } = renderGrouped(this.editorView, this.options.content);

    this.contentUpdate = update;
    this.menu.appendChild(dom);

    // Append the menu
    editorView.dom.parentNode.appendChild(this.menu);

    // Update the view
    this.update(editorView, null);
    this.disabledVisible = false;
  }

  setChangeDragnDropStatus(value = false, isTimeout = false) {
    const change = (value) => () => {
      this.menu.style.display = "none";
      this.disabledVisible = value;
    };

    if (isTimeout) {
      setTimeout(change(value), 300);
    } else {
      change(value)();
    }
  }

  update(view, lastState) {
    if (this.disabledVisible) {
      return;
    }
    // console.log('SelectionMenu.update', view, lastState)

    // Got timer?
    if (this.timer !== null) {
      // console.log('SelectionMenu.clearTimeout')
      clearTimeout(this.timer);
    }

    const { state, readOnly } = view;

    // Hide menu
    if (!state || readOnly || state.selection.empty) {
      if (this.menu.style.display !== "none") {
        this.menu.style.display = "none";
      }
      return;
    }

    /*
			Show menu
		*/

    // Delay in MS to show the menu (faster for mouse double click)
    const delayMS =
      view?.lastClick?.type && view.lastClick.type === "doubleClick"
        ? 100
        : 400;

    // console.log('SelectionMenu.setTimeout')
    this.timer = setTimeout(() => {
      // console.log('SelectionMenu.runTimeout')

      this.menu.style.display = "flex";

      if (!this.menu.offsetParent) {
        if (this.menu.style.display !== "none") {
          this.menu.style.display = "none";
        }
        return;
      }

      // Update the Content state before calculating the position
      this.contentUpdate(this.editorView.state);

      /*
				Position the inline menu on screen
			*/

      // Get the selection from ProseMirror
      const { from } = state.selection; // , to

      // Let's position the menu
      try {
        /*
					Get the viewport width (to get the maximum dimensions we have)
					Example: 1920
				*/
        const viewportWidth = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );

        /*
					Get coordinates (DOM rectangle) of a selection start (cursor from position)
					relative to the window (absolute position).
					Example: DOMRect {x: 894.5 <-- cursor X pos, y: 235 <-- cursor Y pos, width: 0, height: 20, top: 235, …}
				*/
        const selectionStart = view.coordsAtPos(from);
        // const end = view.coordsAtPos(to)

        /*
					Get menu position and sizing (DOM rectangle).
					Example: DOMRect {x: 1478, y: 157, width: 283.109375 <-- menu width, height: 34, top: 157, …}
				*/
        let menuBox = this.menu.getBoundingClientRect();

        /*
					Get immediate parent position and sizing (DOM rectangle) of this inline menu.
					Currently it's: wt-editor-holder which has style position: relative
					Example: DOMRect {x: 616.5 <-- Parent X pos, y: 131 <-- Parent Y pos, width: 670, height: 208, top: 131, …}
				*/
        let parentBox = this.menu.offsetParent.getBoundingClientRect();

        // console.log('Width.parentBox', this.menu.offsetParent, parentBox)
        // console.log('Width', viewportWidth, selectionStart, menuBox, from, view)

        // let menuPosLeft = (((selectionStart.left + end.left) / 2) - (menuBox.width/2) - parentBox.left);

        /*
					Let's set menu's left position
				*/

        // We get the absolute position of selection cursor X from the left of the screen
        let menuPosLeft = selectionStart.left;

        // We substract the offset of a parent pos on screen ('cause we need relative X pos for the menu)
        menuPosLeft -= parentBox.x;

        // Too right (menu width + it's X pos is outside the viewport)?
        if (menuPosLeft + menuBox.width >= viewportWidth) {
          console.log(
            "Width.TooRight",
            menuPosLeft,
            menuBox.width,
            viewportWidth,
            parentBox.x
          );
          menuPosLeft = viewportWidth - menuBox.width - 10 - parentBox.x;
        }

        // Too left and outside of the window?
        if (menuPosLeft < 5) menuPosLeft = 5;

        /*
					Let's set menu's left position
				*/

        // We get the absolute position of selection cursor Y from the top of the screen
        let menuPosTop = selectionStart.top;

        // We substract the offset of a parent pos on screen ('cause we need relative Y pos for the menu)
        menuPosTop -= parentBox.top;

        // The menu should be above the selection, so substract the menu height + small offset
        menuPosTop -= menuBox.height + 10;

        /*
					Position the menu
				*/

        this.menu.style.left = menuPosLeft + "px";
        this.menu.style.top = menuPosTop + "px";
      } catch (err) {}
    }, delayMS);
  }

  destroy() {
    if (this.menu) {
      this.menu.remove();
    }
  }
}
