/**
 * Firebase
 */
import firebase from "firebase/app";

/**
 * Create the User in our Firestore database
 *
 * @param {string} Firebase user id = Firestore document id
 * @param {object} User profile data
 */
export const firestoreUserCreate = async (uid, userData) => {
  console.log("firestoreUserCreate", uid, userData);

  // Save to Firestore (will overwrite the data if it exists)
  await firebase.firestore().collection("users").doc(uid).set(userData);
};

/**
 * Update the User data in our Firestore database
 *
 * @param {string} Firebase user id = Firestore document id
 * @param {object} User profile data (could be a single field in a profile data)
 */
export const firestoreUserUpdate = async (uid, newProfileData) => {
  // console.log('firestoreUserUpdate', uid, newProfileData)

  // Update just some fields of a document without overwriting the entire document
  await firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .update(newProfileData);
};
