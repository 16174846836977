/**
 * Prosemirror libraries
 */
import {
  findWrapping,
  liftTarget,
  canSplit,
  ReplaceAroundStep,
} from "prosemirror-transform";
import { Slice, Fragment, NodeRange } from "prosemirror-model";
import { TextSelection } from "prosemirror-state"; // NodeSelection, Selection, AllSelection
// import { setBlockType } from 'prosemirror-commands'

/**
 * Components
 */
import { cursorParagraphBefore } from "components/ProseMirror/Utils";

/**
 * Toggle the list opened/closed
 *
 * @param {object} Current state
 * @param {int} Cursor position
 * @param {object} Current item (Node)
 */
export function ListToggle(state, pos, itemNode) {
  // console.log('ListToggle', state, pos, itemNode)

  /*
		Looking for block_element
	*/

  const tr = state.tr;

  const elementSelf = tr.doc.resolve(pos + 1);

  // Compile new attributes
  let newAttrs = { ...elementSelf.parent.attrs };
  newAttrs.open = !newAttrs.open;

  // const elementBefore = tr.doc.resolve(pos - 1)
  // const elementAfter = tr.doc.resolve(pos + 1)
  console.log("ListToggle.elementBefore", newAttrs, elementSelf);

  let newTr = null;
  try {
    newTr = tr.setNodeMarkup(
      pos, // (blockElementPos - 1), // why -1?? not sure. but works
      null,
      newAttrs
    );
  } catch (e) {
    console.log("ListToggle.Pos.Exception", e);
  }
  const elementSelf2 = tr.doc.resolve(pos + 1);
  console.log("ListToggle.elementAfter", newAttrs, elementSelf2);

  // Replace element with new one with same ID and reverted "open" attribute
  if (newTr) return newTr;
  else return;

  /*
	let tr = state.tr
	// let origPos = pos

	// Resolve pos
	const currentPos = tr.doc.resolve(pos)
	// console.log('ListToggle', pos, currentPos.end(), currentPos)

	/*
	// Get a parent
	// let parent = currentPos.node(currentPos.depth - 1)
	let nodeStart = currentPos.start()
	console.log('ListToggle.DEBUG.Start', nodeStart)

	// Get all sub.nodes
	currentPos.parent.forEach((node, offset, index) => {
		// ...offset + parentStart 
		let childPos = offset + nodeStart
		console.log('ListToggle.DEBUG.Child', index, offset, childPos, node)
	})
	* /
	
	// Get node at pos
	let openState = null
	let nodeAt = tr.doc.nodeAt(pos)
	while (
		nodeAt
		&& nodeAt?.type
		&& (
			nodeAt.type === currentPos.parent.type.schema.nodes.bullet_list
			|| nodeAt.type === currentPos.parent.type.schema.nodes.ordered_list
			|| nodeAt.type === currentPos.parent.type.schema.nodes.todo_list
			// || nodeAt.type === currentPos.parent.type.schema.nodes.hidden_list
		)
		&& pos <= currentPos.end()
	) {
		// console.log('ListToggle.nodeAt', pos, nodeAt)

		// What state are we all in?
		if (openState === null) openState = !nodeAt.attrs.open

		// console.log('ListToggle.State', openState)

		// Toggle it
		tr.setNodeMarkup(
			pos,
			null,
			{
				blockid: nodeAt.attrs.blockid,
				open: openState
			}
		)

		// Get next node
		pos += nodeAt.nodeSize
		nodeAt = tr.doc.nodeAt(pos)
	}

	/*
	// Closed?
	if (!openState) { 
		// Move the cursor to the start
		tr.setSelection(TextSelection.create(tr.doc, ??pos))
	}
	*/

  /*
	// Multiple chidren?
	if (
		currentPos?.parent
		&& currentPos.parent?.childCount > 2
	) {
		// const parentPos = currentPos.node(currentPos.depth - 1)
		// console.log('ListToggle.Parent', parentPos)

		// Multiple children?
		// if () { }

		// Foreach child
		let cursorPos = currentPos.before()
		console.log('ListToggle.Start', cursorPos)
		for (let i = 0; i < currentPos.parent.childCount; i++) {
			// Get the child
			const currentChild = currentPos.parent.child(i)
			console.log('ListToggle.currentChild', currentChild)

			// Move the cursor
			cursorPos += currentChild.nodeSize //  - 1)
			console.log('ListToggle.Move', currentChild.nodeSize, cursorPos)

			if (
				currentChild?.type !== currentPos.parent.type.schema.nodes.bullet_list
				&& currentChild?.type !== currentPos.parent.type.schema.nodes.ordered_list
				&& currentChild?.type !== currentPos.parent.type.schema.nodes.todo_list
			) {
				continue
			}

			// Resolve node
			const resolvedChild = tr.doc.resolve(cursorPos)
			console.log('ListToggle.currentChild.Resolved', resolvedChild)

			let nodeAt = tr.doc.nodeAt(pos)
			console.log('ListToggle.currentChild.nodeAt1', nodeAt)

			nodeAt = tr.doc.nodeAt(pos + itemNode.nodeSize)
			console.log('ListToggle.currentChild.nodeAt2', nodeAt)

			// Change the open state
			if (
				resolvedChild?.parent?.attrs
				&& "open" in resolvedChild.parent.attrs
			) {
				console.log('ListToggle.currentChild.Toggle', resolvedChild.parent.attrs.blockid, !resolvedChild.parent.attrs.open)

				tr.setNodeMarkup(
					resolvedChild.start(),
					null,
					{
						blockid: resolvedChild.parent.attrs.blockid,
						open: !resolvedChild.parent.attrs.open
					}
				)
			}
		}
	} */

  // Это нужно будет отключить:
  // Replace element with new one with same ID and reverted "open" attribute
  /* return state.tr.setNodeMarkup(
		pos,
		null,
		{
			blockid: itemNode.attrs.blockid,
			open: !itemNode.attrs.open
		}
	) * /
	// console.log('ListToggle.Return')
	return tr
	*/
}

/**
 * Process the menu item click command,
 * converting selected node into specified list,
 * or converting one type of list into another one.
 * @param {object} List Node type (todo_list, bullet_list, ordered_list)
 * @param {array} Element attributes
 */
export function convertList(listType, attrs) {
  /**
   * Do the conversion
   * @param {object} Current document state
   * @param {object} Transformation dispatcher (if missing - the command will not run)
   * @return {boolean} Processing is possible (if no dispatch)? Processing is successfull (with dispatch)?
   */
  return function (state, dispatch) {
    console.log("convertList", listType);

    /*
			Prepare the data to work with
		*/

    // Get the selection
    let { $from, $to } = state.selection;

    // Selection range
    let range = $from.blockRange($to);

    // No range found? Can't continue
    if (!range) return false;

    // Schema nodes
    let schemaNodes = state.doc.type.schema.nodes;

    // Get immediate parent
    const parent = $from.node($from.depth - 1);
    console.log("convertList", parent);

    // Transaction
    let tr = state.tr;

    /*
			Is it a List under the selection?
		*/

    let isList = false;
    let liftListFrom;
    let liftListType;

    // console.log('convertList.range', range)

    // Todo list or todo list item
    if (
      range?.parent?.type?.name === "todo_list" ||
      range?.parent?.type?.name === "todo_item"
    ) {
      // console.log('convertList.checkList.todo')

      isList = true;
      liftListType = schemaNodes.todo_list; // 'todo_list'
      liftListFrom = schemaNodes.todo_item;

      // Bullet/ordered-list or list item
    } else if (
      range?.parent?.type?.name === "bullet_list" ||
      range?.parent?.type?.name === "ordered_list" ||
      range?.parent?.type?.name === "list_item"
    ) {
      // console.log('convertList.checkList.others')

      isList = true;
      liftListType = range.parent.type; // range.parent.type.name
      liftListFrom = schemaNodes.list_item;

      // What type of list are we in?
      if (range.parent.type.name === "list_item" && $from?.depth >= 3) {
        const listParent = $from.node($from.depth - 2);
        liftListType = listParent?.type; // listParent?.type?.name
      }
    }

    /*
			Cursor is not in the first child of a parent,
			let's work as if we are not in list.
		*/

    if (parent.firstChild !== $from.parent) {
      isList = false;
    }

    /*
			List to list conversion
		*/
    if (isList) {
      // console.log('convertList.isList', liftListType, liftListFrom.name, listType?.name)

      // Same type conversion? Do nothing.
      if (listType?.name === liftListType?.name) return true;

      console.log("convertList.convert");

      /*
				We need to run a sequence of editor commands,
				let's prepare a holder for them
			*/

      /*
			let transactions
			
			// if (currentNode.name === listType.name) transactions = liftListItem(schema.nodes.list_item);
			// else if (oppositeListOf[currentNode.name] === listType.name)

			// A set of commands to apply
			transactions = chainTransactions(
				// Lift the list item out to a simple primitive: paragraph
				liftListItem(liftListFrom),
				// Wrap the resulting paragraph to a new List
				wrapInList(listType, attrs)
			)

			transactions(state, dispatch)
			*/

      /*
			WORKED:
			// Lift out of the list
			let liftOutResult = stateLiftOutOfList(tr, state, dispatch, range)
			console.log('convertList.isList.stateLiftOutOfList', liftOutResult)
			if (liftOutResult) {
				tr = liftOutResult
				state = state.apply(tr)

				// Wrap into a new list
				const wrapResult = stateWrapInList(tr, state, listType, attrs)
				console.log('convertList.isList.wrapResult', wrapResult)
				if (wrapResult) tr = wrapResult

			} else { 
				// Lift out of the list
				liftOutResult = stateLiftToOuterList(tr, state, dispatch, listType, range)
				console.log('convertList.isList.stateLiftToOuterList', liftOutResult)

				if (liftOutResult) {
					tr = liftOutResult
					state = state.apply(tr)
				}

				// Wrap into a new list
				const wrapResult = stateWrapInList(tr, state, listType, attrs)
				console.log('convertList.isList.wrapResult', wrapResult)
				if (wrapResult) tr = wrapResult
			}
			*/

      // Extend $to
      // const newTo = tr.doc.resolve($from.end($from.depth - 1))
      // console.log('convertList.isList.liftList.extended', newTo)

      // tr = state.tr
      // dispatch(tr)
      // return

      // const parentEnd = $from.end($from.depth - 1)
      // console.log()

      // Let's select all the node from start to end
      const toParent = $to.node($to.depth - 1);
      console.log("convertList.toParent", toParent);
      let initialFrom, initialTo;
      if (parent.childCount > 1 && parent === toParent) {
        console.log("convertList.isList.liftList.selectionExpand");

        initialFrom = state.selection.from;
        initialTo = state.selection.to;

        // console.log('convertList.isList.liftList.parent.descendants', childNode, childPos)
        const selectFrom = $from.start($from.depth - 1) + 1;
        let selectTo;
        parent.descendants((childNode, childPos) => {
          // console.log('convertList.isList.liftList.descendants', childNode, childPos)
          selectTo = selectFrom + childPos + childNode.nodeSize;
        });

        tr.setSelection(TextSelection.create(tr.doc, selectFrom, selectTo - 1));
        state = state.apply(tr);
      }

      const liftPerformed = stateLiftListItem(
        tr,
        state,
        dispatch,
        liftListType,
        liftListFrom
      );
      console.log("convertList.isList.liftList", liftPerformed);
      if (liftPerformed) {
        tr = liftPerformed;
        state = state.apply(tr);
      }

      // state = state.apply(tr)

      // Wrap into a new list
      const wrapResult = stateWrapInList(tr, state, listType, attrs);
      console.log("convertList.isList.wrapResult", wrapResult);
      if (wrapResult) tr = wrapResult;

      // Revert the selection (if it was changed)
      if (initialFrom && initialTo) {
        tr.setSelection(TextSelection.create(tr.doc, initialFrom, initialTo));
      }

      if (tr && dispatch) {
        dispatch(
          tr.scrollIntoView()
          // We will perform the wrapping using the latest "tr"
          // doWrapInList(tr, range, wrap, doJoin, listType)
        );
      }

      // dispatch(tr.scrollIntoView())

      /*
			Heading?
		*/
    } else if (
      $from?.parent?.type?.name === "heading" ||
      $to?.parent?.type?.name === "heading"
    ) {
      // console.log('convertList.heading', $from?.parent?.type?.name, schemaNodes.paragraph)

      /* 
				I was trying to apply multiple
				operations via chainTransactions
				but always got the "Applying a mismatched transaction"
				error while executing setBlockType -> wrapInList :(
				
				A set of commands to apply
			transactions = chainTransactions(
				// Convert to a simple primitive: paragraph
				setBlockType(schemaNodes.paragraph),
				// Wrap the resulting paragraph to a new List
				wrapInList(listType, attrs)
			) */

      /*
				First step.
				Convert to a Paragraph
			*/

      // Get from(number) .. to(number) for the selection
      let { from, to } = state.selection;

      // Set a block type between selections
      tr.setBlockType(from, to, schemaNodes.paragraph);

      // Apply the transaction and get the new State
      state = state.apply(tr);

      /*
				Wrap the resulting paragraphs in list
			* /
			
			// Get the selection
			let { $from, $to } = state.selection

			// Get the range
			let range = $from.blockRange($to), doJoin = false, outerRange = range
			
			// No range found?
			if (!range) return false

			// This is at the top of an existing list item
			if (range.depth >= 2 && $from.node(range.depth - 1).type.compatibleContent(listType) && range.startIndex === 0) {
				// Don't do anything if this is the top of the list
				if ($from.index(range.depth - 1) === 0) return false
				let $insert = state.doc.resolve(range.start - 2)
				outerRange = new NodeRange($insert, $insert, range.depth)
				if (range.endIndex < range.parent.childCount)
				range = new NodeRange($from, state.doc.resolve($to.end(range.depth)), range.depth)
				doJoin = true
			}

			let wrap = findWrapping(outerRange, listType, attrs, range)
			if (!wrap) return false
			*/

      const wrapResult = stateWrapInList(tr, state, listType, attrs);
      if (wrapResult) tr = wrapResult;

      if (dispatch) {
        dispatch(
          tr.scrollIntoView()
          // We will perform the wrapping using the latest "tr"
          // doWrapInList(tr, range, wrap, doJoin, listType)
        );
      }

      /*
			Wrap in list
		*/
    } else {
      console.log("convertList.nonList", range?.parent?.type?.name);
      wrapInList(listType, attrs)(state, dispatch);
    }

    return true;
  };
}

export function stateWrapInList(tr, state, listType, attrs) {
  /*
		Wrap the resulting paragraphs in list
	*/

  // Get the selection
  let { $from, $to } = state.selection;
  console.log("stateWrapInList", $from, $to);

  // Get the range
  let range = $from.blockRange($to),
    doJoin = false,
    outerRange = range;

  // No range found?
  if (!range) return false;

  // This is at the top of an existing list item
  if (
    range.depth >= 2 &&
    $from.node(range.depth - 1).type.compatibleContent(listType) &&
    range.startIndex === 0
  ) {
    // Don't do anything if this is the top of the list
    if ($from.index(range.depth - 1) === 0) return false;
    let $insert = state.doc.resolve(range.start - 2);
    outerRange = new NodeRange($insert, $insert, range.depth);
    if (range.endIndex < range.parent.childCount)
      range = new NodeRange(
        $from,
        state.doc.resolve($to.end(range.depth)),
        range.depth
      );
    doJoin = true;
  }

  let wrap = findWrapping(outerRange, listType, attrs, range);
  if (!wrap) return false;

  // We will perform the wrapping using the latest "tr"
  tr = doWrapInList(tr, range, wrap, doJoin, listType);

  return tr;
}

// :: (NodeType, ?Object) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Returns a command function that wraps the selection in a list with
// the given type an attributes. If `dispatch` is null, only return a
// value to indicate whether this is possible, but don't actually
// perform the change.
/**
 * Prepare function for wrapping the selecting into a list
 * @param {object} Node type we need to wrap into (bullet_list, ordered_list, todo_list)
 * @param {object} Attributes list
 * @return {function} Function to appoly the liftout for the exact state
 */
export function wrapInList(listType, attrs) {
  /**
   * Do the wrapping
   * @param {object} Current document state
   * @param {object} Transformation dispatcher (if missing - the command will not run)
   * @return {boolean} Processing is possible (if no dispatch)? Processing is successfull (with dispatch)?
   */
  return function (state, dispatch) {
    console.log("wrapInList", listType);

    // Get the selection
    let { $from, $to } = state.selection;

    // Get the range
    let range = $from.blockRange($to),
      doJoin = false,
      outerRange = range;
    console.log("wrapInList.range", range);

    // No range found?
    if (!range) return false;

    /*
			List conversion
		* /

		if (
			range?.parent?.type?.name === 'todo_list'
			|| range?.parent?.type?.name === 'bullet_list'
			|| range?.parent?.type?.name === 'ordered_list'
		) {
			console.log('wrapInList.listConversion', range.parent.type.name, listType?.name)

			/*
			const tr = state.tr;
			const start = $from.node(range.depth - 1);
			// TODO: how do I pass the node above to `setNodeType`?
			tr.setNodeType(start, listType) // range.start
			if (dispatch) dispatch(tr)


			* /

			function chainTransactions(...commands) {
				return (state, dispatch) => {
					const dispatcher = (tr) => {
						state = state.apply(tr)
						dispatch(tr)
					}
					const last = commands.pop()
					const reduced = commands.reduce((result, command) => {
						return result || command(state, dispatcher)
					}, false)
					return reduced && last !== undefined && last(state, dispatch)
				}
			}

			let transactions = chainTransactions(liftListItem(schema.nodes.list_item), wrapInList(listType, attrs));

			return transactions(state, dispatch);
		}
		*/

    /*
			Default processing
		*/

    // This is at the top of an existing list item
    if (
      range.depth >= 2 &&
      $from.node(range.depth - 1).type.compatibleContent(listType) &&
      range.startIndex === 0
    ) {
      console.log("wrapInList.isTop");

      // Don't do anything if this is the top of the list
      if ($from.index(range.depth - 1) === 0) return false;
      let $insert = state.doc.resolve(range.start - 2);
      outerRange = new NodeRange($insert, $insert, range.depth);
      if (range.endIndex < range.parent.childCount)
        range = new NodeRange(
          $from,
          state.doc.resolve($to.end(range.depth)),
          range.depth
        );
      doJoin = true;
    }

    let wrap = findWrapping(outerRange, listType, attrs, range);
    console.log("wrapInList.wrap", wrap);
    if (!wrap) return false;

    if (dispatch)
      dispatch(
        doWrapInList(state.tr, range, wrap, doJoin, listType).scrollIntoView()
      );

    return true;
  };
}

function doWrapInList(tr, range, wrappers, joinBefore, listType) {
  let content = Fragment.empty;
  for (let i = wrappers.length - 1; i >= 0; i--)
    content = Fragment.from(
      wrappers[i].type.create(wrappers[i].attrs, content)
    );

  tr.step(
    new ReplaceAroundStep(
      range.start - (joinBefore ? 2 : 0),
      range.end,
      range.start,
      range.end,
      new Slice(content, 0, 0),
      wrappers.length,
      true
    )
  );

  let found = 0;
  for (let i = 0; i < wrappers.length; i++)
    if (wrappers[i].type === listType) found = i + 1;
  let splitDepth = wrappers.length - found;

  let splitPos = range.start + wrappers.length - (joinBefore ? 2 : 0),
    parent = range.parent;
  for (
    let i = range.startIndex, e = range.endIndex, first = true;
    i < e;
    i++, first = false
  ) {
    if (!first && canSplit(tr.doc, splitPos, splitDepth)) {
      tr.split(splitPos, splitDepth);
      splitPos += 2 * splitDepth;
    }
    splitPos += parent.child(i).nodeSize;
  }
  return tr;
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Build a command that splits a non-empty textblock at the top level
// of a list item by also splitting that list item.
export function splitListItem(itemType) {
  return function (state, dispatch) {
    console.log("splitListItem");

    /*
			Data to work with
		*/

    // Transaction
    let tr = state.tr;

    // From, to
    let { $from, $to, node } = state.selection;

    // Node types
    let schemaNodes = state.doc.type.schema.nodes;

    // console.log('splitListItem') // , $from, $to, state.selection

    /*
			Invalid environment?
		*/

    if (
      // This is a block (can do nothing)
      (node && node.isBlock) ||
      // The depth is less than doc > list > item > paragraph (this will be depth: 3)
      $from.depth < 2 ||
      // Multiple different elements are in a selection
      !$from.sameParent($to)
    )
      return false;

    /*
			Are we of the right list_item type?
		*/

    let listItemNode = $from.node(-1);
    if (listItemNode.type !== itemType) return false;

    /*
			Get the list
		*/

    let listNode = $from.node(-2);

    // console.log('splitListItem.listNode', listNode, listNode.firstChild === listItemNode)

    /*
			There are multiple children inside list_item
		*/
    if (listItemNode?.childCount > 1) {
      console.log("splitListItem.multi", listItemNode, $from);

      // The paragraph I'm in is NOT empty
      if ($from?.parent?.textContent !== "") {
        console.log("splitListItem.multi.nonEmpty", $from.pos, $from.start());

        // Am I at the very end? And the list is closed?
        if ($from.pos === $from.end() && listItemNode?.attrs?.open === false) {
          console.log(
            "splitListItem.multi.theEnd.Closed",
            $from.pos,
            listItemNode
          );

          // Create item below
          if (dispatch) {
            // Create new child
            const newListItem = listItemNode.type.createAndFill(null);

            // Calc the pos
            const newListItemPos = $from.before() + listItemNode.nodeSize - 1;

            tr = tr.insert(newListItemPos, newListItem);
            state = state.apply(tr);

            // console.log('blockEnter.End.Open.Tr2', JSON.stringify(tr))
            tr = tr.setSelection(TextSelection.create(tr.doc, newListItemPos));
            dispatch(tr.scrollIntoView());

            /* // Move the cursor to it!
						tr = tr.setSelection(
							TextSelection.create(
								tr.doc, newListItemPos
							)
						) */

            // Dispatch it!
            // dispatch(tr.scrollIntoView())

            return true;
          }

          return false;
        }

        // Am I at the very begginning?
        if ($from.pos === $from.start()) {
          console.log("splitListItem.multi.createBefore");

          /*
						Am I the first child in list?
					*/
          if (listNode.firstChild === listItemNode) {
            // Create a paragraph above
            tr = tr.insert(
              $from?.before() - 2,
              schemaNodes.paragraph.createAndFill()
            );
            tr.setSelection(TextSelection.create(tr.doc, $from?.before() - 2));
            dispatch(tr.scrollIntoView());
          } else {
            let nextType =
              $to.pos === $from.end()
                ? listItemNode.contentMatchAt(0).defaultType
                : null;
            // console.log('splitListItem.nextType', nextType)

            // No type?
            if (!nextType) {
              // nextType = $from?.parent?.type
              nextType = schemaNodes.paragraph;
            }

            // Remove the selection starting from $from
            tr = tr.delete($from.pos, $to.pos);

            let types = nextType && [null, { type: nextType }];
            console.log("splitListItem.types", types);

            if (!canSplit(tr.doc, $from.pos, 2, types)) return false;

            if (dispatch) {
              tr.split($from.pos, 2, types);
              dispatch(tr.scrollIntoView());
            }
          }

          return true;
        }

        return false;
      }

      // The paragraph I'm is NOT the last one
      if (listItemNode?.lastChild !== $from?.parent) {
        console.log("splitListItem.multi.notLast");

        // Create a paragraph below
        tr = tr.insert($from?.after(), schemaNodes.paragraph.createAndFill());
        tr.setSelection(TextSelection.create(tr.doc, $from?.after() + 1));
        dispatch(tr.scrollIntoView());

        return true;
      }

      /*
				Empty paragraph, create list item below
			*/

      // console.log('splitListItem.multi.empty', listItemNode, $from)

      // Get a parent
      const parent = $from.node($from.depth - 1);
      const parentResolved = state.tr.doc.resolve($from.start($from.depth - 1));

      // Set the offset from the start
      // let locationOffset = parentResolved.pos

      // Create new child
      const posType = listItemNode.type;
      const listItem = posType.createAndFill(null);

      console.log(
        "splitListItem.multi.empty",
        listItemNode,
        $from,
        parent,
        parentResolved,
        listItem
      );

      // Remove empty paragraph
      tr.delete($from.before(), $from.after());

      state = state.apply(tr);

      // Insert at the beginning of the first list inside parent
      tr = tr.insert($from.before(), listItem);

      // Move the cursor to it!
      tr.setSelection(TextSelection.create(tr.doc, $to.pos + 1));

      // Dispatch it!
      dispatch(tr.scrollIntoView());

      return true;
    }

    console.log("splitListItem.split", listItemNode, $from);

    // We are inside an empty paragraph
    if ($from.parent.content.size === 0) {
      // If this is a nested list, the wrapping
      // list item should be split. Otherwise, bail out and let next
      // command handle lifting.
      if (
        $from.depth === 2 ||
        $from.node(-3).type !== itemType ||
        $from.index(-2) !== $from.node(-2).childCount - 1
      )
        return false;

      if (dispatch) {
        let wrap = Fragment.empty,
          keepItem = $from.index(-1) > 0;
        // Build a fragment containing empty versions of the structure
        // from the outer list item to the parent node of the cursor
        for (
          let d = $from.depth - (keepItem ? 1 : 2);
          d >= $from.depth - 3;
          d--
        ) {
          wrap = Fragment.from($from.node(d).copy(wrap));
        }
        // Add a second list item with an empty default start node
        wrap = wrap.append(Fragment.from(itemType.createAndFill()));
        tr = tr.replace(
          $from.before(keepItem ? null : -1),
          $from.after(-3),
          new Slice(wrap, keepItem ? 3 : 2, 2)
        );
        tr.setSelection(
          state.selection.constructor.near(
            tr.doc.resolve($from.pos + (keepItem ? 3 : 2))
          )
        );
        dispatch(tr.scrollIntoView());
      }
      return true;

      // We have content in paragraph
    } else {
      /*
				Process Enter at the end of the list-item
				(it will generate a new list-item below the current one)
			*/

      // console.log('splitListItem.item', $from.end(), $from.start(), $from.before(), $from.after())
      // console.log('splitListItem.from', $from)
      if (
        // The selection is empty
        state.selection.empty &&
        // Cursor is equal
        $from.pos === $to.pos &&
        // We are at the end
        $from.pos === $from.end() &&
        // Depth is at least 3 (doc > ul > li > paragraph)
        $from.depth >= 3
      ) {
        // Get a parent
        const parent = $from.node($from.depth - 1);
        const parentResolved = state.tr.doc.resolve(
          $from.start($from.depth - 1)
        );
        // const pos1 = state.tr.doc.resolve(parentResolved.posAtIndex(0))
        // const pos2 = state.tr.doc.resolve(parentResolved.posAtIndex(1))
        // console.log('splitListItem.parentResolved', parentResolved, pos1, pos2)

        // Got it?
        if (
          // We got a right parent?
          parent?.type &&
          (parent.type === schemaNodes.list_item ||
            parent.type === schemaNodes.todo_item)
        ) {
          // Set the offset from the start
          let locationOffset = parentResolved.pos;

          // Search for internal list within parent descendants
          let listOpen = false;
          let listNode = null;
          parent.descendants((childNode, childPos) => {
            // Already found
            if (listNode) return false;

            // Is it the right type?
            if (
              // List is found?
              (childNode?.type === schemaNodes.bullet_list ||
                childNode?.type === schemaNodes.ordered_list ||
                childNode?.type === schemaNodes.todo_list) &&
              // And it's open!
              childNode?.attrs?.open
            ) {
              // console.log('splitListItem.foundList', childPos, childNode) // , parentResolved

              // Remember this child
              listNode = childNode;
              listOpen = true;

              // Add to offset to this exact child
              locationOffset += childPos;
            }
          });

          // console.log('splitListItem.parent', locationOffset, parent) // , parentResolved

          // The list is open?
          if (listOpen) {
            // console.log('splitListItem.parentOpen.ItemInside', listOpen)

            // Are we doing this?
            if (dispatch) {
              // What type of child do we need?
              let posType = null;
              listNode.descendants((childNode, childPos) => {
                // Skip further processing
                if (posType) return false;

                // console.log('splitListItem.parentOpen.descendants', childNode, childPos)

                // First Node was found?
                if (
                  childNode.type === schemaNodes.list_item ||
                  childNode.type === schemaNodes.todo_item
                ) {
                  // posFound = childPos
                  posType = childNode.type;
                }
              });

              // Create new child
              const listItem = posType.createAndFill(null);

              // Insert at the beginning of the first list inside parent
              tr = tr.insert(locationOffset + 1, listItem);

              // Move the cursor to it!
              tr.setSelection(TextSelection.create(tr.doc, locationOffset));

              // Dispatch it!
              dispatch(tr.scrollIntoView());
            }

            // Grand parent is closed
          } else {
            // console.log('splitListItem.parentClosed.ItemAfter')

            // Resolve the parent (LI)
            let parentIndex = $from.index($from.depth - 1);
            let parentPos = $from.posAtIndex(parentIndex, $from.depth - 1);
            let parentResolved = state.tr.doc.resolve(parentPos);

            // Get item type
            let itemType = parent.type.createAndFill(null);

            // Are we doing this?
            if (dispatch) {
              // Insert after this item
              tr = tr.insert(parentResolved.after(), itemType);

              // Move the cursor to the start
              tr.setSelection(
                TextSelection.create(tr.doc, parentResolved.after())
              );

              // Dispatch it!
              dispatch(tr.scrollIntoView());
            }
          }

          return true;
        }
      }

      let nextType =
        $to.pos === $from.end()
          ? listItemNode.contentMatchAt(0).defaultType
          : null;
      // console.log('splitListItem.nextType', nextType)

      // No type?
      if (!nextType) {
        // nextType = $from?.parent?.type
        nextType = schemaNodes.paragraph;
      }

      // Remove the selection starting from $from
      tr = tr.delete($from.pos, $to.pos);

      let types = nextType && [null, { type: nextType }];
      console.log("splitListItem.types", types);

      if (!canSplit(tr.doc, $from.pos, 2, types)) return false;

      if (dispatch) {
        tr.split($from.pos, 2, types);
        dispatch(tr.scrollIntoView());
      }
      return true;
    }
  };
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Create a command to lift the list item around the selection up into
// a wrapping list.
/**
 * Prepare function for the lifting the List item outside the List or to the upper List
 * @param {object} Type of the list item we need to lift out of (todo_item, list_item)
 * @return {function} Function to appoly the liftout for the exact state
 */
export function liftListItem(itemType) {
  /**
   * Do the lift out
   * @param {object} Current document state
   * @param {object} Transformation dispatcher (if missing - the command will not run)
   * @return {boolean} Processing is possible (if no dispatch)? Processing is successfull (with dispatch)?
   */
  return function (state, dispatch) {
    console.log("liftListItem", itemType);

    // Get the selection
    let { $from, $to } = state.selection;

    // Get the range
    let range = $from.blockRange($to, (node) => {
      // console.log(node.childCount, node.firstChild.type, itemType);
      return node.childCount && node.firstChild.type === itemType;
    });

    // No range found?
    if (!range) return false;

    // There is no dispatch, so we are just checking and not running
    if (!dispatch) return true;

    // Are we inside a parent list?
    if ($from.node(range.depth - 1).type === itemType) {
      return liftToOuterList(state, dispatch, itemType, range);

      // Outer list node
    } else {
      return liftOutOfList(state, dispatch, range);
    }
  };
}

/**
 * Do the lift out
 * @param {object} Transaction
 * @param {object} Current document state
 * @param {object} Transformation dispatcher (if missing - the command will not run)
 * @param {object} Node type
 * @return {boolean} Processing is possible (if no dispatch)? Processing is successfull (with dispatch)?
 */
export function stateLiftListItem(
  tr,
  state,
  dispatch,
  liftListType,
  liftListItemType
) {
  console.log("stateLiftListItem", liftListType.name, liftListItemType.name);

  // Transaction
  // let tr = state.tr

  // Get the selection
  let { $from, $to } = state.selection;

  // Get the range
  let range = $from.blockRange($to, (node) => {
    console.log(
      "stateLiftListItem.range.node",
      node.childCount,
      node.firstChild.type.name,
      liftListItemType.name
    );
    return node.childCount && node.firstChild.type === liftListItemType;
  });

  // let range = $from.blockRange($to)

  console.log("stateLiftListItem.range", range);

  // No range found?
  if (!range) return false;

  // There is no dispatch, so we are just checking and not running
  if (!dispatch) return true;

  // Transaction holder (in case of success)
  let trPerformed;

  // Are we inside a parent list?
  console.log(
    "stateLiftListItem.type",
    $from.node(range.depth - 1).type.name,
    liftListType.name
  );
  if ($from.node(range.depth - 1).type === liftListType) {
    trPerformed = stateLiftToOuterList(
      tr,
      state,
      dispatch,
      liftListType,
      range
    );
    console.log("stateLiftListItem.stateLiftToOuterList", trPerformed);

    // Outer list node
  } else {
    trPerformed = stateLiftOutOfList(tr, state, dispatch, range);
    console.log("stateLiftListItem.stateLiftOutOfList", trPerformed);
  }

  // Success?
  if (trPerformed) return trPerformed;

  return false;
}

export function liftToOuterList(state, dispatch, itemType, range) {
  let tr = state.tr,
    end = range.end,
    endOfList = range.$to.end(range.depth);
  if (end < endOfList) {
    // There are siblings after the lifted items, which must become
    // children of the last item
    tr.step(
      new ReplaceAroundStep(
        end - 1,
        endOfList,
        end,
        endOfList,
        new Slice(
          Fragment.from(itemType.create(null, range.parent.copy())),
          1,
          0
        ),
        1,
        true
      )
    );
    range = new NodeRange(
      tr.doc.resolve(range.$from.pos),
      tr.doc.resolve(endOfList),
      range.depth
    );
  }
  dispatch(tr.lift(range, liftTarget(range)).scrollIntoView());
  return true;
}

export function stateLiftToOuterList(tr, state, dispatch, itemType, range) {
  let end = range.end,
    endOfList = range.$to.end(range.depth);
  if (end < endOfList) {
    // There are siblings after the lifted items, which must become
    // children of the last item
    tr.step(
      new ReplaceAroundStep(
        end - 1,
        endOfList,
        end,
        endOfList,
        new Slice(
          Fragment.from(itemType.create(null, range.parent.copy())),
          1,
          0
        ),
        1,
        true
      )
    );
    range = new NodeRange(
      tr.doc.resolve(range.$from.pos),
      tr.doc.resolve(endOfList),
      range.depth
    );
  }
  tr.lift(range, liftTarget(range));

  // tr = dispatch(tr.lift(range, liftTarget(range)).scrollIntoView())
  return tr;
}

export function liftOutOfList(state, dispatch, range) {
  let tr = state.tr,
    list = range.parent;
  // Merge the list items into a single big item
  for (
    let pos = range.end, i = range.endIndex - 1, e = range.startIndex;
    i > e;
    i--
  ) {
    pos -= list.child(i).nodeSize;
    tr.delete(pos - 1, pos + 1);
  }
  let $start = tr.doc.resolve(range.start),
    item = $start.nodeAfter;
  let atStart = range.startIndex === 0,
    atEnd = range.endIndex === list.childCount;
  let parent = $start.node(-1),
    indexBefore = $start.index(-1);
  if (
    !parent.canReplace(
      indexBefore + (atStart ? 0 : 1),
      indexBefore + 1,
      item.content.append(atEnd ? Fragment.empty : Fragment.from(list))
    )
  )
    return false;
  let start = $start.pos,
    end = start + item.nodeSize;
  // Strip off the surrounding list. At the sides where we're not at
  // the end of the list, the existing list is closed. At sides where
  // this is the end, it is overwritten to its end.
  tr.step(
    new ReplaceAroundStep(
      start - (atStart ? 1 : 0),
      end + (atEnd ? 1 : 0),
      start + 1,
      end - 1,
      new Slice(
        (atStart
          ? Fragment.empty
          : Fragment.from(list.copy(Fragment.empty))
        ).append(
          atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))
        ),
        atStart ? 0 : 1,
        atEnd ? 0 : 1
      ),
      atStart ? 0 : 1
    )
  );
  dispatch(tr.scrollIntoView());
  return true;
}

export function stateLiftOutOfList(tr, state, dispatch, range) {
  let list = range.parent;

  // Merge the list items into a single big item
  for (
    let pos = range.end, i = range.endIndex - 1, e = range.startIndex;
    i > e;
    i--
  ) {
    pos -= list.child(i).nodeSize;
    tr.delete(pos - 1, pos + 1);
  }

  let $start = tr.doc.resolve(range.start),
    item = $start.nodeAfter;
  let atStart = range.startIndex === 0,
    atEnd = range.endIndex === list.childCount;
  let parent = $start.node(-1),
    indexBefore = $start.index(-1);

  if (
    !parent.canReplace(
      indexBefore + (atStart ? 0 : 1),
      indexBefore + 1,
      item.content.append(atEnd ? Fragment.empty : Fragment.from(list))
    )
  ) {
    console.log("stateLiftOutOfList.cantReplace");
    return false;
  }

  let start = $start.pos,
    end = start + item.nodeSize;
  // Strip off the surrounding list. At the sides where we're not at
  // the end of the list, the existing list is closed. At sides where
  // this is the end, it is overwritten to its end.
  tr.step(
    new ReplaceAroundStep(
      start - (atStart ? 1 : 0),
      end + (atEnd ? 1 : 0),
      start + 1,
      end - 1,
      new Slice(
        (atStart
          ? Fragment.empty
          : Fragment.from(list.copy(Fragment.empty))
        ).append(
          atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))
        ),
        atStart ? 0 : 1,
        atEnd ? 0 : 1
      ),
      atStart ? 0 : 1
    )
  );
  // dispatch(tr.scrollIntoView())
  return tr;
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Create a command to sink the list item around the selection down
// into an inner list.
export function sinkListItem(itemType) {
  return function (state, dispatch) {
    let { $from, $to } = state.selection;
    let range = $from.blockRange(
      $to,
      (node) => node.childCount && node.firstChild.type === itemType
    );
    if (!range) return false;
    let startIndex = range.startIndex;
    if (startIndex === 0) return false;
    let parent = range.parent,
      nodeBefore = parent.child(startIndex - 1);
    if (nodeBefore.type !== itemType) return false;

    if (dispatch) {
      let nestedBefore =
        nodeBefore.lastChild && nodeBefore.lastChild.type === parent.type;
      let inner = Fragment.from(nestedBefore ? itemType.create() : null);
      let slice = new Slice(
        Fragment.from(
          itemType.create(null, Fragment.from(parent.type.create(null, inner)))
        ),
        nestedBefore ? 3 : 1,
        0
      );
      let before = range.start,
        after = range.end;
      dispatch(
        state.tr
          .step(
            new ReplaceAroundStep(
              before - (nestedBefore ? 3 : 1),
              after,
              before,
              after,
              slice,
              1,
              true
            )
          )
          .scrollIntoView()
      );
    }
    return true;
  };
}

/**
 * PRocess the Backspace key hit inside the List
 * @param {object} Node type
 * @param {array} Element attributes
 */
export function listKeyBackspace(listType, attrs) {
  /**
   * Do the processing
   * @param {object} Current document state
   * @param {object} Transformation dispatcher (if missing - the command will not run)
   * @return {boolean} Processing is possible (if no dispatch)? Processing is successfull (with dispatch)?
   */
  return function (state, dispatch) {
    // console.log('listKeyBackspace.TURNEDOFF', listType.name)

    // TURN IT OFF!
    // return false

    /*
			Data
		*/

    // Resolved position of a cursor selection (an empty text selection), or null otherwise
    const $cursor = state?.selection?.$cursor;

    // Transaction
    let tr = state.tr;

    // Node types in schema
    const schemaNodes = state.schema.nodes;

    console.log("listKeyBackspace", listType.name, $cursor);

    /*
			Cursor
		*/
    if (
      $cursor &&
      // A list item could be not higher than 3 (doc(0) > list(1) > list_item(2) > paragraph(3))
      $cursor?.depth >= 3
    ) {
      // Get self (list_item)
      const thisListItem = $cursor.node($cursor.depth - 1);

      /*
				I'm not the right node type
			*/
      if (thisListItem?.type !== listType) {
        return false;
      }

      // Get parent node (list)
      const thisList = $cursor.node($cursor.depth - 2);

      console.log(
        "listKeyBackspace.cursor",
        listType.name,
        $cursor,
        thisList,
        thisListItem
      );

      /*
				Begginning
			*/
      if ($cursor.pos === $cursor.start()) {
        /*
					I have the content
				*/
        if ($cursor?.parent?.textContent !== "") {
          console.log("listKeyBackspace.nonempty", thisList, thisListItem);

          if (
            // Am I the first child of the list?
            thisList.firstChild === thisListItem &&
            // .. and I'm the first child if a ListItem
            thisListItem.firstChild === $cursor?.parent
            // .. and the are no Children in the ListItem
            // && thisListItem.childCount < 2
          ) {
            console.log("listKeyBackspace.firstChild");

            liftListItem(thisListItem.type)(state, dispatch);
            return true;
          }

          /*
					I have no content
				*/
        } else {
          console.log("listKeyBackspace.compare", thisList, thisListItem);

          /*
						Nested list-in-list.
						Convert to a hidden list.
					*/
          if ($cursor.depth >= 5) {
            // Get Node in which this Lists resides
            const parentNode = $cursor.node($cursor.depth - 3);

            console.log("listKeyBackspace.nested.parentNode", parentNode);

            // Yeap, we are inside the list
            if (
              parentNode.type === schemaNodes.list_item ||
              parentNode.type === schemaNodes.todo_item
              // || parentNode.type === schemaNodes.hidden_list_item
            ) {
              /*
								Am I already in hidden_list node?
							* /
							if (thisList?.type?.name === 'hidden_list') {
								console.log('listKeyBackspace.nested.alreadyHidden', parentNode)
								
								// Remove self
								const thisListResolved = tr.doc.resolve($cursor.before() - 1)
								console.log('listKeyBackspace.nested.thisListResolved', thisListResolved)

								// Remove the list totally
								tr = tr.delete(thisListResolved.before(), thisListResolved.after())
								state = state.apply(tr)

								// Create paragraph below
								{ 
									var side = thisListResolved.before() + 1;
									tr = tr.insert(side, schemaNodes.paragraph.createAndFill());
									tr.setSelection(TextSelection.create(tr.doc, side + 1));
								}

								// Dispatch
								dispatch(tr.scrollIntoView());
								
								// Stop further processing
								return true

							/*
								I'm of other node type
							* /
							} else { */
              // Get the range
              let commandResult;
              const { $from, $to } = state.selection;
              let range = $from.blockRange($to);

              // console.log('listKeyBackspace.range', range)

              // Convert self to the hidden_list
              commandResult = stateLiftToOuterList(
                tr,
                state,
                dispatch,
                parentNode.type,
                range
              );
              console.log(
                "listKeyBackspace.stateLiftToOuterList",
                commandResult
              );

              if (commandResult) {
                tr = commandResult;
                state = state.apply(tr);
              }

              // commandResult = stateWrapInList(tr, state, schemaNodes.hidden_list)
              // console.log('listKeyBackspace.stateWrapInList', commandResult)

              // if (commandResult) tr = commandResult

              // Dispatch the transaction
              dispatch(tr);

              // Stop further processing
              return true;
              // }
            }
          }

          // console.log('listKeyBackspace.checker', thisList, thisListItem, $cursor)

          /*
						I'm the last node
					*/
          if (thisList.lastChild === thisListItem) {
            console.log(
              "listKeyBackspace.last",
              thisList,
              thisListItem,
              $cursor
            );

            // Last one? Lift self out
            if (thisListItem?.childCount === 1) {
              console.log(
                "listKeyBackspace.single",
                thisList,
                thisListItem,
                $cursor
              );
              liftListItem(thisListItem.type)(state, dispatch);

              // Got something else? Just delete self
            } else {
              console.log(
                "listKeyBackspace.multi",
                thisList,
                thisListItem,
                $cursor
              );
              // deleteEmptySelf(state, dispatch)

              // Remove the list totally
              tr = tr.delete($cursor.before(), $cursor.after());

              // Move the cursor up
              // tr.setSelection(TextSelection.create(tr.doc, $cursor.before() - 2))

              // Place the cursor at the end of the closest paragraph before the position
              tr = cursorParagraphBefore(state, tr, $cursor.before());

              // state = state.apply(tr)
              dispatch(tr);
            }

            return true;
          }

          /*
						Am I an empty paragraph inside a list_item with multiple children?
					*/
          if (
            $cursor?.parent?.type?.name === "paragraph" &&
            thisListItem?.childCount > 1
          ) {
            console.log(
              "listKeyBackspace.checker",
              thisList,
              thisListItem,
              $cursor
            );

            // Remove the list totally
            tr = tr.delete($cursor.before(), $cursor.after());

            // Move the cursor up
            // tr.setSelection(TextSelection.create(tr.doc, $cursor.before() - 2))

            // Place the cursor at the end of the closest paragraph before the position
            tr = cursorParagraphBefore(state, tr, $cursor.before());

            // state = state.apply(tr)
            dispatch(tr);

            return true;
          }

          liftListItem(thisListItem.type)(state, dispatch);
          return true;

          /*
					// I'm the last child
					if (thisList?.childCount === 1) {
						console.log('listKeyBackspace.lastChild')

						const thisListResolved = tr.doc.resolve($cursor.before())
						console.log('listKeyBackspace.thisListResolved', thisListResolved)

						const thisListResolved2 = tr.doc.resolve($cursor.before() - 1)
						console.log('listKeyBackspace.thisListResolved2', thisListResolved2)

						// Remove the list totally
						tr.delete(thisListResolved2.before(), thisListResolved2.after())

						dispatch(tr)
						return true

					// There are other children
					} else if (thisList?.childCount > 1) {
						console.log('listKeyBackspace.multiChildren')

						const thisListItemResolved = tr.doc.resolve($cursor.before())
						console.log('listKeyBackspace.thisListItemResolved', thisListItemResolved)

						// Remove self!
						if (
							thisListItemResolved?.parent?.type?.name === 'list_item'
							|| thisListItemResolved?.parent?.type?.name === 'todo_item'
							|| thisListItemResolved?.parent?.type?.name === 'hidden_list_item'
						) {
							console.log('listKeyBackspace.delete', thisListItemResolved.start(), thisListItemResolved.end())
							tr.delete(thisListItemResolved.before(), thisListItemResolved.after())
							
							// Appoly the transaction to get the new state
							state = state.apply(tr)

							// Move the cursor to the start
							// tr.setSelection(TextSelection.create(tr.doc, thisListItemResolved.before() - 2))

							console.log('listKeyBackspace.delete.cursor0', tr.doc.resolve(thisListItemResolved.start()))
							console.log('listKeyBackspace.delete.cursor1', tr.doc.resolve(thisListItemResolved.before()))
							console.log('listKeyBackspace.delete.cursor2', tr.doc.resolve(thisListItemResolved.before() - 1))
							console.log('listKeyBackspace.delete.cursor3', tr.doc.resolve(thisListItemResolved.before() - 2))
							console.log('listKeyBackspace.delete.cursor4', tr.doc.resolve(tr.selection.$cursor.pos - 1))
							console.log('listKeyBackspace.delete.cursor5', tr.doc.resolve(tr.selection.$cursor.pos - 2))

							// Find a paragraph position
							const paragraphEnd = findParagraphBefore(state.doc, thisListItemResolved.before())

							tr.setSelection(
								TextSelection.create(
									tr.doc,
									paragraphEnd // (thisListItemResolved.before() - 2) // thisListItemResolved.start() // tr.selection.$cursor.pos - 1 // thisListItemResolved.before() - 2
								)
							)

							dispatch(tr)
							return true
						}
					}
					*/
        }
      }
    }

    // Pass the processing to the next function
    return false;
  };
}
