/**
 * Datetime processing lib(used in date detection in text)
 * https://github.com/wanasit/chrono
 */
import * as chrono from "chrono-node";

/**
 * All document processing operations (read, write, convert, ..)
 * come through this wrapper.
 */

/**
 * Storage
 */
import LocalStorage from "components/Storage/local";
import RemoteStorage from "components/Storage/firestore";

/**
 * Modules
 */
import {
  FromBlocks,
  ToBlocks,
} from "components/ProseMirror/FirestoreConverter";
import { arrayDifference, getDateObject } from "components/System/Utils";

// Firestore main lib
// import firebase from 'firebase/app'

/**
 * Get exact document including all it's blocks with their data
 *
 * @param {string} Knowledgebase id
 * @param {string} Document id
 * @param {boolean} Should we force remote read?
 * @param {boolean} Should we skip compiling ProseMirror JSON from source?
 * @returns {object} Document data or null if not found
 */
export const getDocumentWithBlocks = async (
  knowledgebaseId,
  documentId,
  forceRemote = false,
  noBlocks = false
) => {
  /*
		Settings
	*/

  const _isdebug = false;

  if (_isdebug) console.log("getDocumentWithBlocks", documentId);

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  /*
		Resets
	*/

  let documentData = {};
  let blocksData = [];

  /*
		Get from Local Storage
	*/

  // Can we use local?
  if (!forceRemote) {
    documentData = await LocalStorage.wtGet(documentId);
  }

  // Found?
  if (
    typeof documentData !== "undefined" &&
    // Is not empty
    Object.keys(documentData).length > 0
  ) {
    // Add _localStorage mark
    documentData = { ...documentData, _localStorage: true };

    if (_isdebug)
      console.log("getDocumentWithBlocks.gotLocal", documentId, documentData);

    /*
			Get document blocks if any
		*/
    if (documentData.blocks && documentData.blocks.length > 0) {
      // blocksData = await LocalStorage.wtGetDocBlocks(documentData.blocks)

      const blocksObjects = await getGroup(
        knowledgebaseId,
        documentData.blocks
      );

      // console.log('getDocumentWithBlocks.gotLocal.blocksObjects', blocksObjects)

      /*
				Convert to array
			*/

      for (var currentDocumentId in blocksObjects) {
        blocksData.push(blocksObjects[currentDocumentId]);
      }

      // console.log('getDocumentWithBlocks.gotLocal.blocksData', blocksData)
    }

    // Not Found in LocalStorage
  } else {
    if (_isdebug) {
      if (forceRemote)
        console.log(
          "getDocumentWithBlocks.LocalStorage.forceRemote",
          documentId
        );
      else
        console.log("getDocumentWithBlocks.LocalStorage.notFound", documentId);
    }

    /*
			Get document data from Firestore
		*/

    documentData = await RemoteStorage.wtGet(knowledgebaseId, documentId);

    if (_isdebug)
      console.log(
        "getDocumentWithBlocks.documentData",
        documentId,
        documentData
      );

    // Not found?
    if (documentData === null) {
      if (_isdebug)
        console.log("getDocumentWithBlocks.RemoteStorage.notFound", documentId);
      return null;
    }

    /*
			Get document blocks
		*/

    blocksData = await RemoteStorage.wtGetDocBlocks(
      knowledgebaseId,
      // Array of Documents ids
      documentData.blocks
    );

    /*
			Save all to LocalStorage
		*/

    // We have the main document
    let writeData = [documentData];

    // Save the document
    // await LocalStorage.wtWrite(documentData)

    // Save blocks
    if (blocksData.length !== 0) {
      writeData = [...writeData, ...blocksData];
    }

    // Save bulk
    if (_isdebug)
      console.log(
        "getDocumentWithBlocks.RemoteStorage.WriteBulk",
        documentId,
        writeData
      );
    LocalStorage.wtWriteDoc(
      documentId,
      documentData._version, // (documentData?._version ? documentData._version : false),
      documentData?.datetime_updated ? documentData.datetime_updated : false,
      writeData
    );
  }

  /*
		Convert document blocks
	*/

  if (!noBlocks) {
    documentData.proseMirrorJson = await FromBlocks(
      blocksData,
      knowledgebaseId,
      documentId
    );

    // console.log('getDocumentWithBlocks.DocumentJson', documentData)
  }

  /*
		Return result
	*/

  // console.log('getDocumentWithBlocks.documentData', documentData)

  return documentData;
};

/**
 * Get exact document (without blocks data)
 *
 * @param {string} Knowledgebase id
 * @param {string} Document id
 * @param {boolean} Should we force remote read?
 * @returns {object} Document data or null if not found
 */
export const getDocument = async (
  knowledgebaseId,
  documentId,
  forceRemote = false
) => {
  const _debug = false;

  if (_debug) console.log("getDocument", documentId);

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  /*
		Resets
	*/

  let documentData = {};

  /*
		Get from Local Storage
	*/

  // Can we use local?
  if (!forceRemote) {
    documentData = await LocalStorage.wtGet(documentId);
  }

  // Found?
  if (
    typeof documentData !== "undefined" &&
    // Is not empty
    Object.keys(documentData).length > 0
  ) {
    // Add _localStorage mark
    documentData = { ...documentData, _localStorage: true };

    if (_debug) console.log("getDocument.gotLocal", documentData);

    // Not Found in LocalStorage
  } else {
    if (_debug) {
      if (forceRemote) console.log("getDocument.LocalStorage.forceRemote");
      else console.log("getDocument.LocalStorage.notFound");
    }

    /*
			Get document data from Firestore
		*/

    documentData = await RemoteStorage.wtGet(knowledgebaseId, documentId);

    // Not found?
    if (documentData === null) {
      if (_debug) console.log("getDocument.RemoteStorage.notFound");
      return null;
    }

    /*
			Save all to LocalStorage
		*/

    // We have the main document
    let writeData = [documentData];

    // Save bulk
    if (_debug) console.log("getDocument.RemoteStorage.WriteBulk", writeData);
    LocalStorage.wtWriteDoc(
      documentId,
      documentData._version, // (documentData?._version ? documentData._version : false),
      documentData?.datetime_updated ? documentData.datetime_updated : false,
      writeData
    );
  }

  /*
		Return result
	*/

  if (_debug) console.log("getDocument.documentData", documentData);

  return documentData;
};

/**
 * Search a document
 *
 * @param {string} Knowledgebase id
 * @param {object} Search params
 * @param {boolean} Should we force remote read?
 * @returns {object} Document data or null if not found
 */
export const searchDocument = async (
  knowledgebaseId,
  searchConditions,
  forceRemote = false
) => {
  // console.log('searchDocument', searchConditions)

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  /*
		Resets
	*/

  let documentData = {};

  /*
		Get from Local Storage
	*/

  // Can we use local?
  if (!forceRemote) {
    documentData = await LocalStorage.wtSearch(searchConditions);
  }

  // Found?
  if (
    typeof documentData !== "undefined" &&
    // Is not empty
    Object.keys(documentData).length > 0
  ) {
    // Add _localStorage mark
    documentData = { ...documentData, _localStorage: true };

    // console.log('searchDocument.gotLocal', documentData)

    // Not Found in LocalStorage
  } else {
    // if (forceRemote) console.log('searchDocument.LocalStorage.forceRemote')
    // else console.log('searchDocument.LocalStorage.notFound')

    /*
			Get document data from Firestore
		*/

    documentData = await RemoteStorage.wtSearch(
      knowledgebaseId,
      searchConditions
    );

    // Not found?
    if (documentData === null) {
      // console.log('searchDocument.RemoteStorage.notFound')
      return null;
    }

    /*
			Save to LocalStorage
		* /

		// We have the main document
		let writeData = [documentData]
		
		// Save bulk
		console.log('searchDocument.RemoteStorage.WriteBulk', writeData)
		LocalStorage.wtWriteDoc(
			documentData.id,
			documentData._version, // (documentData?._version ? documentData._version : false),
			.. datetime_updated
			writeData
		)
		*/

    if (documentData && documentData?.id) {
      // console.log('searchDocument.LocalSave', documentData)

      LocalStorage.wtWrite(documentData);
    }
  }

  /*
		Return result
	*/

  // console.log('searchDocument.documentData', documentData)

  return documentData;
};

/**
 * Get a list of documents
 *
 * @param {String} Knowledgebase id
 * @param {Array} Documents ids ["1i0QCmIV83"]
 * @param {boolean} Should we force remote read?
 * @returns {Object} List of documents found {1i0QCmIV83:{is_block: true ..}, ..}, undefined if not found
 */
export const getGroup = async (
  knowledgebaseId,
  documentIds,
  forceRemote = false
) => {
  const _isdebug = false;

  if (_isdebug) console.log("getGroup", documentIds);

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  /*
		Resets
	*/

  // Prepare the resulting object
  let resultDocuments = {};
  documentIds.forEach((currentDocumentId) => {
    resultDocuments[currentDocumentId] = undefined;
  });

  if (_isdebug) console.log("getGroup.Reset", resultDocuments);

  /*
		Get from Local Storage
	*/

  // Can we use local?
  if (!forceRemote) {
    // console.log('getGroup.readLocal')

    // Read all we have
    let localDocuments = await LocalStorage.wtGetDocBlocks(documentIds);

    if (_isdebug)
      console.log("getGroup.readLocal.localDocuments", localDocuments);

    // Update the result
    var i = 0;
    for (let currentDocumentId in resultDocuments) {
      // Invalid document?
      if (
        typeof localDocuments[i] === "object" &&
        localDocuments[i] !== null &&
        !("is_page" in localDocuments[i]) && // it is not a page
        !("is_block" in localDocuments[i]) // it is not a block
      ) {
        console.error(
          "getGroup.readLocal.localDocuments.invalidDocument",
          currentDocumentId,
          localDocuments[i]
        );

        // Add the document as good one
      } else {
        resultDocuments[currentDocumentId] = localDocuments[i];
      }
      i++;
    }

    if (_isdebug)
      console.log("getGroup.readLocal.resultDocuments", resultDocuments);
  }

  /*
		Read remote
	*/

  // Let's reset the list of unread documents
  let unreadDocuments = [];
  for (let currentDocumentId in resultDocuments) {
    if (resultDocuments[currentDocumentId] === undefined) {
      unreadDocuments.push(currentDocumentId);
    }
  }

  // Do we still have un-read documents?
  if (unreadDocuments.length > 0) {
    if (_isdebug) console.log("getGroup.haveUnreadDocuments", unreadDocuments);

    // Read from RemoteStorage
    const remoteDocuments = await RemoteStorage.wtGetDocBlocks(
      knowledgebaseId,
      // Array of Documents ids
      unreadDocuments
    );

    // We have array of remote documents
    if (_isdebug) console.log("getGroup.remoteDocuments", remoteDocuments);

    // Do we have something?
    if (remoteDocuments && remoteDocuments.length > 0) {
      // Let's add them to the resulting object
      remoteDocuments.forEach((currentDocumentId) => {
        // Append to list of documents
        resultDocuments[currentDocumentId.id] = currentDocumentId;
      });

      // Save to Local cache in bulk
      if (_isdebug) console.log("getGroup.writeLocalStorage", remoteDocuments);
      LocalStorage.wtWriteBulk(remoteDocuments);
    }
  }

  if (_isdebug) console.log("getGroup.resultDocuments", resultDocuments);

  // Return result
  return resultDocuments;
};

/**
 * Update document (only with the data that was changed)
 *
 * @param {String} Knowledgebase id
 * @param {String} DocumentId
 * @returns {boolean} Result of operation
 */
export const updateDocument = async (
  knowledgebaseId,
  documentId,
  updatedData
) => {
  // console.log('updateDocument', knowledgebaseId, documentId, updatedData)

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  // Update local version
  await LocalStorage.wtUpdate(documentId, updatedData);

  // Set own process id
  updatedData._process_id = global.processId;

  // Add to RemoteStorage processing queue
  await LocalStorage.wtSaveQueueAdd(
    "update",
    knowledgebaseId,
    documentId,
    updatedData
  );

  return true;
};

/**
 * Delete document
 *
 * @param {String} Knowledgebase id
 * @param {String} DocumentId
 * @returns {boolean} Result of operation
 */
export const deleteDocument = async (knowledgebaseId, documentId) => {
  console.log("deleteDocument", knowledgebaseId, documentId);

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  // Update local version
  await LocalStorage.wtDelete(documentId);

  // Add to RemoteStorage processing queue
  await LocalStorage.wtSaveQueueAdd(
    "delete",
    knowledgebaseId,
    documentId,
    null
  );

  return true;
};

/**
 * Document references, delete
 *
 * @param {String} Knowledgebase id
 * @param {String} Document id to which the reference is set
 * @param {String} Block id from which the reference is going
 * @returns {boolean} Result of operation
 */
export const documentReferenceDelete = async (
  knowledgebaseId,
  linkId,
  blockId
) => {
  console.log("documentReferenceDelete", knowledgebaseId, linkId, blockId);

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  // Update local version
  await LocalStorage.wtReferenceDelete(linkId, blockId);

  // Add to RemoteStorage processing queue
  await LocalStorage.wtSaveQueueAdd(
    "reference_delete",
    knowledgebaseId,
    linkId,
    blockId
  );

  return true;
};

/**
 * Document references, add
 *
 * @param {String} Knowledgebase id
 * @param {String} Document id to which the reference is set
 * @param {String} Block id from which the reference is going
 * @returns {boolean} Result of operation
 */
export const documentReferenceAdd = async (
  knowledgebaseId,
  linkId,
  blockId
) => {
  // console.log('documentReferenceAdd', knowledgebaseId, linkId, blockId)

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  // Update local version
  await LocalStorage.wtReferenceAdd(linkId, blockId);

  // Add to RemoteStorage processing queue
  await LocalStorage.wtSaveQueueAdd(
    "reference_add",
    knowledgebaseId,
    linkId,
    blockId
  );

  return true;
};

/**
 * Save the Document and it's Blocks
 * @param {string} Knowledgebase Id
 * @param {string} Document Id
 * @param {int} Document version
 * @param {Array} ProseMirror Nodes (blocks) JSON
 * @param {Array} List of last known blocks
 * @return {Array} New list of last known blocks ids
 */
export const storeDocument = async (
  knowledgebaseId,
  documentId,
  documentVersion,
  nodesJSON,
  lastBlockIdsState
) => {
  const _debug = false;

  if (_debug)
    console.log(
      "storeDocument",
      knowledgebaseId,
      documentId,
      documentVersion,
      nodesJSON,
      lastBlockIdsState
    );

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  /*
		Let's fixate current store datetime
	*/

  const datetimeUpdated = new Date();

  /*
		Convert ProseMirror blocks into JSON
	*/

  const blocksCurrent = await ToBlocks(nodesJSON);
  // console.log('storeDocument.ToBlocks', blocksCurrent)

  /*
		Current block ids
	*/

  const currentBlockIds = Object.keys(blocksCurrent);
  // console.log('storeDocument.currentBlockIds', currentBlockIds)

  /*
		Missing blocks
	*/

  // What's missing?
  const blocksMissing = arrayDifference(lastBlockIdsState, currentBlockIds);
  // console.log('storeDocument.blocksMissing', blocksMissing)

  /*
		Store local changes
	*/

  await LocalStorage.wtStoreDocument(
    documentId,
    documentVersion,
    blocksCurrent,
    blocksMissing
  );

  /*
		Add to RemoteStorage processing queue
	*/

  LocalStorage.wtSaveQueueAdd(
    "save",
    knowledgebaseId, // Knowledgebase Id
    documentId, // Document Id
    // Data to process
    {
      // Document version (int)
      documentVersion: documentVersion,
      // Save blocks (Object with document Objects)
      blocksCurrent: blocksCurrent,
      // Remove blocks (Array of DocumentIds)
      blocksMissing: blocksMissing,
      // Who is writing? Me!
      processId: global.processId,
      // At what time did this document store happen?
      datetimeUpdated: datetimeUpdated,
    }
  );

  /*
		Compile new array of block ids
	*/
  const newBlockIdsState = [];
  Object.keys(blocksCurrent).forEach((blockId) => {
    newBlockIdsState.push(blockId);
  });

  // Return array of new block ids
  return newBlockIdsState;
};

/**
 * Get/create a document for exact date
 * @param {string} Knowledgebase id
 * @param {object} Date object
 * @param {boolean} Should we force remote read?
 * @returns {string} Document Id
 */
export const getDocumentDate = async (
  knowledgebaseId,
  dateObject,
  forceRemote = false
) => {
  // console.log('getDocumentDate', knowledgebaseId, dateObject, forceRemote)

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  /*
		Resets
	*/

  let documentId = null;
  let documentData = {};

  /*
		Get from Local Storage
	*/

  // Can we use local?
  if (!forceRemote) {
    documentData = await LocalStorage.wtSearchDocumentDate(
      // Javascript Date object
      dateObject.firestore.toDate()
    );
  }

  // Found?
  if (
    typeof documentData !== "undefined" &&
    // Is not empty
    Object.keys(documentData).length > 0
  ) {
    // Set document id
    documentId = documentData.id;

    // console.log('getDocumentDate.gotLocal', documentData)

    // Not Found in LocalStorage
  } else {
    /*
		if (forceRemote) console.log('getDocumentDate.LocalStorage.forceRemote')
		else console.log('getDocumentDate.LocalStorage.notFound')
		*/

    /*
			Get document data from Firestore
		*/

    // NO TRANSACTION, UNSAFE:
    // documentId = await RemoteStorage.wtSearchDocumentDate(
    documentId = await RemoteStorage.wtSearchDocumentDateTransaction(
      knowledgebaseId,
      dateObject
    );

    // console.log('getDocumentDate.gotRemote', documentId)
  }

  /*
		Return result
	*/

  // console.log('getDocumentDate.documentId', documentId)

  return documentId;
};

/**
 * Get a document before exact date
 * @param {string} Knowledgebase id
 * @param {object} JavaScript Date object
 * @param {boolean} Should we force remote read?
 * @returns {object} Document data
 */
export const getDocumentDateBefore = async (
  knowledgebaseId,
  jsDateObject,
  forceRemote = false
) => {
  // console.log('getDocumentDateBefore', knowledgebaseId, jsDateObject, forceRemote)

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  /*
		Resets
	*/

  let documentData = {};

  /*
		Get from Local Storage
	*/

  // Can we use local?
  if (!forceRemote) {
    documentData = await LocalStorage.wtSearchDocumentDateBefore(jsDateObject);
  }

  // Found?
  if (
    typeof documentData !== "undefined" &&
    // Is not empty
    Object.keys(documentData).length > 0
  ) {
    // console.log('getDocumentDateBefore.gotLocal', documentData)
    // Not Found in LocalStorage
  } else {
    /*
		if (forceRemote) console.log('getDocumentDateBefore.LocalStorage.forceRemote')
		else console.log('getDocumentDateBefore.LocalStorage.notFound')
		*/

    /*
			Get document data from Firestore
		*/

    documentData = await RemoteStorage.wtSearchDocumentDateBefore(
      knowledgebaseId,
      jsDateObject
    );

    /*
			Got data? Save it to LocalStorage!
		*/

    if (documentData && documentData?.id) {
      // console.log('getDocumentDateBefore.LocalSave', documentData)

      LocalStorage.wtWrite(documentData);
    }

    // console.log('getDocumentDateBefore.gotRemote', documentData)
  }

  /*
		Return result
	*/

  // console.log('getDocumentDateBefore.documentData', documentData)

  return documentData;
};

/**
 * Create new Document and return it's ID
 * If document exists - return it's ID
 *
 * @param {string} Knowledgebase Id
 * @param {string} Document title
 * @returns {string} Document Id
 */
export const createDocument = async (knowledgebaseId, documentTitle) => {
  // console.log('createDocument', knowledgebaseId, documentTitle)

  /*
		Set
	*/

  let documentId = null;

  /*
		Title processing
	*/

  // Title is set? Look for such a page
  if (documentTitle !== "") {
    // console.log('createDocument.withTitle')

    /*
			Date in Title?
		*/

    // Detect date in Title
    const dateDetected = chrono.parseDate(documentTitle);

    // Date detected!
    if (dateDetected !== null) {
      console.log("createDocument.withTitle.dateDetected", dateDetected);

      // Get Object from date
      const dateObject = getDateObject(dateDetected);
      console.log("createDocument.withTitle.dateObject", dateObject);

      // Search the document with such a date
      documentId = getDocumentDate(knowledgebaseId, dateObject);

      // Return the document id
      return documentId;

      // Date was NOT detected
    } else {
      // Search Document by Title
      const foundDocument = await searchDocument(knowledgebaseId, {
        title: documentTitle,
      });

      // Found something?
      if (foundDocument) {
        // Return it's id
        return foundDocument.id;
      }
    }
  }

  /*
		Save new document
	*/

  documentId = await RemoteStorage.wtSaveDocument(knowledgebaseId, {
    title: documentTitle,
  });

  console.log("createDocument.Added", documentId);

  // Return the document id
  return documentId; // documentRef.id
};

/**
 * Search Documents in Firestore database
 *
 * @param {string} Knowledgebase Id
 * @param {string} String to search
 * @returns {array} Array of found documents objects [{id: 'Z123',title: 'Page'}, ..]
 */
export const fullSearchDocument = async (knowledgebaseId, string) => {
  // console.log('fullSearchDocument', string)

  /*
	FIRESTORE OLD:
	// Fetch the document from Firestore (if exists)
	const documents = await firebase.firestore()
		.collection('knowledgebases').doc(knowledgebaseId)
		.collection('documents')
		.where('is_page', '==', true)
		.orderBy('title')
		.startAt(string)
		.endAt(string + '\uf8ff')
		.get()

	// Not found?
	if (documents.empty) {
		return []
	}

	// Compile
	const searchResult = []
	documents.forEach(function(doc) {
		searchResult.push({
			title: doc.data().title,
			id: doc.id
		})
	})
	*/

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  /*
		Search in LocalStorage
	*/

  const searchResult = await LocalStorage.wtTitleSearch(string);

  // console.log('fullSearchDocument.Result', searchResult)
  return searchResult;
};
