import crel from "crelt";
import {
  joinUp,
  lift,
  selectParentNode,
  setBlockType,
  wrapIn,
} from "prosemirror-commands";
import { undo, redo } from "prosemirror-history";
import { Plugin } from "prosemirror-state";

/**
 * ProseMirror, setup
 */
import {
  liftListItem,
  liftOutOfList,
  stateLiftToOuterList,
  stateWrapInList,
  stateLiftOutOfList,
} from "components/ProseMirror/commands/commandsLists";

/**
 * Modules
 */
import { chainTransactions } from "components/ProseMirror/Utils";

var SVG = "http://www.w3.org/2000/svg";
var XLINK = "http://www.w3.org/1999/xlink";

var prefix = "wt-menu-inline-icon";

function hashPath(path) {
  var hash = 0;
  for (var i = 0; i < path.length; i++) {
    hash = ((hash << 5) - hash + path.charCodeAt(i)) | 0;
  }
  return hash;
}

function getIcon(icon) {
  var node = document.createElement("div");
  node.className = prefix;
  if (icon.path) {
    var name = "pm-icon-" + hashPath(icon.path).toString(16);
    if (!document.getElementById(name)) {
      buildSVG(name, icon);
    }
    var svg = node.appendChild(document.createElementNS(SVG, "svg"));
    svg.style.width = icon.width / icon.height + "em";
    var use = svg.appendChild(document.createElementNS(SVG, "use"));
    use.setAttributeNS(
      XLINK,
      "href",
      /([^#]*)/.exec(document.location)[1] + "#" + name
    );
  } else if (icon.dom) {
    node.appendChild(icon.dom.cloneNode(true));
  } else {
    node.appendChild(document.createElement("span")).textContent =
      icon.text || "";
    if (icon.css) {
      node.firstChild.style.cssText = icon.css;
    }
  }
  return node;
}

function buildSVG(name, data) {
  var collection = document.getElementById(prefix + "-collection");
  if (!collection) {
    collection = document.createElementNS(SVG, "svg");
    collection.id = prefix + "-collection";
    collection.style.display = "none";
    //console.log('DU2', collection.style.display);
    document.body.insertBefore(collection, document.body.firstChild);
  }
  var sym = document.createElementNS(SVG, "symbol");
  sym.id = name;
  sym.setAttribute("viewBox", "0 0 " + data.width + " " + data.height);
  var path = sym.appendChild(document.createElementNS(SVG, "path"));
  path.setAttribute("d", data.path);
  collection.appendChild(sym);
}

var prefix$1 = "wt-menu-inline";

// ::- An icon or label that, when clicked, executes a command.
var MenuItem = function MenuItem(spec) {
  // :: MenuItemSpec
  // The spec used to create the menu item.
  this.spec = spec;
};

// :: (EditorView) → {dom: dom.Node, update: (EditorState) → bool}
// Renders the icon according to its [display
// spec](#menu.MenuItemSpec.display), and adds an event handler which
// executes the command when the representation is clicked.
MenuItem.prototype.render = function render(view) {
  var spec = this.spec;
  var dom = spec.render
    ? spec.render(view)
    : spec.icon
    ? getIcon(spec.icon)
    : spec.label
    ? crel("div", null, translate(view, spec.label))
    : null;
  if (!dom) {
    throw new RangeError("MenuItem without icon or label property");
  }
  if (spec.title) {
    var title =
      typeof spec.title === "function" ? spec.title(view.state) : spec.title;
    dom.setAttribute("title", translate(view, title));
  }
  if (spec.class) {
    dom.classList.add(spec.class);
  }
  if (spec.css) {
    dom.style.cssText += spec.css;
  }

  dom.addEventListener("mousedown", function (e) {
    e.preventDefault();
    if (!dom.classList.contains(prefix$1 + "-disabled")) {
      spec.run(view.state, view.dispatch, view, e);
    }
  });

  function update(state) {
    // console.log('MenuItem', spec);

    /*
			select:
			Optional function that is used to determine whether the item is appropriate at the moment.
			todo
			bullet
			ordered
			quote
			--
			Commented, as:
			we need to show all conversion types
		if (spec.select) {
			console.log('MenuItem.select', spec);

			var selected = spec.select(state);
			// dom.style.display = selected ? "" : "none";
			// console.log('MenuItem.hide', dom.style.display, selected);

			// WHAT THIS DOES???
			// if (!selected) { return false }
		}
		*/

    var enabled = true;
    if (spec.enable) {
      enabled = spec.enable(state) || false;
      setClass(dom, prefix$1 + "-disabled", !enabled);
    }

    /*
			active: determine whether the item is 'active' (for
			example, the item for toggling the strong mark might be active then
			the cursor is in strong text).
		*/
    if (spec.active) {
      // Why in the hell " || false" is for ???
      // var active = enabled && spec.active(state) || false;
      var active = enabled && spec.active(state);
      setClass(dom, prefix$1 + "-active", active);
    }

    return true;
  }

  return { dom: dom, update: update };
};

function translate(view, text) {
  return view._props.translate ? view._props.translate(text) : text;
}

// MenuItemSpec:: interface
// The configuration object passed to the `MenuItem` constructor.
//
//   run:: (EditorState, (Transaction), EditorView, dom.Event)
//   The function to execute when the menu item is activated.
//
//   select:: ?(EditorState) → bool
//   Optional function that is used to determine whether the item is
//   appropriate at the moment. Deselected items will be hidden.
//
//   enable:: ?(EditorState) → bool
//   Function that is used to determine if the item is enabled. If
//   given and returning false, the item will be given a disabled
//   styling.
//
//   active:: ?(EditorState) → bool
//   A predicate function to determine whether the item is 'active' (for
//   example, the item for toggling the strong mark might be active then
//   the cursor is in strong text).
//
//   render:: ?(EditorView) → dom.Node
//   A function that renders the item. You must provide either this,
//   [`icon`](#menu.MenuItemSpec.icon), or [`label`](#MenuItemSpec.label).
//
//   icon:: ?Object
//   Describes an icon to show for this item. The object may specify
//   an SVG icon, in which case its `path` property should be an [SVG
//   path
//   spec](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d),
//   and `width` and `height` should provide the viewbox in which that
//   path exists. Alternatively, it may have a `text` property
//   specifying a string of text that makes up the icon, with an
//   optional `css` property giving additional CSS styling for the
//   text. _Or_ it may contain `dom` property containing a DOM node.
//
//   label:: ?string
//   Makes the item show up as a text label. Mostly useful for items
//   wrapped in a [drop-down](#menu.Dropdown) or similar menu. The object
//   should have a `label` property providing the text to display.
//
//   title:: ?union<string, (EditorState) → string>
//   Defines DOM title (mouseover) text for the item.
//
//   class:: ?string
//   Optionally adds a CSS class to the item's DOM representation.
//
//   css:: ?string
//   Optionally adds a string of inline CSS to the item's DOM
//   representation.

var lastMenuEvent = { time: 0, node: null };
function markMenuEvent(e) {
  lastMenuEvent.time = Date.now();
  lastMenuEvent.node = e.target;
}
function isMenuEvent(wrapper) {
  return (
    Date.now() - 100 < lastMenuEvent.time &&
    lastMenuEvent.node &&
    wrapper.contains(lastMenuEvent.node)
  );
}

// ::- A drop-down menu, displayed as a label with a downwards-pointing
// triangle to the right of it.
var Dropdown = function Dropdown(content, options) {
  this.options = options || {};
  this.content = Array.isArray(content) ? content : [content];
};

// :: (EditorView) → {dom: dom.Node, update: (EditorState)}
// Render the dropdown menu and sub-items.
Dropdown.prototype.render = function render(view) {
  var this$1 = this;

  var content = renderDropdownItems(this.content, view);

  var label = crel(
    "div",
    {
      class: prefix$1 + "-dropdown " + (this.options.class || ""),
      style: this.options.css,
    },
    translate(view, this.options.label)
  );
  if (this.options.title) {
    label.setAttribute("title", translate(view, this.options.title));
  }
  var wrap = crel("div", { class: prefix$1 + "-dropdown-wrap" }, label);
  var open = null,
    listeningOnClose = null;
  var close = function () {
    if (open && open.close()) {
      open = null;
      window.removeEventListener("mousedown", listeningOnClose);
    }
  };
  label.addEventListener("mousedown", function (e) {
    e.preventDefault();
    markMenuEvent(e);
    if (open) {
      close();
    } else {
      open = this$1.expand(wrap, content.dom);
      window.addEventListener(
        "mousedown",
        (listeningOnClose = function () {
          if (!isMenuEvent(wrap)) {
            close();
          }
        })
      );
    }
  });

  function update(state) {
    var inner = content.update(state);
    wrap.style.display = inner ? "" : "none";
    //console.log('DU4', wrap.style.display);
    return inner;
  }

  return { dom: wrap, update: update };
};

Dropdown.prototype.expand = function expand(dom, items) {
  var menuDOM = crel(
    "div",
    { class: prefix$1 + "-dropdown-menu " + (this.options.class || "") },
    items
  );

  var done = false;
  function close() {
    if (done) {
      return;
    }
    done = true;
    dom.removeChild(menuDOM);
    return true;
  }
  dom.appendChild(menuDOM);
  return { close: close, node: menuDOM };
};

/**
 * Create a dropdown menu
 * @param {array} items
 * @param {object} view
 */
function renderDropdownItems(items, view) {
  // Reset
  var rendered = [],
    updates = [];

  // Heading
  rendered.push(
    crel("div", { class: prefix$1 + "-dropdown-label" }, "Turn into")
  );

  // Elements
  for (var i = 0; i < items.length; i++) {
    var ref = items[i].render(view);
    var dom = ref.dom;
    var update = ref.update;
    rendered.push(crel("div", { class: prefix$1 + "-dropdown-item" }, dom));
    updates.push(update);
  }

  // Return the result
  return { dom: rendered, update: combineUpdates(updates, rendered) };
}

function combineUpdates(updates, nodes) {
  return function (state) {
    var something = false;
    for (var i = 0; i < updates.length; i++) {
      var up = updates[i](state);
      nodes[i].style.display = up ? "" : "none";
      if (up) {
        something = true;
      }
    }
    return something;
  };
}

// ::- Represents a submenu wrapping a group of elements that start
// hidden and expand to the right when hovered over or tapped.
var DropdownSubmenu = function DropdownSubmenu(content, options) {
  this.options = options || {};
  this.content = Array.isArray(content) ? content : [content];
};

// :: (EditorView) → {dom: dom.Node, update: (EditorState) → bool}
// Renders the submenu.
DropdownSubmenu.prototype.render = function render(view) {
  var items = renderDropdownItems(this.content, view);

  var label = crel(
    "div",
    { class: prefix$1 + "-submenu-label" },
    translate(view, this.options.label)
  );
  var wrap = crel(
    "div",
    { class: prefix$1 + "-submenu-wrap" },
    label,
    crel("div", { class: prefix$1 + "-submenu" }, items.dom)
  );
  var listeningOnClose = null;
  label.addEventListener("mousedown", function (e) {
    e.preventDefault();
    markMenuEvent(e);
    setClass(wrap, prefix$1 + "-submenu-wrap-active");
    if (!listeningOnClose) {
      window.addEventListener(
        "mousedown",
        (listeningOnClose = function () {
          if (!isMenuEvent(wrap)) {
            wrap.classList.remove(prefix$1 + "-submenu-wrap-active");
            window.removeEventListener("mousedown", listeningOnClose);
            listeningOnClose = null;
          }
        })
      );
    }
  });

  function update(state) {
    var inner = items.update(state);
    // console.log('DU1', wrap.style.display);
    wrap.style.display = inner ? "" : "none";
    return inner;
  }
  return { dom: wrap, update: update };
};

// :: (EditorView, [union<MenuElement, [MenuElement]>]) → {dom: ?dom.DocumentFragment, update: (EditorState) → bool}
// Render the given, possibly nested, array of menu elements into a
// document fragment, placing separators between them (and ensuring no
// superfluous separators appear when some of the groups turn out to
// be empty).
function renderGrouped(view, content) {
  var result = document.createDocumentFragment();
  var updates = [],
    separators = [];
  for (var i = 0; i < content.length; i++) {
    var items = content[i],
      localUpdates = [],
      localNodes = [];
    for (var j = 0; j < items.length; j++) {
      var ref = items[j].render(view);
      var dom = ref.dom;
      var update$1 = ref.update;
      var span = crel("span", { class: prefix$1 + "-item" }, dom);
      result.appendChild(span);
      localNodes.push(span);
      localUpdates.push(update$1);
    }
    if (localUpdates.length) {
      updates.push(combineUpdates(localUpdates, localNodes));
      if (i < content.length - 1) {
        separators.push(result.appendChild(separator()));
      }
    }
  }

  function update(state) {
    var something = false,
      needSep = false;
    for (var i = 0; i < updates.length; i++) {
      var hasContent = updates[i](state);
      if (i) {
        separators[i - 1].style.display = needSep && hasContent ? "" : "none";
      }
      needSep = hasContent;
      if (hasContent) {
        something = true;
      }
    }
    return something;
  }
  return { dom: result, update: update };
}

function separator() {
  return crel("span", { class: prefix$1 + "-separator" });
}

// :: Object
// A set of basic editor-related icons. Contains the properties
// `join`, `lift`, `selectParentNode`, `undo`, `redo`, `strong`, `em`,
// `code`, `link`, `bulletList`, `orderedList`, and `blockquote`, each
// holding an object that can be used as the `icon` option to
// `MenuItem`.
var icons = {
  join: {
    width: 800,
    height: 900,
    path:
      "M0 75h800v125h-800z M0 825h800v-125h-800z M250 400h100v-100h100v100h100v100h-100v100h-100v-100h-100z",
  },
  lift: {
    width: 1024,
    height: 1024,
    path:
      "M219 310v329q0 7-5 12t-12 5q-8 0-13-5l-164-164q-5-5-5-13t5-13l164-164q5-5 13-5 7 0 12 5t5 12zM1024 749v109q0 7-5 12t-12 5h-987q-7 0-12-5t-5-12v-109q0-7 5-12t12-5h987q7 0 12 5t5 12zM1024 530v109q0 7-5 12t-12 5h-621q-7 0-12-5t-5-12v-109q0-7 5-12t12-5h621q7 0 12 5t5 12zM1024 310v109q0 7-5 12t-12 5h-621q-7 0-12-5t-5-12v-109q0-7 5-12t12-5h621q7 0 12 5t5 12zM1024 91v109q0 7-5 12t-12 5h-987q-7 0-12-5t-5-12v-109q0-7 5-12t12-5h987q7 0 12 5t5 12z",
  },
  selectParentNode: { text: "\u2b1a", css: "font-weight: bold" },
  undo: {
    width: 1024,
    height: 1024,
    path:
      "M761 1024c113-206 132-520-313-509v253l-384-384 384-384v248c534-13 594 472 313 775z",
  },
  redo: {
    width: 1024,
    height: 1024,
    path:
      "M576 248v-248l384 384-384 384v-253c-446-10-427 303-313 509-280-303-221-789 313-775z",
  },
  strong: {
    width: 805,
    height: 1024,
    path:
      "M317 869q42 18 80 18 214 0 214-191 0-65-23-102-15-25-35-42t-38-26-46-14-48-6-54-1q-41 0-57 5 0 30-0 90t-0 90q0 4-0 38t-0 55 2 47 6 38zM309 442q24 4 62 4 46 0 81-7t62-25 42-51 14-81q0-40-16-70t-45-46-61-24-70-8q-28 0-74 7 0 28 2 86t2 86q0 15-0 45t-0 45q0 26 0 39zM0 950l1-53q8-2 48-9t60-15q4-6 7-15t4-19 3-18 1-21 0-19v-37q0-561-12-585-2-4-12-8t-25-6-28-4-27-2-17-1l-2-47q56-1 194-6t213-5q13 0 39 0t38 0q40 0 78 7t73 24 61 40 42 59 16 78q0 29-9 54t-22 41-36 32-41 25-48 22q88 20 146 76t58 141q0 57-20 102t-53 74-78 48-93 27-100 8q-25 0-75-1t-75-1q-60 0-175 6t-132 6z",
  },
  em: {
    width: 585,
    height: 1024,
    path:
      "M0 949l9-48q3-1 46-12t63-21q16-20 23-57 0-4 35-165t65-310 29-169v-14q-13-7-31-10t-39-4-33-3l10-58q18 1 68 3t85 4 68 1q27 0 56-1t69-4 56-3q-2 22-10 50-17 5-58 16t-62 19q-4 10-8 24t-5 22-4 26-3 24q-15 84-50 239t-44 203q-1 5-7 33t-11 51-9 47-3 32l0 10q9 2 105 17-1 25-9 56-6 0-18 0t-18 0q-16 0-49-5t-49-5q-78-1-117-1-29 0-81 5t-69 6z",
  },
  code: {
    width: 896,
    height: 1024,
    path:
      "M608 192l-96 96 224 224-224 224 96 96 288-320-288-320zM288 192l-288 320 288 320 96-96-224-224 224-224-96-96z",
  },
  link: {
    width: 951,
    height: 1024,
    path:
      "M832 694q0-22-16-38l-118-118q-16-16-38-16-24 0-41 18 1 1 10 10t12 12 8 10 7 14 2 15q0 22-16 38t-38 16q-8 0-15-2t-14-7-10-8-12-12-10-10q-18 17-18 41 0 22 16 38l117 118q15 15 38 15 22 0 38-14l84-83q16-16 16-38zM430 292q0-22-16-38l-117-118q-16-16-38-16-22 0-38 15l-84 83q-16 16-16 38 0 22 16 38l118 118q15 15 38 15 24 0 41-17-1-1-10-10t-12-12-8-10-7-14-2-15q0-22 16-38t38-16q8 0 15 2t14 7 10 8 12 12 10 10q18-17 18-41zM941 694q0 68-48 116l-84 83q-47 47-116 47-69 0-116-48l-117-118q-47-47-47-116 0-70 50-119l-50-50q-49 50-118 50-68 0-116-48l-118-118q-48-48-48-116t48-116l84-83q47-47 116-47 69 0 116 48l117 118q47 47 47 116 0 70-50 119l50 50q49-50 118-50 68 0 116 48l118 118q48 48 48 116z",
  },
  bulletList: {
    width: 768,
    height: 896,
    path:
      "M0 512h128v-128h-128v128zM0 256h128v-128h-128v128zM0 768h128v-128h-128v128zM256 512h512v-128h-512v128zM256 256h512v-128h-512v128zM256 768h512v-128h-512v128z",
  },
  orderedList: {
    width: 768,
    height: 896,
    path:
      "M320 512h448v-128h-448v128zM320 768h448v-128h-448v128zM320 128v128h448v-128h-448zM79 384h78v-256h-36l-85 23v50l43-2v185zM189 590c0-36-12-78-96-78-33 0-64 6-83 16l1 66c21-10 42-15 67-15s32 11 32 28c0 26-30 58-110 112v50h192v-67l-91 2c49-30 87-66 87-113l1-1z",
  },
  blockquote: {
    width: 640,
    height: 896,
    path:
      "M0 448v256h256v-256h-128c0 0 0-128 128-128v-128c0 0-256 0-256 256zM640 320v-128c0 0-256 0-256 256v256h256v-256h-128c0 0 0-128 128-128z",
  },
};

// :: MenuItem
// Menu item for the `joinUp` command.
var joinUpItem = new MenuItem({
  title: "Join with above block",
  run: joinUp,
  select: function (state) {
    return joinUp(state);
  },
  icon: icons.join,
});

// :: MenuItem
// Menu item for the `lift` command.
var liftItem = new MenuItem({
  title: "Lift out of enclosing block",
  run: lift,
  select: function (state) {
    return lift(state);
  },
  icon: icons.lift,
});

// :: MenuItem
// Menu item for the `selectParentNode` command.
var selectParentNodeItem = new MenuItem({
  title: "Select parent node",
  run: selectParentNode,
  select: function (state) {
    return selectParentNode(state);
  },
  icon: icons.selectParentNode,
});

// :: MenuItem
// Menu item for the `undo` command.
var undoItem = new MenuItem({
  title: "Undo last change",
  run: undo,
  enable: function (state) {
    return undo(state);
  },
  icon: icons.undo,
});

// :: MenuItem
// Menu item for the `redo` command.
var redoItem = new MenuItem({
  title: "Redo last undone change",
  run: redo,
  enable: function (state) {
    return redo(state);
  },
  icon: icons.redo,
});

// :: (NodeType, Object) → MenuItem
// Build a menu item for wrapping the selection in a given node type.
// Adds `run` and `select` properties to the ones present in
// `options`. `options.attrs` may be an object or a function.
// AK: Quote Node type only
function wrapItem(nodeType, options) {
  var passedOptions = {
    run: function run(state, dispatch) {
      // FIXME if (options.attrs instanceof Function) options.attrs(state, attrs => wrapIn(nodeType, attrs)(state))
      return wrapIn(nodeType, options.attrs)(state, dispatch);
    },
    select: function select(state) {
      return wrapIn(
        nodeType,
        options.attrs instanceof Function ? null : options.attrs
      )(state);
    },
  };
  for (var prop in options) {
    passedOptions[prop] = options[prop];
  }
  return new MenuItem(passedOptions);
}

// :: (NodeType, Object) → MenuItem
// Build a menu item for changing the type of the textblock around the
// selection to the given type. Provides `run`, `active`, and `select`
// properties. Others must be given in `options`. `options.attrs` may
// be an object to provide the attributes for the textblock node.
// !!! AK comment: for "paragraph" and "heading" nodes ONLY
function blockTypeItem(nodeType, options) {
  var command = setBlockType(nodeType, options.attrs);
  var passedOptions = {
    /**
     * Execute command on "paragraph", "heading" item click in the menu
     * @param {Object} Current editor state
     * @param {Object} Dispatcher
     */
    run: (state, dispatch) => {
      // console.log('MenuBuild.blockTypeItem', state, dispatch)

      /*
				Current state we are in (where is the cursor?)
			*/

      // console.log('MenuBuild.blockTypeItem', nodeType?.name)

      const ref = state.selection;
      const $from = ref.$from;
      const $to = ref.$to;
      let node = ref.node;

      // Get the schema
      const schemaNodes = state.doc.type.schema.nodes;

      /*
				We are working with a selection
			*/
      if (!node && $from?.parent) {
        // console.log('MenuBuild.blockTypeItem.selection', $from)

        /*
					Check if this is a List we are trying to convert to something
				*/
        if ($from?.depth >= 3) {
          const parent = $from.node($from.depth - 1);

          console.log(
            "MenuBuild.blockTypeItem.selection.nested",
            $from,
            parent
          );

          /*
						Yes, it's a List.
					*/
          if (
            parent?.type?.name === "todo_item" ||
            parent?.type?.name === "list_item"
          ) {
            // console.log('MenuBuild.blockTypeItem.selection.list_item', $from.depth, $from.node($from.depth - 3))

            /*
							Is it a deep nested lists (a list inside a list)?
						*/
            if ($from.depth >= 5) {
              const parentList = $from.node($from.depth - 2);
              const parentListItem = $from.node($from.depth - 1);
              const nextParent = $from.node($from.depth - 3);

              console.log(
                "MenuBuild.blockTypeItem.selection.list_item",
                $from,
                parentListItem,
                parentList,
                nextParent
              );

              /*
								Yes, it's nested
							*/
              if (
                nextParent?.type?.name === "list_item" ||
                nextParent?.type?.name === "todo_item"
              ) {
                console.log();

                /*
									Multiple items?
									And I'm not the first Child?
									Transformation to Heading was requested?
								*/
                if (
                  parentListItem?.childCount > 1 &&
                  parentListItem?.firstChild !== $from?.parent &&
                  (nodeType?.name === "heading" ||
                    nodeType?.name === "paragraph")
                ) {
                  let tr = state.tr;
                  let { from, to } = state.selection;
                  console.log(
                    "MenuBuild.blockTypeItem.selection.multi_heading"
                  );

                  tr.setBlockType(from, to, nodeType, options.attrs);

                  dispatch(tr);
                  return true;
                }

                // Get the range
                let range = $from.blockRange($to);

                // console.log('MenuBuild.blockTypeItem.selection.list_item', range)

                // Join with the parent
                if (range) {
                  // Try to lift out of list
                  let liftedOut;
                  let { from, to, $from, $to } = state.selection;
                  let tr = state.tr;

                  // Not a paragraph
                  if (nodeType?.name !== "paragraph") {
                    // liftedOut = liftOutOfList(state, dispatch, range)
                    liftedOut = stateLiftOutOfList(tr, state, dispatch, range);
                    console.log(
                      "MenuBuild.blockTypeItem.liftOutOfList",
                      liftedOut
                    );

                    if (liftedOut) {
                      tr = liftedOut;
                      state = state.apply(tr);
                    }
                  }

                  // Not lifted
                  if (!liftedOut) {
                    // tr = stateLiftToOuterList(tr, state, dispatch, nextParent?.type?.name, range)
                    // state = state.apply(tr)

                    console.log(
                      "MenuBuild.blockTypeItem.stateLiftToOuterList",
                      nextParent?.type?.name,
                      from,
                      to,
                      $from,
                      "(start: " +
                        $from.start() +
                        ", end: " +
                        $from.end() +
                        ")",
                      $to,
                      "(start: " + $to.start() + ", end: " + $to.end() + ")",
                      to + " <= " + $from.end()
                    );
                    // tr.setNodeMarkup(from - 1, schemaNodes.hidden_list_item)
                    // tr = stateLiftOutOfList(tr, state, dispatch, range)

                    // Single item selected (selection "to" is less or equal than from "node end")
                    if (to <= $from.end()) {
                      const commandResult = stateLiftToOuterList(
                        tr,
                        state,
                        dispatch,
                        nextParent?.type?.name,
                        range
                      );
                      // console.log('stateLiftToOuterList', tr)
                      if (commandResult) tr = commandResult;

                      // Multiple items selected
                    } else {
                      const commandResult = stateLiftOutOfList(
                        tr,
                        state,
                        dispatch,
                        range
                      );
                      if (commandResult) tr = commandResult;
                    }
                    state = state.apply(tr);

                    // let { from, to } = state.selection
                    // tr.setBlockType(from, to, schemaNodes.hidden_list)

                    // const commandResult = stateWrapInList(tr, state, schemaNodes.hidden_list, options.attrs)
                    // if (commandResult) tr = commandResult
                    // console.log('stateWrapInList', tr)

                    if (nodeType?.name === "heading") {
                      console.log(
                        "MenuBuild.blockTypeItem.stateLiftToOuterList.heading",
                        from,
                        to
                      );
                      tr.setBlockType(
                        from,
                        to,
                        schemaNodes.heading,
                        options.attrs
                      );
                    }

                    // console.log('MenuBuild.blockTypeItem.stateLiftToOuterList.result', liftedOut)

                    // Try to lift to outer list
                    // liftedOut = liftToOuterList(state, dispatch, nextParent?.type?.name, range)
                    // console.log('MenuBuild.blockTypeItem.liftToOuterList', liftedOut)

                    // Let's convert to a hidden list
                    // setBlockType(schemaNodes.hidden_list)(state, dispatch) // , options.attrs

                    /*
										let tr = state.tr
										let { from, to } = state.selection
										// tr.setBlockType(from, to, schemaNodes.paragraph)
										// state = state.apply(tr)
										// dispatch(tr)
										dispatch(tr.setBlockType(from, to, schemaNodes.paragraph, options.attrs))
										*/

                    /*
										let transactions
			
										// A set of commands to apply
										transactions = chainTransactions(
											// Lift the list item out to a simple primitive: paragraph
											liftListItem(liftListFrom),
											// Wrap the resulting paragraph to a new List
											wrapInList(listType, attrs)
										)

										transactions(state, dispatch)
										*/
                  } else {
                    if (nodeType?.name === "heading") {
                      console.log(
                        "MenuBuild.blockTypeItem.stateLiftToOuterList.heading",
                        from,
                        to
                      );
                      tr.setBlockType(
                        from,
                        to,
                        schemaNodes.heading,
                        options.attrs
                      );
                    }
                  }

                  // console.log('MenuBuild.blockTypeItem.selection.list_item', liftedOut)

                  dispatch(tr);

                  // Do nothing else past this point
                  return true;
                }
              }
            }

            /*
							List -> Paragraph
						*/
            if (nodeType?.name === "paragraph") {
              console.log("MenuBuild.blockTypeItem.paragraph", parent, $from);

              /*
								Let's try to Lift the list item out,
								converting it to a simple primitive: paragraph
							*/

              // Am I the first child?
              // console.log('blockTypeItem.paragraph', parent, $from, parent.type)
              if (parent?.firstChild === $from?.parent) {
                console.log("blockTypeItem.paragraph.first");

                liftListItem(parent.type)(state, dispatch);
              } else {
                console.log("blockTypeItem.paragraph.other");

                setBlockType(nodeType, options.attrs)(state, dispatch);
              }

              /*
							List -> Heading
						*/
            } else if (nodeType?.name === "heading") {
              console.log("MenuBuild.blockTypeItem.heading", $from, parent);

              // Am I a single paragraph in a list?
              if (
                $from?.parent?.type?.name === "paragraph" &&
                parent?.childCount === 1
              ) {
                console.log("blockTypeItem.heading.single");

                /*
									We need to run a sequence of editor commands,
									let's prepare a holder for them
								*/

                let transactions;

                // A set of commands to apply
                transactions = chainTransactions(
                  // Lift the list item out to a simple primitive: paragraph
                  liftListItem(parent.type),
                  // Try to set a block type Heading to it
                  setBlockType(nodeType, options.attrs)
                );

                // Run the commands
                transactions(state, dispatch);

                // Am I the first child?
              } else if (parent?.firstChild === $from?.parent) {
                console.log("blockTypeItem.heading.first");

                let transactions;

                // A set of commands to apply
                transactions = chainTransactions(
                  // Lift the list item out to a simple primitive: paragraph
                  liftListItem(parent.type),
                  // Try to set a block type Heading to it
                  setBlockType(nodeType, options.attrs)
                );

                // Run the commands
                transactions(state, dispatch);

                // Just convert
              } else {
                console.log("blockTypeItem.heading.basic");

                setBlockType(nodeType, options.attrs)(state, dispatch);
              }
            }

            // Do nothing else past this point
            return true;
          }
        }
      }

      /*
				Simple type conversion
			*/

      // console.log('MenuBuild.blockTypeItem.simple')
      command(state, dispatch);
    },
    /**
     * Check if this menu item could be shown as inactive (disabled) for current cursor position
     * @param {Object} Current editor state
     * @return {Boolean} True if menu item could be active
     */
    enable: function enable() {
      // state
      /*
				Commented for now, we allow any type of conversion
			* /
			
			return command(state)

			*/

      return true;
    },
    /**
     * Check if this menu item could be shown as active (selected) for current cursor position
     * @param {Object} Current editor state
     * @return {Boolean} True if menu item could be active
     */
    active: function active(state) {
      /*
				Current state we are in (where is the cursor?)
			*/

      // console.log('MenuBuild.blockTypeItem', nodeType?.name)

      const ref = state.selection;
      const $from = ref.$from;
      const to = ref.to;
      let node = ref.node;

      // console.log('MenuBuild.blockTypeItem.active', to, $from.end(), nodeType, node, $from)

      /*
				This is a Single cursor position
			*/

      if (node) {
        // console.log('MenuBuild.blockTypeItem.active.node', node, node.hasMarkup(nodeType, options.attrs))
        return node.hasMarkup(nodeType, options.attrs);
      }

      /*
				This is a Selection
			*/

      // This is a cross-nodes selection, we can't mark this menu item as active
      if (!(to <= $from.end())) return false;

      // COMMENTED OLD CODE
      // This initial code does not work well,
      // random blockid attribute breaks the comparison logics
      // return to <= $from.end() && $from.parent.hasMarkup(nodeType, options.attrs)

      // Take a parent node from the Selection start
      node = $from?.parent;

      // Reset the active state
      let isActive = false;

      // console.log('MenuBuild.blockTypeItem.menuItem', nodeType?.name, node?.type?.name)

      // Same nodeType?
      if (nodeType?.name === node?.type?.name) {
        // Heading?
        if (node.type.name === "heading") {
          // Same level?
          if (options.attrs?.level === node?.attrs?.level) {
            isActive = true;
          }

          // Am I the paragraph?
        } else if (node.type.name === "paragraph") {
          // I'm inside someting?
          if ($from?.depth > 1) {
            const checkParent = $from.node($from.depth - 1);
            // console.log('MenuInline.blockTypeItem.parent', checkParent)

            // My parent is list_item or todo_item?
            if (
              checkParent?.type?.name === "list_item" ||
              checkParent?.type?.name === "todo_item"
            ) {
              // we can't mark the paragraph as active
            } else {
              isActive = true;
            }

            // I'm alone
          } else {
            isActive = true;
          }

          // Other types, yeap, we are of the same type
        } else {
          isActive = true;
        }

        // The type is different
      } else {
        /* // Selection is a paragraph?
				if (node.type.name === 'paragraph') {
					// I'm inside someting?
					if ($from?.depth > 1) {
						const checkParent = $from.node($from.depth - 1)
						console.log('MenuInline.paragraph.inside', checkParent)
					}
				} */
      }

      return isActive;
    },
  };

  for (var prop in options) {
    passedOptions[prop] = options[prop];
  }

  return new MenuItem(passedOptions);
}

// Work around classList.toggle being broken in IE11
function setClass(dom, cls, on) {
  if (on) {
    dom.classList.add(cls);
  } else {
    dom.classList.remove(cls);
  }
}

var prefix$2 = "ProseMirror-menubar";

function isIOS() {
  if (typeof navigator == "undefined") {
    return false;
  }
  var agent = navigator.userAgent;
  return (
    !/Edge\/\d/.test(agent) &&
    /AppleWebKit/.test(agent) &&
    /Mobile\/\w+/.test(agent)
  );
}

// :: (Object) → Plugin
// A plugin that will place a menu bar above the editor. Note that
// this involves wrapping the editor in an additional `<div>`.
//
//   options::-
//   Supports the following options:
//
//     content:: [[MenuElement]]
//     Provides the content of the menu, as a nested array to be
//     passed to `renderGrouped`.
//
//     floating:: ?bool
//     Determines whether the menu floats, i.e. whether it sticks to
//     the top of the viewport when the editor is partially scrolled
//     out of view.
function menuBar(options) {
  return new Plugin({
    view: function view(editorView) {
      return new MenuBarView(editorView, options);
    },
  });
}

var MenuBarView = function MenuBarView(editorView, options) {
  var this$1 = this;

  this.editorView = editorView;
  this.options = options;

  this.wrapper = crel("div", { class: prefix$2 + "-wrapper" });
  this.menu = this.wrapper.appendChild(crel("div", { class: prefix$2 }));
  this.menu.className = prefix$2;
  this.spacer = null;

  editorView.dom.parentNode.replaceChild(this.wrapper, editorView.dom);
  this.wrapper.appendChild(editorView.dom);

  this.maxHeight = 0;
  this.widthForMaxHeight = 0;
  this.floating = false;

  var ref = renderGrouped(this.editorView, this.options.content);
  var dom = ref.dom;
  var update = ref.update;
  this.contentUpdate = update;
  this.menu.appendChild(dom);
  this.update();

  if (options.floating && !isIOS()) {
    this.updateFloat();
    var potentialScrollers = getAllWrapping(this.wrapper);
    this.scrollFunc = function (e) {
      var root = this$1.editorView.root;
      if (!(root.body || root).contains(this$1.wrapper)) {
        potentialScrollers.forEach(function (el) {
          return el.removeEventListener("scroll", this$1.scrollFunc);
        });
      } else {
        this$1.updateFloat(e.target.getBoundingClientRect && e.target);
      }
    };
    potentialScrollers.forEach(function (el) {
      return el.addEventListener("scroll", this$1.scrollFunc);
    });
  }
};

MenuBarView.prototype.update = function update() {
  this.contentUpdate(this.editorView.state);

  if (this.floating) {
    this.updateScrollCursor();
  } else {
    if (this.menu.offsetWidth !== this.widthForMaxHeight) {
      this.widthForMaxHeight = this.menu.offsetWidth;
      this.maxHeight = 0;
    }
    if (this.menu.offsetHeight > this.maxHeight) {
      this.maxHeight = this.menu.offsetHeight;
      this.menu.style.minHeight = this.maxHeight + "px";
    }
  }
};

MenuBarView.prototype.updateScrollCursor = function updateScrollCursor() {
  var selection = this.editorView.root.getSelection();
  if (!selection.focusNode) {
    return;
  }
  var rects = selection.getRangeAt(0).getClientRects();
  var selRect = rects[selectionIsInverted(selection) ? 0 : rects.length - 1];
  if (!selRect) {
    return;
  }
  var menuRect = this.menu.getBoundingClientRect();
  if (selRect.top < menuRect.bottom && selRect.bottom > menuRect.top) {
    var scrollable = findWrappingScrollable(this.wrapper);
    if (scrollable) {
      scrollable.scrollTop -= menuRect.bottom - selRect.top;
    }
  }
};

MenuBarView.prototype.updateFloat = function updateFloat(scrollAncestor) {
  var parent = this.wrapper,
    editorRect = parent.getBoundingClientRect(),
    top = scrollAncestor
      ? Math.max(0, scrollAncestor.getBoundingClientRect().top)
      : 0;

  if (this.floating) {
    if (
      editorRect.top >= top ||
      editorRect.bottom < this.menu.offsetHeight + 10
    ) {
      this.floating = false;
      this.menu.style.position = this.menu.style.left = this.menu.style.top = this.menu.style.width =
        "";
      this.menu.style.display = "";
      this.spacer.parentNode.removeChild(this.spacer);
      this.spacer = null;
    } else {
      var border = (parent.offsetWidth - parent.clientWidth) / 2;
      this.menu.style.left = editorRect.left + border + "px";
      this.menu.style.display =
        editorRect.top > window.innerHeight ? "none" : "";
      if (scrollAncestor) {
        this.menu.style.top = top + "px";
      }
    }
  } else {
    if (
      editorRect.top < top &&
      editorRect.bottom >= this.menu.offsetHeight + 10
    ) {
      this.floating = true;
      var menuRect = this.menu.getBoundingClientRect();
      this.menu.style.left = menuRect.left + "px";
      this.menu.style.width = menuRect.width + "px";
      if (scrollAncestor) {
        this.menu.style.top = top + "px";
      }
      this.menu.style.position = "fixed";
      this.spacer = crel("div", {
        class: prefix$2 + "-spacer",
        style: "height: " + menuRect.height + "px",
      });
      parent.insertBefore(this.spacer, this.menu);
    }
  }
};

MenuBarView.prototype.destroy = function destroy() {
  if (this.wrapper.parentNode) {
    this.wrapper.parentNode.replaceChild(this.editorView.dom, this.wrapper);
  }
};

// Not precise, but close enough
function selectionIsInverted(selection) {
  if (selection.anchorNode === selection.focusNode) {
    return selection.anchorOffset > selection.focusOffset;
  }
  return (
    selection.anchorNode.compareDocumentPosition(selection.focusNode) ===
    Node.DOCUMENT_POSITION_FOLLOWING
  );
}

function findWrappingScrollable(node) {
  for (var cur = node.parentNode; cur; cur = cur.parentNode) {
    if (cur.scrollHeight > cur.clientHeight) {
      return cur;
    }
  }
}

function getAllWrapping(node) {
  var res = [window];
  for (var cur = node.parentNode; cur; cur = cur.parentNode) {
    res.push(cur);
  }
  return res;
}

export {
  Dropdown,
  DropdownSubmenu,
  MenuItem,
  blockTypeItem,
  icons,
  joinUpItem,
  liftItem,
  menuBar,
  redoItem,
  renderGrouped,
  selectParentNodeItem,
  undoItem,
  wrapItem,
};
