/**
 * System libraries
 */
import React from "react";

/**
 * Show limits counter for the account
 * @param {Object} props
 */
const LimitsCounter = (props) => {
  // console.log('LimitsCounter')

  /*
		Props
	*/

  const paragraphsCount =
    props?.paragraphsCount > 0 ? props?.paragraphsCount : 1;
  const openPaymentModal = props.openPaymentModal;
  const toggleLeftBar = props.toggleLeftBar;
  const showPaymentModal = () => {
    // Close left bar
    toggleLeftBar();

    // Open payment modal
    openPaymentModal();
  };

  /*
		Count it
	*/

  const paragraphsMax = 1000;
  const usedPercent = Math.min(
    100,
    Math.round((paragraphsCount * 100) / paragraphsMax)
  );

  /*
		Show it
	*/

  return (
    <div className="wotodo-limits-info" onClick={showPaymentModal}>
      <div className="wotodo-limits-info__title">Upgrade to go unlimited</div>
      <div className="wotodo-limits-info__description">
        This workspace has used {paragraphsCount} of its {paragraphsMax}{" "}
        paragraphs limit ({usedPercent}%).
      </div>
      <div className="wotodo-limits-info__progressbar">
        <div style={{ width: `${usedPercent}%` }}></div>
      </div>
    </div>
  );
};

export default LimitsCounter;
