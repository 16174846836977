import { Slice, Fragment } from "prosemirror-model";
import { TodoToggle } from "components/ProseMirror/commands/commandsListsTodo";
import { ListToggle } from "components/ProseMirror/commands/commandsLists";
import {
  BlockToggle,
  BlockToggleV3,
} from "components/ProseMirror/commands/commandsBlock";
import { closestParent, nodeIsChildOfNode } from "components/ProseMirror/Utils";

export function EventsProcessorPrevent(event) {
  console.log("EventsProcessorPrevent", event);

  // Stop from happening!
  event.stopPropagation();
  event.preventDefault();
  return false;
}

/**
 * Process Clicks
 * @param {object} Editor view
 * @param {object} Resolved position
 * @param {object} Node
 * @param {object} Node position
 * @param {object} Javascript Event
 * @param {boolean} Is it a direct click
 * @param {object} ReactJS history (for navigation)
 */
export function EventsProcessorClick(
  editorView,
  pos,
  node,
  nodePos,
  event,
  direct,
  history
) {
  const _debug = false;

  /* if (_debug)
    console.log(
      "EventsProcessorClick",
      event,
      event.target.tagName,
      direct ? "Direct" : "Outer",
      node
    ); */

  /*
		Not a direct click?
		The direct flag will be true for the inner node.
		https://prosemirror.net/docs/ref/#view.EditorProps.handleClickOn
	*/
  if (!direct) return true;

  /*
		Mouse Up
	*/
  if (event.type === "mouseup") {
    if (_debug) console.log("EventsProcessorClick.mouseup");

    /*
		Docs
			event.altKey: false // when hit with Alt key pressed
			event.ctrlKey: false // when hit with Ctrl key pressed

			event.button -> https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
			0: Main button pressed, usually the left button or the un-initialized state
			1: Auxiliary button pressed, usually the wheel button or the middle button (if present)
			2: Secondary button pressed, usually the right button
			3: Fourth button, typically the Browser Back button
			4: Fifth button, typically the Browser Forward button
		*/

    // Main button pressed (by default it's a left button)
    if (event.button === 0) {
      // if (_debug) console.log('EventsProcessorClick.closest', event.target.closest('.block-element'));

      /*
				Links
			*/
      if (event.target.tagName === "A") {
        if (_debug)
          console.log(
            "EventsProcessorClick.link",
            event.target.href,
            event.target.hostname,
            window.location.hostname,
            event.target.pathname
          );

        // Internal links
        if (event.target.hostname === window.location.hostname) {
          if (_debug) console.log("EventsProcessorClick.internal");

          event.stopPropagation();
          event.preventDefault();

          history.push(event.target.pathname);
          return false;

          // External links
        } else {
          if (_debug) console.log("EventsProcessorClick.external");

          event.stopPropagation();
          event.preventDefault();

          // With Ctrl?
          if (event.ctrlKey) {
            window.open(event.target.href, "_blank");

            // With Alt
          } else if (event.altKey) {
            // window.location.href = event.target.href;
            // With Shift
          } else if (event.shiftKey) {
            // window.location.href = event.target.href;
            // Simple click
          } else {
            window.open(event.target.href, "_blank").focus();
          }

          return false;
        }

        /*
				ToDo list
			*/
      } else if (event.target.classList.contains("todo-checkbox")) {
        if (_debug) console.log("EventsProcessorClick.todoToggle");

        // Prevent it from happening inside the Smart Links block!
        if (event.target.closest(".smartlinks-mentions")) {
          return false;
        }

        // Toggle the block (open/close)
        editorView.dispatch(TodoToggle(editorView.state, nodePos, node));

        /*
					Blur the editor.
					Removes the cursor.
					Important for mobile in order not to show the keyboard
				*/
        editorView.dom.blur();

        return true;

        /*
				Toggle list
			*/
      } else if (event.target.classList.contains("list-item-toggle")) {
        if (_debug) console.log("EventsProcessorClick.listToggle");

        // Toggle the block (open/close)
        editorView.dispatch(ListToggle(editorView.state, nodePos, node));

        /*
					Blur the editor.
					Removes the cursor.
					Important for mobile in order not to show the keyboard
				*/
        editorView.dom.blur();

        return true;

        /*
				Toggle block block_title
			*/
      } else if (event.target.classList.contains("block-title-toggle")) {
        if (_debug) console.log("EventsProcessorClick.blockToggle");

        // Toggle the block (open/close)
        let newDispatch = BlockToggle(editorView.state, nodePos, node);
        if (newDispatch) editorView.dispatch(newDispatch);

        /*
					Blur the editor.
					Removes the cursor.
					Important for mobile in order not to show the keyboard
				*/
        editorView.dom.blur();

        return true;

        /*
				Toggle block v3
			*/
      } else if (event.target.classList.contains("block-element-toggle")) {
        if (_debug)
          console.log(
            "EventsProcessorClick.block-element-toggle",
            nodePos,
            node
          );

        // Toggle the block (open/close)
        let newDispatch = BlockToggleV3(editorView.state, nodePos, node);
        if (newDispatch) editorView.dispatch(newDispatch);

        /*
					Blur the editor.
					Removes the cursor.
					Important for mobile in order not to show the keyboard
				*/
        editorView.dom.blur();

        return true;

        /*
				Card
			*/
      } else if (
        // event.target.parentElement &&
        // event.target.parentElement.classList.contains("block-element") &&
        // event.target.parentElement.dataset.iscard === "true"
        event.target.closest(".block-element") &&
        event.target.closest(".block-element").dataset.iscard === "true"
      ) {
        const selfNodeResolved = editorView.state.tr.doc.resolve(nodePos + 1);
        const parentNodeResolved = closestParent(
          editorView.state.tr.doc,
          selfNodeResolved,
          editorView.state.schema.nodes.block_element
        );

        if (_debug)
          console.log(
            "EventsProcessorClick.card",
            event.target.parentElement.dataset,
            nodePos,
            node,
            selfNodeResolved,
            parentNodeResolved
          );

        // Not a child of a first node?
        if (
          parentNodeResolved?.parent?.firstChild !== selfNodeResolved?.parent &&
          !nodeIsChildOfNode(
            selfNodeResolved,
            parentNodeResolved?.parent?.firstChild
          )
        ) {
          if (_debug)
            console.log(
              "EventsProcessorClick.card.notFirstChild",
              parentNodeResolved?.parent?.firstChild,
              selfNodeResolved?.parent
            );
          return false;
        }

        // Toggle the block (open/close)
        let newDispatch = BlockToggleV3(
          editorView.state,
          parentNodeResolved.before(),
          parentNodeResolved.parent
        );
        if (newDispatch) editorView.dispatch(newDispatch);

        /*
					Blur the editor.
					Removes the cursor.
					Important for mobile in order not to show the keyboard
				*/
        // editorView.dom.blur()

        return true;
      }
    }
  }
}

/*
    Transform pasted content before it is applied to the document.  
*/
export function EventsProcessorTransformPasted(slice) {
  // console.log('EventsProcessorTransformPasted', slice)

  return new Slice(linkify(slice.content), slice.openEnd, slice.openStart);
}

// const HTTP_LINK_REGEX = /\bhttps?:\/\/[\w_/.]+/g
const HTTP_LINK_REGEX = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
let linkify = function (fragment) {
  var linkified = [];

  // For each pasted Node
  fragment.forEach(function (child) {
    // Text? Process links
    if (child.isText) {
      // console.log('linkify.child', child)

      const text = child.text;
      var pos = 0,
        match;

      while ((match = HTTP_LINK_REGEX.exec(text))) {
        var start = match.index;
        var end = start + match[0].length;
        var link = child.type.schema.marks["link"];
        // console.log('linkify.match', match)

        // simply copy across the text from before the match
        if (start > 0) {
          // console.log('linkify.push.1', child.cut(pos, start))
          linkified.push(child.cut(pos, start));
        }

        // The link Text
        const urlText = text.slice(start, end);

        // The link Href
        let urlHref = urlText;

        // It does not start with http?
        if (
          !/^http/i.test(urlHref) &&
          !/^\//i.test(urlHref) // does not start with "/" symbol
        ) {
          // Add scheme to the url
          urlHref = "https://" + urlHref;
          // console.log('linkify.scheme', urlHref)
        }

        // console.log('linkify.push.2', child.cut(start, end).mark(link.create({href: urlHref}).addToSet(child.marks)))
        linkified.push(
          child
            .cut(start, end)
            .mark(link.create({ href: urlHref }).addToSet(child.marks))
        );
        pos = end;
      }

      // copy over whatever is left
      if (pos < text.length) {
        // console.log('linkify.push.3', child.cut(pos))
        linkified.push(child.cut(pos));
      }

      // Block? Look for child node
    } else {
      linkified.push(child.copy(linkify(child.content)));
    }
  });

  return Fragment.fromArray(linkified);
};
