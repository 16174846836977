/**
 * System libraries
 */
import React, { useState, useEffect, useRef } from "react";

/**
 * Share block
 * @param {Object} props
 * @return {object} React component
 */
const ShareBlock = (props) => {
  /*
		Props
	*/

  const isVisible = props.isVisible;
  const documentId = props.documentId;
  const knowledgebaseId = props.knowledgebaseId;
  // const modalClose = props.modalClose
  const isShared = props.isShared;
  const setShare = props.setShare;

  // console.log('ShareBlock', knowledgebaseId, documentId, isShared)

  /*
		Copy to clipboard
	*/

  const [copyLinkText, setCopyLinkText] = useState("Copy link");
  const linkRef = useRef(null);

  const copyToClipboard = (e) => {
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();

    // Hint text
    setCopyLinkText("✓ Copied!");
    setTimeout(() => {
      setCopyLinkText("Copy link");
    }, 1000);
  };

  /*
		Initial document share status
	*/

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(isShared);
  }, [isShared]);

  /*
		"Copied" hint cleanup
	*/

  useEffect(() => {
    setCopyLinkText("Copy link");
  }, [isVisible]);

  /*
		Update share state
	*/

  const toggleShareState = async () => {
    // Update the document
    await setShare(knowledgebaseId, documentId, !isChecked);

    // Toggle the checkbox
    setIsChecked(!isChecked);
  };

  return (
    <div className="wotodo-shareblock">
      <div className="wotodo-popup-heading">
        <div className="wotodo-popup-heading__text">
          <h4 className="wotodo-popup-heading__title">Share to web</h4>
          <p className="wotodo-popup-heading__subtitle">
            {isChecked
              ? "Anyone with the link can view"
              : "Publish and share link with anyone"}
          </p>
        </div>
        <div className="wotodo-ios-toggler">
          <input
            id="shareCheckbox"
            type="checkbox"
            value="true"
            onChange={toggleShareState}
            checked={isChecked ? true : false}
          />
          <label htmlFor="shareCheckbox"></label>
        </div>
      </div>
      <div className={`wotodo-share-input${isChecked ? " js-active" : ""}`}>
        <input
          ref={linkRef}
          className="wotodo-share-input__input"
          type="text"
          value={`https://shared.wotodo.co/document/${knowledgebaseId}/${documentId}`}
          readOnly
          disabled={!isChecked}
        />
      </div>
      <div className={`wotodo-share-copy${isChecked ? " js-active" : ""}`}>
        <button
          className="wotodo-share-copy__button"
          type="button"
          onClick={copyToClipboard}
        >
          {copyLinkText}
        </button>
      </div>
    </div>
  );
};

export default ShareBlock;
