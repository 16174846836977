import React, { useState, useCallback, useEffect } from "react";
import DocumentListPagination from "./DocumentListPagination";
import usePagination from "./usePagination";
import moment from "moment";
import DocumentListSearch from "./DocumentListSearch";
import FilterButton from "./FilterButton";
import { filterList, searchTerm } from "../views/Utils";

const mockList = [
  ...[...new Array(20 * 6)].map((_, i) => ({
    title: `title${i + 1}`,
    datetime_created: new Date(Date.now() + i * 24 * 60 * 60 * 1000),
    id: i + 1,
  })),
  {
    title: `A`,
    datetime_created: new Date(Date.now() + 500 * 24 * 60 * 60 * 1000),
    id: 500,
  },
  {
    title: `Z`,
    datetime_created: new Date(Date.now() - 501 * 24 * 60 * 60 * 1000),
    id: 501,
  },
];

export default function DocumentList() {
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [state, setState] = useState({
    sortDirection: "desc",
    sortField: "datetime_created",
  });

  const changeSortType = useCallback(
    (field, direction) => {
      if (direction !== state.sortDirection) {
        setState({ ...state, sortDirection: direction, sortField: field });
      }
    },
    [state]
  );

  const invSortType = (field) => () =>
    changeSortType(field, state.sortDirection === "asc" ? "desc" : "asc");

  useEffect(() => {
    setList(searchTerm(mockList, searchValue, "title"));
  }, [searchValue, mockList]);

  useEffect(() => {
    setList(filterList(state.sortField, mockList, state.sortDirection));
  }, [state, mockList]);

  const {
    currentList,
    goNext,
    goBack,
    goFirst,
    goLatest,
    currentPage,
    countPage,
    changePage,
  } = usePagination(list);

  return (
    <div className="list">
      <div className="list__tools-bar">
        <DocumentListSearch onSearch={setSearchValue} />
      </div>

      <ul className="list__content">
        <li className="list-item list-header">
          <FilterButton
            title="TITLE"
            value={state.sortField === "title" ? state.sortDirection : null}
            onClick={invSortType("title")}
          />
          <FilterButton
            title="CREATED"
            value={
              state.sortField === "datetime_created"
                ? state.sortDirection
                : null
            }
            onClick={invSortType("datetime_created")}
          />
        </li>
        {currentList.map(({ title, datetime_created, id }) => {
          return (
            <li key={id} className="list-item">
              <div className="list-item__title">{title}</div>
              <div className="list-item__date">
                {moment(datetime_created).format("MMMM Do, YYYY")}
              </div>
            </li>
          );
        })}
      </ul>
      <DocumentListPagination
        changePage={changePage}
        currentPage={currentPage}
        countPage={countPage}
        goNext={goNext}
        goBack={goBack}
        goFirst={goFirst}
        goLatest={goLatest}
      />
    </div>
  );
}
