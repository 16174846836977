import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

/**
 * Toggle plugin for block_element Node.
 * Creates an editor decoration with html element
 * to catch Mouse click events and open/close block_element
 */
export const blocksTogglePlugin = () => {
  const _debug = false;

  return new Plugin({
    props: {
      decorations: (state) => {
        const decorations = [];

        state.doc.descendants((node, pos) => {
          if (node.type.name === "block_element") {
            if (_debug)
              console.log(
                "blocksTogglePlugin.block_element",
                node?.attrs?.blockid,
                node?.childCount
              );

            if (node.childCount > 0) {
              // Card block_element
              if (node?.attrs?.iscard === true) {
                // Simple block_element
              } else {
                if (_debug)
                  console.log(
                    "blocksTogglePlugin.block_element",
                    node,
                    pos,
                    node.nodeSize
                  );

                const toggleElement = document.createElement("span");
                toggleElement.classList.add("block-element-toggle");

                decorations.push(
                  // Add the toggle element as the last child of block_element
                  Decoration.widget(pos + node.nodeSize - 1, toggleElement)
                );
              }
            } else {
              console.error(
                "blocksTogglePlugin.block_element.invalidChildCount",
                node
              );
            }
          }
        });

        return DecorationSet.create(state.doc, decorations);
      },
    },
  });
};
