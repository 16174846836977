/**
 * Firestore
 */
import firebase from "firebase/app";

/**
 * System libraries
 */
// import { useContext } from 'react' // , Component, React,

/**
 * Contexts
 */
// import { UserContext } from 'contexts/UserContext'

/**
 * Async foreach array
 * @doc https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
 *
 * @param {array} array
 * @param {function} callback
 */
export async function asyncForEachArray(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

/**
 * Async foreach object
 *
 * @param {object} object
 * @param {function} callback
 */
export async function asyncForEachObject(object, callback) {
  for (let index in object) {
    // console.log('asyncForEachObject', index, object[index])
    await callback(index, object[index]);
  }
}

/**
 * Is the object empty?
 *
 * @param {object} Object to check
 * @return {boolean} True if is empty
 */
export function objectIsEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/*
	Arrays differences
	@doc https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript#answer-33034768
*/

/**
 * Return array of items from Before which are missing in After
 *
 * @param {array} Before
 * @param {array} After
 * @return {array} Items disappeared in After
 */
export function arrayDifference(before, after) {
  const beforeStringified = before.map((item) => {
    return JSON.stringify(item);
  });

  const afterStringified = after.map((item) => {
    return JSON.stringify(item);
  });

  // console.log('arrayDifference', beforeStringified, afterStringified)

  const differenceStringified = beforeStringified.filter(
    (x) => !afterStringified.includes(x)
  );

  // console.log('arrayDifference.diff', differenceStringified)

  const difference = differenceStringified.map((item) => {
    return JSON.parse(item);
  });

  // console.log('arrayDifference.object', difference)

  return difference;
}

/**
 * intersection
 
export function arrayIntersection(before, after) {
	let intersection = arr1.filter(x => arr2.includes(x));
}*/

/**
 * symmetric difference
 
export function arraySymmetricDifference(before, after) {
	let difference = arr1
                 .filter(x => !arr2.includes(x))
                 .concat(arr2.filter(x => !arr1.includes(x)));
}*/

/**
 * Check if a user hit limit for a free account
 * @param {object} userData
 * @return {object} Hit
 */
export function profileCheckLimits(userData) {
  // console.log('profileCheckLimits', userData)

  // Account is paid?
  if (userData.isPaid) {
    // console.log('profileCheckLimits.isPaid')
    return { hit: false };
  }

  /*
	// Too many Smart Links
	if (
		userData?.smartLinksCount
		&& userData.smartLinksCount >= 200
	) {
		console.log('profileCheckLimits.smartLinksLimit', userData.smartLinksCount)
		return { hit: true, type: 'links', message: 'You are over the Smart Links limit for the Free plan' }
	}
	*/

  // Too many Paragraphs
  if (userData?.paragraphsCount && userData.paragraphsCount >= 1000) {
    // console.log('profileCheckLimits.paragraphsLimit', userData.paragraphsCount)
    return {
      hit: true,
      type: "paragraphs",
      message: "You are over the blocks limit for the Free plan",
    };
  }

  // No limits
  // console.log('profileCheckLimits.noLimits')
  return { hit: false };
}

/**
 * Get today in different types
 * @return {short:, long:, firestore:}
 */
export function getDateObject(date) {
  // console.log('getDateObject')

  // Reset
  let result = {};

  // Date is not set? Use today
  if (typeof date === "undefined") {
    // console.log('getDateObject.Today')
    date = new Date();
  }

  // Get as "09-25-2020"
  // const shortFormat = { month: '2-digit', day: '2-digit', year: 'numeric' }
  // result.short = date.toLocaleDateString('en-US', shortFormat)
  const shortFormat = new Intl.DateTimeFormat("en", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  let [
    { value: monthShort },
    ,
    { value: dayShort },
    ,
    { value: yearShort },
  ] = shortFormat.formatToParts(date);
  result.short = monthShort + "-" + dayShort + "-" + yearShort;

  // const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
  // const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(today)
  // result.mdy = `${month}-${day}-${year}`

  // Get as "September 25th, 2020"
  const longFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  let [
    { value: monthLong },
    ,
    { value: dayLong },
    ,
    { value: yearLong },
  ] = longFormat.formatToParts(date);
  result.long = monthLong + " " + dayLong + dateNth(dayLong) + ", " + yearLong;

  /*
		Create a UTC date for Firestore

		Date.UTC
		https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC

	*/

  // Firestore // 'December 10, 1815'
  // const firestoreFormat = { month: 'long', day: 'numeric', year: 'numeric' }
  // result.firestore = firebase.firestore.Timestamp.fromDate(new Date(date.toLocaleDateString('en-US', firestoreFormat)))

  // INVALID: ALWAYS USES UTC RECALC
  // const firestoreFormat = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  // result.firestore = firebase.firestore.Timestamp.fromMillis(firestoreFormat)

  // result.firestore1 = firebase.firestore.Timestamp.fromDate(new Date(result.long))

  /*
	result.date = date
	const current = date
	current.setHours(0, 0, 0)
	result.current = current
	result.firestore2 = firebase.firestore.Timestamp.fromDate(current)

	result.firestore3 = firebase.firestore.Timestamp.fromDate(
		new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
	)
	*/

  // Get a UTC Date for my exact local day
  const utcDayFromLocal = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  );

  // What Date it will be with my timezone from this UTC day?
  const localDateFromUtcDay = new Date(utcDayFromLocal);

  // Set a Firestore date from this
  result.firestore = firebase.firestore.Timestamp.fromDate(localDateFromUtcDay);

  // result.debug1 = new Date(date.toLocaleDateString('en-US', firestoreFormat))
  // result.debug2 = date.toLocaleDateString('en-US', firestoreFormat)

  // console.log('getDateObject.result', result)

  return result;
}

/**
 * Get the suffix for a day number
 * @param {String} Day
 */
function dateNth(day) {
  switch (day) {
    case "1":
    case "21":
    case "31":
      return "st";
    case "2":
    case "22":
      return "nd";
    case "3":
    case "23":
      return "rd";
    default:
      return "th";
  }
}

/**
 * Compare two versions like "1.0.6" and "1.0.9"
 * and will return:
 * @doc https://stackoverflow.com/a/6832721
 * @param {String} New version
 * @param {String} Old version
 * @param {Array} Comparison options
 * @return {Numeric} 0: equal, 1: New>Old, -1: Old>New
 */
export function versionsCompare(v1, v2, options) {
  var lexicographical = options && options.lexicographical,
    zeroExtend = options && options.zeroExtend,
    v1parts = v1.split("."),
    v2parts = v2.split(".");

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push("0");
    while (v2parts.length < v1parts.length) v2parts.push("0");
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

/**
 * Do two objects have the same set of keys?
 * WARNING: this function does not compare values!
 * @param {object} a
 * @param {object} b
 * @return {boolean} True if they are the same
 */
export function objectsHaveSamekeys(a, b) {
  const aKeys = Object.keys(a).sort();
  const bKeys = Object.keys(b).sort();

  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}
