/**
 * System libraries
 */
import React from "react";

/**
 * Based on FortAwesome:
 * https://github.com/FortAwesome/react-fontawesome
 *
 * How to:
 * https://www.digitalocean.com/community/tutorials/how-to-use-font-awesome-5-with-react
 *
 * Check for icons here:
 * https://fontawesome.com/icons?d=gallery&q=star
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// FaS
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faSignOutAlt,
  faStar,
  faCircle,
  faAngleDown,
  faAngleUp,
  faCaretLeft,
  faCaretRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
// FaR
import {
  faStar as farStar,
  faEdit as farEdit,
} from "@fortawesome/free-regular-svg-icons";

export function chevronLeft() {
  return <FontAwesomeIcon icon={faChevronCircleLeft} />;
}

export const chevronRight = () => {
  return <FontAwesomeIcon icon={faChevronCircleRight} />;
};

export const signOut = () => {
  return <FontAwesomeIcon icon={faSignOutAlt} />;
};

export const starInactive = () => {
  return <FontAwesomeIcon icon={farStar} />;
};

export const starActive = () => {
  return <FontAwesomeIcon icon={faStar} />;
};

export const pagePencil = () => {
  return <FontAwesomeIcon icon={farEdit} />;
};

export const saveIcon = () => {
  return <FontAwesomeIcon icon={faCircle} />;
};

export const SingleArrowDown = () => {
  return <FontAwesomeIcon icon={faAngleDown} />;
};

export const SingleArrowUp = () => {
  return <FontAwesomeIcon icon={faAngleUp} />;
};

export const SingleArrowLeft = () => {
  return <FontAwesomeIcon icon={faCaretLeft} />;
};

export const SingleArrowRight = () => {
  return <FontAwesomeIcon icon={faCaretRight} />;
};

export const DoubleArrowLeft = () => {
  return <FontAwesomeIcon icon={faAngleDoubleLeft} />;
};

export const DoubleArrowRight = () => {
  return <FontAwesomeIcon icon={faAngleDoubleRight} />;
};

export const Search = () => {
  return <FontAwesomeIcon icon={faSearch} />;
};
