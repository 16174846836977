/**
 * System libraries
 */
import React, { useEffect, useState } from "react";

/**
 * Contexts
 */
import { useHistory } from "react-router-dom";

/**
 * ProseMirror, system libraries
 */
// State, describes the current editors states (current and future)
import { EditorState } from "prosemirror-state";
// User interface components that visualize the Editor in a browser
import { EditorView } from "prosemirror-view";
// Describes the document Model
import { editorSchema } from "../ProseMirror/schema/editorSchema.js";
import * as prosemirrorState from "prosemirror-state";

/**
 * Modules
 */
import { getGroup, getDocument } from "components/Document/processor";
import {
  proseMirrorSearchBacklink,
  proseMirrorSearchLinksCleanup,
} from "components/ProseMirror/Utils";
import { EventsProcessorClick } from "../ProseMirror/EventsProcessors"; // , EventsProcessorPrevent
import { blocksTogglePlugin } from "components/ProseMirror/plugins/blocksToggle";
import { recursiveFixBlockTitle } from "components/ProseMirror/FirestoreConverter";

/**
 * Smart links block
 */
export default function SmartLinks(props) {
  /*
		Settings
	*/

  const _debug = false;

  /*
		System
	*/

  // History
  let history = useHistory();

  /*
		Props
	*/

  if (_debug) console.log("SmartLinks", props);

  const knowledgebaseId = props.knowledgebaseId;
  const documentId = props.documentId;
  const referencesIds = props.referencesIds;
  const gotoPage = props.gotoPage;

  /*
		States
	*/

  // Reference documents
  const [references, setReferences] = useState({});

  // Open/closed
  const [open, setOpen] = useState(true); // open by default
  const toggleOpen = () => {
    setOpen(!open);
  };

  /**
   * Process document references (SmartLinks).
   * On change: knowledgebaseId, documentId, referencesIds
   */
  useEffect(() => {
    // if (_debug) console.log('SmartLinks.init')

    const initReferences = async () => {
      if (_debug) console.log("SmartLinks.initReferences", referencesIds);

      // Reset the new references object
      let resultReferences = {};

      // Get (object containing documents objects OR undefined if not found)
      const referenceDocuments = await getGroup(
        knowledgebaseId,
        referencesIds.reverse() // Array of documentIds from Latest to Oldest
      );
      // console.log('SmartLinks.referenceDocuments', referenceDocuments)

      // Compile all references in parallel
      const cachedParents = {};
      await Promise.all(
        Object.keys(referenceDocuments).map(async (referenceDocumentId) => {
          if (_debug) console.log('SmartLinks.Document', referenceDocumentId, referenceDocuments[referenceDocumentId])

          // Not found?
          if (
            !referenceDocuments[referenceDocumentId] ||
            referenceDocuments[referenceDocumentId] === undefined
          ) {
            // console.log('SmartLinks.Document.Empty')
            // do nothing
            // It's a block
          } else if (referenceDocuments[referenceDocumentId]?.is_block) {
            /*
						Get parent data
					*/
            let parentData = null;

            // Already fetched?
            if (
              cachedParents[referenceDocuments[referenceDocumentId].parent_id]
            ) {
              parentData =
                cachedParents[
                  referenceDocuments[referenceDocumentId].parent_id
                ];

              // Not yet cached
            } else {
              // Fetch from Storage
              parentData = await getDocument(
                knowledgebaseId,
                referenceDocuments[referenceDocumentId].parent_id
              );
              if (parentData) {
                cachedParents[
                  referenceDocuments[referenceDocumentId].parent_id
                ] = parentData;
              }
            }

            // Parent data exists?
            // console.log('SmartLinks.parentData', parentData)
            if (parentData) {
              // Reset the document
              let jsonDoc = {
                type: "doc",
                content: [],
              };

              // Save node
              let elementObject = JSON.parse(
                referenceDocuments[referenceDocumentId].json
              );
              if (_debug) console.log('SmartLinks.elementObject', elementObject);

              // Recursive fix (block_title)
              elementObject = recursiveFixBlockTitle(elementObject);

              // Search for a backlink inside the object
              elementObject = proseMirrorSearchBacklink(
                elementObject,
                documentId,
                0
              );
              if (_debug) console.log('SmartLinks.FoundBacklink', elementObject)

              // Cleanup the references
              elementObject = proseMirrorSearchLinksCleanup(
                elementObject,
                documentId,
                0
              );
              if (_debug) console.log('SmartLinks.Cleanup', elementObject)

              // Ref.link found?
              if (elementObject) {
                // Is multi?
                if (
                  elementObject?._ismulti &&
                  elementObject?.content &&
                  elementObject?.content?.length
                ) {
                  for (let m = 0; m < elementObject.content.length; m++) {
                    jsonDoc.content.push(elementObject.content[m]);
                  }
                } else {
                  jsonDoc.content.push(elementObject);
                }
                // console.log('SmartLinks.jsonDoc', jsonDoc)

                const elementDoc = editorSchema.nodeFromJSON(jsonDoc);
                // console.log('SmartLinks.Element', elementDoc)

                if (
                  !resultReferences[
                    referenceDocuments[referenceDocumentId].parent_id
                  ]
                ) {
                  resultReferences[
                    referenceDocuments[referenceDocumentId].parent_id
                  ] = {
                    documentTitle: parentData.title,
                    blocks: [],
                  };
                }

                resultReferences[
                  referenceDocuments[referenceDocumentId].parent_id
                ].blocks.push(elementDoc);
              }
            }

            // It's a Page
          } else if (referenceDocuments[referenceDocumentId]?.is_page) {
            if (!resultReferences[referenceDocumentId]) {
              resultReferences[referenceDocumentId] = {
                documentTitle: referenceDocuments[referenceDocumentId].title,
                blocks: [],
              };
            }

            /* resultReferences[referenceDocumentId].push({
						documentTitle: referenceDocuments[referenceDocumentId].title
					}) */
          }
        })
      );
      if (_debug) console.log("SmartLinks.resultReferences", resultReferences);

      setReferences(resultReferences);
    };

    // Load References
    initReferences();
  }, [knowledgebaseId, documentId, referencesIds]);

  /**
   * Create editors for each found reference block
   * On change: documentId, references
   */
  useEffect(() => {
    if (_debug) console.log("SmartLinks.useEffect");

    // Not empty?
    if (references && Object.keys(references).length > 0) {
      // Foreach reference document
      Object.keys(references).forEach((key) => {
        if (references[key].blocks.length > 0) {
          references[key].blocks.forEach((blockText, blockIndex) => {
            const refId =
              "doc" + documentId + "-ref" + key + "-block" + blockIndex;

            // ProseMirror editor view initialization
            // console.log('Init.Reference: ', refId, document.getElementById(refId))

            // Already?
            if (document.getElementById(refId).querySelector(".ProseMirror")) {
              // console.log('Init.Reference.ALREADY: ', refId, document.getElementById(refId), document.getElementById(refId).querySelector('.ProseMirror'))
              return;
            }

            /*
							Init read-only ProseMirror editor for every mention block
							https://prosemirror.net/docs/ref/#view.EditorProps.editable
						*/
            new EditorView(
              // Editor Div
              document.getElementById(refId),
              {
                // State
                state: EditorState.create({
                  doc: blockText,
                  // plugins: editorPlugins.current,
                  plugins: [
                    new prosemirrorState.Plugin({
                      props: {
                        attributes: { class: "wt-editor-engine" },
                      },
                    }),
                    blocksTogglePlugin()
                  ],
                }),
                /* // Called for each node around a click, from the inside out. The direct flag will be true for the inner node.
								handleClickOn: (editorView, pos, node, nodePos, event, direct) => {
									//console.log('SmartLinks.Click', direct, event.type, event.button, event.target.tagName, event.target.hostname)
									// EventsProcessorClick(editorView, pos, node, nodePos, event, direct, history)
									// Stop from happening!
									event.stopPropagation()
									event.preventDefault()
									return false
								}, */
                // Called for each node around a click, from the inside out. The direct flag will be true for the inner node.
                handleClickOn: (
                  editorView,
                  pos,
                  node,
                  nodePos,
                  event,
                  direct
                ) => {
                  EventsProcessorClick(
                    editorView,
                    pos,
                    node,
                    nodePos,
                    event,
                    direct,
                    history
                  );
                },
                editable: () => false,
              }
            );
          });
        }
      });
    }
  }, [documentId, references, history]);

  /*
		Output
	*/

  // No references?
  if (!references || Object.keys(references).length === 0) {
    // console.log('SmartLinks.emptyResultReferences', references)
    return null;
  }

  // Return block
  // console.log('SmartLinks.renderReferences', references)
  return (
    <div className="wt-smartlinks-block">
      <div className="wt-smartlinks-title">
        <button
          className="wt-smartlinks-toggle btn btn-link m-0 p-0"
          onClick={toggleOpen}
        >
          <img
            src={
              open
                ? "/images/icons/arrow-black-down.svg"
                : "/images/icons/arrow-black-right.svg"
            }
            alt=""
          />
        </button>
        <div>Smart Links</div>
      </div>

      <div
        className={
          open ? "wt-smartlinks-wrapper" : "wt-smartlinks-wrapper wt-collapsing"
        }
      >
        {Object.keys(references).map((key) => {
          return (
            <div
              key={`key-doc${documentId}-ref${key}`}
              className="wt-smartlinks-document"
            >
              <a
                href={`/document/${key}`}
                onClick={(event) => {
                  gotoPage(event, `/document/${key}`);
                }}
              >
                {references[key].documentTitle}
              </a>

              {
                // Show all smartlink mentions from the exact document
                references[key].blocks.length > 0 ? (
                  <SmartLinksMentions
                    documentId={documentId}
                    referenceIndex={key}
                    blocks={references[key].blocks}
                  />
                ) : (
                  ""
                )
              }
            </div>
          );
        })}
      </div>
    </div>
  );
}

/**
 * Exact document smart links
 */
const SmartLinksMentions = (props) => {
  /*
		Props
	*/

  const documentId = props.documentId;
  const referenceIndex = props.referenceIndex;
  const blocks = props.blocks;

  /*
		Output
	*/

  // Return block
  return (
    <div className="wt-smartlinks-mentions">
      {blocks.map((blockText, blockIndex) => {
        const refId =
          "doc" + documentId + "-ref" + referenceIndex + "-block" + blockIndex;

        // console.log('Show.Reference: ', refId, document.getElementById(refId))

        // return <li key={`ref-${referenceIndex}-block-${blockIndex}`}>{blockText.textContent}</li>
        return <div key={refId} id={refId}></div>;
        // return <div key={`ref-${referenceIndex}-block-${blockIndex}`} id={`ref-${referenceIndex}-pm-${blockIndex}`}></div>
      })}
    </div>
  );
};
