/**
 * System libraries
 */
import React from "react";

/**
 * Configs
 */
import * as generalConfig from "configs/firebase";

/**
 * Firebase, firestore
 */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

// Initialize Firebase
firebase.initializeApp(generalConfig.firebaseConfig);

/**
 * FirebaseUI based on:
 * https://github.com/firebase/firebaseui-web-react
 * and Bootstrap
 */
export default function FirebaseAuth() {
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  );
}
