/**
 * System libraries
 */
import React, { useContext, useState } from "react";

/**
 * Stripe
 */
import { loadStripe } from "@stripe/stripe-js";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Modules
 */
import ModalWrapper from "components/views/modals/Wrapper";
import ElementLoader from "components/views/elements/Loader";
import { firestoreUserUpdate } from "components/Firestore/User";

/**
 * Payment modal
 * @param {Object} props
 */
export function ModalPayment(props) {
  // console.log('ModalPayment')

  /*
		Props
	*/

  const isOpen = props.isOpen;
  const modalClose = props.modalClose;

  /*
		Stripe config
	*/

  const stripePriceId = "price_1HknIrBtfuD1320bFQMviefV";
  const stripePromise = loadStripe(
    "pk_live_51HkRGlBtfuD1320b9SbUS12wTWlccLsS5VUgbYBaxA6DXPU4fJuf8d26Ugbewct17lEIReDtoBnXxAPN86WoMKHX00LlxlazAD"
  );

  /*
		Loader
	*/

  const [isLoading, setIsLoading] = useState(false);

  /*
		Alert box
	*/

  // Alert
  const [alertShow, setAlertShow] = useState(false);
  const [paymentError, setPaymentError] = useState("");

  /**
   * Display error inside a modal
   * @param {String} Error text
   */
  const displayError = (errText) => {
    // Remove loader
    setIsLoading(false);

    // Set error text
    setPaymentError(errText);

    // Show alert
    setAlertShow(true);
  };

  /*
		Context
	*/

  // Get the User context
  const { userData } = useContext(UserContext);

  // User data
  const userEmail =
    userData?.email && userData.email !== "" ? userData.email : "";
  let stripeCustomerId =
    userData?.stripeCustomerId && userData.stripeCustomerId !== ""
      ? userData.stripeCustomerId
      : false;
  let stripeSessionId =
    userData?.stripeSessionId && userData.stripeSessionId !== ""
      ? userData.stripeSessionId
      : false;

  /**
   * Payment processor
   */
  const paymentProcess = async () => {
    // console.log('paymentProcess')

    /*
			We are loading
		*/

    // Remove alerts
    setAlertShow(false);

    // Set is loading state
    setIsLoading(true);

    /*
			Reset data
		*/

    let firestoreUpdatedData = {};

    /*
			Init Stripe
		*/

    const stripe = await stripePromise;

    /*
			Customer is not yet registered?
		*/

    if (!stripeCustomerId) {
      // console.log('paymentProcess.createCustomer', userEmail)

      // Send request
      const customerResponse = await fetch(
        "https://app.wotodo.co/stripe/create-customer",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userEmail,
          }),
        }
      );

      // console.log('paymentProcess.createCustomer.response', customerResponse)

      // Error
      if (!customerResponse.ok) {
        displayError(`Could not create customer: ${customerResponse.status}`);
        return false;
      }

      // Parse reply
      const customerResponseData = await customerResponse.json();
      if (
        !customerResponseData?.customer ||
        !customerResponseData.customer?.id
      ) {
        displayError(`Could not create customer: empty Customer Id`);
        return false;
      }

      // console.log('paymentProcess.createCustomer.data', customerResponseData)

      // Add data to save at Firestore
      firestoreUpdatedData.stripeCustomerId = customerResponseData.customer.id;

      // Save locally
      stripeCustomerId = customerResponseData.customer.id;
      userData.stripeCustomerId = customerResponseData.customer.id;
    }

    /*
			Create session
		*/

    // console.log('paymentProcess.createSession', stripePriceId, stripeCustomerId)

    // Send request
    const sessionResponse = await fetch(
      "https://app.wotodo.co/stripe/create-checkout-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: stripePriceId,
          customerId: stripeCustomerId,
        }),
      }
    );

    // console.log('paymentProcess.createSession.response', sessionResponse)

    // Error
    if (!sessionResponse.ok) {
      displayError(`Could not create session: ${sessionResponse.status}`);
      return false;
    }

    // Parse reply
    const sessionResponseData = await sessionResponse.json();
    if (!sessionResponseData?.sessionId) {
      displayError(`Could not create session: empty Session Id`);
      return false;
    }

    // console.log('paymentProcess.createSession.data', sessionResponseData)

    // Add data to save at Firestore
    firestoreUpdatedData.stripeSessionId = sessionResponseData.sessionId;

    // Save locally
    stripeSessionId = sessionResponseData.sessionId;
    userData.stripeSessionId = sessionResponseData.sessionId;

    /*
			Save user data at Firestore
		*/

    if (Object.keys(firestoreUpdatedData).length !== 0) {
      // console.log('paymentProcess.firestoreUserUpdate', userData.uid, firestoreUpdatedData)

      firestoreUserUpdate(userData.uid, firestoreUpdatedData);
    }

    // Redirect to Stripe
    await stripe.redirectToCheckout({
      sessionId: stripeSessionId,
    });

    return false;
  };

  // Loading
  if (isLoading) {
    return (
      <ModalWrapper
        isOpen={isOpen}
        showCloseButton={true}
        content={
          <div className="wotodo-modal-payment-form">
            <ElementLoader />
          </div>
        }
        modalClose={modalClose}
      />
    );

    // Plans selection
  } else {
    return (
      <ModalWrapper
        isOpen={isOpen}
        showCloseButton={true}
        content={
          <div className="wotodo-modal-payment-form">
            {/* Plans list */}
            <p>
              Your Wotodo is on a free Personal Plan with storage limits.
              <br />
              Upgrade to get unlimited paragraphs and storage.
            </p>

            <hr />

            {/* Error block */}
            <div
              className={`wotodo-modal-alert${
                alertShow ? " wotodo-modal-alert-open" : ""
              }`}
            >
              <div className="wotodo-modal-alert__generic wotodo-modal-alert__error">
                <h4>{paymentError}</h4>
              </div>
            </div>

            <div className="wotodo-modal-payment-plans">
              <TariffPlan
                title="Personal"
                price="0"
                isActive={userData.plan === "personal" ? true : false}
                paymentProcess={paymentProcess}
              />
              <TariffPlan
                title="Personal Pro"
                price="5"
                isActive={userData.plan === "personal-pro" ? true : false}
                paymentProcess={paymentProcess}
              />
            </div>
          </div>
        }
        modalClose={modalClose}
      />
    );
  }
}

function TariffPlan(props) {
  return (
    <div
      className={
        "wotodo-modal-payment-plancard" +
        (props.isActive ? " wotodo-modal-payment-plancard-current" : "")
      }
    >
      <div>
        <div className="wotodo-modal-payment-plancard-title">{props.title}</div>

        <div className="wotodo-modal-payment-purchase">
          <div className="wotodo-modal-payment-plancard-price">
            ${props.price}
            <span>{props.price > 0 ? " per month" : ""}</span>
          </div>

          <div className="wotodo-modal-payment-plancard-button">
            <div></div>
            {!props.isActive ? (
              <button
                className="wotodo-modal-payment-plancard-button-active"
                onClick={() => {
                  props.paymentProcess();
                }}
              >
                {props.price > 0 ? "Upgrade" : "Downgrade"}
              </button>
            ) : (
              <button
                className="wotodo-modal-payment-plancard-button-inactive"
                disabled
              >
                Current Plan
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
