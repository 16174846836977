/**
 * System libraries
 */
import React, { useEffect } from "react";

/**
 * Bootstrap
 */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

/**
 * Application libraries
 */
import FirebaseAuth from "components/Firebase/Auth";

/**
 * Styling
 */
import "css/authorize.css";

/**
 * Render the authorization page
 */
export default function PageAuthorize(props) {
  console.log("Page.Authorize", props);

  useEffect(() => {
    // Add class
    document.body.classList.add("wt-authorize");

    // Clean up
    return () => {
      document.body.classList.remove("wt-authorize");
    };
  }, []);

  return (
    <Container className="h-100">
      <Row className="h-100">
        <Col sm={6} className="wt-welcome-left"></Col>
        <Col sm={6} className="pl-3 pl-sm-4 d-flex wt-welcome-right">
          <div className="pl-sm-4">
            <div>
              <img
                src="/images/logo-authorize.svg"
                alt="Wotodo.co"
                id="wtAuthLogo"
              ></img>
              <p className="mb-5 mt-3 wt-authorize">
                We're trying to keep this special and are carefully selecting
                new members.
                <br />
                Thank you for your patience.
              </p>
              <FirebaseAuth />
              {/* <div className="firebaseui-card-footer firebaseui-provider-sign-in-footer">
								<p className="firebaseui-tos firebaseui-tospp-full-message">
									By continuing, you are indicating that you accept our <a href="" className="firebaseui-link firebaseui-tos-link" target="_blank">Terms of Service</a> and <a href="javascript:void(0)" className="firebaseui-link firebaseui-pp-link" target="_blank">Privacy Policy</a>.
								</p>
							</div> */}
              {props?.authError ? (
                <Alert variant={"danger"}>{props.authError}</Alert>
              ) : (
                ""
              )}
              {props?.authLoading ? (
                <div
                  className="spinner-grow"
                  style={{ width: "2rem", height: "2rem" }}
                ></div>
              ) : (
                ""
              )}
            </div>
            {/* <div className="wt-signin">
							Don’t have an account yet? <a href="https://app.wotodo.co/onboard/">Sign up here</a>.
						</div> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
