/**
 * When the Document is loaded via Editor
 * this DocumentController kicks in and does the following:
 * - if the document was read from LocalStorage checks RemoteStorage for update;
 * - initializes Firestore onSnapshot listener;
 * - processes any Firestore onSnapshot events;
 * - re-checks document on events: onlone, focus.
 */

/**
 * System libraries
 */
import { useEffect } from "react";

/**
 * Storage
 */
import LocalStorage from "components/Storage/local";
import RemoteStorage from "components/Storage/firestore";

/**
 * Components
 */
import { getDocumentWithBlocks } from "components/Document/processor";

/**
 * Document controller (synchronizes document with RemoteStorage)
 * @params {Object} Props
 */
export default function DocumentController(props) {
  // console.log('DocumentController', props)

  /*
		Props
	*/

  const knowledgebaseId = props.knowledgebaseId;
  const documentId = props.documentId;
  const documentVersion = props.documentVersion;
  const documentDatetimeUpdated = props.documentDatetimeUpdated;
  const isLocalRead = props.localRead;

  /*
		Local read? Check RemoteStorage for changes.
		On change: knowledgebaseId, documentId, isLocalRead
	*/
  useEffect(() => {
    if (isLocalRead) {
      // console.log('DocumentController.localRead', knowledgebaseId, documentId)

      /*
				Re-cache the document
			*/
      getDocumentWithBlocks(
        knowledgebaseId,
        documentId,
        true, // force reading from Remote and writing to Local
        true // we do not need Json
      );
    }
  }, [knowledgebaseId, documentId, isLocalRead]);

  /*
		Firestore onSnapshot listener
		On change: knowledgebaseId, documentId, documentVersion, documentDatetimeUpdated, isLocalRead
	*/
  useEffect(() => {
    /*
		console.log(
			'DocumentController.FirestoreListener.Mount',
			knowledgebaseId,
			documentId,
			documentVersion,
			documentDatetimeUpdated, // numeric, timestamp
			isLocalRead
		)
		*/

    // Listener detach function
    let documentListenerDetach = null;

    /**
     * Firestore Snapshots listener (on the document changes)
     * @doc https://firebase.google.com/docs/firestore/query-data/listen#web
     * @param {string} Changed document id
     * @param {object} Changed document data
     */
    const remoteListener = async (changedDocumentId, changedDocumentData) => {
      /*
				Init LocalStorage
			*/

      LocalStorage.initDb(knowledgebaseId);

      /*
				Datetime updated processings
			*/

      let changedDatetimeUpdated = 0;
      if (changedDocumentData?.datetime_updated) {
        changedDatetimeUpdated = changedDocumentData.datetime_updated.getTime();
      }

      /*
			console.log(
				'DocumentController.remoteListener',
				changedDocumentId,
				changedDocumentData,
				changedDatetimeUpdated,
				global.processId
			)
			*/

      /*
				This is an older operation?
			
			if (changedDatetimeUpdated < documentDatetimeUpdated) {
				console.log(
					'DocumentController.remoteListener.versionLow(remote, local)',
					changedDatetimeUpdated,
					documentDatetimeUpdated
				)
				return
			}
			*/

      /*
				Get local versioning table
			*/

      const localVersion = await LocalStorage.wtDocumentVersionGet(
        changedDocumentId
      );
      // console.log('DocumentController.remoteListener.localVersion', localVersion)

      /*
				My Own save to Firestore
			*/

      if (
        localVersion &&
        changedDocumentData?._process_id &&
        changedDocumentData._process_id === global.processId
      ) {
        /*
				console.log(
					'DocumentController.remoteListener.ownChange (remote, local)',
					changedDatetimeUpdated,
					localVersion.datetime_updated.getTime(),
					changedDatetimeUpdated >= localVersion.datetime_updated.getTime()
				)
				*/

        // My own latest operation
        if (changedDatetimeUpdated >= localVersion.datetime_updated.getTime()) {
          // Update local document version and datetime data
          await LocalStorage.wtUpdate(changedDocumentId, {
            _version: changedDocumentData?._version
              ? changedDocumentData._version
              : 0,
            _process_id: changedDocumentData._process_id,
            datetime_updated: changedDocumentData.datetime_updated,
          });
        }
        /* // How could that be that my own operation is in future?
				} else if (changedDatetimeUpdated > localVersion.datetime_updated.getTime()) {
					console.error(
						'DocumentController.remoteListener.futureChange.',
						changedDocumentId,
						changedDocumentData,
						changedDatetimeUpdated,
						localVersion
					)
				} */

        // We should do nothing for own updates
        return;
      }

      /*
				Get the local version of the document
			*/

      const localDocument = await LocalStorage.wtGet(changedDocumentId);

      /*
				Datetime updated processing
			*/

      let localDatetimeUpdated = 0;
      if (localDocument?.datetime_updated) {
        localDatetimeUpdated = localDocument.datetime_updated.getTime();
      }

      /*
				This Snapshot is too old
			*/

      if (changedDatetimeUpdated <= localDatetimeUpdated) {
        /*
				console.log(
					'DocumentController.remoteListener.lowerDatetimeUpdated',
					changedDatetimeUpdated,
					localDatetimeUpdated
				)
				*/

        // We should do nothing
        return;
      }

      /*
			// Firestore reports version which is LESS than we have in version storage
			if (changedDocumentData._version < localVersion.version) {
				console.error('DocumentController.remoteListener.unexpectedLowerVersion', changedDocumentData, localVersion)
				// We should do nothing
				return
			}
			*/

      /*
				Reported version is newer by update datetime?
			

			// DEBUG
			console.log(
				'DocumentController.remoteListener.dateComparisonDoc',
				changedDatetimeUpdated, localDatetimeUpdated,
				changedDatetimeUpdated === localDatetimeUpdated
			)

			if (
				// Firestore version equals Local version of this document
				// changedDocumentData._version === localVersion.version
				// Change datetime is the same
				changedDatetimeUpdated === localDatetimeUpdated
				// AND Firestore process equals Local document process
				&& changedDocumentData?._process_id
				&& localDocument?._process_id
				&& changedDocumentData._process_id === localDocument._process_id
			) {
				console.log('DocumentController.remoteListener.versionLower', changedDocumentData, localVersion, localDocument)
				// We should do nothing
				return
			}
			*/

      /*
				Re-cache the document
			*/

      // Re-read the document data
      const newDocumentData = await getDocumentWithBlocks(
        knowledgebaseId,
        changedDocumentId,
        true, // force reading from Remote and writing to Local
        true // we do not need Json
      );
      // console.log('DocumentController.remoteListener.reCache', newDocumentData)

      // console.log('DocumentController.remoteListener.Complete')
    };

    // Listen to the document changes
    documentListenerDetach = RemoteStorage.wtDocumentListen(
      knowledgebaseId,
      documentId,
      remoteListener
    );

    /*
			Component unmounts?
		*/
    return () => {
      // console.log('DocumentController.Listener.Unmount', knowledgebaseId, documentId, documentVersion, isLocalRead)

      // Set new state to prevent the async processing from finishing
      // isUnmounted = true

      // Firestore. Detach from listening the DocumentController changes
      if (documentListenerDetach !== null) {
        // console.log('DocumentController.Listener.Detach', documentId)
        documentListenerDetach();
        documentListenerDetach = null;
      }
    };
  }, [
    knowledgebaseId,
    documentId,
    documentVersion,
    documentDatetimeUpdated,
    isLocalRead,
  ]);

  /*
		Events listener: focus, online. Check for the document change.
		ON change: knowledgebaseId, documentId
	*/
  useEffect(() => {
    /* console.log(
			'DocumentController.eventsListener.Mount',
			knowledgebaseId,
			documentId
		) */

    /*
			Re-cache the document
		*/
    const syncDocument = async (event) => {
      // console.log('DocumentController.eventsListener.syncDocument', knowledgebaseId, documentId, event?.type)

      getDocumentWithBlocks(
        knowledgebaseId,
        documentId,
        true, // force reading from Remote and writing to Local
        true // we do not need Json
      );
    };

    /*
			Add listeners
		*/

    // Focus
    window.addEventListener("focus", syncDocument);

    // Online
    window.addEventListener("online", syncDocument);

    /*
			Component unmounts?
		*/
    return () => {
      // console.log('DocumentController.eventsListener.Unmount', knowledgebaseId, documentId)

      /*
				Remove listeners
			*/

      // Focus
      window.removeEventListener("focus", syncDocument);

      // Online
      window.removeEventListener("online", syncDocument);
    };
  }, [knowledgebaseId, documentId]);

  // We do not need to render anything
  return null;
}
