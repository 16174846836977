/**
 * OL element
 */
export const orderedListSpec = {
  group: "block",
  content: "list_item+",

  /*
		Element attributes
	*/
  attrs: {
    // Number at which the list starts counting
    order: { default: 1 },
    // Unique block id (used for saving the elements)
    blockid: { default: "" },
    // Is the block togglable and open by default?
    // open: { default: true },
  },
  /*
		Parse element on Copy/Paste operation
	*/
  parseDOM: [
    {
      tag: "ol",
      getAttrs(dom) {
        return {
          // Get the block ID
          blockid: dom.getAttribute("data-blockid"),
          // Is the element open? Always open!
          // open: true, // dom.getAttribute('data-open') === 'true'
          // Which order does this element has?
          order: dom.hasAttribute("start") ? +dom.getAttribute("start") : 1,
        };
      },
    },
  ],
  /*
		Rules of creating element in DOM
	*/
  toDOM(node) {
    return [
      "div",
      {
        class: "wotodo-list drag-n-drop-parent-node",
        "data-blockid": node.attrs.blockid,
        // 'data-open': node.attrs.open.toString(),
      },
      node.attrs.order === 1
        ? [
            "ol",
            {
              // 'data-blockid': node.attrs.blockid
            },
            0,
          ]
        : [
            "ol",
            {
              // 'data-blockid': node.attrs.blockid,
              start: node.attrs.order,
            },
            0,
          ],
    ];
  },
};

/**
 * UL element
 */
export const bulletListSpec = {
  group: "block",
  content: "list_item+",

  /*
		Element attributes
	*/
  attrs: {
    // Unique block id (used for saving the elements)
    blockid: { default: "" },
    // Is the block togglable and open by default?
    // open: { default: true },
  },
  /*
		Parse element on Copy/Paste operation
	*/
  parseDOM: [
    {
      tag: "ul",
      getAttrs(dom) {
        return {
          // Get the block ID
          blockid: dom.getAttribute("data-blockid"),
          // Is the element open? Always open!
          // open: true, // dom.getAttribute('data-open') === 'true'
        };
      },
    },
  ],
  /*
		Rules of creating element in DOM
	*/
  toDOM(node) {
    // console.log('UL', node)
    /* const arrowImg = document.createElement('img')
		arrowImg.setAttribute('src', '/images/icons/arrow-gray-right.svg')
		arrowImg.setAttribute('alt', 'Toggle block')
		arrowImg.setAttribute('title', 'Toggle block') */

    return [
      "div",
      {
        class: "wotodo-list drag-n-drop-parent-node",
        "data-blockid": node.attrs.blockid,
        // 'data-open': node.attrs.open.toString(),
      },
      [
        "ul",
        {
          // class: 'toggle-wrapper'
        },
        0,
      ],
    ];
  },
};

/**
 * LI element
 */
export const listItemSpec = {
  content: "(paragraph|heading) block*",

  /*
		Element attributes
	*/
  attrs: {
    // Is the block togglable and open by default?
    open: { default: true },
    // Has some children
    children: { default: false },
  },

  /*
		Parse element on Copy/Paste operation
	*/
  parseDOM: [
    {
      tag: "li",
      getAttrs(dom) {
        return {
          // Is the element open? Always open!
          open: true, // dom.getAttribute('data-open') === 'true'
          // Is the element marked as done?
          children: dom.getAttribute("data-children") === "true",
        };
      },
    },
  ],

  /*
		Rules of creating element in DOM
	*/
  toDOM(node) {
    // console.log('LI', node)

    /* // Has children?
		if (node.childCount > 1) {
			return [
				"li",
				..
			]

		} else {
			return [
				"li",
				0
			]
		} */
    return [
      "li",
      {
        "data-open": node.attrs.open.toString(),
        "data-children": node.attrs.children.toString(),
        class: "drag-n-drop-parent-node",
      },
      [
        "span",
        {
          class: "list-item-toggle",
          contenteditable: "false",
        },
      ],
      [
        "div",
        {
          class: "list-item-content",
        },
        0,
      ],
    ];
  },
  defining: true,
};
