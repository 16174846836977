/**
 * This is a service to keep LocalStorage synced with RemoteStorage.
 * This is important: for read speed, for Search logics.
 */

/**
 * System libraries
 */
import { useEffect } from "react";

/**
 * Storage
 */
import LocalStorage from "components/Storage/local";
import RemoteStorage from "components/Storage/firestore";

/**
 * Full sync RemoteStorage -> LocalStorage.
 * Periodical re-sync RemoteStorage -> LocalStorage.
 * @param {Object} Props
 */
export default function RemoteSync(props) {
  // console.log('RemoteSync', props)

  /*
		Props
	*/

  const knowledgebaseId = props.knowledgebaseId;

  /*
		Run the RemoteSync logics.
		On change: knowledgebaseId
	*/
  useEffect(() => {
    // console.log('RemoteSync.Mount', knowledgebaseId)

    /*
			Variables
		*/

    // Full sync done?
    let fullSyncDone = false;

    // Sync timer
    let syncProcessorTimer = null;

    // Firestore onSnapshots function to unsubscribe from new events
    let pagesListenerUnsubscribe = null;

    /*
			Initialization
		*/

    // LocalStorage
    LocalStorage.initDb(knowledgebaseId);

    /*
			Run the sync!
		*/

    const syncProcessor = async (event) => {
      // console.log('RemoteSync.syncProcessor', (event?.type ? event.type : 'unset'))

      /*
				Clear the timer (if any)
			*/

      // Do we need to clear the timer?
      if (syncProcessorTimer !== null) {
        // console.log('RemoteSync.syncProcessor.clearTimeout')

        clearTimeout(syncProcessorTimer);
        syncProcessorTimer = null;
      }

      /*
				We are not in focus
			*/
      if (!document.hasFocus()) {
        // console.log('RemoteSync.syncProcessor.notInFocus')
        // return
        /*
				We are offline
			*/
      } else if (!navigator.onLine) {
        // console.log('RemoteSync.syncProcessor.offline')
        // return
        /*
				Process!
			*/
      } else {
        /*
					Full database synchronization (pages + blocks)
				*/

        if (!fullSyncDone) {
          // console.log('RemoteSync.syncProcessor.fullSyncRequired')

          // Set a flag
          fullSyncDone = true;

          // Do the full sync
          await fullSync(knowledgebaseId);
        }

        /*
					Pages only sync
				*/

        // Do we have a listener?
        if (pagesListenerUnsubscribe !== null) {
          // console.log('RemoteSync.syncProcessor.pagesListenerUnsubscribe')

          pagesListenerUnsubscribe();
          pagesListenerUnsubscribe = null;
        }

        // Process page sync and create a new listener
        pagesListenerUnsubscribe = await pagesSync(knowledgebaseId);
      }

      /*
				Set a timer for next pages sync
			*/

      // console.log('RemoteSync.syncProcessorTimer')

      // Set the new timer
      syncProcessorTimer = setTimeout(async () => {
        syncProcessor({ type: "timer" });
      }, 30000); // once in 30 seconds
    };
    syncProcessor({ type: "init" });

    /*
			Events listeners
		*/

    window.addEventListener("focus", syncProcessor);
    window.addEventListener("online", syncProcessor);

    /*
			Component unmounts?
		*/
    return () => {
      console.log("RemoteSync.Unmount");

      /*
				Remove events listeners
			*/

      window.removeEventListener("focus", syncProcessor);
      window.removeEventListener("online", syncProcessor);

      /*
				Clear timers
			*/

      // Do we need to clear the timer?
      if (syncProcessorTimer !== null) {
        console.log("RemoteSync.Unmount.syncProcessorTimer.clearTimeout");

        clearTimeout(syncProcessorTimer);
        syncProcessorTimer = null;
      }

      /*
				Firestore listeners
			*/

      if (pagesListenerUnsubscribe !== null) {
        console.log("RemoteSync.Unmount.pagesListenerUnsubscribe");

        pagesListenerUnsubscribe();
        pagesListenerUnsubscribe = null;
      }
    };
  }, [knowledgebaseId]);

  // We do not need to render anything
  return null;
}

/**
 * Full database synchronization
 * @param {String} knowledgebaseId
 */
async function fullSync(knowledgebaseId) {
  // console.log('RemoteSync.fullSync', knowledgebaseId)

  /*
		Settings and variables
	*/

  const nowDate = new Date();
  // const minSyncTime = (nowDate.getTime() - (24 * 60 * 60000))

  /*
		Check the stored last sync time, contains Javascript Date()
	*/

  const lastSync = await LocalStorage.wtAppConfGet("last_sync");
  // console.log('RemoteSync.fullSync.lastSync', lastSync)

  /*
		Not yet required?
	*/

  if (
    lastSync
    // && lastSync.getTime() > minSyncTime
  ) {
    // console.log('RemoteSync.fullSync.tooEarly', lastSync.getTime(), '>', minSyncTime)
    // console.log('RemoteSync.fullSync.already')
    return;
  }

  // console.log('RemoteSync.fullSync.do')

  /*
		Let's run the Full sync!
	*/

  // Save last run datetime
  await LocalStorage.wtAppConfSet("last_sync", nowDate);

  /*
		Get all the documents!
	*/

  const allDocs = await RemoteStorage.wtGetAllDocs(knowledgebaseId);
  // console.log('RemoteSync.fullSync.allDocs', allDocs)

  /*
		Store all docs in the LocalStorage
	*/

  for (const doc of allDocs) {
    // It is a Page
    if (doc?.is_page) {
      /*
			THIS TRIGGERS TypeError: Cannot set property 'onerror' of undefined
			I do not know why..
			// Write with a check
			await LocalStorage.wtWriteDoc(
				doc.id,
				(doc?._version ? doc._version : 0),
				(doc?.datetime_updated ? doc.datetime_updated : false),
				doc
			)
			*/

      // Simple doc replace
      LocalStorage.wtWrite(doc);

      // It is a Block
    } else if (doc?.is_block) {
      // Write without check
      LocalStorage.wtWrite(doc);
    }
  }

  // console.log('RemoteSync.fullSync.done')
}

/**
 * Synchronize pages (is_page = true)
 * @param {String} knowledgebaseId
 * @return {Function} Firestore listener unsubscribe
 */
async function pagesSync(knowledgebaseId) {
  // console.log('RemoteSync.pagesSync', knowledgebaseId)

  /*
		When was the last pages sync?
		Check the stored last sync time.
		Javascript Date() object.
	*/

  let lastPagesSync = await LocalStorage.wtAppConfGet("last_pages_sync");
  // console.log('RemoteSync.pagesSync.lastPagesSync', lastPagesSync)

  /*
		Update sync
	*/

  let syncPages = [];

  // No pages sync at all yet?
  if (!lastPagesSync) {
    // console.log('RemoteSync.pagesSync.fullSync')

    // Get all pages from Firestore
    syncPages = await RemoteStorage.wtGetAllPages(knowledgebaseId);

    // Pages were synced earlier
  } else {
    // console.log('RemoteSync.pagesSync.updateSync', lastPagesSync)

    // Get new/changed datetime_title_updated pages from Firestore
    syncPages = await RemoteStorage.wtGetPagesWithTitleUpdate(
      knowledgebaseId,
      lastPagesSync
    );
  }

  // console.log('RemoteSync.pagesSync.syncPages', syncPages)

  // Got pages?
  if (syncPages && syncPages.length > 0) {
    // Process pages and return new know Last update Date() object
    lastPagesSync = await docsStore(knowledgebaseId, syncPages, lastPagesSync);
    // console.log('RemoteSync.pagesSync.processedLastPagesSync', lastPagesSync)
  }

  /*
		Save the last pages sync date
	*/

  // Is it still null? Let's set current point as the start
  if (lastPagesSync === false) lastPagesSync = new Date();

  // console.log('RemoteSync.pagesSync.updateLastPagesSync', lastPagesSync)

  await LocalStorage.wtAppConfSetTransaction("last_pages_sync", lastPagesSync);

  /*
		Backwards sync compatibility update.
		REMOVE AFTER 12/2020
		This will help stay in sync with old devices,
		which will create/update documents
		and not set datetime_title_updated I use for after-syncs
	*/

  let lastSyncCompat = await LocalStorage.wtAppConfGet("last_sync_compat");
  if (!lastSyncCompat) {
    lastSyncCompat = new Date();
    lastSyncCompat.setDate(lastSyncCompat.getDate() - 1);
  }
  // console.log('RemoteSync.pagesSync.lastSyncCompat', lastSyncCompat)

  syncPages = [];

  // Get all new/changed pages Firestore
  syncPages = await RemoteStorage.wtGetPagesWithUpdated(
    knowledgebaseId,
    lastSyncCompat
  );

  // console.log('RemoteSync.backwardsSync.syncPages', syncPages)

  // Got pages?
  if (syncPages && syncPages.length > 0) {
    // Process!
    lastSyncCompat = await docsStore(
      knowledgebaseId,
      syncPages,
      lastSyncCompat,
      true
    );
    // console.log('RemoteSync.backwardsSync.processedlastSyncCompat', lastSyncCompat)
  }

  /*
		Save the last pages sync date
	*/

  // Is it still null? Let's set current point as the start
  if (lastSyncCompat === false) lastSyncCompat = new Date();

  // console.log('RemoteSync.pagesSync.updateLastSyncCompat', lastSyncCompat)

  await LocalStorage.wtAppConfSetTransaction(
    "last_sync_compat",
    lastSyncCompat
  );

  /*
		Firestore collection listener
	*/

  const pagesListenerUnsubscribe = RemoteStorage.wtTitleUpdatesListen(
    knowledgebaseId,
    lastPagesSync,
    async (knowledgebaseId, syncPages, lastPagesSync) => {
      console.log(
        "RemoteSync.pagesListener",
        knowledgebaseId,
        syncPages,
        lastPagesSync
      );

      // Store docs
      const newPagesSync = await docsStore(
        knowledgebaseId,
        syncPages,
        lastPagesSync
      );

      console.log("RemoteSync.pagesListener.newPagesSync", newPagesSync);

      // Update last sync time
      if (newPagesSync) {
        await LocalStorage.wtAppConfSetTransaction(
          "last_pages_sync",
          newPagesSync
        );
      }
    }
  );

  // console.log('RemoteSync.pagesSync.done')

  // Return Firestore Unsubscribe function
  return pagesListenerUnsubscribe;
}

/**
 * Process all the pages we've got
 * @param {String} knowledgebaseId
 * @param {Array} Pages to process
 * @param {Object} Last known update date, Javascript Date() object
 * @param {Boolean} Compat mode? Skipping the document_title_updated?
 * @returns {Object} New known update date, Javascript Date() object
 */
async function docsStore(
  knowledgebaseId,
  syncPages,
  lastPagesSync,
  compatMode = false
) {
  // console.log('RemoteSync.docsStore', knowledgebaseId, syncPages, lastPagesSync)

  // For each document
  for (const currentPage of syncPages) {
    // console.log('RemoteSync.docsStore.currentPage', currentPage)

    /*
			Known update date of the document.
			Javascript Date() object.
		*/

    let documentUpdateDate = null;

    // Got datetime_title_updated
    if (currentPage?.datetime_title_updated && !compatMode) {
      documentUpdateDate = currentPage.datetime_title_updated;

      // Got datetime_updated
    } else if (currentPage?.datetime_updated) {
      documentUpdateDate = currentPage.datetime_updated;

      // Got datetime_created
    } else if (currentPage?.datetime_created) {
      documentUpdateDate = currentPage.datetime_created;
    }

    // console.log('RemoteSync.docsStore.documentUpdateDate', documentUpdateDate)

    /*
			Update last known date updated param
		*/

    if (
      documentUpdateDate !== null &&
      (lastPagesSync === false ||
        documentUpdateDate.getTime() > lastPagesSync.getTime())
    ) {
      lastPagesSync = documentUpdateDate;

      // console.log('RemoteSync.pagesSync.newLastPagesSync', lastPagesSync)
    }

    /*
			Missing datetime_title_updated field in Firebase document?
		*/

    if (documentUpdateDate !== null && !currentPage?.datetime_title_updated) {
      // console.log('RemoteSync.docsStore.updateTitleUpdated', currentPage)

      // Save it!
      await RemoteStorage.wtUpdateDocument(knowledgebaseId, currentPage.id, {
        datetime_title_updated: documentUpdateDate,
      });
    }

    /*
			Skip own document updates
		*/

    if (
      currentPage?._process_id &&
      currentPage._process_id === global.processId
    ) {
      // console.log('RemoteSync.docsStore.ownChange', currentPage._process_id, global.processId)

      // Only update the datetime_title_updated
      if (currentPage?.datetime_title_updated) {
        // console.log('RemoteSync.docsStore.updateTitleDate', currentPage?.datetime_title_updated)

        // Update local document
        await LocalStorage.wtWriteTitleUpdate(currentPage.id, {
          datetime_title_updated: currentPage.datetime_title_updated,
        });
      }

      continue;
    }

    /*
			Write the doc
		*/

    /*
		// New data to save
		const newDocData = { 'title': currentPage.title }
		if (currentPage?.datetime_title_updated) {
			newDocData.datetime_title_updated = currentPage?.datetime_title_updated
		}
		*/

    // await LocalStorage.wtWrite(currentPage)
    /* await LocalStorage.wtWriteTitleUpdate(
			currentPage.id,
			newDocData
		) */

    await LocalStorage.wtWriteTitleSave(currentPage);
  }

  // Return new last known title update
  return lastPagesSync;
}
