/**
 * System libraries
 */
import React, { useContext } from "react";

/**
 * Bootstrap
 */
// import Button from 'react-bootstrap/Button';

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Sign out button
 */
export default function FirebaseSignOut() {
  // Get the User context
  const { logOut } = useContext(UserContext); // userData, userSaved,

  // console.log('Page.Firebase.SignOut'); // , userData, userSaved, logOut

  /* <button onClick={() => firebase.auth().signOut()}>Sign-out</button> */
  /* <span role="img" aria-label="Sign out">🚶</span> */
  /* <button className="btn btn-sm btn-link text-black" onClick={() => logOut()}>{ Icons.signOut() }</button> */
  return (
    <button className="btn btn-sm btn-link" onClick={() => logOut()}>
      Sign Out
    </button>
  );
}
