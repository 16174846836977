/**
 * System libraries
 */
import React, { createRef, useEffect, useState, useRef } from "react";

/**
 * Check if an element is in viewport
 * @param {boolean} Hidden by default
 * @param {number} offset - Number of pixels up to the observable element from the top
 * @return {object} Visibility boolean, Element Ref
 */
export const useVisibility = (hidden = false, offset = 0) => {
  /*
		Set up
	*/

  // Visibility state
  const [isVisible, setIsVisible] = useState(hidden);

  // ELement Ref
  const currentElement = createRef();

  /**
   * Scroll event processor
   */
  const onScroll = () => {
    // No element
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }

    // Calculate position
    const top = currentElement.current.getBoundingClientRect().top;
    // console.log('useVisibility.currentElement', currentElement.current, top)

    // Is it on screen?
    const visible = top + offset >= 0 && top - offset <= window.innerHeight;
    // console.log('useVisibility.onScroll', top, visible, currentElement.current)

    // New visibility state
    setIsVisible(visible);
  };

  /**
   * On component mount
   */
  useEffect(() => {
    // Listen to Scroll
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  // Return the element's Ref and it's visibility state
  return [isVisible, currentElement];
};

/**
 * Set a Focus for the element
 */
export const useFocus = () => {
  // Element Ref
  const htmlElRef = useRef(null);

  // Current element Focus function
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  // Return element's Ref and FOcus function for it
  return [htmlElRef, setFocus];
};

/**
 * Return debounced value
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

/**
 * Text highlighter
 * @param {String} Text to do the highlight in
 * @param {String} Highlight part
 * @return {Object} React component
 */
export const highlightText = (text, highlight) => {
  // Split text on highlight term, include term itself into parts, ignore case
  highlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  // Return all parts combined with highlight wrapped in U tag
  return (
    <>
      {parts.map((part, index) => {
        return (
          <span key={index}>
            {part.toLowerCase() === highlight.toLowerCase() ? (
              <u>{part}</u>
            ) : (
              part
            )}
          </span>
        );
      })}
    </>
  );
};

/**
 * Return array, which filtered our notes
 * by date or title
 */
export const filterList = (field, arr, direction) => {
  const copy = [...arr];

  if (field === "datetime_created") {
    if (direction === "asc") {
      return copy.sort((a, b) => a.datetime_created - b.datetime_created);
    }
    return copy.sort((a, b) => b.datetime_created - a.datetime_created);
  }

  const mod = direction === "asc" ? 1 : -1;
  return copy.sort(function (a, b) {
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1 * mod;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1 * mod;
    return 0;
  });
};

/**
 * Return filtered array, if entrance array includes
 * entrance param "value"
 */
export const searchTerm = (arr, value, key) => {
  return arr.filter((item) => {
    return item[key].indexOf(value) > -1;
  });
};
