/**
 * System libraries
 */

import React, { useContext } from "react"; // useState, useRef,

import { useHistory } from "react-router-dom";

/**
 * Modules
 */
import { createDocument } from "components/Document/processor";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Bootstrap
 */
// import { Button, Modal, Form } from 'react-bootstrap'

/**
 * Create new document (button, modal, logics)
 */
export function NavButtonCreateDocument(props) {
  // Redirect to the page after creation
  let history = useHistory();

  // Get the User context
  const { userData } = useContext(UserContext);
  // console.log('Page.BootstrapCreateDocument', userData);

  // Page loader Toggle function
  const togglePageLoaded = props.togglePageLoaded;

  /*
	// Form validation
	const formRef = useRef(null)
	const [validated, setValidated] = useState(false)

	// Modal state
	const [show, setShow] = useState(false)
	
	// Modal state processing
	const handleClose = () => {
		// Reset the field
		setDocumentTitle('')
		
		// Reset the forms validation status
		setValidated(false)
		
		// Hide the modal
		setShow(false)
	}
	const handleShow = () => setShow(true)

	// Input state
	const [documentTitle, setDocumentTitle] = useState('');

	// Form processing
	const handleSubmit = (event) => {
		console.log('BootstrapCreateDocument.handleSubmit', documentTitle)
		
		// Prevent from processing
		event.preventDefault()
		event.stopPropagation()

		// Validate the form
		const form = formRef.current

		// Form is invalid
		if (form.checkValidity() === false) {
			console.log('BootstrapCreateDocument.handleSubmit.Invalid')

			// We've finished with checking the form and we can show the validation state
			setValidated(true)

		// Form is valid
		} else {
			console.log('BootstrapCreateDocument.handleSubmit.Valid')

			// We've finished with checking the form and we can show the validation state
			setValidated(true)

			// Create document at Firestore
			const createAsync = async () => {
				// console.log('BootstrapCreateDocument.createAsync', documentTitle)

				// Create the new Document
				const documentId = await createDocument(
					userData.knowledgebaseId, // in exact Knowledgebase
					documentTitle // with specified Title
				)
				
				// Success
				if (documentId) {
					console.log('BootstrapCreateDocument.handleSubmit.success')

					// Close modal
					handleClose()

					// Redirect to the new document
					// RouterDocument(documentId)

					history.push(`/document/${documentId}`)
					// window.location.href = '/document/' + documentId

					// Error
				} else {
					console.log('BootstrapCreateDocument.handleSubmit.error')
				}
			}
			createAsync()
		}
	}
	*/

  // Page creation
  const handleCreate = () => {
    // Show the Page loader
    togglePageLoaded(false);

    // The document title is empty by default
    const documentTitle = "";

    console.log("BootstrapCreateDocument.handleCreate");

    // Create document at Firestore
    const createAsync = async () => {
      // console.log('BootstrapCreateDocument.createAsync', documentTitle)

      // Create the new Document
      const documentId = await createDocument(
        userData.knowledgebaseId, // in exact Knowledgebase
        documentTitle // with specified Title
      );

      // Success
      if (documentId) {
        console.log("BootstrapCreateDocument.handleCreate.success", documentId);

        history.push(`/document/${documentId}`);

        // Error
      } else {
        console.log("BootstrapCreateDocument.handleCreate.error");
      }
    };

    // Run create function
    createAsync();
  };

  /**
   * Button
   */
  return (
    <>
      {/*
			<Button style={{marginLeft: '20px'}} variant="primary" onClick={ handleShow }>
				<span role="img" aria-label="Create a page">📝 New page</span>
			</Button>
			*/}

      <button
        type="button"
        className="btn-link wotodo-header__button"
        onClick={handleCreate /* handleShow */}
      >
        <img
          src="/images/icons/topmenu_newpage.svg"
          className="sidebar-icon-newpage"
          title="Create a page"
          alt="Create a page"
        />
      </button>

      {/*
			<Modal show={ show } onHide={ handleClose }>
				<Modal.Header closeButton>
					<Modal.Title>Create a new page</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						ref={formRef}
						noValidate
						validated={validated}
						onSubmit={handleSubmit}
					>
						<Form.Group controlId="validationDocumentTitle">
							<Form.Control
								type="text"
								name="documentTitle"
								placeholder="Page Title"
								value={ documentTitle }
								onChange={ e => setDocumentTitle(e.target.value) }
								required
								/ * minLength="2" * /
							/>
							<Form.Control.Feedback type="invalid" tooltip>
								Please provide a valid Page Title
							</Form.Control.Feedback>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={ handleClose }>
						Cancel
					</Button>
					<Button variant="primary" onClick={ handleSubmit }>
						Create
					</Button>
				</Modal.Footer>
			</Modal >
			*/}
    </>
  );
}

/**
 * Create new document (button, modal, logics)
 * /
export const bootstrapCreateDocument = () => {
	// Redirect to the page after creation
	let history = useHistory()

	// Get the User context
    const { userData } = useContext(UserContext)
	// console.log('Page.BootstrapCreateDocument', userData);

	// Form validation
	const formRef = useRef(null)
	const [validated, setValidated] = useState(false)

	// Modal state
	const [show, setShow] = useState(false)

	// Modal state processing
	const handleClose = () => {
		// Reset the field
		setDocumentTitle('')
		
		// Reset the forms validation status
		setValidated(false)
		
		// Hide the modal
		setShow(false)
	}
	const handleShow = () => setShow(true)

	// Input state
	const [documentTitle, setDocumentTitle] = useState('');

	// Form processing
	const handleCreate = (event) => {
		console.log('BootstrapCreateDocument.handleCreate', documentTitle)
		
		// Prevent from processing
		event.preventDefault()
		event.stopPropagation()

		// Validate the form
		const form = formRef.current

		// Form is invalid
		if (form.checkValidity() === false) {
			console.log('BootstrapCreateDocument.handleCreate.Invalid')

			// We've finished with checking the form and we can show the validation state
			setValidated(true)

		// Form is valid
		} else {
			console.log('BootstrapCreateDocument.handleCreate.Valid')

			// We've finished with checking the form and we can show the validation state
			setValidated(true)

			// Create document at Firestore
			const createAsync = async () => {
				// console.log('BootstrapCreateDocument.createAsync', documentTitle)

				// Create the new Document
				const documentId = await createDocument(
					userData.knowledgebaseId, // in exact Knowledgebase
					documentTitle // with specified Title
				)
				
				// Success
				if (documentId) {
					console.log('BootstrapCreateDocument.handleCreate.success')

					// Close modal
					handleClose()

					// Redirect to the new document
					// RouterDocument(documentId)

					history.push(`/document/${documentId}`)
					// window.location.href = '/document/' + documentId

					// Error
				} else {
					console.log('BootstrapCreateDocument.handleCreate.error')
				}
			}
			createAsync()
		}
	}

	/**
	 * Button and modal
	 * /
	return (
		<>
			<Button style={{marginLeft: '20px'}} variant="primary" onClick={ handleShow }>
				<span role="img" aria-label="Create a page">📝 New page</span>
			</Button>
			
			<Modal show={ show } onHide={ handleClose }>
				<Modal.Header closeButton>
					<Modal.Title>Create a new page</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						ref={formRef}
						noValidate
						validated={validated}
						onSubmit={handleCreate}
					>
						<Form.Group controlId="validationDocumentTitle">
							<Form.Control
								type="text"
								name="documentTitle"
								placeholder="Page Title"
								value={ documentTitle }
								onChange={ e => setDocumentTitle(e.target.value) }
								required
								/* minLength="2" * /
							/>
							<Form.Control.Feedback type="invalid" tooltip>
								Please provide a valid Page Title
							</Form.Control.Feedback>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={ handleClose }>
						Cancel
					</Button>
					<Button variant="primary" onClick={ handleCreate }>
						Create
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
} */

/**
 * Bootstrap create page logics (button, modal, form)
 * /
export function BootstrapCreatePage() {
	// Form validation rules
	const formSchema = yup.object({
		pageTitle: yup.string().required().min(2),
	});

	// Modal state
	const [show, setShow] = useState(false)

	// Modal state processing
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	// Input state
	const [input, setInput] = useState('');

	// Form processing
	const handleCreate = async (event) => {
		// Prevent from processing
		event.preventDefault();

		console.log('BootstrapCreatePage.handleCreate')

		// validate input
		if (!input) {
			return false
		}

		// Create page at Firestore
		const pageCreated = await FirestoreCreatePage(input)

		// Success
		if (pageCreated) {
			console.log('BootstrapCreatePage.handleCreate.success')

			// Close modal
			handleClose()

			// Reset the field
			setInput('')

			// Redirect to the new page
			// ..

		// Error
		} else {
			console.log('BootstrapCreatePage.handleCreate.error')
		}

		return false
	}

	return (
		<Formik
			validationSchema={ formSchema }
			onSubmit={console.log}
			initialValues={{
				pageTitle: ''
			}}
		>
			{({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors,
			}) => (
				<>
					<Button variant="primary" onClick={ handleShow }>
						<span role="img" aria-label="Create a page">📝 New page</span>
					</Button>
					
					<Modal show={ show } onHide={ handleClose }>
						<Modal.Header closeButton>
							<Modal.Title>Create a new page</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form noValidate onSubmit={ handleSubmit }>
								<Form.Group controlId="validationFormikPageTitle">
									<Form.Label>pageTitle</Form.Label>
									<Form.Control
										type="text"
										name="pageTitle"
										placeholder="Page Title"
										value={ values.pageTitle }
										onChange={ handleChange }
										isValid={!errors}
										className={isValid ? 'form-control is-invalid' : 'form-control'}
										/>
									<Form.Control.Feedback type="invalid" tooltip>
										{ errors.pageTitle }
									</Form.Control.Feedback>
								</Form.Group>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={ handleClose }>
								Cancel
							</Button>
							<Button variant="primary" onClick={ handleSubmit }>
								Create
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</Formik>
	)
} */
