/**
 * System libraries
 */
import React, { useEffect } from "react";

/**
 * Simple App analytics.
 * Count app loads, seconds used.
 * Send to Wotodo server
 */
export default function AnalyticsWotodo(props) {
  /*
		Props
	*/

  const userId = props.userId;

  /*
		Inits
	*/

  // Did we send the event?
  const isLoadedReported = React.useRef(false);

  /*
		Initilize Analytics tracker
		On change: userId
	*/
  useEffect(() => {
    const _debug = false;

    if (_debug) console.log("AnalyticsWotodo");

    // Flag for the Unmount (to check againts after async operations are finished)
    // let isUnmounted = false

    /**
     * On App focus
     */
    const onAppFocus = () => {
      if (_debug) console.log("AnalyticsWotodo.onAppFocus");

      // Already sent?
      if (isLoadedReported.current === true) {
        if (_debug) console.log("AnalyticsWotodo.onAppFocus.Already");
        return;
      }

      // Not in focus?
      if (!document.hasFocus()) {
        if (_debug) console.log("AnalyticsWotodo.onAppFocus.notInFocus");
        return;
      }

      // Sent
      isLoadedReported.current = true;

      // Additional data
      const analyticsData = {};
      if (window.location !== window.parent.location)
        analyticsData.extension = true;

      // Analytics
      analyticsWotodoSend(userId, "loaded", analyticsData);

      if (_debug) console.log("AnalyticsWotodo.onAppFocus.isLoadedReported");
    };

    /**
     * App activity resets
     */
    let appActiveSeconds = 0;
    let appActiveIs = false;
    let appActiveStopTimer = null;
    let appActiveLogTimer = null;
    let appActiveCounterTimer = null;

    /**
     * On App active
     */
    const onAppActive = () => {
      // We are active
      if (appActiveIs === false) {
        if (_debug) console.log("AnalyticsWotodo.onAppActive.Seconds.Start");
        appActiveIs = true;
      }

      /*
				Inactivity timer
			*/

      // Clear timeout if any
      if (appActiveStopTimer !== null) clearTimeout(appActiveStopTimer);

      // Set new inactivity timer
      appActiveStopTimer = setTimeout(() => {
        if (_debug) console.log("AnalyticsWotodo.onAppActive.Seconds.Stop");
        appActiveIs = false;
      }, 1000);

      /*
				Log timer
			*/

      // Clear timeout if any
      if (appActiveLogTimer !== null) clearTimeout(appActiveLogTimer);

      // Set new inactivity timer
      appActiveLogTimer = setTimeout(() => {
        if (_debug)
          console.log(
            "AnalyticsWotodo.onAppActive.Seconds.Log",
            appActiveSeconds
          );

        // Invalid?
        if (appActiveSeconds <= 0) return;

        // Analytics
        analyticsWotodoSend(userId, "active", { seconds: appActiveSeconds });

        // Clear the seconds
        appActiveSeconds = 0;
      }, 5000);
    };

    /**
     * Start App activity timer
     */
    appActiveCounterTimer = setInterval(() => {
      // Active?
      if (appActiveIs) {
        appActiveSeconds++;
        if (_debug)
          console.log(
            "AnalyticsWotodo.appActiveCounterTimer.Seconds",
            appActiveSeconds
          );
      }
    }, 1000);

    /**
     *  Start App Loaded and in Focus timer
     */
    let loadedTimeout = setTimeout(() => {
      if (_debug) console.log("AnalyticsWotodo.loadedTimeout");
      onAppFocus();
    }, 3000);

    /*
			Focus listener
		*/

    // Listen for focus changes
    window.addEventListener("focus", onAppFocus);

    /*
			Activity listeners
		*/

    // Listen for keydown
    window.addEventListener("keydown", onAppActive);

    // Listen for mousedown
    window.addEventListener("mousedown", onAppActive);

    // Listen for mousemove
    window.addEventListener("mousemove", onAppActive);

    // Listen for scroll
    window.addEventListener("scroll", onAppActive);

    /*
			Component unmounts?
		*/
    return () => {
      if (_debug) console.log("AnalyticsWotodo.Unmount");

      // Set new state to prevent the async processing from finishing
      // isUnmounted = true

      // Clear timeout if any
      if (loadedTimeout) clearTimeout(loadedTimeout);

      // Remove on focus listener
      window.removeEventListener("focus", onAppFocus);

      // Remove keydown listener
      window.removeEventListener("keydown", onAppActive);

      // Remove mousedown listener
      window.removeEventListener("mousedown", onAppActive);

      // Remove mousemove listener
      window.removeEventListener("mousemove", onAppActive);

      // Remove scroll listener
      window.removeEventListener("scroll", onAppActive);

      // Clear Activity Counter timer
      if (appActiveCounterTimer) clearInterval(appActiveCounterTimer);
    };
  }, [userId]);

  // We do not need to render anything
  return null;
}

/**
 * Send analytics to the server
 * @param {String} userId
 * @param {String} eventType
 * @param {Object} eventData
 */
export function analyticsWotodoSend(userId, eventType, eventData) {
  /*
		System
	*/

  const _debug = false;

  if (_debug) console.log("AnalyticsWotodo.Send", userId, eventType, eventData);

  /*
		Are we online?
	*/

  if (!navigator?.onLine) {
    // console.log('AnalyticsWotodo.offline')
    return;
  }

  /*
		Validate
	*/

  if (typeof userId === "undefined" || typeof eventType === "undefined") {
    return;
  }

  /*
		Prepare request data
	*/

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
      eventType: eventType,
      eventData: eventData,
    }),
  };

  /*
		Send data
	*/

  fetch("https://app.wotodo.co/analytics/log/", requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (_debug) console.log("AnalyticsWotodo.Send.Response", data);
    })
    .catch(function (error) {
      console.log("AnalyticsWotodo.Send.Response.Error", error);
    });

  return true;
}
