/**
 * ProseMirror, system libraries
 */
import { Plugin } from "prosemirror-state";

// Unique IDs generation
import { nanoid } from "nanoid";

// const isTargetNodeOfType = (node, type) => (node.type === type);

// const isNodeHasAttribute = (node, attrName) => Boolean(node.attrs && node.attrs[attrName]);

// const attrName = 'blockid';

export const blockIdsPlugin = () => {
  // console.log('createPlugin.init')

  return new Plugin({
    appendTransaction: (transactions, prevState, nextState) => {
      // console.log('createPlugin.appendTransaction')

      const tr = nextState.tr;
      let modified = false;

      if (transactions.some((transaction) => transaction.docChanged)) {
        // Reset the list of used blockids
        let usedBlockIds = {};

        // console.log('createPlugin.docChanged')

        // Adds a unique id to a node
        nextState.doc.descendants((node, pos) => {
          // console.log('blockIdsPlugin.descendants', node)

          if (
            /*
						(
							isTargetNodeOfType(node, nextState.schema.nodes.paragraph)
							|| isTargetNodeOfType(node, nextState.schema.nodes.blockquote)
							|| isTargetNodeOfType(node, nextState.schema.nodes.heading)
							|| isTargetNodeOfType(node, nextState.schema.nodes.code_block)
							|| isTargetNodeOfType(node, nextState.schema.nodes.image)
							|| isTargetNodeOfType(node, nextState.schema.nodes.ordered_list)
							|| isTargetNodeOfType(node, nextState.schema.nodes.bullet_list)
							|| isTargetNodeOfType(node, nextState.schema.nodes.todo_list)
							|| isTargetNodeOfType(node, nextState.schema.nodes.paragraph_wrapper)
							|| isTargetNodeOfType(node, nextState.schema.nodes.block_element)
						)
						&&
						!isNodeHasAttribute(node, attrName)
						*/

            // Got attributes?
            node &&
            node?.attrs &&
            // Has an attribute of blockid?
            "blockid" in node.attrs
          ) {
            // console.log('blockIdsPlugin.hasBlockId(' + node?.type?.name + ')', node.attrs['blockid'])

            // Empty? Duplicated?
            if (
              node.attrs["blockid"] === null ||
              node.attrs["blockid"] === "" ||
              node.attrs["blockid"] === 0 ||
              node.attrs["blockid"] in usedBlockIds
            ) {
              // Generate new id
              const newBlockId = nanoid(10);

              /*
							// DEBUG
							if (node.attrs['blockid'] in usedBlockIds) {
								console.log('blockIdsPlugin.isDuplicated: ' + node.attrs['blockid'] + ' > ' + newBlockId, usedBlockIds)

							} else if (node.attrs['blockid'] === null || node.attrs['blockid'] === '' || node.attrs['blockid'] === 0) {
								console.log('blockIdsPlugin.isEmpty: ' + node.attrs['blockid'] + ' > ' + newBlockId, usedBlockIds)

							} else {
								console.log('blockIdsPlugin.STRANGE: ' + node.attrs['blockid'] + ' > ' + newBlockId, usedBlockIds)
							}
							*/

              // Get current attributes
              const attrs = node.attrs;

              // Update the attributes for this block
              tr.setNodeMarkup(pos, undefined, {
                ...attrs,
                blockid: newBlockId,
              });

              // Set as modified
              modified = true;

              // Save this blockid as used
              usedBlockIds[newBlockId] = true;

              // The block id is OK
            } else {
              // Save this blockid as used
              usedBlockIds[node.attrs["blockid"]] = true;
            }
          }
        });
      }

      return modified ? tr : null;
    },
  });
};
