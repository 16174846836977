/**
 * Firestore
 */
import firebase from "firebase/app";

/**
 * Get/Create the users knowledgebase
 *
 * @param {object} userData
 * @return {string} Knowledgebase id
 */
export const FirestoreGetKnowledgebase = async (userData) => {
  console.log("FirestoreGetKnowledgebase.run: ", userData);

  /*
        Cache. Do we know the last used Knowledgebase?
    
    
    const lastKnowledgebaseId = localStorage.getItem('lastKnowledgebaseId') || null
    
    // Yes, we do!
    if (lastKnowledgebaseId) {
        console.log('FirestoreGetKnowledgebase.last: ', lastKnowledgebaseId)
        return lastKnowledgebaseId
	}
	*/

  /*
        We do not know which database to use, let's pick one
    */

  let knowledgebaseId = null;
  console.log("FirestoreGetKnowledgebase.Get");

  // Init Firebase
  const db = firebase.firestore();

  // Fetch from Firestore
  const knowledgebases = await db
    .collection("knowledgebases")
    .where("owner_user_id", "==", userData.uid)
    .limit(1)
    .get();
  console.log("FirestoreGetKnowledgebase.Fetched:", knowledgebases);

  /*
        No knowledgebase yet? Let's create one
    */
  if (knowledgebases.empty) {
    console.log("FirestoreGetKnowledgebase.Empty");

    // Create a knowledgebase
    const knowledgebaseRef = await db.collection("knowledgebases").add({
      owner_user_id: userData.uid,
    });

    // Save the knowledgebase id
    console.log("FirestoreGetKnowledgebase.Added:", knowledgebaseRef.id);
    knowledgebaseId = knowledgebaseRef.id;

    /*
        Do we have an id?
    */
  } else if (knowledgebases.docs[0].id) {
    console.log("FirestoreGetKnowledgebase.Exists:", knowledgebases.docs[0].id);

    // Return it
    knowledgebaseId = knowledgebases.docs[0].id;
  }

  /*
        Save and return
    */

  // Save to local storage
  // localStorage.setItem('lastKnowledgebaseId', knowledgebaseId)

  // Return the Knowledgebase id
  return knowledgebaseId;
};
