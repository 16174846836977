/**
 * Editor
 * Based on: https://stackblitz.com/edit/react-prosemirror-editor?file=index.js
 * which is based on: https://github.com/prosemirror/prosemirror-example-setup
 */

/**
 * System libraries
 */
import React, { useContext, useEffect, useState } from "react"; // , Component

/**
 * Datetime processing lib(used in date detection in text)
 * https://github.com/wanasit/chrono
 */
import * as chrono from "chrono-node";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";
import { useHistory } from "react-router-dom";

/**
 * Storage
 */
import LocalStorage from "components/Storage/local";

/**
 * Modules
 */
import DocumentController from "components/Document/DocumentController";
import { arrayDifference, profileCheckLimits } from "components/System/Utils";
import {
  proseMirrorSearchLinks,
  proseMirrorCountParagraphs,
} from "components/ProseMirror/Utils";

/**
 * ProseMirror, system libraries
 */
// State, describes the current editors states (current and future)
import { EditorState, Selection } from "prosemirror-state";
// User interface components that visualize the Editor in a browser
import { EditorView } from "prosemirror-view";
import applyDevTools from "prosemirror-dev-tools";

// Key mapping
import { keymap } from "prosemirror-keymap";

/**
 * ProseMirror, setup
 */
import { defaultSetup } from "components/ProseMirror/Setup";
import {
  liftListItem,
  sinkListItem,
  splitListItem,
  listKeyBackspace,
  convertList,
} from "components/ProseMirror/commands/commandsLists";

// Describes the document Model Schema
import { editorSchema } from "components/ProseMirror/schema/editorSchema";
import { buildMenuItems } from "components/ProseMirror/schema/menuSchema";

// Floating menu
import { selectionMenu } from "../ProseMirror/menu/menu-floating.js";

// Plugins
// import { dragDropPlugin } from "components/ProseMirror/plugins/dragDropPlugin";
import { blockIdsPlugin } from "components/ProseMirror/plugins/blockIds";
import { placeholder } from "components/ProseMirror/plugins/placeholder";
import { listsTogglePlugin } from "components/ProseMirror/plugins/listsToggle";
import { blocksTogglePlugin } from "components/ProseMirror/plugins/blocksToggle";

/**
 * Modules
 */
import {
  getDocumentWithBlocks,
  getDocument,
  updateDocument,
  deleteDocument,
  documentReferenceDelete,
  documentReferenceAdd,
  storeDocument,
  searchDocument,
} from "components/Document/processor";
import { chainCommands } from "prosemirror-commands";
import {
  wrapInBlock,
  blockEnter,
  blockBackspace,
  blockTab,
  cardElementCreate,
} from "components/ProseMirror/commands/commandsBlock";
import { headingEnter } from "components/ProseMirror/commands/commandsHeading";
import {
  EventsProcessorClick,
  EventsProcessorTransformPasted,
} from "../ProseMirror/EventsProcessors";
import { firestoreUserUpdate } from "components/Firestore/User";
import { queueUserUpdate } from "components/UserProfile/update";
import { getDateObject } from "../System/Utils";
import { todoMarkToggle } from "components/ProseMirror/commands/commandsListsTodo";
import { ModalShare } from "components/views/modals/Share";

/**
 * Bootstrap
 */
import { DuplicatedDocumentModal } from "../views/modals/DuplicatedDocumentModal";

// Our modified version of prosemirror-commands
import {
  newlineInCode,
  createParagraphNear,
  liftEmptyBlock,
  splitBlock,
  deleteSelection,
  joinBackward,
  selectNodeBackward,
  joinForward,
  selectNodeForward,
  deleteEmptySelf,
  backspaceDeleteEmpty,
  blockElementDelete,
} from "../ProseMirror/commands/commandsDefault";

/**
 * Calendar elements
 */
import CalendarTitle from "components/Calendar/CalendarTitle";

/**
 * Editor elements
 */
import EditorTitle from "components/Editor/EditorTitle";

/**
 * Pages and elements
 */
import ElementLoader from "components/views/elements/Loader";
import SmartLinks from "components/Editor/SmartLinks";

/**
 * Editor component
 * @param {Object} Props
 */
export default function Editor(props) {
  // console.log('Editor', props)

  /*
		System inits
	*/

  // History
  let history = useHistory();

  // Get the User context
  const { userData } = useContext(UserContext);

  /*
		Loaders
	*/

  // Page loader Toggle function
  const togglePageLoaded = props.togglePageLoaded;

  // Save is pending function
  const setSavePending = props.setSavePending;

  // Document loaded state (loader)
  const [initialized, setInitialized] = React.useState(false);

  // Switch the loader state
  const toggleInitialized = (state) => {
    // Page loader (visual)
    togglePageLoaded(true);

    // Local document loader (affects the shown elements)
    setInitialized(state);
  };

  /*
		Limit hit
	*/

  // Limit hit Toggle function
  const toggleLimitHit = props.toggleLimitHit;

  /*
		Document inits
	*/

  // Document Data
  const [documentTitle, setDocumentTitle] = React.useState(null);
  // const [documentData, setDocumentData] = React.useState(null)
  const documentData = React.useRef(null);
  const documentLatestTransaction = React.useRef(null);

  // Title update refs
  const documentTitleInitial = React.useRef(null);
  const documentTitleTosave = React.useRef(null);
  const documentDuplicateId = React.useRef(null);

  /*
		Editor inits
	*/

  // Reset Plugins
  let editorPlugins = React.useRef(null);

  // Save document title timer
  const saveTitleTimer = React.useRef(null);

  // Save document timer
  // The returned object will persist for the full lifetime of the component.
  // useRef will give you the same ref object on every render.
  // Mutating the .current property doesn’t cause a re-render.
  const saveTimer = React.useRef(null);

  /*
		This object contains all the BlocksIds
		that were in the document in the last known state:
		- when the document was first loaded;
		- when the document was last saved.
	*/
  const lastBlockIdsState = React.useRef([]);

  /*
		Save document is running?
		Can't do this check, as it can in theory return from latest save,
		and it's better to save multiple times than not to save latest version.
	const saveRunning = React.useRef(false)
	*/

  // Editor
  const editorView = React.useRef(null);
  const editorState = React.useRef(null);

  /*
		Version control poller timer
	*/

  // Local Document version change poller timer
  const versionPollerTimer = React.useRef(null);

  /**
   * Version control poller timer setter
   */
  const versionPollerSetter = () => {
    // The timer is running? Clear it!
    if (versionPollerTimer.current !== null) {
      clearTimeout(versionPollerTimer.current);
    }

    // Set a new Timer
    versionPollerTimer.current = setTimeout(() => {
      documentChangePoller();
    }, 500);
  };

  /*
		Share
	*/

  // Share modal
  const [modalShareState, setModalShareState] = useState(false);
  const modalShareOpen = () => {
    setModalShareState(true);
  };
  const modalShareClose = () => {
    setModalShareState(false);
  };

  // Share status
  const documentShareState = async (
    knowledgebaseId,
    documentId,
    shareState
  ) => {
    // console.log('documentShareState', knowledgebaseId, documentId, shareState)

    // Update it!
    await updateDocument(knowledgebaseId, documentId, {
      is_shared: shareState,
      datetime_updated: new Date(),
    });

    // Change state
    documentData.current.is_shared = shareState;
  };

  /*
		Focus
	*/

  const initialFocus = React.useRef(true);

  /*
		Duplicated document Modal
	*/

  // Modal state (shown / hidden)
  const [modalDuplicatedState, setModalDuplicatedState] = useState(false);

  // Duplicated document Title
  const [modalDuplicatedTitle, setModalDuplicatedTitle] = useState("");

  // Show the Modal
  const modalDuplicatedOpen = (duplicatedTitle) => {
    // Update duplicated Title in the Modal
    setModalDuplicatedTitle(duplicatedTitle);

    // Show modal
    setModalDuplicatedState(true);
  };

  // Handle merge decline
  const modalDuplicatedHandleDecline = () => {
    console.log("modalDuplicatedHandleDecline");

    // Revert the title to Initial
    setDocumentTitle(documentTitleInitial.current);

    /* // Update the shortcuts
		documentTitleTosave.current = {
			title: documentTitleInitial.current,
			// .. ? is_date
		} */

    // Save the old Title state

    // Close modal
    setModalDuplicatedState(false);
  };

  // Handle merge confirm
  const modalDuplicatedHandleConfirm = async () => {
    // No document to merge with
    if (
      documentDuplicateId.current === null ||
      documentDuplicateId.current === false
    )
      return;

    console.log("modalDuplicatedHandleConfirm", documentDuplicateId.current);

    // Save current document (could lead to potential fuckups as this is ASYNC!)
    // console.log('modalDuplicatedHandleConfirm.saveDocument')
    // await saveDocument()

    // Fetch data
    const currentDocumentData = await getDocument(
      userData.knowledgebaseId,
      documentData.current.id
    );

    // Not found?
    if (!currentDocumentData) return;

    console.log(
      "modalDuplicatedHandleConfirm.getCurrent",
      documentData.current.id,
      currentDocumentData
    );

    // Data to merge
    let mergeData = {};

    // Got blocks?
    if (
      currentDocumentData?.blocks &&
      currentDocumentData.blocks?.length &&
      currentDocumentData.blocks.length > 0
    ) {
      console.log("modalDuplicatedHandleConfirm.mergeData.blocks");
      // mergeData.blocks = firebase.firestore.FieldValue.arrayUnion(...currentDocumentData.blocks)
      mergeData.blocks_arrayUnion = currentDocumentData.blocks;
    }

    // Got references?
    if (
      currentDocumentData?.references &&
      currentDocumentData.references?.length &&
      currentDocumentData.references.length > 0
    ) {
      console.log("modalDuplicatedHandleConfirm.mergeData.references");
      // mergeData.references = firebase.firestore.FieldValue.arrayUnion(...currentDocumentData.references)
      mergeData.references_arrayUnion = currentDocumentData.references;
    }

    console.log("modalDuplicatedHandleConfirm.mergeData", mergeData);

    // Got changes?
    if (Object.keys(mergeData).length > 0) {
      console.log(
        "modalDuplicatedHandleConfirm.mergeData.Save",
        documentDuplicateId.current
      );

      // Update it!
      await updateDocument(
        userData.knowledgebaseId,
        documentDuplicateId.current,
        mergeData
      );
    }

    console.log("modalDuplicatedHandleConfirm.Delete", documentData.current.id);

    // Delete current document
    await deleteDocument(userData.knowledgebaseId, documentData.current.id);

    // We do not have any document anymore
    documentData.current = null;

    console.log(
      "modalDuplicatedHandleConfirm.redirect",
      `/document/${documentDuplicateId.current}`
    );

    // Redirect to the new page
    // history.push(`/document/${documentDuplicateId.current}`)

    // Close modal
    // setModalDuplicatedState(false)

    // Change location
    window.location.href = `/document/${documentDuplicateId.current}`;
  };

  /**
   * Add new Card block_element
   * @param {object} Button click event
   */
  function createBlock(e) {
    // console.log("createBlock");

    // We can't run if there is no document
    if (editorView.current === null) return;

    cardElementCreate(editorView);

    e.preventDefault();
    return false;
  }

  /**
   * Toggle shortcut on a document
   * @param {object} Event
   * @param {boolean} Add page to object?
   */
  function toggleShortcut(e, add) {
    console.log("toggleShortcut");

    // Stop propagation
    e.preventDefault();

    // We can't run if there is no document
    if (documentData.current === null) return;

    console.log("Editor.Shortcut", documentData.current.id, add);

    // Create a new object (this _FORCES_ the state change)
    let newShortcuts = { ...props.shortcuts };

    // Add to shortcuts?
    if (add) {
      newShortcuts[documentData.current.id] = documentTitle;

      // Remove from shortcuts?
    } else {
      delete newShortcuts[documentData.current.id];
    }

    // console.log('Editor.Shortcut.newShortcuts', newShortcuts)

    // Save own state
    // setShortcuts(newShortcuts)

    // Update parent
    props.toggleShortcut(newShortcuts);

    // Save to Firestore
    firestoreUserUpdate(userData.uid, {
      shortcuts: Object.keys(newShortcuts),
    });
  }

  /**
   * Follow inner page via Router
   *
   * @param {object} Click event
   * @param {string} Location to follow
   */
  const gotoPage = (event, location) => {
    // console.log("gotoPage");

    // Prevent from processing
    event.preventDefault();
    event.stopPropagation();

    // console.log(location)

    history.push(location);
  };

  /**
   * Check if the document has changes in local storage.
   * This could happen if onSnapshot arrived, or there was a background document update,
   * or other Tab updated this page in parallel
   */
  const documentChangePoller = async () => {
    // console.log('documentChangePoller', documentData.current)

    /*
			Validations
		*/

    // Missing user data
    if (!userData?.uid || !userData?.knowledgebaseId) {
      console.log(
        "documentChangePoller.documentListenOnChange.noUserData",
        userData
      );
      return;
    }

    // Missing document data
    if (documentData.current === null || !documentData?.current?.id) {
      console.log(
        "documentChangePoller.invalidDocumentId",
        documentData.current
      );

      // Set new timer
      versionPollerSetter();

      return;
    }

    /*
			Init LocalStorage
		*/

    LocalStorage.initDb(userData.knowledgebaseId);

    /*
			Process
		*/

    // Remember the state
    const currentDocumentId = documentData.current.id;
    // const currentDocumentVersion = documentData.current._version

    // Datetime updated
    let currentDocumentDateUpdated = 0;
    if (documentData.current?.datetime_updated) {
      currentDocumentDateUpdated = documentData.current.datetime_updated.getTime();
    }

    // Title Datetime updated
    let currentDocumentTitleDateUpdated = 0;
    if (
      documentData.current?.datetime_title_updated &&
      typeof documentData.current.datetime_title_updated.getTime === "function"
    ) {
      currentDocumentTitleDateUpdated = documentData.current.datetime_title_updated.getTime();
      // console.log('Editor.datetime_title_updated', documentData.current?.datetime_title_updated, currentDocumentTitleDateUpdated)
    }

    // console.log('documentChangePoller.currentDocumentDateUpdated', currentDocumentDateUpdated)

    /*
			Get local document
		*/

    const checkDocument = await LocalStorage.wtGet(currentDocumentId);

    /*
			No document?
		*/

    if (!checkDocument) {
      console.log("documentChangePoller.missingDocument");

      // Set new timer
      versionPollerSetter();

      return;
    }

    /*
			Datetime updated processing
		*/

    let checkDatetimeUpdated = 0;
    if (checkDocument?.datetime_updated) {
      checkDatetimeUpdated = checkDocument.datetime_updated.getTime();
    }

    let checkTitleDatetimeUpdated = 0;
    if (
      checkDocument?.datetime_title_updated &&
      typeof checkDocument.datetime_title_updated.getTime === "function"
    ) {
      checkTitleDatetimeUpdated = checkDocument.datetime_title_updated.getTime();
    }

    /*
			Datetime has no change
		*/

    if (
      checkDatetimeUpdated <= currentDocumentDateUpdated &&
      checkTitleDatetimeUpdated <= currentDocumentTitleDateUpdated
    ) {
      /* console.log(
				'documentChangePoller.versionNoChange(current,local)',
				checkDatetimeUpdated, currentDocumentDateUpdated
			) */

      // Set new timer
      versionPollerSetter();

      return;
    }

    /*
			Version changed!
		*/

    /*
		console.log(
			'documentChangePoller.versionChanged!(current,local)',
			currentDocumentId,
			// currentDocumentVersion, checkDocument._version,
			checkDatetimeUpdated, currentDocumentDateUpdated,
			checkDocument
		)
		*/

    /*
			Get local versioning table
		*/

    // const localVersion = await LocalStorage.wtDocumentVersionGet(currentDocumentId)
    // console.log('documentChangePoller.localVersion', localVersion)

    /*
			My Own save to Firestore
		*/

    if (
      // localVersion
      // &&
      checkDocument?._process_id &&
      checkDocument._process_id === global.processId
    ) {
      // checkDatetimeUpdated === localVersion.datetime_updated.getTime()

      /*
			console.log(
				'documentChangePoller.ownChange',
				// checkDocument._version, localVersion,
				checkDatetimeUpdated,
				// localVersion.datetime_updated.getTime(),
				global.processId
			)
			*/

      // Update document version!
      documentData.current._version = checkDocument._version;

      // Update document datetime_updated!
      if (checkDocument?.datetime_updated) {
        documentData.current.datetime_updated = checkDocument.datetime_updated;
      }

      // Update document datetime_title_updated!
      if (checkDocument?.datetime_title_updated) {
        documentData.current.datetime_title_updated =
          checkDocument.datetime_title_updated;
      }

      // Set new timer
      versionPollerSetter();

      return;
    }

    /*
			Kill any pending saves!
		*/

    // The Title Save timer is running? Kill it!
    if (saveTitleTimer.current !== null) {
      // console.log('documentChangePoller.killTitleSave')
      clearTimeout(saveTitleTimer.current);
      saveTitleTimer.current = null;
    }

    // The Document Save timer is running? Kill it!
    if (saveTimer.current !== null) {
      // console.log('documentChangePoller.killDocumentSave')
      clearTimeout(saveTimer.current);
      saveTimer.current = null;
    }

    /*
			Re-read the document from the LocalStorage
		*/

    const newDocumentData = await getDocumentWithBlocks(
      userData.knowledgebaseId,
      documentData.current.id
    );
    // console.log('documentChangePoller.reRead', newDocumentData)

    /*
			Re-validate resulting data
		*/

    // Document Id changed?
    if (newDocumentData.id !== documentData.current.id) {
      // console.log('documentChangePoller.documentIdChanged!', newDocumentData.id, documentData.current.id)

      // We can't continue
      return;
    }

    /*
			Update the document data
		*/

    documentData.current = newDocumentData;
    documentData.current.knowledgebaseId = userData.knowledgebaseId;

    /*
			Let's update the known blocks list with data from the new document.
		*/

    lastBlockIdsState.current = documentData.current.blocks;
    // console.log('documentChangePoller.lastBlockIdsState', lastBlockIdsState.current)

    // Set Document title
    setDocumentTitle(documentData.current.title);

    // Update the initial Document Title
    documentTitleInitial.current = documentData.current.title;

    /*
			Update the ProseMirror editor
		*/

    // console.log('documentChangePoller.updateProseMirror', documentData.current.proseMirrorJson)

    // Set new State
    let newState = EditorState.create({
      doc: editorSchema.nodeFromJSON(documentData.current.proseMirrorJson), // doc,
      // Plugins
      plugins: editorPlugins.current, // view.state.plugins
    });
    editorView.current.updateState(newState);

    // Reset the latest transaction state
    documentLatestTransaction.current = null;

    /*
			Set a new timeout
		*/

    versionPollerSetter();

    // console.log('documentChangePoller.Complete')
  };

  /**
   * Initial Editor build.
   * Executed once, after the component was mounted on screen.
   */
  useEffect(() => {
    // Flag for the Unmount (to check againts after async operations are finished)
    let isUnmounted = false;

    /**
     * Build the Editor
     */
    const buildEditor = async () => {
      // No userData yet? We can't run
      if (userData === undefined) {
        // console.log('Editor.Build.NoData')
        return;
      }

      // console.log('Editor.Build')

      /*
				Get the document data
			*/

      // Get the Document data
      const currentDocumentData = await getDocumentWithBlocks(
        userData.knowledgebaseId,
        // This is the initial document id at the time the component is mounted
        props.documentId
      );

      // Document is not found?
      if (documentData === null) {
        console.log("Editor.Build.notFound");

        // Redirect
        if (window && window?.location) {
          window.location.href = "/";
        }

        // We can't do the effect!
        return;
      }

      /*
				Component already is unmounted?
			*/

      if (isUnmounted) {
        console.log("Editor.Build.UNMOUNTED");

        // We can't do the effect!
        return;
      }

      /*
				Update the document data
			*/

      documentData.current = currentDocumentData;
      documentData.current.knowledgebaseId = userData.knowledgebaseId;

      /*
				Plugins
			*/
      {
        // Replace ProseMirror keyboard key reactions with our modified versions
        const defaultKeys = {
          Enter: chainCommands(
            newlineInCode,
            /*
							Check account limits
						*/
            () => {
              const isLimited = profileCheckLimits(userData);

              // Limit reached!
              if (isLimited.hit) {
                // Block the Enter key
                // console.log('defaultKeys.profileCheckLimits.hit')

                // Show alert
                toggleLimitHit({
                  hit: true,
                  message: isLimited.message,
                });

                return true;
              }

              // No limits, allow
              console.log("defaultKeys.profileCheckLimits");
              return false;
            },
            createParagraphNear,
            liftEmptyBlock,
            splitBlock
          ),
          Backspace: chainCommands(
            // If there is a selection, remove it!
            deleteSelection,
            backspaceDeleteEmpty,
            joinBackward,
            selectNodeBackward
          ),
          Delete: chainCommands(
            // If there is a selection, remove it!
            deleteSelection,
            // Removing empty Nodes
            deleteEmptySelf,
            // Processing Delete key inside the block_element Node
            blockElementDelete,
            joinForward,
            selectNodeForward
          ),
        };

        // Initialize plugins
        editorPlugins.current = defaultSetup(
          {
            // Schema
            schema: editorSchema,

            // Turn off the menu
            menuBar: false,

            // Should I remove this??
            // Keyboard RE-map existing keys to other commands
            mapKeys: {}, // chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlock)
          },
          defaultKeys
        );

        /*
					Add keymaps
					The order in which they appear determines their precedence
					(the ones early in the array get to dispatch first)
				*/
        editorPlugins.current = [
          /*
						How-Tos:
						1) I can "chainCommands"
						https://prosemirror.net/docs/ref/#commands.chainCommands
						Combine a number of command functions into a single function
						(which calls them one by one until *** one returns true ***).
					*/
          // Blocks with indentation
          keymap({
            // Lists Indent/Un-indent on Tab
            // 'Tab': wrapInParagraph(editorSchema.nodes.paragraph),
            // 'Shift-Tab': liftListItem(editorSchema.nodes.list_item),
            // WORKING
            // 'Tab': wrapInList(editorSchema.nodes.block_wrapper)
            // WORKING
            Enter: chainCommands(
              // .. in block_element
              blockEnter(),
              // .. in Headings (h1,h2,h3)
              headingEnter()
            ),
            Backspace: blockBackspace(editorSchema.nodes.block_element),
            // 'Tab': wrapInBlock() // editorSchema.nodes.todo_item
            Tab: blockTab(), // editorSchema.nodes.todo_item
            // 'Tab': wrapIn(editorSchema.nodes.blockquote)
          }),
          /*
						ToDo lists keys processing
					*/
          keymap({
            // Ctrl + Enter (Mac, Windows)
            "Mod-Enter": chainCommands(
              // Toggle todo_item as done / undone
              todoMarkToggle(editorSchema.nodes.todo_list),
              // Convert current position into todo_list
              convertList(editorSchema.nodes.todo_list)
            ),
            Enter: splitListItem(editorSchema.nodes.todo_item),
            Tab: sinkListItem(editorSchema.nodes.todo_item), // use this if you want to nest todos
            "Shift-Tab": liftListItem(editorSchema.nodes.todo_item),
            Backspace: listKeyBackspace(editorSchema.nodes.todo_item),
          }),
          /* // Hidden
					keymap({
						'Enter': splitListItem(editorSchema.nodes.hidden_list_item),
						'Tab': sinkListItem(editorSchema.nodes.hidden_list_item), // use this if you want to nest todos
						'Shift-Tab': liftListItem(editorSchema.nodes.hidden_list_item),
						'Backspace': listKeyBackspace(editorSchema.nodes.hidden_list_item),
					}), */
          // Lists
          keymap({
            // Lists Indent/Un-indent on Tab
            Tab: sinkListItem(editorSchema.nodes.list_item),
            "Shift-Tab": liftListItem(editorSchema.nodes.list_item),
            Backspace: listKeyBackspace(editorSchema.nodes.list_item),
          }),
          /* // Enter
					keymap({
						'Enter': chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlock),
					}), */
          ...editorPlugins.current,
        ];

        /*
				editorPlugins.current.push(
					// Enter
					keymap({
						'Enter': chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlock),
					}),
				)
				*/

        /*
				// Key mapping + Default Setup Plugin
				editorPlugins.current = [keymap(todoItemKeymap)].concat(defaultSetup({
					schema: editorSchema,
					menuBar: false,
					// Keyboard RE-map existing keys to other commands
					// mapKeys: {"Mod-[": prosemirrorSchemaList.liftListItem(type)}
				}))
				*/

        /*
				// Main (static) menu Plugin
				editorPlugins.current.push(
					menuBar({
						content: buildMenuItems(documentData, editorSchema).inline
					})
				)
				*/

        // Inline menu Plugin
        editorPlugins.current.push(
          selectionMenu({
            content: buildMenuItems(documentData, editorSchema).inline,
          })
        );

        /*
				// Lists Key mapping Plugin
				editorPlugins.current.push(
					keymap({
						// Lists Indent/Un-indent on Tab
						'Tab': sinkListItem(editorSchema.nodes.list_item),
						'Shift-Tab': liftListItem(editorSchema.nodes.list_item),
					})/*,
					keymap({
						// Todo lists
						'Enter': splitListItem(editorSchema.nodes.todo_item),
						'Tab': sinkListItem(editorSchema.nodes.todo_item),
						'Shift-Tab': liftListItem(editorSchema.nodes.todo_item)
					})* /
				)
				*/

        // Unique IDs (data-blockid) for Blocks (Nodes) generator Plugin
        editorPlugins.current.push(blockIdsPlugin());

        // Add Drag'n'Drop plugin
        // editorPlugins.current.push(dragDropPlugin());

        // Add a toggle element to the lists items
        editorPlugins.current.push(listsTogglePlugin());

        // Placeholder
        editorPlugins.current.push(
          placeholder({
            content: "Start typing…",
          })
        );

        /**
         * Toggle plugin for block_element Node.
         * Creates an editor decoration with html element
         * to catch Mouse click events and open/close block_element
         */
        editorPlugins.current.push(blocksTogglePlugin());

        /*
				// Placeholder
				editorPlugins.current.push(
					() => {
						return new Plugin({
							props: {
								decorations(state) {
									let doc = state.doc
									if (doc.childCount == 1 && doc.firstChild.isTextblock && doc.firstChild.content.size == 0)
										return DecorationSet.create(doc, [Decoration.widget(1, document.createTextNode('Place holder here'))])
								}
							}
						})
					}
				)
				*/
      }

      /*
				Initialize the document
			*/

      /*
				Let's load the initial blocks list from the document.
				We have the following blocks exist in the document.
				Example:
				Array(3)
				0: "wkN9oX_mVq"
				1: "2EgJ00XKBp"
				2: "DTNZtHSQyu"
			*/
      lastBlockIdsState.current = documentData.current.blocks;
      // console.log('Editor.Build.lastBlockIdsState', lastBlockIdsState.current)

      // ProseMirror editor state initialization
      editorState.current = EditorState.create({
        // Initial document contents
        // From created DOM
        // doc: DOMParser.fromSchema(editorSchema).parse(proseMirrorContents),
        // From JSON
        doc: editorSchema.nodeFromJSON(documentData.current.proseMirrorJson), // editorSchema.nodeFromJSON(proseMirrorJson),
        // Plugins
        plugins: editorPlugins.current,
      });

      // ProseMirror editor view initialization
      editorView.current = new EditorView(
        // Editor Div
        document.getElementById(`editorHolder-${props.documentId}`),
        // Editor
        {
          // State
          state: editorState.current,
          // Called for each node around a click, from the inside out. The direct flag will be true for the inner node.
          handleClickOn: (editorView, pos, node, nodePos, event, direct) => {
            EventsProcessorClick(
              editorView,
              pos,
              node,
              nodePos,
              event,
              direct,
              history
            );
          },
          handlePaste: (view, event, slice) => {
            console.log("handlePaste", event, slice);
          },
          // On Dom events
          handleDOMEvents: {
            // On Focus
            focus(view) {
              // console.log('handleDOMEvents.inFocus')

              // We need to report that we are in focus now
              if ("focusedChange" in props) {
                // console.log('handleDOMEvents.focusedChange', props.documentId)

                // Report back
                props.focusedChange(props.documentId);
              }
            },
          },
          /* // Can be used to transform pasted HTML text, before it is parsed, for example to clean it up.
          transformPastedHTML: (html) => {
            console.log(
              "transformPastedHTML",
              html,
              html.replace(/\sdata-blockid="[A-Za-z0-9_-]+"/g, "")
            );

            return html.replace(/\sdata-blockid="[A-Za-z0-9_-]+"/g, "");
          }, */
          // Transform pasted content before it is applied to the document.
          transformPasted: (slice) => {
            return EventsProcessorTransformPasted(slice);
          },
          dispatchTransaction(transaction) {
            // console.log('dispatchTransaction')

            /*
							Apply the resulting state to the Editor
						*/
            // console.log('dispatchTransaction.Apply')
            let newState = editorView.current.state.apply(transaction);
            editorView.current.updateState(newState);

            //current state as json in text area
            //json.value =  JSON.stringify(editorView.current.editor.state.doc.toJSON(), null, 4);

            // Got changes? Save the document
            if (transaction.docChanged) {
              // Update isSaved state
              // props.toggleIsSaved(false)

              // Update the latest transaction
              if (documentLatestTransaction.current === null) {
                // documentLatestTransaction.current = transaction
                documentLatestTransaction.current = transaction.before;
                // console.log('Editor.dispatchTransaction.Latest.Init', documentLatestTransaction.current)
              }

              // console.log('Editor.dispatchTransaction.docChanged', transaction)
              // console.log('Editor.dispatchTransaction.After', editorView.current.state.doc)

              /*
								Document save timer
							*/

              // Do we have an active timer?
              if (saveTimer.current !== null) {
                // console.log('Editor.dispatchTransaction.clearTimeout')

                // Clear timer
                clearTimeout(saveTimer.current);
                saveTimer.current = null;
              }

              // Save is pending (visual icon)
              setSavePending(true);

              // Set a new timer
              // console.log('Editor.dispatchTransaction.setTimeout')
              saveTimer.current = setTimeout(() => {
                saveDocument();
              }, 300);
            }
          },
        }
      );

      // Prosemirror development tools
      if (process?.env?.NODE_ENV === "development") {
        applyDevTools(editorView.current);
      }

      // Set document title
      setDocumentTitle(documentData.current.title);

      // Set the initial Document Title
      documentTitleInitial.current = documentData.current.title;

      // Init local Document version change poller timer
      versionPollerSetter();

      // We are ready!
      toggleInitialized(true);

      // console.log('Editor.Build.Finalize')
    };
    buildEditor();

    /*
		// Specify how to clean up after this effect.
		// This is the optional cleanup mechanism for effects.
		// Every effect may return a function that cleans up after it.
		// React performs the cleanup when the component unmounts.
		// React also cleans up effects from the previous render before running the effects next time.
		*/
    return () => {
      const _debug = false;

      if (_debug)
        console.log("Editor.Build.unmount", documentData?.current?.id);

      // We are unmounted
      isUnmounted = true;

      /*
				Clear version poller timer
			*/

      // The timer is running? Clear it!
      if (versionPollerTimer.current !== null) {
        clearTimeout(versionPollerTimer.current);
        versionPollerTimer.current = null;

        if (_debug)
          console.log(
            "Editor.Build.unmount.versionPollerTimer",
            documentData?.current?.id
          );
      }

      /*
				There is a bug. When a user is logging out I can't write to his DB postfactum :(
			*/

      // No document is available
      if (documentData.current === null) {
        if (_debug) console.log("Editor.Build.Cleanup.noData");
        return;
      }

      /*
				Save the document before unmount
			*/

      // The timer is running? So the document needs to be saved!
      if (saveTimer.current !== null) {
        // Clear the timer
        clearTimeout(saveTimer.current);
        saveTimer.current = null;

        if (_debug)
          console.log(
            "Editor.Build.unmount.saveDocument",
            documentData.current.id
          );

        // Force the save
        saveDocument();
      }

      /*
				Save current document title
			*/

      // The timer is running? So the document needs to be saved!
      if (saveTitleTimer.current !== null) {
        // Clear the timer
        clearTimeout(saveTitleTimer.current);
        saveTitleTimer.current = null;

        if (_debug)
          console.log(
            "Editor.Build.unmount.saveTitle",
            documentData.current.id
          );

        // Force the Title save
        saveTitle(userData.knowledgebaseId, documentData.current.id);
      }
    };
  }, []);
  // If you want to run an effect and clean it up only once (on mount and unmount),
  // you can pass an empty array([]) as a second argument.
  // This tells React that your effect doesn’t depend on any values from props or state,
  // so it never needs to re - run.

  /**
   * iFrame reaction to messages
   */
  useEffect(() => {
    /*
			Focus processing
		*/

    /**
     * We have a message from the parent window
     * @param {Object} Event data
     */
    const onIframeMessage = (event) => {
      // console.log('Editor.onIframeMessage', event)

      // Focus the Editor
      if (event.data === "focus-event") {
        // Parent is in focus
        // console.log('Editor.onIframeMessage.editorView.Focus', editorView.current)

        // Move focus to the editor
        if (editorView.current !== null) {
          editorView.current.focus();

          /*
						Is it initial opening?
					*/
          if (initialFocus.current === true) {
            // console.log('Editor.onIframeMessage.editorView.Focus.initialFocus')
            initialFocus.current = false;

            // Set the cursor to the end of the document
            const selection = Selection.atEnd(editorView.current.docView.node);
            const tr = editorView.current.state.tr.setSelection(selection);
            const state = editorView.current.state.apply(tr);
            editorView.current.updateState(state);
          }
        }
      }
    };

    /**
     * Cursor was set manually, so there is no need to re-set it to the end of the doc
     */
    const initialFocusOverride = () => {
      // console.log('Editor.focusedEditor.initialFocusOverride')

      if (initialFocus.current === true) {
        // console.log('Editor.focusedEditor.initialFocusOverride.Set')
        initialFocus.current = false;
      }
    };

    // I was the last focused editor?
    if (props.focusedEditor) {
      // console.log('Editor.focusedEditor', props.documentId)

      // Let's listen to the messages
      window.addEventListener("message", onIframeMessage);
    }

    // On mouse click
    document.addEventListener("mousedown", initialFocusOverride);

    // Effect cleaner
    return () => {
      // Am I the first editor?
      if (props.focusedEditor) {
        // console.log('Editor.focusedEditor.unmount', props.documentId)

        // Remove on message listener
        window.removeEventListener("message", onIframeMessage);
      }

      // On mouse click
      document.removeEventListener("mousedown", initialFocusOverride);
    };
  }, [props.documentId, props.focusedEditor]);

  /**
   * When the props.documentId is changed!
   */
  useEffect(() => {
    // Flag for the Unmount (to check againts after async operations are finished)
    let isUnmounted = false;

    /**
     * Update the Editor
     */
    const updateEditor = async () => {
      // Set as loading
      toggleInitialized(false);

      // No document? This is the Initialization run
      if (documentData.current === null) {
        // console.log('Editor.Update.noDocument')
        return;
      }

      // Set the document ids
      const newDocumentId = props.documentId;
      const oldDocumentId = documentData.current.id;

      console.log("Editor.Update", oldDocumentId, newDocumentId);

      /*
				Save current document title
			*/

      // The timer is running? So the document needs to be saved!
      if (saveTitleTimer.current !== null) {
        clearTimeout(saveTitleTimer.current);
        saveTitleTimer.current = null;

        /*
					Force run Title save
				*/
        saveTitle(userData.knowledgebaseId, documentData.current.id);
      }

      /*
				Save current document contents
			*/

      // The timer is running? So the document needs to be saved!
      if (saveTimer.current !== null) {
        // console.log('Editor.Update.clearTimeout')
        clearTimeout(saveTimer.current);
        saveTimer.current = null;

        // Force the save of the old document
        console.log("Editor.Current.Document.Save", oldDocumentId);

        // Let's wait for the document to be saved
        await saveDocument();
      }

      /*
				Load new
			*/

      // Get the New document data
      console.log("Editor.Update.GetNew", newDocumentId);
      const newDocumentData = await getDocumentWithBlocks(
        userData.knowledgebaseId,
        newDocumentId
      );

      // Document is not found?
      if (newDocumentData === null) {
        console.log("Editor.Update.notFound");

        // Redirect
        if (window && window?.location) {
          window.location.href = "/";
        }

        // We can't do the effect!
        return;
      }

      /*
				Component already is unmounted?
			*/

      if (isUnmounted) {
        console.log("Editor.Update.UNMOUNTED");

        // We can't do the update!
        return;
      }

      /*
				Update the document data
			*/

      documentData.current = newDocumentData;
      documentData.current.knowledgebaseId = userData.knowledgebaseId;

      /*
				Let's update the known blocks list with
				data from the new document.
			*/

      lastBlockIdsState.current = documentData.current.blocks;
      console.log("Editor.Update.lastBlockIdsState", lastBlockIdsState.current);

      // Set Document title
      setDocumentTitle(documentData.current.title);

      // Update the initial Document Title
      documentTitleInitial.current = documentData.current.title;

      /*
				Update the ProseMirror editor
			*/

      console.log("Editor.Update.Doc", documentData.current.proseMirrorJson);

      // Set new State
      let newState = EditorState.create({
        doc: editorSchema.nodeFromJSON(documentData.current.proseMirrorJson), // doc,
        // Plugins
        plugins: editorPlugins.current, // view.state.plugins
      });
      editorView.current.updateState(newState);

      // Reset the latest transaction state
      documentLatestTransaction.current = null;

      // Set new version checker poller timer
      versionPollerSetter();

      /*
				Done loading
			*/

      // We are initialized
      toggleInitialized(true);

      console.log("Editor.Update.Finalize");
    };
    updateEditor();

    /*
			Component unmounts?
			Set new state to prevent the async processing from finishing
		*/
    return () => (isUnmounted = true);
  }, [props.documentId]);

  /**
   * Save the document
   */
  const saveDocument = async () => {
    const _debug = false;

    /* // Already running
		if (saveRunning.current === true) {
			console.log('Editor.saveDocument.alreadyRunning')
			return
		}

		// We are running
		saveRunning.current = true */

    if (_debug) console.log("Editor.saveDocument", documentData.current);

    if (documentData.current === null) {
      // saveRunning.current = false
      return;
    }

    // console.log('Editor.saveDocument', documentData.current.id)

    // Clear previous timer (if any)
    if (saveTimer.current !== null) {
      if (_debug) console.log("Editor.saveDocument.clearTimeout");

      clearTimeout(saveTimer.current);
      saveTimer.current = null;
    }

    /*
			Current state
		*/

    // There is no previous state?
    if (documentLatestTransaction.current === null) {
      // console.log('Editor.missingLatestTransaction')
      // saveRunning.current = false
      return;
    }

    // Before and After
    const docBeforeJSON = documentLatestTransaction.current.toJSON();
    const docAfterJSON = editorView.current.state.doc.toJSON();
    // console.log('Editor.saveDocument.States', docBeforeJSON, docAfterJSON)

    // Update the latest transaction
    documentLatestTransaction.current = editorView.current.state.doc;

    /*
			Reference links processing
		*/
    let smartLinksDiff = 0;
    {
      // Get links before the document edit
      const linksBefore = proseMirrorSearchLinks(docBeforeJSON); // transaction.before.toJSON()
      // console.log('Editor.dispatchTransaction.referenceLinks.linksBefore', linksBefore)

      // Get links after the document edit
      // Use editorView.current.state.doc to ensure I'm working with the latest state (after all plugins updated it)
      const linksAfter = proseMirrorSearchLinks(docAfterJSON); // transaction.doc.toJSON()
      // console.log('Editor.dispatchTransaction.referenceLinks.linksAfter', linksAfter)

      // Got links anywhere?
      if (linksBefore.length > 0 || linksAfter.length > 0) {
        // console.log('Editor.dispatchTransaction.referenceLinks.GotLinks', linksBefore, linksAfter)

        /*
					Something disappeared?
				*/

        const linksRemoved = arrayDifference(linksBefore, linksAfter);
        if (linksRemoved.length) {
          // console.log('Editor.dispatchTransaction.referenceLinks.Remove', linksRemoved)

          linksRemoved.forEach((diffReference) => {
            // Remove link from References
            documentReferenceDelete(
              documentData.current.knowledgebaseId,
              diffReference.linkId,
              diffReference.blockId
            );
          });
        }

        /*
					This is a hacky way of dealing with all the Reference links (by updating them all)
				*/
        const linksAdded = linksAfter;
        if (linksAdded.length) {
          // console.log('Editor.dispatchTransaction.referenceLinks.Added', linksAdded)

          linksAdded.forEach((diffReference) => {
            // Add link to References
            documentReferenceAdd(
              documentData.current.knowledgebaseId,
              diffReference.linkId,
              diffReference.blockId
            );
          });
        }

        // Links diff
        smartLinksDiff = linksAfter.length - linksBefore.length;
        // console.log('Editor.saveDocument.smartLinksDiff', smartLinksDiff)
      }
    }

    /*
			Paragraphs counter
		*/
    let paragraphsDiff = 0;
    {
      // Get paragprahs count before the document edit and after
      const paragraphsBefore = proseMirrorCountParagraphs(docBeforeJSON);
      const paragraphsAfter = proseMirrorCountParagraphs(docAfterJSON);

      // Links diff
      paragraphsDiff = paragraphsAfter - paragraphsBefore;
      // console.log('Editor.saveDocument.paragraphsDiff', paragraphsDiff)
    }

    // console.log('Editor.saveDocument.userData', userData)

    /*
			Update the user profile (if there are changes)
		*/
    if (smartLinksDiff !== 0 || paragraphsDiff !== 0) {
      // Reset profile updates
      let profileUpdates = {};

      // Smart links changed
      if (smartLinksDiff !== 0) {
        // console.log('Editor.saveDocument.Profile.smartLinksDiff', userData.smartLinksCount, smartLinksDiff)

        // What is the new count?
        let newSmartLinksCount = userData.smartLinksCount + smartLinksDiff;

        // Less than zero?
        if (newSmartLinksCount < 0) newSmartLinksCount = 0;

        // Update the User data
        userData.smartLinksCount = newSmartLinksCount;

        // Profile requires update
        profileUpdates.smartLinksCount = newSmartLinksCount;
      }

      // Paragraphs changed
      if (paragraphsDiff !== 0) {
        // console.log('Editor.saveDocument.Profile.paragraphsDiff', userData.paragraphsCount, paragraphsDiff)

        // What is the new count?
        let newParagraphsCount = userData.paragraphsCount + paragraphsDiff;

        // Less than zero?
        if (newParagraphsCount < 0) newParagraphsCount = 0;

        // Update the User data
        userData.paragraphsCount = newParagraphsCount;

        // Profile requires update
        profileUpdates.paragraphsCount = newParagraphsCount;
      }

      // Any update?
      if (Object.keys(profileUpdates).length > 0) {
        // console.log('Editor.saveDocument.firestoreUserUpdate', profileUpdates)

        // Save to Firestore
        // firestoreUserUpdate(userData.uid, profileUpdates)

        // Add entry to local queue for sync
        queueUserUpdate(
          userData.uid,
          documentData.current.knowledgebaseId,
          profileUpdates
        );
      }
    }

    /*
			Limit hit?
		*/

    const isLimited = profileCheckLimits(userData);
    // console.log('saveDocument.profileCheckLimits', isLimited)

    // Limit hit!
    if (isLimited.hit) {
      // console.log('saveDocument.profileCheckLimits.Hit')

      // Show alert
      toggleLimitHit({
        hit: true,
        message: isLimited.message,
      });

      // No Limit
    } else {
      // Hide it
      toggleLimitHit({
        hit: false,
        message: "",
      });
    }

    // TEMP DEBUG
    // console.log('Editor.saveDocument.DEBUG1', editorView)
    // console.log('Editor.saveDocument.DEBUG1', editorView)

    // Get the document content
    // console.log('Editor.saveDocument.Doc', editorView.current.state.doc)
    const nodesJSON = docAfterJSON.content;
    // console.log('Editor.saveDocument.Content', nodesJSON)

    /*
			Wait for it to be saved.
			And update the list of last known blocks.
		*/
    lastBlockIdsState.current = await storeDocument(
      userData.knowledgebaseId, // Current knowledgebase
      documentData.current.id, // The document we are saving
      documentData.current._version, // The version of the document
      nodesJSON, // Current document JSON
      lastBlockIdsState.current // Array of last blocks we had in the previous document state
    );

    if (_debug)
      console.log(
        "Editor.saveDocument.lastBlockIdsState",
        lastBlockIdsState.current
      );
    // saveRunning.current = false
    return true;
  };

  /**
   * Enter Keydown in document Title
   *
   * @param {object} JavaScript event
   */
  const titleKeyDown = (e) => {
    console.log("titleKeyDown", e);

    // Enter is hit?
    if (e.key === "Enter") {
      // console.log('titleKeyDown.Enter')

      // Stop the Enter from doing changes in the document
      e.preventDefault();
      e.stopPropagation();

      // Do we have an editor?
      if (editorView && editorView?.current) {
        // Move focus to the editor
        editorView.current.focus();
      }

      return false;
    }
  };

  /**
   * Save document Title
   * @param {*} e
   */
  const saveTitle = async (knowledgebaseId, documentId) => {
    // console.log('saveTitle') // , { ...documentTitleTosave.current }

    // No document?
    if (documentData.current === null) {
      console.log("saveTitle.noDocument");
      return;
    }

    // No document Title data
    if (documentTitleTosave.current === null) {
      console.log("saveTitle.noTitle");
      return;
    }

    /*
			Search for a document with same Title
		*/

    let duplicatedDocument = false;
    if (documentTitleTosave.current.title !== "") {
      duplicatedDocument = await searchDocument(userData.knowledgebaseId, {
        title: documentTitleTosave.current.title,
      });
    }

    // Document is duplicated
    if (
      duplicatedDocument &&
      // Different document id?
      duplicatedDocument?.id !== documentId
    ) {
      console.log("saveTitle.Duplicated", duplicatedDocument);

      // Remember it
      documentDuplicateId.current = duplicatedDocument.id;

      // Show the confirmation modal
      modalDuplicatedOpen(documentTitleTosave.current.title);

      // Can't process further
      return;
    }

    // Should we lock the title?
    if (documentTitleTosave.current?.is_date) {
      // Change the Title
      setDocumentTitle(documentTitleTosave.current.title);

      // Lock the title
      // documentData.current.is_date = true
    }

    // console.log('saveTitle.Process')

    /*
			Shortcuts update
		*/

    if (
      props.shortcuts &&
      // This Document is in shortcuts list?
      props.documentId in props.shortcuts
    ) {
      // console.log('Editor.shortcuts', props.documentId)

      // Create a new object (this _FORCES_ the state change)
      let newShortcuts = { ...props.shortcuts };

      // console.log('Editor.shortcuts.list', newShortcuts)

      // Remove the document from shortcuts
      if (
        !documentTitleTosave?.current?.title ||
        documentTitleTosave.current.title.trim() === ""
      ) {
        // console.log('Editor.shortcuts.empty', documentTitleTosave.current.title)

        delete newShortcuts[documentData.current.id];

        // Update page title
      } else {
        // console.log('Editor.shortcuts.full', documentTitleTosave.current.title)

        newShortcuts[documentData.current.id] =
          documentTitleTosave.current.title;
      }

      // Update parent
      props.toggleShortcut(newShortcuts);
    }

    // Save it!
    // console.log('saveTitle.Process', documentTitleTosave.current)

    // Change the new Initial document title
    documentTitleInitial.current = documentTitleTosave.current.title;

    /*
			Save new Title
		*/

    // Update it!
    await updateDocument(
      knowledgebaseId,
      documentId,
      documentTitleTosave.current
    );
  };

  /**
   * Edit document Title
   *
   * @param {object} JavaScript event
   */
  const updateTitle = (e) => {
    // console.log('updateTitle', e)

    // Get the updated title from the input
    const newTitle = e.target.value;

    // Input state update (show the change)
    setDocumentTitle(newTitle);

    /*
			Title to save
		*/

    // New data for the document
    documentTitleTosave.current = {
      title: newTitle,
      is_date: "__spec_fieldValue_delete", // firebase.firestore.FieldValue.delete(),
      date_set: "__spec_fieldValue_delete", // firebase.firestore.FieldValue.delete(),
    };

    /*
			Date processor
		*/

    // Detect date in Title
    const dateDetected = chrono.parseDate(newTitle);

    // Date detected!
    if (dateDetected !== null) {
      console.log("updateTitle.dateDetected", dateDetected);

      // Get Object from date
      const dateObject = getDateObject(dateDetected);
      console.log("updateTitle.dateObject", dateObject);

      // Update Title to save
      documentTitleTosave.current = {
        title: dateObject.long,
        is_date: true,
        date_set: dateObject.firestore,
      };
    }

    /*
			Timer before saving
		*/

    // Clear previous timer
    if (saveTitleTimer.current !== null) {
      clearTimeout(saveTitleTimer.current);
      saveTitleTimer.current = null;
    }

    // Set new timer
    saveTitleTimer.current = setTimeout(
      saveTitle,
      2000,
      userData.knowledgebaseId,
      documentData.current.id
    );
  };

  /*
		Editor CSS class
	*/

  let wrapperClassName = "wotodo-day-item";
  let editorClassName = "wotodo-item wotodo-item_v2";

  // Not yes initialized
  if (!initialized) {
    wrapperClassName += " wt-document-wrapper-loading";
  }

  // Single document?
  if (!props?.fromCalendar) {
    wrapperClassName += " wotodo-day-item_single";
    editorClassName += " wotodo-item_v2_single";
  }

  // Focused?
  if (props?.focusedEditor) {
    wrapperClassName += " wt-document-focused";
  }

  /*
		Output editor
	*/

  // Draw it!
  return (
    <div className={wrapperClassName}>
      <div className={editorClassName}>
        {!initialized ? (
          // Loading
          <ElementLoader />
        ) : (
          // Loaded
          <>
            {/* Document Controller service (synchronization) */}
            {documentData && documentData?.current ? (
              <DocumentController
                knowledgebaseId={userData.knowledgebaseId}
                documentId={documentData.current.id}
                documentVersion={documentData.current._version}
                documentDatetimeUpdated={
                  documentData.current?.datetime_updated
                    ? documentData.current?.datetime_updated.getTime()
                    : 0
                }
                localRead={documentData.current?._localStorage ? true : false}
              />
            ) : (
              <></>
            )}

            {
              /* Title */
              props?.fromCalendar ? (
                <CalendarTitle
                  documentTitle={documentTitle}
                  isBookmarked={
                    props?.shortcuts && props.documentId in props.shortcuts
                  }
                  toggleShortcut={toggleShortcut}
                  createBlock={createBlock}
                />
              ) : (
                <EditorTitle
                  documentTitle={documentTitle}
                  isBookmarked={
                    props?.shortcuts && props.documentId in props.shortcuts
                  }
                  isEditable={
                    documentData?.current &&
                    documentData.current.is_page && // Is Page
                    !documentData.current?.is_date // Is not Date
                  }
                  toggleShortcut={toggleShortcut}
                  updateTitle={updateTitle}
                  titleKeyDown={titleKeyDown}
                  isShared={
                    documentData?.current && documentData.current?.is_shared
                  }
                  modalShareOpen={modalShareOpen}
                  createBlock={createBlock}
                />
              )
            }
          </>
        )}

        {/* Editor holder div */}
        <div className="wotodo-note">
          <div
            className="wt-editor-holder text-left"
            id={`editorHolder-${props.documentId}`}
          ></div>
        </div>

        {/* SmartLinks block */}
        {
          // Do we have references ready and not empty?
          documentData &&
          documentData?.current &&
          documentData.current?.references &&
          documentData.current.references.length > 0 ? (
            <SmartLinks
              knowledgebaseId={userData.knowledgebaseId}
              documentId={documentData.current.id}
              referencesIds={documentData.current.references}
              gotoPage={gotoPage}
            />
          ) : (
            ""
          )
        }

        {/* Duplicated document modal */}
        <DuplicatedDocumentModal
          show={modalDuplicatedState}
          handleDecline={modalDuplicatedHandleDecline}
          handleConfirm={modalDuplicatedHandleConfirm}
          duplicatedTitle={modalDuplicatedTitle}
        />

        {
          /* Share modal (single document only) */
          !props?.fromCalendar && documentData?.current ? (
            <ModalShare
              isOpen={modalShareState}
              modalClose={modalShareClose}
              documentId={documentData.current.id}
              knowledgebaseId={userData.knowledgebaseId}
              isShared={documentData.current?.is_shared}
              setShare={documentShareState}
            />
          ) : (
            ""
          )
        }
      </div>
    </div>
  );
}
