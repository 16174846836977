import React, { useState, useEffect } from "react";
import { useDebounce } from "../../views/Utils";
import "../../../css/documentList.css";

export default function DocumentListSearch({ onSearch }) {
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce(searchValue, 300);

  useEffect(() => {
    onSearch(debouncedValue);
  }, [debouncedValue, onSearch]);

  return (
    <div className="search-bar">
      <input
        className="search-bar__input"
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        type="text"
        placeholder="Type for search"
      />
    </div>
  );
}
