/**
 * Storage
 */
import LocalStorage from "components/Storage/local";

/**
 * Queue user update
 *
 * @param {String} User id
 * @param {Knowledgebase id} Knowledgebase id
 * @param {Object} Data to update
 * @returns {boolean} Result of the operation
 */
export const queueUserUpdate = async (userId, knowledgebaseId, userData) => {
  // console.log('queueUserUpdate', userId, userData)

  /*
		Init LocalStorage
	*/

  LocalStorage.initDb(knowledgebaseId);

  // Add to RemoteStorage processing queue
  await LocalStorage.wtSaveQueueAdd(
    "user_update",
    knowledgebaseId,
    userId,
    userData
  );

  return true;
};
