/**
 * System libraries
 */
import React, { useRef, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

/**
 * Sortable logics.
 * Based on:
 * https://github.com/clauderic/react-sortable-hoc
 * Examples:
 * https://clauderic.github.io/react-sortable-hoc/#/advanced/activate-onpress-200ms?_k=1u7s3n
 * Sandbox:
 * https://codesandbox.io/s/o104x95y86?file=/src/index.js
 */
import { sortableContainer, sortableElement } from "react-sortable-hoc";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * Move Array items with ease
 */
import arrayMove from "array-move";

/**
 * Application libraries
 */
import FirebaseSignOut from "components/Firebase/SignOut";

/**
 * Modules
 */
import LimitsCounter from "components/views/elements/LimitsCounter";

/**
 * Left sidebar
 *
 * @param {Object} Props list
 */
export function LeftBar(props) {
  // console.log('LeftBar', props)

  /*
		System
	*/

  let history = useHistory();

  /*
		Context
	*/

  // Get the User context
  const { userData } = useContext(UserContext);
  const paragraphsCount = userData.paragraphsCount;

  /*
		Props
	*/

  const sortShortcuts = props.sortShortcuts;
  const shortcuts = props.shortcuts;
  const leftBarOpen = props.leftBarOpen;
  const toggleLeftBar = props.toggleLeftBar;
  const openPaymentModal = props.openPaymentModal;

  /*
		References
	*/

  // Shortcuts div reference
  const shortcutsDivRef = useRef(null);

  /*
		States
	*/

  // Shortcuts as Array
  const [shortcutsList, setShortcutsList] = useState([]);

  /*
 		Effects
	*/

  /**
   * Process click outside the Shortcuts block
   * On reference change OR on Shortcuts open state change
   */
  useEffect(() => {
    /**
     * Handle clicks outside the Left bar menu and close the menu.
     */
    function handleClickOutside(event) {
      // console.log('LeftBar.handleClickOutside', leftBarOpen)

      // Click is outside
      if (
        shortcutsDivRef.current &&
        !shortcutsDivRef.current.contains(event.target)
      ) {
        // Do we need to close it?
        if (
          // Shortcuts are open
          leftBarOpen &&
          (!event.target?.className ||
            !event.target.className.includes("sidebar-icon-shortcuts"))
        ) {
          // Close it
          toggleLeftBar();
        }
      } /* else {
				console.log("You clicked inside of me!")
			}*/
    }

    // Listen for mouse down events
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // console.log('LeftBar.removeEventListener', leftBarOpen)

      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shortcutsDivRef, leftBarOpen, toggleLeftBar]);

  /**
   * On shortcuts change
   */
  useEffect(() => {
    // console.log('LeftBar.useEffect', shortcuts);

    // Convert to array for Sortable
    if (shortcuts && Object.keys(shortcuts).length > 0) {
      const arrayShortcuts = Object.keys(shortcuts).map((key) => {
        return { documentId: key, documentTitle: shortcuts[key] };
      });

      // console.log('LeftBar.setShortcuts', arrayShortcuts)
      setShortcutsList(arrayShortcuts);
    }
  }, [shortcuts]);

  /**
   * Follow inner page via Router
   *
   * @param {object} Click event
   * @param {string} Location to follow
   */
  const gotoPage = (event, location) => {
    // Prevent from processing
    event.preventDefault();
    event.stopPropagation();

    // console.log('gotoPage', location)

    history.push(location);
  };

  /**
   * Sortable. On Sort end
   * @param {Object} Sortable data (old, new indexes)
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // Move the sorted item in the Array
    const newItems = arrayMove(shortcutsList, oldIndex, newIndex);
    // console.log('onSortEnd', oldIndex, newIndex, newItems)

    // Set own Shortcuts state
    setShortcutsList(newItems);

    // Report to parent (updated shortcuts list)
    const shortuctIds = newItems.map((value) => value.documentId);
    sortShortcuts(shortuctIds);
  };

  /**
   * Sortable item (element)
   * @param {Object} Element data
   */
  const SortableItem = sortableElement(({ value }) => {
    return (
      <a
        className="wotodo-sidebar-block__link"
        key={value.documentId}
        href={`/document/${value.documentId}`}
        onClick={(event) => {
          // Close the bar
          toggleLeftBar(false);
          // Follow the link
          gotoPage(event, `/document/${value.documentId}`);
        }}
      >
        {value.documentTitle}
      </a>
    );
  });

  /**
   * Sortable container
   * @param {Object} Container data
   */
  const SortableContainer = sortableContainer(({ children }) => {
    return <div className="wotodo-sidebar-block__links">{children}</div>;
  });

  /**
   * Return the LeftBar html element
   */
  return (
    <div id="sideWrapper" className={leftBarOpen ? "open" : ""}>
      <nav ref={shortcutsDivRef} id="sideShortcuts">
        <div className="wotodo-sidebar-block">
          {shortcutsList && shortcutsList.length > 0 ? (
            <>
              <h4 className="wotodo-sidebar-block__title">Favourites</h4>
              <SortableContainer
                onSortEnd={onSortEnd}
                /* pressDelay={100} */
                distance={5}
                lockAxis="y"
              >
                {shortcutsList.map((value, index) => {
                  return (
                    <SortableItem
                      key={`item-${value.documentId}`}
                      index={index}
                      value={value}
                    />
                  );
                })}
              </SortableContainer>
            </>
          ) : (
            <div>
              <p className="text-muted">
                This is the place for your Shortcuts.
              </p>
              <p className="text-muted">
                Just click the Star icon{" "}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="star"
                  className="svg-inline--fa fa-star fa-w-18 "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
                  ></path>
                </svg>{" "}
                next to the page title.
              </p>
            </div>
          )}
        </div>

        <div className="wotodo-sidebar__bottom">
          {/* Limits counter (only if account is not yet paid and q-ty of paragraphs is high) */}
          {!userData?.isPaid && paragraphsCount > 500 ? (
            <LimitsCounter
              paragraphsCount={paragraphsCount}
              openPaymentModal={openPaymentModal}
              toggleLeftBar={toggleLeftBar}
            />
          ) : (
            ""
          )}

          {/* Sign out link */}
          <FirebaseSignOut />

          {/* Version number */}
          <div>{global.appVersion}</div>
        </div>
      </nav>
    </div>
  );
}
