/**
 * System libraries
 */
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Contexts
 */
import { UserContext } from "contexts/UserContext";

/**
 * ProseMirror
 */
import Editor from "components/Editor/Editor";

/**
 * Pages and elements
 */
import Calendar from "components/views/Calendar";

/**
 * Payment
 */
import { ModalPayment } from "components/views/modals/Payment";

/**
 * Services
 */
import { analyticsWotodoSend } from "services/AnalyticsWotodo";
import VersionControl from "services/VersionControl";

/**
 * Modules
 */
import { LeftBar } from "components/views/sidebars/LeftBar";
import { TopNav } from "components/views/topnav/TopNav";
import { firestoreUserUpdate } from "components/Firestore/User";
import { getGroup } from "components/Document/processor";

/**
 * Storage
 */
import LocalStorage from "components/Storage/local";

/**
 * Main index template
 * @param {object} Props
 */
export default function PageIndex(props) {
  // console.log('PageIndex', props)

  /*
		Context
	*/

  // Get the User context
  const { userData } = useContext(UserContext);

  /*
		Hooks
	*/

  const location = useLocation();

  // Redirect to the page after creation
  // let history = useHistory()

  /*
		Props
	*/

  const setSavePending = props.setSavePending;

  /*
		Page data
	*/

  // Set page
  const currentDocumentId = props.documentId;

  // Calendar view?
  const calendarView = props?.calendarView ? true : false;

  /*
		Success message
	*/

  const [successMessage, setSuccessMessage] = React.useState({});

  /*
		Alert and Limit hit
	*/

  // Alert message
  const [limitHit, setLimitHit] = React.useState({});
  const toggleLimitHit = (state) => {
    // console.log('PageIndex.toggleLimitHit', state)

    // No change?
    if (limitHit && limitHit.hit && limitHit.hit === state.hit) {
      // console.log('PageIndex.toggleLimitHit.noChange')
      return;
    }

    // Update the state
    setLimitHit(state);
  };

  /*
		Shortcuts
	*/

  // State of shortcuts
  const [shortcuts, setShortcut] = useState({});

  /*
		Left bar
	*/

  // State LeftBar
  const [leftBarOpen, setToggleLeftBar] = useState(false);
  const toggleLeftBar = () => {
    // setToggleLeftBar(open) // (open => !open)
    setToggleLeftBar(!leftBarOpen);
  };

  /*
		Save state/icon
	
	// State isSaved
	const [isSaved, setIsSaved] = useState(true)
	const toggleIsSaved = (state) => {
		setIsSaved(state)
	}
	*/

  /*
		Loader
	*/

  // Initial component render (should we show loader?)
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const togglePageLoaded = (state) => {
    setPageLoaded(state);
  };

  /*
		Payment
	*/

  // Payment modal (closed by default)
  const [modalPaymentState, setModalPaymentState] = React.useState(false);
  const openPaymentModal = () => {
    setModalPaymentState(true);
  };
  const closePaymentModal = () => {
    setModalPaymentState(false);
  };

  // Update user data
  const updateUserData = (newPlan) => {
    console.log("updateUserData");

    // Reset profile updates
    let profileUpdates = {};

    // Change user data
    userData.plan = newPlan;
    profileUpdates.plan = newPlan;

    // Free?
    if (newPlan === "personal") {
      userData.isPaid = false;
      profileUpdates.isPaid = false;

      // Paid
    } else {
      userData.isPaid = true;
      profileUpdates.isPaid = true;

      // Hide limit hit alert
      toggleLimitHit({
        hit: false,
        message: "",
      });

      // Notification
      analyticsWotodoSend(userData.uid, "paid", { plan: newPlan });
    }

    // Save to Firestore
    if (Object.keys(profileUpdates).length > 0) {
      firestoreUserUpdate(userData.uid, profileUpdates);
    }

    // console.log('updateUserData.Save', profileUpdates)

    return false;
  };

  /*
		Limit hit button, open modal
	*/

  const limitButtonClick = () => {
    // console.log('limitButtonClick')

    // Open payment modal
    openPaymentModal();

    return false;
  };

  /*
		Init
	*/

  // Initialize (once)
  useEffect(() => {
    // console.log('PageIndex.Initialize')

    // Flag for the Unmount (to check againts after async operations are finished)
    let isUnmounted = false;

    /* // Onboarding? Should forward to Getting Started page
		if (
			userData?.onboard
			&& userData.onboard === 1
			&& userData?.lastDocumentId
			&& userData.lastDocumentId !== ''
		) {
			console.log('PageIndex.isOnboarding', userData.lastDocumentId)

			// One-time onboarding
			userData.onboard = 2

			// Go to it
			history.push(`/document/${userData.lastDocumentId}`)

			// Do nothing
			return
		} */

    /*
			Convert shortcuts array of document ids into object with document titles
		*/
    const updateShortcuts = async () => {
      // console.log('PageIndex.updateShortcuts', userData.shortcuts)

      // Do we have any shortcuts at all?
      if (userData.shortcuts && Object.keys(userData.shortcuts).length > 0) {
        // console.log('PageIndex.updateShortcuts.read')

        // Reset the new shortcuts object
        let resultShortcuts = {};

        // Get the Shortcuts from DB
        const shortcutDocuments = await getGroup(
          userData.knowledgebaseId,
          // Shortcuts documents ids from profile (array)
          userData.shortcuts
        );

        // console.log('PageIndex.updateShortcuts.data', shortcutDocuments)

        /*
					Component already is unmounted?
				*/

        if (isUnmounted) {
          // console.log('PageIndex.updateShortcuts.UNMOUNTED')

          // We can't do the update!
          return;
        }

        // Convert
        for (var documentId in shortcutDocuments) {
          // Skip documents without title
          if (
            !shortcutDocuments[documentId]?.title ||
            shortcutDocuments[documentId]?.title.trim() === ""
          )
            continue;

          // Add to array
          resultShortcuts[documentId] = shortcutDocuments[documentId].title;
        }
        // console.log('PageIndex.updateShortcuts.result', resultShortcuts)

        // Set new state with Shortcuts
        setShortcut(resultShortcuts);

        // No shortcuts
      } else {
        // console.log('updateShortcuts.Close')

        // Close the left bar with Shortcuts
        setToggleLeftBar(false);
      }
    };
    updateShortcuts();

    // Init LocalStorage
    LocalStorage.initDb(userData.knowledgebaseId);

    /*
			Component unmounts?
			Set new state to prevent the async processing from finishing
		*/
    return () => (isUnmounted = true);
  }, []); // <-- this ensures this will run once only!

  /*
		Payment result
	*/

  useEffect(() => {
    // Got query in URL?
    if (location?.search !== "") {
      // console.log('useEffect.paymentResult', location?.search)

      // Account is free?
      if (
        !userData?.plan ||
        userData.plan === "" ||
        userData.plan === "personal"
      ) {
        // console.log('useEffect.paymentResult.free', location?.search)

        // Stripe reports failed payment
        if (location.search === "?fromStripe=fail") {
          // Show alert
          toggleLimitHit({
            hit: true,
            message: "Payment failed 😞",
          });

          // Stripe reports success
        } else if (
          location.search.indexOf("?fromStripe=success&session_id") === 0
        ) {
          const stripeSessionId = location.search.replace(
            "?fromStripe=success&session_id=",
            ""
          );

          // Same id as in the account?
          if (userData?.stripeSessionId === stripeSessionId) {
            // Change user data
            updateUserData("personal-pro");

            // Show success message
            setSuccessMessage({
              show: true,
              message: "Woohoo, welcome aboard! 🎉😊",
            });
          }
        }
      }
    }
  });

  /**
   * Update shortcuts list from a child
   * @param {object} New shortcuts
   */
  const toggleShortcut = (newShortcuts) => {
    console.log("PageIndex.toggleShortcut", newShortcuts);

    // Set local shortcuts
    setShortcut(newShortcuts);

    // Change the list of Users shortcuts (for a next render)
    userData.shortcuts = Object.keys(newShortcuts);
  };

  /**
   * Update sortable shortcuts list
   * @param {array} Resorted shortcuts
   */
  const sortShortcuts = (resortedShortcuts) => {
    console.log("PageIndex.sortShortcuts", resortedShortcuts, shortcuts);

    /*
			Update Firebase db
		*/

    // Save to Firestore
    firestoreUserUpdate(userData.uid, {
      shortcuts: resortedShortcuts,
    });

    /*
			Update local variable
		*/

    // Change the list of Users shortcuts (for a next render)
    userData.shortcuts = resortedShortcuts;
    // userData.

    // Update local array
    let resultShortcuts = {};

    // Convert
    for (let documentId of resortedShortcuts) {
      resultShortcuts[documentId] = shortcuts[documentId];
    }

    // Set local shortcuts
    setShortcut(resultShortcuts);
    console.log("PageIndex.sortShortcuts.resultShortcuts", resultShortcuts);
  };

  /**
   * Return result
   */
  return (
    <div
      className={`wotodo wotodo_v2${
        window?.location !== window?.parent?.location ? " is-extension" : ""
      }`}
    >
      {/* Success message */}
      {successMessage?.show ? (
        <div className="wt-alert-global wt-alert-global-open">
          <div className="wotodo-alert-success js-active">
            <h4 className="wotodo-alert-success__title">
              {successMessage.message}
            </h4>
            <button
              className="wotodo-alert-success__button"
              onClick={() => {
                setSuccessMessage({ show: false, message: "" });
              }}
            >
              x
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Limit Hit alert. Upgrade required */}
      {limitHit?.hit ? (
        <div className="wt-alert-global wt-alert-global-open">
          <div className="wotodo-update__content wotodo-update__content_update wotodo-update__content_limit js-active">
            <h4 className="wotodo-update__title">{limitHit.message}</h4>
            <button
              className="wotodo-update__button"
              onClick={limitButtonClick}
            >
              Upgrade
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Version control */}
      <VersionControl />

      {/* Top navigation */}
      <TopNav
        togglePageLoaded={togglePageLoaded}
        leftBarOpen={leftBarOpen}
        toggleLeftBar={toggleLeftBar}
        shortcuts={shortcuts}
        pageLoaded={pageLoaded}
        /* isSaved={isSaved} */
      />

      {/* Left sidebar */}
      <LeftBar
        togglePageLoaded={togglePageLoaded}
        leftBarOpen={leftBarOpen}
        toggleLeftBar={toggleLeftBar}
        sortShortcuts={sortShortcuts}
        shortcuts={shortcuts}
        openPaymentModal={openPaymentModal}
      />

      {/* Center content */}
      <div className="wotodo__content wotodo__content_v2">
        {/* Center area */}
        <div className="wotodo-month-item">
          {
            // Calendar view
            calendarView ? (
              <Calendar
                toggleLimitHit={toggleLimitHit}
                togglePageLoaded={togglePageLoaded}
                setSavePending={setSavePending}
                /* documentId={currentDocumentId} */
                shortcuts={shortcuts}
                toggleShortcut={toggleShortcut}
                /* toggleIsSaved={toggleIsSaved} */
              />
            ) : (
              // Single editor view
              <Editor
                focusedEditor={true}
                toggleLimitHit={toggleLimitHit}
                togglePageLoaded={togglePageLoaded}
                setSavePending={setSavePending}
                documentId={currentDocumentId}
                shortcuts={shortcuts}
                toggleShortcut={toggleShortcut}
                /* toggleIsSaved={toggleIsSaved} */
              />
            )
          }
        </div>
      </div>

      {/* Payment Modal (only if account is not yet paid) */}
      {!userData?.isPaid ? (
        <ModalPayment
          isOpen={modalPaymentState}
          modalClose={() => closePaymentModal()}
        />
      ) : (
        ""
      )}
    </div>
  );
}
