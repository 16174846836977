/**
 * Functions to work with IndexedDB
 *
 * Based on Dexie:
 * https://dexie.org/
 *
 * @doc https://dexie.org/docs/
 *
 * GitHub:
 * https://github.com/dfahlander/Dexie.js
 * React example:
 * https://github.com/dfahlander/Dexie.js/tree/master/samples/react/src
 *
 * Examples:
 * https://fireflysemantics.medium.com/managing-contacts-with-dexie-9eed629e5ad7
 */

// Dexie lib
import Dexie from "dexie";

// Firestore main lib
import firebase from "firebase/app";

/*
	Storage settings
*/

class LocalStorage {
  // documents = null

  db = null;
  dbVersion = 6;

  /**
   * Construct the local storage wrapper extending Dexie
   */
  //constructor() {
  // super(dbName)

  // console.log('LocalStorage.Init', dbName, dbVersion)

  /*
			Object store
		

		this.version(dbVersion).stores({
			/*
				Declare indexes:
				@doc https://dexie.org/docs/API-Reference#quick-reference
				First index is Primary key
			* /
			documents: 'id, title, is_page, is_date, date_set, *references, *blocks, [is_page+is_date+date_set]',
			savequeue: '++id, operation, knowledgebase_id, document_id, data, locked',
			versions: 'id'
		})
		*/

  /*
			Upgrade from version 1 to version 2
		* /
		db.version(2).stores({
			friends: "++id, [firstName+lastName], yearOfBirth, *tags", // Change indexes
			gameSessions: null // Delete table

		}).upgrade(tx => {
			// Will only be executed if a version below 2 was installed.
			return tx.table("friends").modify(friend => {
				friend.firstName = friend.name.split(' ')[0];
				friend.lastName = friend.name.split(' ')[1];
				friend.birthDate = new Date(new Date().getFullYear() - friend.age, 0);
				delete friend.name;
				delete friend.age;
			});
		})
		*/

  // this.documents = this.table('documents')
  //}

  /**
   * Initialize Dexie
   */
  initDb(knowledgebaseId) {
    // Already
    if (this.db) return true;

    // console.log('LocalStorage.initDb', knowledgebaseId)

    // Init Dexie
    this.db = new Dexie(knowledgebaseId);

    // Init stores
    this.db.version(this.dbVersion).stores({
      /*
				Declare indexes:
				@doc https://dexie.org/docs/API-Reference#quick-reference
				First index is Primary key
			*/
      documents:
        "id, title, is_page, is_date, date_set, *references, *blocks, [is_page+is_date+date_set]",
      savequeue: "++id, operation, knowledgebase_id, document_id, data, locked",
      docversions: "id",
      appconf: "app_var",
    });
  }

  /**
   * Read exact document from Local storage
   * @param {String} documentId
   * @return {Object} Document data or undefined if not found
   */
  async wtGet(documentId) {
    let documentData = await this.db.documents
      .where("id")
      .equals(documentId)
      .first();

    // console.log('LocalStorage.wtGet', documentId, documentData)

    // Convert the variables from LocalStorage format
    documentData = this.wtConvertAfterRead(documentData);

    return documentData;
  }

  /**
   * Blocks function
   * @param {Array} Array of Document Ids
   * @return {Array} Array of Documents (objects)
   */
  async wtGetDocBlocks(documentData) {
    // console.log('LocalStorage.wtGetDocBlocks', documentData)

    // Empty blocks?
    if (!documentData.length) {
      console.log("LocalStorage.wtGetDocBlocks.Empty");
      return [];
    }

    /*
			Get blocks
		*/

    const documentBlocks = await this.db.documents.bulkGet(
      // Array of primary keys of the objects to retrieve
      documentData
    );

    // console.log('LocalStorage.wtGetDocBlocks.documentBlocks', documentData, documentBlocks)

    /*
			Sort
			Dexie:
			- Every position in given key array will correspond to the same position in the resulting array.
			- For those keys that do not exist in the database, undefined will be returned in their place.
		*/
    let i = 0;
    let orderedBlocks = [];
    documentData.forEach((document) => {
      // Convert the variables from LocalStorage format
      const currentBlockData = this.wtConvertAfterRead(documentBlocks[i]);

      orderedBlocks.push(currentBlockData);
      i++;
    });

    // console.log('LocalStorage.wtGetDocBlocks.orderedBlocks', documentData, orderedBlocks)

    // Return blocks
    return orderedBlocks;
  }

  /**
   * Write to LocalStorage (add OR replace existing)
   * @param {Object} documentData
   */
  async wtWrite(documentData) {
    // console.log('LocalStorage.wtWrite', documentData)

    // Convert the variables to LocalStorage format
    documentData = this.wtConvertBeforeSave(documentData);

    // Adds new or replaces existing object in the object store.
    await this.db.documents.put(documentData);
  }

  /**
   * Write (add OR replace) all Block documents
   * of the main Document to LocalStorage
   * using Transaction. Check the vcersion before writing
   * (skip the write operation if version is lower than existing)
   *
   * @param {String} Document id
   * @param {int} Document version
   * @param {object} Document datetime updated, Javascript Date
   * @param {Array} Documents, array of objects
   * @return {Boolean} Result of the operation
   */
  async wtWriteDoc(documentId, documentVersion, datetimeUpdated, updatedData) {
    /*
			Settings
		*/

    const _isdebug = false;

    // No Documents in blocks?
    if (updatedData.length === 0) return false;

    if (_isdebug)
      console.log(
        "LocalStorage.wtWriteDoc",
        documentId,
        documentVersion,
        updatedData
      );

    // Convert the variables to LocalStorage format
    updatedData = this.wtConvertBeforeSave(updatedData);

    /*
			Run transaction!
		*/
    this.db
      .transaction("rw", this.db.documents, async () => {
        if (_isdebug)
          console.log("LocalStorage.wtWriteDoc.Transaction.Start", documentId);

        // Get Local storage version
        const localDocument = await this.db.documents.get(documentId);

        /*
				Datetime processing
			*/

        let localDatetimeUpdated = 0;
        if (localDocument && localDocument?.datetime_updated) {
          localDatetimeUpdated = localDocument.datetime_updated.getTime();
        }

        let newDatetimeUpdated = 0;
        if (datetimeUpdated) {
          newDatetimeUpdated = datetimeUpdated.getTime();
        }

        if (_isdebug) {
          console.log(
            "LocalStorage.wtWriteDoc.Transaction.localDocument",
            documentId,
            localDocument,
            localDatetimeUpdated,
            newDatetimeUpdated
          );
        }

        // Can we write?
        if (
          // The document is not found
          typeof localDocument === "undefined" ||
          /* // We have no version
				// || !localDocument?._version
				// || !documentVersion
				// Or local version is Outdated
				|| localDocument._version < documentVersion
				also check the datetimeupdated ??
					?? remove version check? */
          // Check datetime_updated
          // || (!localDatetimeUpdated && newDatetimeUpdated > 0)
          // || localDatetimeUpdated <= newDatetimeUpdated

          // Or new version is higher OR equal to local
          // || newDatetimeUpdated >= localDatetimeUpdated

          // Or new document update_datetime is exactly newer than local datetime (with milliseconds)
          newDatetimeUpdated > localDatetimeUpdated
        ) {
          if (_isdebug)
            console.log(
              "LocalStorage.wtWriteDoc.Transaction.Update",
              documentId,
              updatedData
            );

          // Bulk write
          await this.db.documents.bulkPut(updatedData);

          // We can't write
        } else {
          if (_isdebug)
            console.log("LocalStorage.wtWriteDoc.Transaction.Skip", documentId);
        }

        // Transaction Complete
      })
      .then(() => {
        if (_isdebug)
          console.log("LocalStorage.wtWriteDoc.Complete", documentId);

        // Transaction Failed
      })
      .catch((err) => {
        console.error("LocalStorage.wtWriteDoc.Error", documentId, err.stack);
      });

    return true;
  }

  /**
   * Write Documents in bulk using Transaction
   * @param {Array} Documents, array of objects
   * @return {Boolean} Result of the operation
   */
  async wtWriteBulk(documentsArray) {
    // No Documents?
    if (documentsArray.length === 0) return false;

    // console.log('LocalStorage.wtWriteBulk', documentsArray)

    // Convert the variables to LocalStorage format
    documentsArray = this.wtConvertBeforeSave(documentsArray);

    /*
			Run transaction!
		*/
    this.db
      .transaction("rw", this.db.documents, async () => {
        // console.log('LocalStorage.wtWriteBulk.Transaction.Start')

        // Bulk write
        await this.db.documents.bulkPut(documentsArray);

        // Transaction Complete
      })
      .then(() => {
        // console.log('LocalStorage.wtWriteBulk.Complete')
        // Transaction Failed
      })
      .catch((err) => {
        console.error("LocalStorage.wtWriteBulk.Error", err.stack);
      });

    return true;
  }

  /**
   * Write to LocalStorage (update existing document)
   * @param {String} Document Id
   * @param {Object} Data to update
   */
  async wtUpdate(documentId, updatedData) {
    // console.log('LocalStorage.wtUpdate', documentId, updatedData)

    // Merge requested?
    if (
      "blocks_arrayUnion" in updatedData ||
      "references_arrayUnion" in updatedData
    ) {
      // Merge blocks
      if ("blocks_arrayUnion" in updatedData) {
        await this.db.documents
          .where("id")
          .equals(documentId)
          .modify((docData) => {
            docData.blocks = docData.blocks
              .concat(updatedData.blocks_arrayUnion)
              .filter((value, index, self) => {
                return self.indexOf(value) === index;
              });
          });
      }

      // Merge references
      if ("references_arrayUnion" in updatedData) {
        await this.db.documents
          .where("id")
          .equals(documentId)
          .modify((docData) => {
            // We have no references yet
            if (
              !("references" in docData) ||
              !Array.isArray(docData.references)
            ) {
              docData.references = updatedData.references_arrayUnion;

              // Append to references
            } else {
              // Concat
              docData.references = docData.references.concat(
                updatedData.references_arrayUnion
              );

              // Unique
              docData.references = [...new Set(docData.references)];
              /* .filter((value, index, self) => {
									return self.indexOf(value) === index
								}) */
            }
          });
      }

      // Simple update
    } else {
      /*
				Removing fields
			*/

      if (
        "is_date" in updatedData &&
        updatedData.is_date === "__spec_fieldValue_delete"
      ) {
        await this.db.documents
          .where("id")
          .equals(documentId)
          .modify((docData) => {
            delete docData.is_date;
          });
      }

      if (
        "date_set" in updatedData &&
        updatedData.date_set === "__spec_fieldValue_delete"
      ) {
        await this.db.documents
          .where("id")
          .equals(documentId)
          .modify((docData) => {
            delete docData.date_set;
          });
      }

      /*
				Updating the document
			*/

      // Convert the variables to LocalStorage format
      updatedData = this.wtConvertBeforeSave(updatedData, false);

      // Adds new or replaces existing object in the object store.
      await this.db.documents.update(documentId, updatedData);
    }
  }

  /**
   * Delete the document from LocalStorage
   * @param {String} Document Id
   */
  async wtDelete(documentId) {
    // console.log('LocalStorage.wtDelete', documentId)

    // Delete object from store
    await this.db.documents.delete(documentId);
  }

  /**
   * Document references, delete
   * @param {String} Document id to which the reference is set
   * @param {String} Block id from which the reference is going
   */
  async wtReferenceDelete(linkId, blockId) {
    // console.log('LocalStorage.wtReferenceDelete', linkId, blockId)

    // Delete element from array
    await this.db.documents
      .where("id")
      .equals(linkId)
      .modify((docData) => {
        if ("references" in docData && Array.isArray(docData.references)) {
          // Here you can modify the references property to remove blockId from it:
          docData.references = docData.references.filter((p) => p !== blockId);
        }
      });
  }

  /**
   * Document references, add
   * @param {String} Document id to which the reference is set
   * @param {String} Block id from which the reference is going
   */
  async wtReferenceAdd(linkId, blockId) {
    // console.log('LocalStorage.wtReferenceAdd', linkId, blockId)

    // Delete element from array
    await this.db.documents
      .where("id")
      .equals(linkId)
      .modify((docData) => {
        // We have no references yet
        if (!("references" in docData) || !Array.isArray(docData.references)) {
          docData.references = [blockId];

          // Append to references
        } else {
          // Add
          docData.references.push(blockId);

          // Remove duplicates
          docData.references = [...new Set(docData.references)];
        }
      });
  }

  /**
   * Write to local save Queue to send to RemoteStorage
   * @param {String} Knowledgebase Id
   * @param {String} Document Id
   * @param {Object} Data to save
   */
  async wtSaveQueueAdd(
    operationType,
    knowledgebaseId,
    documentId,
    updatedData
  ) {
    // console.log('LocalStorage.wtSaveQueueAdd', operationType, knowledgebaseId, documentId, updatedData)

    /*
			Pre-conversion of the data
		*/

    updatedData = this.wtSaveQueueConvertBeforeSave(updatedData);

    /*
			Operation types: .. see StorageKeeper.js
		*/

    // Adds entry to the queue
    await this.db.savequeue.add({
      operation: operationType,
      knowledgebase_id: knowledgebaseId,
      document_id: documentId,
      data: updatedData,
      locked: 0,
    });
  }

  /**
   * Delete the document from LocalStorage
   * @param {int} Entry Id
   */
  async wtSaveQueueDelete(entryId) {
    // console.log('LocalStorage.wtSaveQueueDelete', entryId)

    // Delete object from store
    await this.db.savequeue.delete(entryId);
  }

  /**
   * Read local Queue ordered by id
   * @return {Array} List of entries to process (objects)
   */
  async wtReadQueue() {
    // console.log('LocalStorage.wtReadQueue')

    /*
			Run transaction!
		*/
    const currentQueue = await this.db.transaction(
      "rw",
      this.db.savequeue,
      async () => {
        // console.log('LocalStorage.wtReadQueue.Transaction.Start')

        // Get Local storage version
        const unlockedEntries = await this.db.savequeue
          .where("locked")
          .equals(0)
          // I can't use orderBy as WHERE uses OTHER btree for search
          // orderBy will work ONLY if the where will be on the same index!
          // .orderBy(':id')
          .sortBy("id"); // returns Array containing the found objects
        // .toArray()

        // console.log('LocalStorage.wtReadQueue.Transaction.unlockedEntries', unlockedEntries)

        // No entries?
        if (!unlockedEntries || unlockedEntries.length === 0) {
          // console.log('LocalStorage.wtReadQueue.Transaction.noEntries')
          return [];
        }

        /*
				Got entries, pre-process it
			*/

        for (var i = 0; i < unlockedEntries.length; i++) {
          // Lock entry
          await this.db.savequeue.update(unlockedEntries[i].id, { locked: 1 });

          // Convert special params in the queue
          unlockedEntries[i].data = this.wtSaveQueueConvertAfterRead(
            unlockedEntries[i].data
          );
        }

        // Return entries
        // console.log('LocalStorage.wtReadQueue.entries', unlockedEntries)
        return unlockedEntries;
      }
    );

    // console.log('LocalStorage.wtReadQueue.currentQueue', currentQueue)
    return currentQueue;
  }

  /**
   * Unlock locked Queue entries
   * @return {Array} List of entries to unlock
   * @return {boolean}
   */
  async wtUnlockQueue(currentQueue) {
    console.log("LocalStorage.wtUnlockQueue", currentQueue);

    /*
			Run transaction!
		*/
    await this.db.transaction("rw", this.db.savequeue, async () => {
      // console.log('LocalStorage.wtUnlockQueue.Transaction.Start')

      for (var i = 0; i < currentQueue.length; i++) {
        await this.db.savequeue.update(currentQueue[i].id, { locked: 0 });
      }

      return true;
    });

    console.log("LocalStorage.wtUnlockQueue.currentQueue", currentQueue);
    return true;
  }

  /**
   * How many entries do we have in local queue
   * @return {int} Number of entries
   */
  async wtQueueEntries() {
    // console.log('LocalStorage.wtQueueEntries')

    // Get Local storage version
    const queueEntries = await this.db.savequeue.toArray();

    // console.log('LocalStorage.wtQueueEntries', queueEntries)

    // No entries?
    if (!queueEntries) return 0;

    // Return entries count
    return queueEntries.length;
  }

  /**
   * Save the Document and it's Blocks in LocalStorage if the version allows
   * @param {String} Document id
   * @param {Int} Document version
   * @param {Object} Current blocks
   * @param {Array} Missing blocks ids
   */
  async wtStoreDocument(
    documentId,
    documentVersion,
    blocksCurrent,
    blocksMissing
  ) {
    // console.log('LocalStorage.wtStoreDocument', documentId, documentVersion, blocksCurrent, blocksMissing)

    /*
			Prepare data
		*/

    // Array of current Block ids (to save in the main document)
    const currentBlockIds = Object.keys(blocksCurrent);

    /*
			Run transaction
		*/

    await this.db.transaction("rw", this.db.documents, async () => {
      /*
				Get current local stored version
			*/

      // Get Local storage version
      const localDocument = await this.db.documents.get(documentId);

      // console.log('LocalStorage.wtStoreDocument.Transaction.localDocument', localDocument)

      // Reset version to zero if are not set
      if (!("_version" in localDocument)) localDocument._version = 0;

      // Can't write, version is too old
      if (localDocument._version > documentVersion) {
        // console.log('LocalStorage.wtStoreDocument.oldVersion!(local, writing)', localDocument._version, documentVersion)
        // We are ok to write
      } else {
        // console.log('LocalStorage.wtStoreDocument.versionOk(local, writing)', localDocument._version, documentVersion)

        /*
					Remove blocks
				*/

        if (blocksMissing && blocksMissing.length > 0) {
          // console.log('LocalStorage.wtStoreDocument.blocksMissing', blocksMissing)

          await this.db.documents.bulkDelete(blocksMissing);
        }

        /*
					Add/update blocks
				*/

        // Convert to Array
        let blocksArray = [];
        for (let blockId in blocksCurrent) {
          blocksArray.push({
            id: blockId,
            is_block: true,
            parent_id: documentId,
            json: blocksCurrent[blockId].json,
          });
        }
        // console.log('LocalStorage.wtStoreDocument.blocksArray', blocksArray)

        // Convert the variables to LocalStorage format
        blocksArray = this.wtConvertBeforeSave(blocksArray);

        // Save
        await this.db.documents.bulkPut(blocksArray);

        /*
					Save THE document
				*/

        let documentUpdateData = {
          blocks: currentBlockIds,
          // WE CAN'T CHANGER THIS, AS THIS SHOULD BE CHANGED AT FIRESTORE ONLY!
          // datetime_updated: firebase.firestore.Timestamp.now(),
        };

        // Convert the variables to LocalStorage format
        documentUpdateData = this.wtConvertBeforeSave(documentUpdateData);

        this.db.documents.update(documentId, documentUpdateData);
      }
    });
  }

  /**
   * Set actual document version
   * @param {String} Document id
   * @param {int} Current version
   * @param {Object} Firestore timestamp
   */
  async wtDocumentVersionSet(
    documentId,
    currentVersion,
    firestoreDatetimeUpdated
  ) {
    // console.log('LocalStorage.wtDocumentVersionSet', documentId, currentVersion, firestoreDatetimeUpdated)

    // Convert to Date
    const datetimeUpdated = firestoreDatetimeUpdated.toDate();

    // Adds new or replaces existing object in the object store.
    await this.db.docversions.put({
      id: documentId,
      version: currentVersion,
      process: global.processId,
      datetime_updated: datetimeUpdated,
    });
  }

  /**
   * Set actual document version
   * @param {String} Document id
   * @return {Object} Version info (version: int, process: string)
   *
   */
  async wtDocumentVersionGet(documentId) {
    const versionData = await this.db.docversions
      .where("id")
      .equals(documentId)
      .first();

    // console.log('LocalStorage.wtDocumentVersionGet', documentId, versionData)

    // Not found?
    if (!versionData) return false;

    return versionData;
  }

  /**
   * Search document with exact date
   * @param {Object} Javascript Date object
   * @return {Object} Document data or undefined if not found
   */
  async wtSearchDocumentDate(dateObject) {
    // console.log('LocalStorage.wtSearchDocumentDate', dateObject)

    // Search Date document
    let documentData = await this.db.documents
      .where({
        is_page: 1,
        is_date: 1,
        date_set: dateObject,
      })
      .first();

    // console.log('LocalStorage.wtSearchDocumentDate.documentData', documentData)

    // Convert the variables from LocalStorage format
    documentData = this.wtConvertAfterRead(documentData);

    return documentData;
  }

  /**
   * Get a document before exact date
   * @param {Object} Javascript Date object
   * @return {Object} Document data or undefined if not found
   */
  async wtSearchDocumentDateBefore(jsDateObject) {
    // console.log('LocalStorage.wtSearchDocumentDateBefore', jsDateObject)

    // Search Date document
    const foundEntries = await this.db.documents
      /* .where('is_page').equals(1)
			.where('is_date').equals(1) */
      .where("date_set")
      .below(jsDateObject)
      // .orderBy('date_set')
      .and((doc) => doc.is_page === 1 && doc.is_date === 1)
      .reverse()
      .sortBy("date_set");
    // .first()

    // console.log('LocalStorage.wtSearchDocumentDateBefore.documentData', foundEntries)

    // Not found?
    if (!foundEntries || foundEntries.length === 0) {
      return undefined;
    }

    // Get the first document
    let documentData = foundEntries[0];

    // Convert the variables from LocalStorage format
    documentData = this.wtConvertAfterRead(documentData);

    return documentData;
  }

  /**
   * Search a document with conditions
   * @param {Object} Search conditions
   * @return {Object} Document data or undefined if not found
   */
  async wtSearch(searchConditions) {
    let documentData = await this.db.documents
      .where("title")
      .equals(searchConditions.title)
      .first();

    // console.log('LocalStorage.wtSearch', searchConditions, documentData)

    // Convert the variables from LocalStorage format
    documentData = this.wtConvertAfterRead(documentData);

    return documentData;
  }

  /**
   * LocalStorage has some limitations on the type of the fields:
   * https://dexie.org/docs/Indexable-Type
   * We CAN'T index: booleans or objects,
   * so we need to convert them.
   * @param {object} Document data to process
   * @return {object} Document data to return
   */
  wtConvertBeforeSave(documentData, versionAdd = true) {
    // Is Array?
    if (Array.isArray(documentData)) {
      // console.log('wtConvertBeforeSave.array', documentData)

      // Recursive processing
      return documentData.map((currentDocument) => {
        return this.wtConvertBeforeSave(currentDocument);
      });

      // Is Object
    } else if (typeof documentData === "object" && documentData !== null) {
      const newDocument = { ...documentData };

      // console.log('wtConvertBeforeSave.before', newDocument.id, newDocument)

      /*
				Removing unnecessary data
			*/

      if ("knowledgebaseId" in newDocument) delete newDocument.knowledgebaseId;

      if ("proseMirrorJson" in newDocument) delete newDocument.proseMirrorJson;

      if (
        "is_date" in newDocument &&
        newDocument.is_date === "__spec_fieldValue_delete"
      ) {
        delete newDocument.is_date;
      }

      if (
        "date_set" in newDocument &&
        newDocument.date_set === "__spec_fieldValue_delete"
      ) {
        delete newDocument.date_set;
      }

      /*
				Missing fields
			*/

      // Check version
      if (versionAdd) {
        if (!("_version" in newDocument)) newDocument._version = 0;
      }

      /*
				Booleans conversion
			*/

      // Is page
      if ("is_page" in newDocument)
        newDocument.is_page = newDocument.is_page ? 1 : 0;

      // Is block
      if ("is_block" in newDocument)
        newDocument.is_block = newDocument.is_block ? 1 : 0;

      // Is date
      if ("is_date" in newDocument)
        newDocument.is_date = newDocument.is_date ? 1 : 0;

      // Is shared
      if ("is_shared" in newDocument)
        newDocument.is_shared = newDocument.is_shared ? 1 : 0;

      /*
				Objects conversion
			*/

      /*
				Convert Firestore Timestamp object to Javascript Date
				https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp#fromdate
			*/

      // Date set
      if ("date_set" in newDocument) {
        // Is Firestore Timestmap?
        if (newDocument.date_set instanceof firebase.firestore.Timestamp) {
          // Convert to Javascript Date Object
          newDocument.date_set = newDocument.date_set.toDate();
        }
      }

      // Updated
      if ("datetime_updated" in newDocument) {
        // Is Firestore Timestmap?
        if (
          newDocument.datetime_updated instanceof firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newDocument.datetime_updated = newDocument.datetime_updated.toDate();
        }
      }

      // Created
      if ("datetime_created" in newDocument) {
        // Is Firestore Timestmap?
        if (
          newDocument.datetime_created instanceof firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newDocument.datetime_created = newDocument.datetime_created.toDate();
        }
      }

      // console.log('wtConvertBeforeSave.after', newDocument.id, newDocument)

      return newDocument;

      // Unknown
    } else {
      console.error("wtConvertBeforeSave.unknownFormat", documentData);
    }

    return documentData;
  }

  /**
   * We need to convert the LocalStorage format to App format after reading
   * @param {object} Document data to process
   * @return {object} Document data to return
   */
  wtConvertAfterRead(documentData) {
    // Empty? (undefined, null, ..)
    if (!documentData) {
      // do nothing
      // Is Array?
    } else if (Array.isArray(documentData)) {
      // Recursive processing
      return documentData.map((currentDocument) => {
        return this.wtConvertAfterRead(currentDocument);
      });

      // Is Object
    } else if (typeof documentData === "object" && documentData !== null) {
      const newDocument = { ...documentData };

      /*
				Missing fields
			*/

      // Check version
      if (!("_version" in newDocument)) newDocument._version = 0;

      /*
				Booleans conversion
			*/

      // Is page
      if ("is_page" in newDocument && typeof newDocument.is_page === "number")
        newDocument.is_page = newDocument.is_page === 1 ? true : false;

      // Is block
      if ("is_block" in newDocument && typeof newDocument.is_block === "number")
        newDocument.is_block = newDocument.is_block === 1 ? true : false;

      // Is date
      if ("is_date" in newDocument && typeof newDocument.is_date === "number")
        newDocument.is_date = newDocument.is_date === 1 ? true : false;

      // Is shared
      if (
        "is_shared" in newDocument &&
        typeof newDocument.is_shared === "number"
      )
        newDocument.is_shared = newDocument.is_shared === 1 ? true : false;

      /*
				Objects conversion
			*/

      /*
				Convert Firestore Timestamp object to Javascript Date
				https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp#fromdate
			*/

      // Date set
      if ("date_set" in newDocument) {
        // Is Firestore Timestmap?
        if (newDocument.date_set instanceof firebase.firestore.Timestamp) {
          // Convert to Javascript Date Object
          newDocument.date_set = newDocument.date_set.toDate();
        }
      }

      // Updated
      if ("datetime_updated" in newDocument) {
        // Is Firestore Timestmap?
        if (
          newDocument.datetime_updated instanceof firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newDocument.datetime_updated = newDocument.datetime_updated.toDate();
        }
      }

      // Created
      if ("datetime_created" in newDocument) {
        // Is Firestore Timestmap?
        if (
          newDocument.datetime_created instanceof firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newDocument.datetime_created = newDocument.datetime_created.toDate();
        }
      }

      return newDocument;

      // Unknown
    } else {
      console.error("wtConvertAfterRead.unknownFormat", documentData);
    }

    return documentData;
  }

  /**
   * LocalStorage has some limitations on the type of the fields:
   * https://dexie.org/docs/Indexable-Type
   * We CAN'T store recursive fields for Firestore like "instanceof fb.firestore.FieldValue"
   * @doc https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#delete
   * so we need to convert them.
   * @param {object} Save data to process
   * @return {object} Save data to return
   */
  wtSaveQueueConvertBeforeSave(saveData) {
    // Empty? (undefined, null, ..)
    if (!saveData || typeof saveData === "string") {
      // do nothing
      // Is Array?
    } else if (Array.isArray(saveData)) {
      // console.log('wtSaveQueueConvertBeforeSave.array', saveData)

      // Recursive processing
      return saveData.map((currentDocument) => {
        return this.wtSaveQueueConvertBeforeSave(currentDocument);
      });

      // Is Object
    } else if (typeof saveData === "object" && saveData !== null) {
      const newSaveData = { ...saveData };

      // console.log('wtSaveQueueConvertBeforeSave.before', newSaveData)

      /*
				Objects conversion
			*/

      /*
				Value could be "instanceof fb.firestore.FieldValue"
				when deleting a field or updating it
				@doc https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#delete
				
				CAN'T CONVERT! as FieldValue could have different commands!
			* /

			// Date set
			if ('date_set' in newSaveData) {
				// Is Firestore FieldValue?
				if (newSaveData.date_set instanceof firebase.firestore.FieldValue) {
					// Convert to special string for later back-conversion
					newSaveData.date_set = '__spec_fieldValue_delete'
				}
			}

			// Is date
			if ('is_date' in newSaveData) {
				// Is Firestore FieldValue?
				if (newSaveData.is_date instanceof firebase.firestore.FieldValue) {
					// Convert to special string for later back-conversion
					newSaveData.is_date = '__spec_fieldValue_delete'
				}
			}
			*/

      /*
				Objects conversion
			*/

      /*
				Convert Firestore Timestamp object to Javascript Date
				https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp#fromdate
			*/

      // Date set
      if ("date_set" in newSaveData) {
        // Is Firestore Timestmap?
        if (newSaveData.date_set instanceof firebase.firestore.Timestamp) {
          // Convert to Javascript Date Object
          newSaveData.date_set = newSaveData.date_set.toDate();
        }
      }

      // Updated
      if ("datetime_updated" in newSaveData) {
        // Is Firestore Timestmap?
        if (
          newSaveData.datetime_updated instanceof firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newSaveData.datetime_updated = newSaveData.datetime_updated.toDate();
        }
      }

      // Created
      if ("datetime_created" in newSaveData) {
        // Is Firestore Timestmap?
        if (
          newSaveData.datetime_created instanceof firebase.firestore.Timestamp
        ) {
          // Convert to Javascript Date Object
          newSaveData.datetime_created = newSaveData.datetime_created.toDate();
        }
      }

      // console.log('wtSaveQueueConvertBeforeSave.after', newSaveData)

      return newSaveData;

      // Unknown
    } else {
      console.error("wtSaveQueueConvertBeforeSave.unknownFormat", saveData);
    }

    return saveData;
  }

  /**
   * LocalStorage has some limitations on the type of the fields:
   * https://dexie.org/docs/Indexable-Type
   * We CAN'T store recursive fields for Firestore like "instanceof fb.firestore.FieldValue"
   * @doc https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#delete
   * so we need to convert them.
   * @param {object} Save data to process
   * @return {object} Save data to return
   */
  wtSaveQueueConvertAfterRead(saveData) {
    // Empty? (undefined, null, ..)
    if (!saveData || typeof saveData === "string") {
      // do nothing
      // Is Array?
    } else if (Array.isArray(saveData)) {
      // console.log('wtSaveQueueConvertAfterRead.array', saveData)

      // Recursive processing
      return saveData.map((currentDocument) => {
        return this.wtSaveQueueConvertAfterRead(currentDocument);
      });

      // Is Object
    } else if (typeof saveData === "object" && saveData !== null) {
      const newSaveData = { ...saveData };

      // console.log('wtSaveQueueConvertAfterRead.before', newSaveData)

      /*
				Objects conversion
			*/

      /*
				Value could be "instanceof fb.firestore.FieldValue"
				when deleting a field or updating it
				@doc https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#delete
			*/

      /*
				FieldValue.delete
			*/

      // Date set
      if ("date_set" in newSaveData) {
        // Is special string for fieldValue.delete operation
        if (newSaveData.date_set === "__spec_fieldValue_delete") {
          // Convert to FieldValue delete function
          newSaveData.date_set = firebase.firestore.FieldValue.delete();
        }
      }

      // Is date
      if ("is_date" in newSaveData) {
        // Is special string for fieldValue.delete operation
        if (newSaveData.is_date === "__spec_fieldValue_delete") {
          // Convert to FieldValue delete function
          newSaveData.is_date = firebase.firestore.FieldValue.delete();
        }
      }

      /*
				FieldValue.arrayUnion
			*/

      // Blocks
      if ("blocks_arrayUnion" in newSaveData) {
        // Convert to FieldValue arrayUnion function
        newSaveData.blocks = firebase.firestore.FieldValue.arrayUnion(
          ...newSaveData.blocks_arrayUnion
        );
        delete newSaveData.blocks_arrayUnion;
      }

      // References
      if ("references_arrayUnion" in newSaveData) {
        // Convert to FieldValue arrayUnion function
        newSaveData.references = firebase.firestore.FieldValue.arrayUnion(
          ...newSaveData.references_arrayUnion
        );
        delete newSaveData.references_arrayUnion;
      }

      /*
				Objects conversion
			*/

      /*
				Convert Javascript Date object to Firestore Timestamp object
				https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp#fromdate

				IMPORTANT:
				value could be "instanceof fb.firestore.FieldValue"
				when deleting a field or updating it
				@doc https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue#delete
			*/

      // Date set
      if ("date_set" in newSaveData) {
        // Check before conversion
        if (
          // Is NOT Firestore Timestmap?
          !(newSaveData.date_set instanceof firebase.firestore.Timestamp) &&
          // AND is a Javascript Date
          newSaveData.date_set instanceof Date
        ) {
          // Convert to Firestore Timestmap
          newSaveData.date_set = firebase.firestore.Timestamp.fromDate(
            newSaveData.date_set
          );
        }
      }

      // Updated
      if ("datetime_updated" in newSaveData) {
        // Check before conversion
        if (
          // Is NOT Firestore Timestmap?
          !(
            newSaveData.datetime_updated instanceof firebase.firestore.Timestamp
          ) &&
          // AND is a Javascript Date
          newSaveData.datetime_updated instanceof Date
        ) {
          // Convert to Firestore Timestmap
          newSaveData.datetime_updated = firebase.firestore.Timestamp.fromDate(
            newSaveData.datetime_updated
          );
        }
      }

      // Created
      if ("datetime_created" in newSaveData) {
        // Check before conversion
        if (
          // Is NOT Firestore Timestmap?
          !(
            newSaveData.datetime_created instanceof firebase.firestore.Timestamp
          ) &&
          // AND is a Javascript Date
          newSaveData.datetime_created instanceof Date
        ) {
          // Convert to Firestore Timestmap
          newSaveData.datetime_created = firebase.firestore.Timestamp.fromDate(
            newSaveData.datetime_created
          );
        }
      }

      /*
				Finished
			*/

      // console.log('wtSaveQueueConvertAfterRead.after', newSaveData)

      return newSaveData;

      // Unknown
    } else {
      console.error("wtSaveQueueConvertAfterRead.unknownFormat", saveData);
    }

    return saveData;
  }

  /**
   * Save app configuration
   * @param {String} Variable name
   * @param {any} Variable data
   */
  async wtAppConfSet(appVar, varData) {
    console.log("LocalStorage.wtAppConfSet", appVar, varData);

    // Adds new or replaces existing object in the object store.
    await this.db.appconf.put({
      app_var: appVar,
      var_data: varData,
    });
  }

  /**
   * Get app configuration
   * @param {String} Variable name
   * @return {any} Variable data
   *
   */
  async wtAppConfGet(appVar) {
    const varData = await this.db.appconf
      .where("app_var")
      .equals(appVar)
      .first();

    // console.log('LocalStorage.wtAppConfGet', appVar, varData)

    // Not found?
    if (!varData) return false;

    return varData?.var_data;
  }

  /**
   * Search for a string in Documents Titles
   * @param {String} String to search in Titles
   * @param {Int} Limit the qty of results
   * @return {Array} List of documents found [{id: 123, title: 'Document'}, ..]
   */
  async wtTitleSearch(searchString, limit = 10) {
    /*
			No search?
		*/

    searchString = searchString.trim().toLocaleLowerCase();
    if (searchString === "") {
      return [];
    }

    /*
			Get all local documents
		*/

    const allDocuments = await this.db.documents
      .where("is_page")
      .equals(1)
      .toArray();

    /*
			Search for it!
		*/

    let foundDocuments = [];
    for (const currentDocument of allDocuments) {
      // Invalid document?
      if (
        !currentDocument?.id ||
        !currentDocument?.title ||
        currentDocument.title.trim() === ""
      ) {
        continue;
      }

      // Found!
      if (
        currentDocument.title
          .trim()
          .toLocaleLowerCase()
          .indexOf(searchString) !== -1
      ) {
        foundDocuments.push({
          id: currentDocument.id,
          title: currentDocument.title,
        });
      }
    }

    /*
			Limit?
		*/

    if (limit > 0) {
      foundDocuments = foundDocuments.slice(0, limit);
    }

    return foundDocuments;
  }

  /**
   * Save app configuration using Transaction check
   * @param {String} Variable name
   * @param {Object} Javascript Date
   */
  async wtAppConfSetTransaction(appVar, varData) {
    // console.log('LocalStorage.wtAppConfSetTransaction', appVar, varData)

    /*
			Run transaction!
		*/
    this.db
      .transaction("rw", this.db.appconf, async () => {
        // Get Local storage version
        const localDocument = await this.db.appconf
          .where("app_var")
          .equals(appVar)
          .first();

        // console.log('LocalStorage.wtAppConfSetTransaction.localDocument', localDocument)

        // Can we write?
        if (
          // The document is not found or has invalid data
          typeof localDocument === "undefined" ||
          !localDocument?.var_data ||
          // Value to write is higher
          varData.getTime() > localDocument?.var_data.getTime()
        ) {
          // console.log('LocalStorage.wtAppConfSetTransaction.Transaction.Save', localDocument?.var_data, varData)

          // Bulk write
          await this.db.appconf.put({
            app_var: appVar,
            var_data: varData,
          });

          // We can't write
        } else {
          // console.log('LocalStorage.wtAppConfSetTransaction.Transaction.Skip', localDocument?.var_data, varData)
        }

        // Transaction Complete
      })
      .then(() => {
        // console.log('LocalStorage.wtAppConfSetTransaction.Complete')
        // Transaction Failed
      })
      .catch((err) => {
        console.error("LocalStorage.wtAppConfSetTransaction.Error", err.stack);
      });
  }

  /**
   * Save the document title only if it was changed
   * @param {Object} documentData
   */
  async wtWriteTitleSave(documentData) {
    // console.log('LocalStorage.wtWriteTitleSave', documentData)

    // Convert the variables to LocalStorage format
    documentData = this.wtConvertBeforeSave(documentData);

    /*
			Run transaction!
		*/
    this.db
      .transaction("rw", this.db.documents, async () => {
        // Get Local storage version
        const localDocument = await this.db.documents
          .where("id")
          .equals(documentData.id)
          .first();

        // console.log('LocalStorage.wtWriteTitleSave.localDocument', localDocument)

        // Can we write?
        if (
          // The document is not found
          typeof localDocument === "undefined" ||
          !localDocument?.datetime_title_updated ||
          !documentData?.datetime_title_updated ||
          // Value to write is higher
          documentData.datetime_title_updated.getTime() >
            localDocument.datetime_title_updated.getTime()
        ) {
          // console.log('LocalStorage.wtWriteTitleSave.Transaction.Save', documentData?.datetime_title_updated, localDocument?.datetime_title_updated)

          // Bulk write
          await this.db.documents.put(documentData);

          // We can't write
        } else {
          // console.log('LocalStorage.wtWriteTitleSave.Transaction.Skip', documentData?.datetime_title_updated, localDocument?.datetime_title_updated)
        }

        // Transaction Complete
      })
      .then(() => {
        // console.log('LocalStorage.wtWriteTitleSave.Complete')
        // Transaction Failed
      })
      .catch((err) => {
        console.error("LocalStorage.wtWriteTitleSave.Error", err.stack);
      });
  }

  /**
   * Update the document title only if it was changed
   * @param {String} documentId
   * @param {Object} documentData
   */
  async wtWriteTitleUpdate(documentId, documentData) {
    // console.log('LocalStorage.wtWriteTitleUpdate', documentId, documentData)

    // Convert the variables to LocalStorage format
    documentData = this.wtConvertBeforeSave(documentData);

    /*
			Run transaction!
		*/
    this.db
      .transaction("rw", this.db.documents, async () => {
        // Get Local storage version
        const localDocument = await this.db.documents
          .where("id")
          .equals(documentId)
          .first();

        // console.log('LocalStorage.wtWriteTitleUpdate.localDocument', localDocument)

        // Can we write?
        if (
          // The document is found
          localDocument ||
          !localDocument?.datetime_title_updated ||
          // Value to write is higher
          documentData.datetime_title_updated.getTime() >
            localDocument.datetime_title_updated.getTime()
        ) {
          // console.log('LocalStorage.wtWriteTitleUpdate.Transaction.Save', documentData?.datetime_title_updated, localDocument?.datetime_title_updated)

          // Bulk write
          await this.db.documents.update(documentId, documentData);

          // We can't write
        } else {
          // console.log('LocalStorage.wtWriteTitleUpdate.Transaction.Skip', documentData?.datetime_title_updated, localDocument?.datetime_title_updated)
        }

        // Transaction Complete
      })
      .then(() => {
        // console.log('LocalStorage.wtWriteTitleUpdate.Complete')
        // Transaction Failed
      })
      .catch((err) => {
        console.error("LocalStorage.wtWriteTitleUpdate.Error", err.stack);
      });
  }
}

export default new LocalStorage();
