/**
 * Prosemirrror
 */
import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

export const placeholder = () => {
  return new Plugin({
    /* props: {
			decorations: state => {
				const decorations = []

				const decorate = (node, pos) => {
					// console.log('decorate', node)

					if (node.type.isBlock && node.childCount === 0) {
						decorations.push(
							Decoration.node(pos, pos + node.nodeSize, {
								class: 'wt-editor-placeholder'
							})
						)
					}
				}

				state.doc.descendants(decorate)

				// const transaction = state.tr.setMeta('focused', true)
                // view.dispatch(transaction)

				return DecorationSet.create(state.doc, decorations)
			}
		} */
    props: {
      decorations(state) {
        const doc = state.doc;

        if (
          doc.childCount > 1 ||
          !doc.firstChild.isTextblock ||
          doc.firstChild.content.size > 0
        )
          return;

        const placeHolder = document.createElement("div");
        placeHolder.classList.add("wt-editor-placeholder");
        placeHolder.textContent = "Click here to start writing";

        return DecorationSet.create(doc, [Decoration.widget(1, placeHolder)]);
      },
    },
  });
};
