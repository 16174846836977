import React, { useState, useEffect, useCallback } from "react";
const initialState = {
  currentList: [],
  currentPage: 1,
  countPage: 1,
  isNext: false,
  isBack: false,
};

function usePagination(documentList, itemsOnPage = 30) {
  const [state, setState] = useState(initialState);

  const reset = useCallback(
    (pagePosition = 1) => {
      const startSlice = (pagePosition - 1) * itemsOnPage;
      const stopSlice =
        pagePosition * itemsOnPage > documentList.length
          ? documentList.length
          : pagePosition * itemsOnPage;

      setState({
        currentPage: 1,
        currentList: documentList.slice(startSlice, stopSlice),
        countPage: Math.ceil(documentList.length / itemsOnPage),
      });
    },
    [documentList, itemsOnPage]
  );

  const changePage = useCallback(
    (pagePosition = 1) => {
      const startSlice = (pagePosition - 1) * itemsOnPage;
      const stopSlice =
        pagePosition * itemsOnPage > documentList.length
          ? documentList.length
          : pagePosition * itemsOnPage;

      setState({
        ...state,
        currentPage: pagePosition,
        currentList: documentList.slice(startSlice, stopSlice),
      });
    },
    [documentList, itemsOnPage, state]
  );

  const goNext = () => {
    if (isNext()) {
      changePage(state.currentPage + 1);
    }
  };

  const goBack = () => {
    if (isBack()) {
      changePage(state.currentPage - 1);
    }
  };

  const goLatest = () => {
    changePage(state.countPage);
  };

  const goFirst = () => {
    changePage(1);
  };

  const isNext = () => {
    return state.currentPage + 1 <= state.countPage;
  };

  const isBack = () => {
    return state.currentPage - 1 >= 1;
  };

  useEffect(() => {
    reset();
  }, [documentList]);

  return {
    ...state,
    changePage,
    goNext,
    goBack,
    goLatest,
    goFirst,
    isNext: isNext(),
    isBack: isBack(),
  };
}

export default usePagination;
